import { useTheme } from "@emotion/react";
import React, { PropsWithChildren } from "react";
import { ResourceType } from "../../constants/enums";
import { useCaseManagementStore } from "../context/CaseManagementStoreProvider";
import Box from "./Box";

interface Props {
  resourceID: string;
  resourceContext?: { forecast: number };
  resourceName: string;
  resourceType: ResourceType;
}

export default function ResourceSelector(props: PropsWithChildren<Props>) {
  const theme = useTheme();
  const caseManagementStore = useCaseManagementStore();

  if (caseManagementStore.isResourceSelectionMode === false) {
    if (props.children) {
      return <>{props.children}</>;
    }

    return null;
  }

  if (props.children) {
    return (
      <Box
        margin={theme.space_md}
        position="relative"
        height="100%"
        width="100%"
      >
        <Box
          backgroundColorOnHover={theme.resource_selector_background_hover}
          border={`5px dashed ${theme.resource_selector_border}`}
          borderOnHover={`5px solid ${theme.primary_color_focus}`}
          borderRadius={theme.borderRadius_3}
          cursor="pointer"
          height="98%"
          left="1%"
          position="absolute"
          top="1%"
          width="98%"
          zIndex={theme.zIndex_1600}
          onClick={() =>
            caseManagementStore.set({
              isSideDrawerOpen: true,
              isResourceSelectionMode: false,
              selectedResourceID: props.resourceID,
              selectedResourceName: props.resourceName,
              selectedResourceType: props.resourceType,
              ...(props.resourceContext
                ? { selectedResourceContext: props.resourceContext }
                : {}),
            })
          }
        />
        {props.children}
      </Box>
    );
  }

  return (
    <Box position="absolute" height="100%" width="100%">
      <Box
        backgroundColorOnHover={theme.resource_selector_background_hover}
        border={`5px dashed ${theme.resource_selector_border}`}
        borderOnHover={`5px solid ${theme.primary_color_focus}`}
        borderRadius={theme.borderRadius_3}
        cursor="pointer"
        height="100%"
        left="0"
        position="absolute"
        top="0"
        width="100%"
        zIndex={theme.zIndex_1600}
        onClick={() =>
          caseManagementStore.set({
            isSideDrawerOpen: true,
            isResourceSelectionMode: false,
            selectedResourceID: props.resourceID,
            selectedResourceName: props.resourceName,
            selectedResourceType: props.resourceType,
            ...(props.resourceContext
              ? { selectedResourceContext: props.resourceContext }
              : {}),
          })
        }
      >
        {props.children}
      </Box>
    </Box>
  );
}
