import Modal from "@/ui-lib/components/Modal";
import { TableLegacy } from "@/ui-lib/components/Table";
import TextInput from "@/ui-lib/components/TextInput";
import { DateRange } from "@/utils/dates";
import { useTheme } from "@emotion/react";
import { faFileExport, faSearch } from "@fortawesome/free-solid-svg-icons";
import Button from "@ternary/api-lib/ui-lib/components/Button";
import Box from "@ternary/web-ui-lib/components/Box";
import Flex from "@ternary/web-ui-lib/components/Flex";
import Icon from "@ternary/web-ui-lib/components/Icon";
import Text from "@ternary/web-ui-lib/components/Text";
import { formatDate } from "@ternary/web-ui-lib/utils/dates";
import { formatCurrency } from "@ternary/web-ui-lib/utils/formatNumber";
import prettyBytes from "pretty-bytes";
import React, { useMemo, useState } from "react";
import { CSVLink } from "react-csv";
import { Column } from "react-table";
import copyText from "../../copyText";
import { EBStore } from "../types";

interface Props {
  snapshots: EBStore[];
  date: DateRange;
  isLoading: boolean;
  onClose: () => void;
}

type TableData = {
  lineItemUsageAccountId: string;
  snapshotUsageBytes: number;
  totalCost: number;
  snapshotId: string;
};

export default function AWSStorageEBSSnapshotsTable(props: Props): JSX.Element {
  const theme = useTheme();
  const [searchText, setSearchText] = useState("");

  const subTableTitle =
    props.snapshots.length > 0
      ? `Snapshots ${props.snapshots[0].billPayerAccountId} / ${props.snapshots[0].region}`
      : null;

  const columns = useMemo(
    (): Column<TableData>[] => [
      {
        accessor: "snapshotId",
        align: "left",
        Header: copyText.ebsSubTableSnapshotGroupHeader_snapshotId,
        truncate: true,
        width: 125,
      },
      {
        accessor: "lineItemUsageAccountId",
        align: "center",
        Header: copyText.ebsSubTableSnapshotGroupHeader_lineItemUsageAccountId,
        truncate: true,
        width: 125,
      },
      {
        accessor: "snapshotUsageBytes",
        align: "center",
        Cell: ({ value }) => <>{prettyBytes(value)}</>,
        Header: copyText.ebsSubTableSnapshotGroupHeader_snapshotUsageBytes,
        sortDescFirst: false,
        width: 80,
      },
      {
        accessor: "totalCost",
        align: "right",
        Cell: ({ value: totalCost }) => (
          <>{formatCurrency({ number: totalCost })}</>
        ),
        Header: copyText.ebsSubTableSnapshotGroupHeader_totalCost,
        sortDescFirst: false,
        sortType: (rowA, rowB, columnID, desc) => {
          const a = rowA.original[columnID];
          const b = rowB.original[columnID];
          if (typeof a !== "number" && typeof b !== "number") return 0;
          if (typeof a !== "number") return desc ? -1 : 1;
          if (typeof b !== "number") return desc ? 1 : -1;

          return a === b ? 0 : a < b ? -1 : 1;
        },
        width: 80,
      },
    ],
    [props.snapshots]
  );

  const data: TableData[] = useMemo(() => {
    const tableData = props.snapshots.map((snapshot) => {
      return {
        lineItemUsageAccountId: snapshot.lineItemUsageAccountId,
        snapshotUsageBytes: snapshot.snapshotUsageBytes,
        totalCost: snapshot.totalCost,
        snapshotId: snapshot.volumeId.split("/")[1],
      };
    });
    return tableData;
  }, [props.snapshots]);

  const filteredData = useMemo(() => {
    if (searchText.length === 0) return data;

    return data.filter((snapshot) => {
      const str = searchText.toLowerCase();

      const snapshotName = snapshot.snapshotId.toLowerCase();
      const snapshotLineItemAccountID =
        snapshot.lineItemUsageAccountId.toLowerCase();

      return (
        snapshotName.includes(str) || snapshotLineItemAccountID.includes(str)
      );
    });
  }, [data, searchText]);

  return (
    <Modal isOpen showCloseButton onClose={props.onClose} minWidth={1100}>
      <Modal.Header>
        <Flex
          justifyContent="space-between"
          marginLeft={theme.space_sm}
          width="100%"
        >
          <Text fontSize={theme.h4_fontSize}>{subTableTitle}</Text>

          <Flex alignItems="center">
            <Box width={250} marginRight={theme.space_lg}>
              <TextInput
                disabled={!props.snapshots.length}
                iconEnd={
                  <Icon color={theme.text_color_secondary} icon={faSearch} />
                }
                placeholder={copyText.searchInputPlaceholder}
                size="medium"
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
              />
            </Box>

            <CSVLink
              data={getCSVData(filteredData).rows}
              headers={getCSVData(filteredData).headers}
              filename={`${subTableTitle}-${formatDate(
                new Date(),
                "MM-dd-yyyy"
              )}`}
            >
              <Button
                iconStart={<Icon color="inherit" icon={faFileExport} />}
                primary
                size="small"
                marginRight={theme.space_md}
              >
                {copyText.exportButtonLabel}
              </Button>
            </CSVLink>
          </Flex>
        </Flex>
      </Modal.Header>
      <Modal.Body>
        <Box width="100%" overflowX="auto">
          <TableLegacy
            columns={columns}
            data={filteredData}
            isLoading={props.isLoading}
            initialState={{ sortBy: [{ id: "totalCost", desc: true }] }}
            showPagination
            sortable
          />
        </Box>
      </Modal.Body>
    </Modal>
  );
}

const csvAccessors = [
  "snapshotId",
  "lineItemUsageAccountId",
  "snapshotUsageBytes",
  "totalCost",
] as const;

type CSVData = {
  headers: { key: string; label: string }[];
  rows: Record<string, string | number>[];
};

function getCSVData(data: TableData[]): CSVData {
  if (!data.length) {
    return { headers: [], rows: [] };
  }

  const rows = data.map((datum) => ({
    snapshotId: datum.snapshotId,
    lineItemUsageAccountId: datum.lineItemUsageAccountId,
    snapshotUsageBytes: datum.snapshotUsageBytes,
    totalCost: datum.totalCost,
  }));

  const headers = csvAccessors.map((csvAccessor) => {
    // ensure rows has a value for each accessor
    const key: keyof (typeof rows)[number] = csvAccessor;

    // ensure copyText has a value for each accessor
    const copyTextKey: keyof typeof copyText = `ebsSubTableSnapshotGroupHeader_${csvAccessor}`;
    const label = copyText[copyTextKey];

    return { key, label };
  });

  return { headers, rows };
}
