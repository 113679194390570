const queryKeys = {
  allDashboards: ["dashboards"] as const,
  dashboard: (dashboardID: string) =>
    [...queryKeys.allDashboards, dashboardID] as const,
  allReports: ["reports"] as const,
  datadogIntegration: ["integrations", "datadog"] as const,
  tenantDashboards: (tenantID: string) =>
    [...queryKeys.allDashboards, tenantID] as const,
  tenantReports: (tenantID: string) =>
    [...queryKeys.allReports, tenantID] as const,
};

export default queryKeys;
