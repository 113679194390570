import { useTheme } from "@emotion/react";
import React, { useCallback, useRef } from "react";
import { Customized } from "recharts";

type RechartsProps = {
  chartY?: number;
  isTooltipActive?: boolean;
  offset?: {
    bottom: number;
    brushBottom: number;
    height: number;
    left: number;
    right: number;
    top: number;
    width: number;
  };
  yAxisMap?: { [yAxisID: string]: { niceTicks?: number[] } | undefined };
};

type CollectorProps = {
  collect: (data: InternalData) => void;
  yAxisID: string;
};

type InternalData = {
  tickRange: [number, number];
  yRange: [number, number];
};

export function useTooltipCollector(
  yAxisID: string
): [JSX.Element, (cursorY: number) => number] {
  const dataRef = useRef<InternalData>({
    tickRange: [0, 0],
    yRange: [0, 0],
  });

  const element = (
    <Customized
      component={
        <Collector
          yAxisID={yAxisID}
          collect={(data) => {
            dataRef.current.tickRange = data.tickRange;
            dataRef.current.yRange = data.yRange;
          }}
        />
      }
    />
  );

  const getDataValueAtCursorY = useCallback((cursorY: number): number => {
    const [yMin, yMax] = dataRef.current.yRange;
    const [dataMin, dataMax] = dataRef.current.tickRange;
    const yPercent = 1 - (cursorY - yMin) / (yMax - yMin);
    const valueAtCursor = dataMin + yPercent * (dataMax - dataMin);
    return valueAtCursor;
  }, []);

  return [element, getDataValueAtCursorY];
}

function Collector(props: RechartsProps & CollectorProps) {
  if (!props.offset || !props.yAxisMap || !props.yAxisMap[props.yAxisID]) {
    return null;
  }

  const ticks = props.yAxisMap[props.yAxisID]?.niceTicks ?? [0, 0];
  const tickRange: [number, number] = [ticks[0], ticks[ticks.length - 1]];
  const yRange: [number, number] = [
    props.offset.top,
    props.offset.height + props.offset.top,
  ];

  props.collect({ tickRange, yRange });

  return null;
}

function HorizontalLine(props: RechartsProps) {
  const theme = useTheme();

  if (!props.isTooltipActive || !props.chartY || !props.offset) {
    return null;
  }

  return (
    <line
      pointerEvents="none"
      x1={props.offset.left}
      y1={props.chartY}
      x2={props.offset.left + props.offset.width}
      y2={props.chartY}
      strokeDasharray="3 3"
      stroke={theme.chart_cursor_line_color}
    />
  );
}

export function useHorizontalLine(): JSX.Element {
  return <Customized component={<HorizontalLine />} />;
}
