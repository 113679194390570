import { useQuery } from "@tanstack/react-query";
import { InternalApiClient } from "../../../api/internal/InternalApiClient";
import { useInternalApiClient } from "../../../api/internal/InternalApiClientProvider";
import { UseQueryOptions, UseQueryResult } from "../../../lib/react-query";

type RoleEntity = Awaited<ReturnType<InternalApiClient["getRoles"]>>[number];

export default function useGetRoles(
  options?: UseQueryOptions<RoleEntity[], Error>
): UseQueryResult<RoleEntity[], Error> {
  const client = useInternalApiClient();

  return useQuery({
    queryKey: ["roles"],
    queryFn: () => client.getRoles(),
    ...options,
  });
}
