import CoreAPIClientError from "@/api/core/CoreAPIClientError";
import { useCoreAPIClient } from "@/api/core/CoreAPIClientProvider";
import { useQuery } from "@tanstack/react-query";
import CoreAPIClient from "../../../api/core/CoreAPIClient";
import { UseQueryOptions, UseQueryResult } from "../../../lib/react-query";

type ManifestEntity = Awaited<
  ReturnType<CoreAPIClient["getMspBillingManifestsByTenantID"]>
>[0];

export function useGetMspBillingManifestsByTenantID(
  tenantID: string,
  options?: UseQueryOptions<ManifestEntity[], CoreAPIClientError>
): UseQueryResult<ManifestEntity[], CoreAPIClientError> {
  const client = useCoreAPIClient();

  return useQuery({
    queryKey: ["manifests", tenantID],
    queryFn: () => client.getMspBillingManifestsByTenantID(tenantID),
    ...options,
  });
}
