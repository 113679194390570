import { DateRange } from "@/utils/dates";
import getMergeState from "@/utils/getMergeState";
import { useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import {
  faCalendar,
  faChevronDown,
  faX,
} from "@fortawesome/free-solid-svg-icons";
import {
  getCubeDateRangeFromDurationType,
  getDateRangeFromLastNDays,
  getDateRangeFromLastNMonths,
  isValidRollingWindow,
} from "@ternary/api-lib/analytics/utils";
import {
  ChartType,
  CompareDurationType,
  DataSource,
  DurationType,
} from "@ternary/api-lib/constants/enums";
import Tooltip from "@ternary/api-lib/ui-lib/components/Tooltip";
import Flex from "@ternary/web-ui-lib/components/Flex";
import Icon from "@ternary/web-ui-lib/components/Icon";
import React, { useState } from "react";

import { actions } from "@ternary/api-lib/telemetry";
import Button from "@ternary/api-lib/ui-lib/components/Button";
import { noop } from "@ternary/api-lib/utils/ReportUtils";
import { formatDate } from "@ternary/web-ui-lib/utils/dates";
import { differenceInDays, startOfMonth, startOfYear, sub } from "date-fns";
import paths from "../../../constants/paths";
import { useActivityTracker } from "../../../context/ActivityTrackerProvider";
import useAvailableGlobalDate from "../../../hooks/useAvailableGlobalDate";
import { useMatchPath } from "../../../lib/react-router";
import copyText from "../../copyText";
import CompareDatePickerContent from "./CompareDatePickerContent";
import CustomDatePickerContent from "./CustomDatePickerContent";

const StyledButton = styled(Button)<{ isGrouped?: boolean }>(
  ({ isFirst, isGrouped, isLast, selected, theme }) => ({
    borderRadius: isGrouped && !isFirst && !isLast ? 0 : undefined,
    ...(isGrouped && isLast
      ? { borderBottomLeftRadius: 0, borderTopLeftRadius: 0 }
      : {}),
    ...(isGrouped && isFirst
      ? { borderBottomRightRadius: 0, borderTopRightRadius: 0 }
      : {}),
    paddingLeft: isGrouped ? theme.space_sm : theme.space_xs,
    paddingRight: isGrouped ? theme.space_sm : theme.space_xs,
    backgroundColor: selected
      ? theme.date_picker_selected_highlight
      : undefined,
  })
);

export interface Props {
  chartType?: ChartType;
  compareDateRange?: DateRange | null;
  compareDurationType?: CompareDurationType | null;
  dataSource?: DataSource;
  dateRange?: DateRange | null;
  disabled?: boolean;
  durationType: DurationType;
  hiddenOptions?: DurationType[];
  isFiscalMode?: boolean;
  isGlobalDateControls?: boolean;
  isGrouped?: boolean;
  maxDate?: Date;
  minDate?: Date;
  n?: number | null;
  showRollingLookback?: boolean;
  suspendCustomDates?: boolean;
  onChangeCompareDateRange?: (
    durationType: CompareDurationType | null,
    dateRange?: DateRange | null
  ) => void;
  onChangeDateRange: (
    durationType: DurationType,
    dateRange?: DateRange,
    isCompareChecked?: boolean
  ) => void;
  onChangeRollingLookback?: (
    durationType: DurationType,
    nLookback: number
  ) => void;
}

const initialState = {
  isInvoiceMonth: false,
  isCompareDropdownOpen: false,
  isCustomDropdownOpen: false,
  showCustomCompareDropdown: false,
};

const dateRangeText = {
  [DurationType.LAST_MONTH]: {
    default: copyText.dateRangeControlsLastMonthLabel_default,
  },
  [DurationType.LAST_NINETY_DAYS]: {
    default: copyText.dateRangeControlsLast90DaysLabel_default,
    fiscal: copyText.dateRangeControlsLast90DaysLabel_fiscal,
  },
  [DurationType.LAST_SEVEN_DAYS]: {
    default: copyText.dateRangeControlsLast7DaysLabel_default,
    fiscal: copyText.dateRangeControlsLast7DaysLabel_fiscal,
  },
  [DurationType.LAST_THIRTY_DAYS]: {
    default: copyText.dateRangeControlsLast30DaysLabel_default,
    fiscal: copyText.dateRangeControlsLast30DaysLabel_fiscal,
  },
  [DurationType.MONTH_TO_DATE]: {
    default: copyText.dateRangeControlsMTDLabel_default,
    fiscal: copyText.dateRangeControlsMTDLabel_fiscal,
  },
  [DurationType.QUARTER_TO_DATE]: {
    default: copyText.dateRangeControlsQTDLabel_default,
    fiscal: copyText.dateRangeControlsQTDLabel_fiscal,
  },
  [DurationType.YEAR_TO_DATE]: {
    default: copyText.dateRangeControlsYTDLabel_default,
    fiscal: copyText.dateRangeControlsYTDLabel_fiscal,
  },
};

const now = new Date();

// TODO: Replace Font Awesome with BB Icons
export default function DateRangeControls(props: Props): JSX.Element {
  const activityTracker = useActivityTracker();
  const theme = useTheme();
  const currentPath = useMatchPath();
  const globalDate = useAvailableGlobalDate();

  const [state, setState] = useState(initialState);
  const mergeState = getMergeState(setState);

  const durationType =
    !props.isGlobalDateControls && globalDate.date
      ? DurationType.CUSTOM
      : props.durationType;

  function handleChangeDurationType(durationType: DurationType) {
    if (
      props.onChangeCompareDateRange &&
      props.compareDurationType !== CompareDurationType.PREVIOUS_PERIOD
    ) {
      props.onChangeCompareDateRange(null, null);
    }

    activityTracker.captureAction(actions.SELECT_DURATION_TYPE);

    props.onChangeDateRange(durationType, undefined);
  }

  function handleChangeDateRange(
    dateRange: DateRange,
    isInvoiceMonth: boolean
  ) {
    if (
      props.onChangeCompareDateRange &&
      props.compareDurationType !== CompareDurationType.PREVIOUS_PERIOD
    ) {
      props.onChangeCompareDateRange(null, null);
    }

    if (isInvoiceMonth) {
      activityTracker.captureAction(actions.SELECT_CUSTOM_INVOICE_DATE_RANGE);
    } else {
      activityTracker.captureAction(actions.SELECT_CUSTOM_FIXED_DATE_RANGE);
    }

    props.onChangeDateRange(
      isInvoiceMonth ? DurationType.INVOICE : DurationType.CUSTOM,
      dateRange
    );
  }

  function handleChangeCompareDateRange(
    durationType: CompareDurationType,
    dateRange: DateRange | undefined
  ) {
    if (!props.onChangeCompareDateRange) return;

    if (durationType === CompareDurationType.PREVIOUS_PERIOD) {
      activityTracker.captureAction(actions.SELECT_COMPARE_PREVIOUS_PERIOD);
    } else {
      activityTracker.captureAction(actions.SELECT_COMPARE_CUSTOM_DATE_RANGE);
    }

    props.onChangeCompareDateRange(durationType, dateRange);
  }

  function handleChangeRollingLookback(
    durationType: DurationType,
    nLookback: number
  ) {
    if (!props.onChangeRollingLookback) return;

    if (props.onChangeCompareDateRange) {
      props.onChangeCompareDateRange(null, null);
    }

    activityTracker.captureAction(actions.SELECT_CUSTOM_WINDOW_DATE_RANGE);

    props.onChangeRollingLookback(durationType, nLookback);
  }

  const dateRange = getDateRange(props.durationType, props.dateRange, props.n);

  const compareDateRange = props.compareDateRange;

  const isDisabledDetailedBilling =
    props.dataSource === DataSource.DETAILED_BILLING || props.disabled
      ? true
      : false;

  const isDisabled =
    (!props.isGlobalDateControls && globalDate.date !== null) || props.disabled;

  const isHidden = (duration: DurationType) =>
    !props.hiddenOptions ? false : props.hiddenOptions.includes(duration);

  const textKey = props.isFiscalMode ? "fiscal" : "default";

  const showCompare =
    currentPath === paths._reportBuilder ||
    currentPath === paths._reportBuilderNew ||
    currentPath === paths._reports;

  const maxCompareStartDate = getMaxCompareStartDate(
    props.durationType,
    props.dateRange
  );

  let displayedDurationTypes: DurationType[] = [
    DurationType.LAST_MONTH,
    DurationType.YESTERDAY,
    DurationType.LAST_SEVEN_DAYS,
    DurationType.LAST_THIRTY_DAYS,
    DurationType.LAST_NINETY_DAYS,
    DurationType.MONTH_TO_DATE,
    DurationType.QUARTER_TO_DATE,
    DurationType.YEAR_TO_DATE,
    DurationType.CUSTOM,
  ];

  displayedDurationTypes = displayedDurationTypes.filter(
    (durationType) => !isHidden(durationType)
  );

  const displayedOptions: (DurationType | CompareDurationType)[] = [
    ...displayedDurationTypes,
  ];

  if (showCompare) {
    displayedOptions.push(CompareDurationType.PREVIOUS_PERIOD);
  }

  return (
    <Flex alignItems="center">
      <Flex alignItems="center">
        {/* Last Month */}
        {!isHidden(DurationType.LAST_MONTH) && (
          <StyledButton
            disabled={isDisabled}
            isFirst={displayedOptions.indexOf(DurationType.LAST_MONTH) === 0}
            isGrouped={props.isGrouped}
            isLast={
              displayedOptions.indexOf(DurationType.LAST_MONTH) ===
              displayedOptions.length - 1
            }
            marginLeft={props.isGrouped ? undefined : theme.space_xxs}
            secondary={
              durationType === DurationType.LAST_MONTH &&
              !state.isCustomDropdownOpen
            }
            selected={props.durationType === DurationType.LAST_MONTH}
            size="small"
            onClick={() => handleChangeDurationType(DurationType.LAST_MONTH)}
          >
            {copyText.dateRangeControlsLastMonthLabel_default}
          </StyledButton>
        )}
        {/* Yesterday */}
        {!isHidden(DurationType.YESTERDAY) && (
          <StyledButton
            disabled={isDisabled}
            isFirst={displayedOptions.indexOf(DurationType.YESTERDAY) === 0}
            isGrouped={props.isGrouped}
            isLast={
              displayedOptions.indexOf(DurationType.YESTERDAY) ===
              displayedOptions.length - 1
            }
            marginLeft={props.isGrouped ? undefined : theme.space_xxs}
            secondary={
              durationType === DurationType.YESTERDAY &&
              !state.isCustomDropdownOpen
            }
            selected={props.durationType === DurationType.YESTERDAY}
            size="small"
            onClick={() => handleChangeDurationType(DurationType.YESTERDAY)}
          >
            {copyText.dateRangeControlsYesterdayLabel}
          </StyledButton>
        )}
        {/* 7 Day */}
        {!isHidden(DurationType.LAST_SEVEN_DAYS) && (
          <StyledButton
            disabled={isDisabled}
            isFirst={
              displayedOptions.indexOf(DurationType.LAST_SEVEN_DAYS) === 0
            }
            isGrouped={props.isGrouped}
            isLast={
              displayedOptions.indexOf(DurationType.LAST_SEVEN_DAYS) ===
              displayedOptions.length - 1
            }
            marginLeft={props.isGrouped ? undefined : theme.space_xxs}
            secondary={
              durationType === DurationType.LAST_SEVEN_DAYS &&
              !state.isCustomDropdownOpen
            }
            selected={props.durationType === DurationType.LAST_SEVEN_DAYS}
            size="small"
            onClick={() =>
              handleChangeDurationType(DurationType.LAST_SEVEN_DAYS)
            }
          >
            {dateRangeText[DurationType.LAST_SEVEN_DAYS][textKey]}
          </StyledButton>
        )}
        {/* 30 Day */}
        {!isHidden(DurationType.LAST_THIRTY_DAYS) && (
          <StyledButton
            disabled={isDisabled}
            isFirst={
              displayedOptions.indexOf(DurationType.LAST_THIRTY_DAYS) === 0
            }
            isGrouped={props.isGrouped}
            isLast={
              displayedOptions.indexOf(DurationType.LAST_THIRTY_DAYS) ===
              displayedOptions.length - 1
            }
            marginLeft={props.isGrouped ? undefined : theme.space_xxs}
            secondary={
              durationType === DurationType.LAST_THIRTY_DAYS &&
              !state.isCustomDropdownOpen
            }
            selected={props.durationType === DurationType.LAST_THIRTY_DAYS}
            size="small"
            onClick={() =>
              handleChangeDurationType(DurationType.LAST_THIRTY_DAYS)
            }
          >
            {dateRangeText[DurationType.LAST_THIRTY_DAYS][textKey]}
          </StyledButton>
        )}
        {/* 90 Day */}
        {!isHidden(DurationType.LAST_NINETY_DAYS) && (
          <StyledButton
            disabled={isDisabledDetailedBilling || isDisabled}
            isFirst={
              displayedOptions.indexOf(DurationType.LAST_NINETY_DAYS) === 0
            }
            isGrouped={props.isGrouped}
            isLast={
              displayedOptions.indexOf(DurationType.LAST_NINETY_DAYS) ===
              displayedOptions.length - 1
            }
            marginLeft={props.isGrouped ? undefined : theme.space_xxs}
            secondary={
              durationType === DurationType.LAST_NINETY_DAYS &&
              !state.isCustomDropdownOpen
            }
            selected={props.durationType === DurationType.LAST_NINETY_DAYS}
            size="small"
            onClick={() =>
              handleChangeDurationType(DurationType.LAST_NINETY_DAYS)
            }
          >
            <Tooltip
              hide={props.dataSource !== DataSource.DETAILED_BILLING}
              content={copyText.disableDateControlTooltipCaption}
            >
              {dateRangeText[DurationType.LAST_NINETY_DAYS][textKey]}
            </Tooltip>
          </StyledButton>
        )}
        {/* MTD */}
        {!isHidden(DurationType.MONTH_TO_DATE) && (
          <StyledButton
            disabled={
              isDisabled || (isDisabledDetailedBilling && props.isFiscalMode)
            }
            isFirst={displayedOptions.indexOf(DurationType.MONTH_TO_DATE) === 0}
            isGrouped={props.isGrouped}
            isLast={
              displayedOptions.indexOf(DurationType.MONTH_TO_DATE) ===
              displayedOptions.length - 1
            }
            marginLeft={props.isGrouped ? undefined : theme.space_xxs}
            secondary={
              durationType === DurationType.MONTH_TO_DATE &&
              !state.isCustomDropdownOpen
            }
            selected={props.durationType === DurationType.MONTH_TO_DATE}
            size="small"
            onClick={() => handleChangeDurationType(DurationType.MONTH_TO_DATE)}
          >
            <Tooltip
              hide={
                props.dataSource !== DataSource.DETAILED_BILLING ||
                !props.isFiscalMode
              }
              content={copyText.disableDateControlTooltipCaption}
            >
              {dateRangeText[DurationType.MONTH_TO_DATE][textKey]}
            </Tooltip>
          </StyledButton>
        )}
        {/* QTD */}
        {!isHidden(DurationType.QUARTER_TO_DATE) && (
          <StyledButton
            disabled={
              isDisabled || (isDisabledDetailedBilling && props.isFiscalMode)
            }
            isFirst={
              displayedOptions.indexOf(DurationType.QUARTER_TO_DATE) === 0
            }
            isGrouped={props.isGrouped}
            isLast={
              displayedOptions.indexOf(DurationType.QUARTER_TO_DATE) ===
              displayedOptions.length - 1
            }
            marginLeft={props.isGrouped ? undefined : theme.space_xxs}
            secondary={
              durationType === DurationType.QUARTER_TO_DATE &&
              !state.isCustomDropdownOpen
            }
            selected={props.durationType === DurationType.QUARTER_TO_DATE}
            size="small"
            onClick={() =>
              handleChangeDurationType(DurationType.QUARTER_TO_DATE)
            }
          >
            <Tooltip
              hide={
                props.dataSource !== DataSource.DETAILED_BILLING ||
                !props.isFiscalMode
              }
              content={copyText.disableDateControlTooltipCaption}
            >
              {dateRangeText[DurationType.QUARTER_TO_DATE][textKey]}
            </Tooltip>
          </StyledButton>
        )}
        {/* YTD */}
        {!isHidden(DurationType.YEAR_TO_DATE) && (
          <StyledButton
            disabled={isDisabledDetailedBilling || isDisabled}
            isFirst={displayedOptions.indexOf(DurationType.YEAR_TO_DATE) === 0}
            isGrouped={props.isGrouped}
            isLast={
              displayedOptions.indexOf(DurationType.YEAR_TO_DATE) ===
              displayedOptions.length - 1
            }
            marginLeft={props.isGrouped ? undefined : theme.space_xxs}
            secondary={
              durationType === DurationType.YEAR_TO_DATE &&
              !state.isCustomDropdownOpen
            }
            selected={props.durationType === DurationType.YEAR_TO_DATE}
            size="small"
            onClick={() => handleChangeDurationType(DurationType.YEAR_TO_DATE)}
          >
            <Tooltip
              hide={props.dataSource !== DataSource.DETAILED_BILLING}
              content={copyText.disableDateControlTooltipCaption}
            >
              {dateRangeText[DurationType.YEAR_TO_DATE][textKey]}
            </Tooltip>
          </StyledButton>
        )}
        {/* Custom */}
        {!isHidden(DurationType.CUSTOM) && (
          <CustomDatePickerContent
            n={props.n}
            dateRange={dateRange}
            dataSource={props.dataSource}
            disabled={isDisabled}
            durationType={props.durationType}
            isOpen={state.isCustomDropdownOpen}
            maxDate={props.maxDate}
            minDate={props.minDate}
            showRollingLookback={props.showRollingLookback}
            showInvoice={!isHidden(DurationType.INVOICE)}
            onChangeRollingWindow={handleChangeRollingLookback}
            onChangeDateRange={handleChangeDateRange}
            onClose={() =>
              mergeState({ isInvoiceMonth: false, isCustomDropdownOpen: false })
            }
            onOpen={() =>
              mergeState({
                isCustomDropdownOpen: true,
                isCompareDropdownOpen: false,
              })
            }
          >
            <StyledButton
              disabled={isDisabled}
              iconStart={<Icon icon={faCalendar} />}
              isFirst={displayedOptions.indexOf(DurationType.CUSTOM) === 0}
              isGrouped={props.isGrouped}
              isLast={
                displayedOptions.indexOf(DurationType.CUSTOM) ===
                displayedOptions.length - 1
              }
              marginLeft={props.isGrouped ? undefined : theme.space_xxs}
              secondary={
                state.isCustomDropdownOpen ||
                props.durationType === DurationType.CUSTOM ||
                props.durationType === DurationType.INVOICE ||
                props.durationType === DurationType.LAST_N_DAYS ||
                props.durationType === DurationType.LAST_N_MONTHS
              }
              selected={
                props.durationType === DurationType.CUSTOM ||
                props.durationType === DurationType.INVOICE ||
                props.durationType === DurationType.LAST_N_DAYS ||
                props.durationType === DurationType.LAST_N_MONTHS
              }
              size="small"
            >
              {getCustomTagLabel(dateRange, durationType, props.n)}
            </StyledButton>
          </CustomDatePickerContent>
        )}
        {/* Compare Date */}
        {showCompare && !props.isGlobalDateControls && (
          <Tooltip
            content={copyText.disableCompareToggleTooltipCaption}
            hide={
              props.chartType === ChartType.KPI ||
              props.chartType === ChartType.LINE ||
              props.chartType === ChartType.TABLE
            }
          >
            <CompareDatePickerContent
              n={props.n}
              compareDurationType={props.compareDurationType ?? undefined}
              dateRange={compareDateRange}
              disabled={
                props.chartType !== ChartType.KPI &&
                props.chartType !== ChartType.LINE &&
                props.chartType !== ChartType.TABLE
              }
              durationType={durationType ?? undefined}
              isOpen={state.isCompareDropdownOpen}
              maxDate={maxCompareStartDate}
              minDate={props.minDate}
              numberOfDaysInRange={differenceInDays(dateRange[1], dateRange[0])}
              onChangeDateRange={handleChangeCompareDateRange}
              onClose={() =>
                mergeState({
                  isInvoiceMonth: false,
                  isCompareDropdownOpen: false,
                })
              }
              onOpen={() =>
                mergeState({
                  isCompareDropdownOpen: true,
                  isCustomDropdownOpen: false,
                })
              }
            >
              <StyledButton
                disabled={
                  props.chartType !== ChartType.KPI &&
                  props.chartType !== ChartType.LINE &&
                  props.chartType !== ChartType.TABLE
                }
                iconEnd={
                  <Icon
                    icon={
                      !!props.compareDurationType || !!props.compareDateRange
                        ? faX
                        : faChevronDown
                    }
                    onClick={
                      !!props.compareDurationType || !!props.compareDateRange
                        ? (e) => {
                            e.stopPropagation();
                            activityTracker.captureAction(
                              actions.REMOVE_COMPARE_DATE_RANGE
                            );
                            if (!props.onChangeCompareDateRange) return;
                            props.onChangeCompareDateRange(null, null);
                          }
                        : noop
                    }
                  />
                }
                isFirst={
                  displayedOptions.indexOf(
                    CompareDurationType.PREVIOUS_PERIOD
                  ) === 0
                }
                isGrouped={props.isGrouped}
                isLast={
                  displayedOptions.indexOf(
                    CompareDurationType.PREVIOUS_PERIOD
                  ) ===
                  displayedOptions.length - 1
                }
                marginLeft={props.isGrouped ? undefined : theme.space_xxs}
                secondary={
                  state.isCompareDropdownOpen ||
                  !!props.compareDateRange ||
                  !!props.compareDurationType
                }
                selected={!!props.compareDurationType}
                size="small"
              >
                {getCompareTagLabel(
                  props.compareDateRange,
                  props.compareDurationType
                )}
              </StyledButton>
            </CompareDatePickerContent>
          </Tooltip>
        )}
      </Flex>
    </Flex>
  );
}

function getCompareTagLabel(
  compareDateRange: Date[] | null | undefined,
  compareDurationType: CompareDurationType | undefined | null
) {
  if (!compareDurationType) {
    return copyText.dateRangeControlsCompareLabel;
  }

  switch (compareDurationType) {
    case CompareDurationType.CUSTOM: {
      if (!compareDateRange) {
        return;
      }
      return `${copyText.dateRangeControlsCompareLabel}: ${formatDate(compareDateRange[0], "MM/dd/yyyy")} - ${formatDate(compareDateRange[1], "MM/dd/yyyy")}`;
    }
    case CompareDurationType.INVOICE: {
      if (!compareDateRange) {
        return;
      }
      return `${copyText.dateRangeControlsCompareLabel}: ${formatDate(compareDateRange[0], "MM/yyyy")} - ${formatDate(compareDateRange[1], "MM/yyyy")}`;
    }
    case CompareDurationType.PREVIOUS_PERIOD: {
      return `${copyText.dateRangeControlsCompareLabel}: ${copyText.datePickerComparePreviousPeriodOption}`;
    }
  }
}

function getDateRange(
  durationType: DurationType,
  dateRange?: DateRange | null,
  nLookback?: number | null
): DateRange {
  if (
    durationType === DurationType.LAST_N_DAYS &&
    isValidRollingWindow(nLookback)
  ) {
    return getDateRangeFromLastNDays({ nLookback });
  }

  if (
    durationType === DurationType.LAST_N_MONTHS &&
    isValidRollingWindow(nLookback)
  ) {
    return getDateRangeFromLastNMonths({ nLookback });
  }

  if (!dateRange) {
    return getCubeDateRangeFromDurationType(durationType);
  }

  return [dateRange[0], dateRange[1]];
}

function getCustomTagLabel(
  dateRange: Date[] | null | undefined,
  durationType: DurationType,
  n: number | null | undefined
) {
  if (!dateRange) return copyText.dateRangeControlsCustomLabel;
  if (durationType === DurationType.CUSTOM) {
    return `${copyText.dateRangeControlsCustomLabel}: ${formatDate(dateRange[0], "MM/dd/yyyy")} - ${formatDate(dateRange[1], "MM/dd/yyyy")}`;
  } else if (durationType === DurationType.INVOICE) {
    return `${copyText.dateRangeControlsCustomLabel}: ${formatDate(dateRange[0], "MM/yyyy")} - ${formatDate(dateRange[1], "MM/yyyy")}`;
  } else if (durationType === DurationType.LAST_N_DAYS) {
    return `${copyText.dateRangeControlsCustomLabel}: ${copyText.dateRangeControlsRollingDaysLabel.replace("%n%", n?.toString() ?? "")}`;
  } else if (durationType === DurationType.LAST_N_MONTHS) {
    return `${copyText.dateRangeControlsCustomLabel}: ${copyText.dateRangeControlsRollingMonthsLabel.replace("%n%", n?.toString() ?? "")}`;
  } else {
    return copyText.dateRangeControlsCustomLabel;
  }
}

function getMaxCompareStartDate(
  durationType: DurationType,
  dateRange: DateRange | null | undefined
) {
  let numberOfDaysInRange = 0;
  switch (durationType) {
    case DurationType.YESTERDAY: {
      numberOfDaysInRange = 1;
      break;
    }
    case DurationType.LAST_SEVEN_DAYS: {
      numberOfDaysInRange = 7;
      break;
    }
    case DurationType.LAST_THIRTY_DAYS: {
      numberOfDaysInRange = 30;
      break;
    }
    case DurationType.LAST_NINETY_DAYS: {
      numberOfDaysInRange = 90;
      break;
    }
    case DurationType.MONTH_TO_DATE: {
      return startOfMonth(now);
      break;
    }
    case DurationType.YEAR_TO_DATE: {
      numberOfDaysInRange = differenceInDays(now, startOfYear(now));
      break;
    }
    default:
      if (!dateRange) {
        return;
      }
      numberOfDaysInRange = differenceInDays(dateRange[1], dateRange[0]);
  }

  return sub(now, { days: numberOfDaysInRange });
}
