export interface EventReporterConfig {
  devMode: boolean;
  enableMixpanel: boolean;
  enableSentry: boolean;
}

export interface EventReporter {
  reportError(error: unknown): void;
  reportEvent(name: string, properties: object): void;
  reportMessage(message: string, context?: object): void;
}

export const actions = {
  CHANGE_FISCAL_MODE: "CHANGE_FISCAL_MODE",
  CHANGE_GROUPING_COST_REPORTS: "CHANGE_GROUPING_COST_REPORTS",
  CHANGE_REPORT_CHART_TYPE: "CHANGE_REPORT_CHART_TYPE",
  CHANGE_THEME: "CHANGE_THEME",
  CHANGE_UBERGRAPH_DATE_RANGE: "CHANGE_UBERGRAPH_DATE_RANGE",
  CHANGE_AWS_SAVINGS_PLAN_TYPE: "CHANGE_AWS_SAVINGS_PLAN_TYPE",
  CHANGE_AWS_SERVICE_TYPE: "CHANGE_AWS_SERVICE_TYPE",
  CLICK_LEGEND_TRE: "CLICK_LEGEND_TRE",
  CLICK_SHARE_TOP_NAV: "CLICK_SHARE_TOP_NAV",
  COPY_RAMP_PLAN: "COPY_RAMP_PLAN",
  CREATE_ALERT_RULE: "CREATE_ALERT_RULE",
  CREATE_BILL: "CREATE_BILL",
  CREATE_CASE_ACTIVITY: "CREATE_CASE_ACTIVITY",
  CREATE_CASE: "CREATE_CASE",
  CREATE_CASE_ANOMALY_MENU: "CREATE_CASE_ANOMALY_MENU",
  CREATE_CASE_ANOMALY_DETAIL_MODAL: "CREATE_CASE_ANOMALY_DETAIL_MODAL",
  CREATE_CUSTOM_LABEL: "CREATE_CUSTOM_LABEL",
  CREATE_INSIGHTS_CASE: "CREATE_INSIGHTS_CASE",
  CREATE_LABEL_GROUPING_RULE: "CREATE_LABEL_GROUPING_RULE",
  CREATE_LABEL_PREFERENCES: "CREATE_LABEL_PREFERENCES",
  CREATE_MEASURE_PREFERENCES: "CREATE_MEASURE_PREFERENCES",
  CREATE_MSP_BILLING_INFO: "CREATE_MSP_BILLING_INFO",
  CREATE_MSP_BILLING_RULE: "CREATE_MSP_BILLING_RULE",
  CREATE_MSP_BILLING_RULE_FORM_OPENED: "CREATE_MSP_BILLING_RULE_FORM_OPENED",
  CREATE_RAMP_PLAN: "CREATE_RAMP_PLAN",
  CREATE_REALLOCATION: "CREATE_REALLOCATION",
  CREATE_TRE_DASHBOARD: "CREATE_TRE_DASHBOARD",
  CREATE_TRE_REPORT: "CREATE_TRE_REPORT",
  CSV_EXPORT_LIMIT_REACHED: "CSV_EXPORT_LIMIT_REACHED",
  CUD_VIEW_TYPE_TOGGLED: "CUD_VIEW_TYPE_TOGGLED",
  DASHBOARD_FAVORITE_BUTTON: "DASHBOARD_FAVORITE_BUTTON",
  DELETE_BILL: "DELETE_BILL",
  DELETE_CUSTOM_LABEL: "DELETE_CUSTOM_LABEL",
  DELETE_LABEL_GROUPING_RULE: "DELETE_LABEL_GROUPING_RULE",
  DELETE_MSP_BILLING_INFO: "DELETE_MSP_BILLING_INFO",
  DELETE_RAMP_PLAN: "DELETE_RAMP_PLAN",
  DELETE_TRE_DASHBOARD: "DELETE_TRE_DASHBOARD",
  DELETE_TRE_REPORT: "DELETE_TRE_REPORT",
  DELETE_TRE_REPORT_FROM_TABLE: "DELETE_TRE_REPORT_FROM_TABLE",
  DOWNLOAD_CSV_RAMP_PLAN: "DOWNLOAD_CSV_RAMP_PLAN",
  DRILL_DOWN_KUBERNETES: "DRILL_DOWN_KUBERNETES",
  DRILL_DOWN_UBERGRAPH: "DRILL_DOWN_UBERGRAPH",
  ENV_NOT_AVAILABLE: "ENV_NOT_AVAILABLE",
  FORECASTING_CHART_COLLAPSE: "FORECASTING_CHART_COLLAPSE",
  FORECASTING_CHART_EXPAND: "FORECASTING_CHART_EXPAND",
  FORECASTING_EXPORT: "FORECASTING_EXPORT",
  FORECASTING_GROUPINGS_SELECT: "FORECASTING_GROUPINGS_SELECT",
  FORECASTING_LOOKBACK_RANGE_SELECT: "FORECASTING_LOOKBACK_RANGE_SELECT",
  FORECASTING_MEASURE_SELECT: "FORECASTING_MEASURE_SELECT",
  FORECASTING_CHART_OPTION_SELECT: "FORECASTING_CHART_OPTION_SELECT",
  FORECASTING_VENDOR_SELECT: "FORECASTING_VENDOR_SELECT",
  MSP_BILLING_RULES_FILTERED_BY_TENANT: "MSP_BILLING_RULES_FILTERED_BY_TENANT",
  MSP_DASHBOARD_CLOUD_PROVIDER_FILTER: "MSP_DASHBOARD_CLOUD_PROVIDER_FILTER",
  MSP_DASHBOARD_CLOUD_STATUS_FILTER: "MSP_DASHBOARD_CLOUD_STATUS_FILTER",
  MUTE_ADMIN_SCOPED_VIEW: "MUTE_ADMIN_SCOPED_VIEW",
  OPEN_ALERT_EVENT_FEED: "OPEN_ALERT_EVENT_FEED",
  OPEN_CREATE_FORM_RAMP_PLAN: "OPEN_CREATE_FORM_RAMP_PLAN",
  REMOVE_COMPARE_DATE_RANGE: "REMOVE_COMPARE_DATE_RANGE",
  SIDE_NAV_ITEM_SELECT: "SIDE_NAV_ITEM_SELECT",
  SCOPED_VIEW_CREATED_IN_GLOBAL_MODAL: "SCOPED_VIEW_CREATED_IN_GLOBAL_MODAL",
  SELECT_ALERT_EVENT_TO_DISPLAY: "SELECT_ALERT_EVENT_TO_DISPLAY",
  SELECT_ALERT_TO_INVESTIGATE_IN_TRE: "SELECT_ALERT_TO_INVESTIGATE_IN_TRE",
  SELECT_COMPARE_PREVIOUS_PERIOD: "SELECT_COMPARE_PREVIOUS_PERIOD",
  SELECT_COMPARE_CUSTOM_DATE_RANGE: "SELECT_COMPARE_CUSTOM_DATE_RANGE",
  SELECT_CUSTOM_FIXED_DATE_RANGE: "SELECT_CUSTOM_FIXED_DATE_RANGE",
  SELECT_CUSTOM_INVOICE_DATE_RANGE: "SELECT_CUSTOM_INVOICE_DATE_RANGE",
  SELECT_CUSTOM_WINDOW_DATE_RANGE: "SELECT_CUSTOM_WINDOW_DATE_RANGE",
  SELECT_DURATION_TYPE: "SELECT_DURATION_TYPE",
  SELECT_MSP_ALERT_TO_INVESTIGATE_IN_TRE:
    "SELECT_MSP_ALERT_TO_INVESTIGATE_IN_TRE",
  SELECT_MSP_BILLING_INFO: "SELECT_MSP_BILLING_INFO",
  SELECT_MSP_DASHBOARD_TENANT_FILTER: "SELECT_MSP_DASHBOARD_TENANT_FILTER",
  SELECT_RAMP_PLAN_TO_DISPLAY: "SELECT_RAMP_PLAN_TO_DISPLAY",
  SELECT_RECOMMENDATION: "SELECT_RECOMMENDATION",
  SELECT_TRE_SOURCE: "SELECT_TRE_SOURCE",
  SUBSCRIBE_DASHBAORD_FROM_TABLE: "SUBSCRIBE_DASHBOARD_FROM_TABLE",
  SUBSCRIBE_TRE_REPORT_FROM_TABLE: "SUBSCRIBE_TRE_REPORT_FROM_TABLE",
  TOGGLE_CHART_LEGEND_ITEM: "TOGGLE_CHART_LEGEND_ITEM",
  TOGGLE_REPORT_CREDIT_TYPES: "TOGGLE_REPORT_CREDIT_TYPES",
  TRE_SELECT_DIMENSION_NAME: "TRE_SELECT_DIMENSION_NAME",
  TRE_SELECT_FILTER_NAME: "TRE_SELECT_FILTER_NAME",
  TRE_SELECT_MEASURE_NAME: "TRE_SELECT_MEASURE_NAME",
  TRIGGER_REALLOCATION: "TRIGGER_REALLOCATION",
  UNVERIFIED_EMAIL: "UNVERIFIED_EMAIL",
  UPDATE_ALERT_RULE: "UPDATE_ALERT_RULE",
  UPDATE_ANOMALY: "UPDATE_ANOMALY",
  UPDATE_CASE: "UPDATE_CASE",
  UPDATE_CUSTOM_LABEL: "UPDATE_CUSTOM_LABEL",
  UPDATE_LABEL_GROUPING_RULE: "UPDATE_LABEL_GROUPING_RULE",
  UPDATE_LABEL_PREFERENCES: "UPDATE_LABEL_PREFERENCES",
  VIEW_TRE_REPORT_BUILDER: "VIEW_TRE_REPORT_BUILDER",
  VIEW_TRE_REPORT_DASHBOARD: "VIEW_TRE_REPORT_DASHBOARD",
  VIEW_TRE_REPORT_FROM_TABLE: "VIEW_TRE_REPORT_FROM_TABLE",
  UPDATE_MEASURE_PREFERENCES: "UPDATE_MEASURE_PREFERENCES",
  UPDATE_NOTIFICATION_PREFERENCES: "UPDATE_NOTIFICATION_PREFERENCES",
  UPDATE_RAMP_PLAN: "UPDATE_RAMP_PLAN",
  UPDATE_REALLOCATION: "UPDATE_REALLOCATION",
  UPDATE_RECOMMENDATION: "UPDATE_RECOMMENDATION",
  UPDATE_REPORT_TAGS: "UPDATE_REPORT_TAGS",
  UPDATE_SETTINGS_NOTIFICATION_PREFERENCES:
    "UPDATE_SETTINGS_NOTIFICATION_PREFERENCES",
  UPDATE_TRE_DASHBOARD: "UPDATE_TRE_DASHBOARD",
  UPDATE_TRE_REPORT: "UPDATE_TRE_REPORT",
  UPDATE_TRE_REPORT_FROM_TABLE: "UPDATE_TRE_REPORT_FROM_TABLE",
  UPLOAD_IMAGE: "UPLOAD_IMAGE",
} as const;

export type Action = (typeof actions)[keyof typeof actions];

export interface ActivityTracker {
  captureAction(action: Action, properties?: Record<string, unknown>): void;
}
