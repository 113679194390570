import useGatekeeper from "@/hooks/useGatekeeper";
import Dropdown, { Option } from "@/ui-lib/components/Dropdown";
import LoadingSpinner from "@/ui-lib/components/LoadingSpinner";
import IconExclamationDiamond from "@/ui-lib/icons/IconExclamationDiamond";
import IconGCP from "@/ui-lib/icons/IconGCP";
import { useTheme } from "@emotion/react";
import { faEllipsisV } from "@fortawesome/free-solid-svg-icons";
import {
  CloudCapability,
  DataSource,
  DataStatusType,
} from "@ternary/api-lib/constants/enums";
import Button from "@ternary/api-lib/ui-lib/components/Button";
import Tooltip from "@ternary/api-lib/ui-lib/components/Tooltip";
import Box from "@ternary/web-ui-lib/components/Box";
import Flex from "@ternary/web-ui-lib/components/Flex";
import Icon from "@ternary/web-ui-lib/components/Icon";
import Text from "@ternary/web-ui-lib/components/Text";
import React from "react";
import copyText from "../copyText";
import DataStatusTooltip from "./DataStatusTooltip";

type GCPIntegration = {
  id: string;
  config: {
    bigQueryMonitoring: { projectID: string }[] | null;
    billingAccountID: string | null;
    billingExportSource: { tableID: string } | null;
    detailedBillingExportSource: { tableID: string } | null;
    rootElement: string | null;
  };
  dataStatus: {
    [DataSource.BILLING]: {
      latestRefresh: string | null;
      latestUpstreamCheck: string | null;
      latestUpstreamTimestamp: string | null;
      performanceMode: DataStatusType;
    };
  };
  name: string;
  validations: { error: string; name: string; success: boolean }[];
};

interface Props {
  integration: GCPIntegration;
  isProcessing: boolean;
  onInteraction: (interaction: GCPIntegrationCard.Interaction) => void;
}

export function GCPIntegrationCard(props: Props): JSX.Element | null {
  const theme = useTheme();
  const gatekeeper = useGatekeeper();

  const options: Option[] = [];

  if (gatekeeper.canUpdateDataIntegration) {
    options.push(
      {
        label: copyText.actionMenuItemEditCloud,
        onClick: () =>
          props.onInteraction({
            type: GCPIntegrationCard.INTERACTION_EDIT_BUTTON_CLICKED,
            integrationID: props.integration.id,
          }),
      },
      {
        label: props.isProcessing ? (
          <Flex alignItems="center" justifyContent="space-between">
            <Text>{copyText.actionMenuItemValidateCloud}</Text>
            <LoadingSpinner />
          </Flex>
        ) : (
          copyText.actionMenuItemValidateCloud
        ),
        locked: props.isProcessing,
        onClick: () =>
          props.onInteraction({
            type: GCPIntegrationCard.INTERACTION_REFRESH_BUTTON_CLICKED,
            integrationID: props.integration.id,
          }),
      }
    );
  }

  if (gatekeeper.canDeleteDataIntegration) {
    options.push({
      label: copyText.actionMenuItemDeleteCloud,
      onClick: () =>
        props.onInteraction({
          type: GCPIntegrationCard.INTERACTION_DELETE_BUTTON_CLICKED,
          integrationID: props.integration.id,
        }),
    });
  }

  function Detail({
    label,
    validationName,
    value,
  }: {
    label: string;
    validationName?: CloudCapability;
    value: string;
  }): JSX.Element {
    const validation = props.integration.validations.find(
      (validation) => validation.name === validationName
    );

    const error = validation?.error ? validation?.error : null;

    return (
      <Box marginBottom={theme.space_md}>
        <Flex alignItems="center">
          <Text bold marginRight={theme.space_xxs}>
            {label}
          </Text>
          {error && (
            <Tooltip
              content={
                <Flex maxWidth={500} minWidth={325}>
                  {error}
                </Flex>
              }
            >
              <IconExclamationDiamond size="13px" />
            </Tooltip>
          )}
        </Flex>
        <Text color={error ? theme.feedback_negative : undefined}>
          {value || copyText.notSpecifiedCaption}
        </Text>
      </Box>
    );
  }

  return (
    <Flex
      backgroundColor={theme.panel_backgroundColor}
      borderRadius={theme.borderRadius_2}
      direction="column"
      justifyContent="space-between"
      marginBottom={theme.space_lg}
      marginRight={theme.space_lg}
      padding={theme.space_md}
      width={360}
      height={360}
      scrollable
    >
      <Box>
        <Flex
          alignItems="center"
          justifyContent="space-between"
          marginBottom={theme.space_sm}
        >
          <Flex alignItems="center">
            <Flex
              alignItems="center"
              backgroundColor="white"
              border={`1px solid ${theme.border_color}`}
              borderRadius="50%"
              height={48}
              marginRight={theme.space_sm}
              justifyContent="center"
              width={48}
            >
              <IconGCP size={36} />
            </Flex>
            <Text fontSize={theme.h4_fontSize}>{props.integration.name}</Text>
            <DataStatusTooltip
              latestRefresh={props.integration.dataStatus.BILLING.latestRefresh}
              latestUpstream={
                props.integration.dataStatus.BILLING.latestUpstreamTimestamp
              }
            />
          </Flex>
          <Dropdown options={options} placement="bottom-end">
            <Button iconEnd={<Icon icon={faEllipsisV} />} />
          </Dropdown>
        </Flex>
        <Box>
          <Box marginBottom={theme.space_md}>
            <Text bold>{copyText.billingAccountIDLabel}</Text>
            <Text>{props.integration.config.billingAccountID}</Text>
          </Box>
          <Detail
            label={copyText.billingExportTableIDLabel}
            validationName={CloudCapability.BILL_DATA}
            value={props.integration.config.billingExportSource?.tableID ?? ""}
          />
          <Detail
            label={copyText.rootResourceIDLabel}
            validationName={CloudCapability.INVENTORY_EXPORT}
            value={props.integration.config.rootElement ?? ""}
          />
          <Detail
            label={copyText.monitoredBigQueryProjectsLabel}
            validationName={
              props.integration.config.bigQueryMonitoring
                ? CloudCapability.BIGQUERY_DATA
                : undefined
            }
            value={
              props.integration.config.bigQueryMonitoring
                ? props.integration.config.bigQueryMonitoring
                    .map((config) => config.projectID)
                    .join(", ")
                : copyText.noBQProjectsMessage
            }
          />
        </Box>
      </Box>
    </Flex>
  );
}

GCPIntegrationCard.INTERACTION_EDIT_BUTTON_CLICKED =
  `GCPIntegrationCard.INTERACTION_EDIT_BUTTON_CLICKED` as const;

GCPIntegrationCard.INTERACTION_REFRESH_BUTTON_CLICKED =
  `GCPIntegrationCard.INTERACTION_REFRESH_BUTTON_CLICKED` as const;

GCPIntegrationCard.INTERACTION_DELETE_BUTTON_CLICKED =
  `GCPIntegrationCard.INTERACTION_DELETE_BUTTON_CLICKED` as const;

interface InteractionEditButtonClicked {
  type: typeof GCPIntegrationCard.INTERACTION_EDIT_BUTTON_CLICKED;
  integrationID: string;
}

interface InteractionRefreshButtonClicked {
  type: typeof GCPIntegrationCard.INTERACTION_REFRESH_BUTTON_CLICKED;
  integrationID: string;
}

interface InteractionDeleteButtonClicked {
  type: typeof GCPIntegrationCard.INTERACTION_DELETE_BUTTON_CLICKED;
  integrationID: string;
}

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace GCPIntegrationCard {
  export type Interaction =
    | InteractionEditButtonClicked
    | InteractionRefreshButtonClicked
    | InteractionDeleteButtonClicked;
}

export default GCPIntegrationCard;
