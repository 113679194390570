import { useTheme } from "@emotion/react";
import {
  faExclamationTriangle,
  faTable,
} from "@fortawesome/free-solid-svg-icons";
import { createColumnHelper } from "@tanstack/react-table";
import { groupBy } from "lodash";
import prettyBytes from "pretty-bytes";
import React, { useMemo, useState } from "react";
import { fiscalGranularities } from "../../../analytics/fiscalDateUtils";
import { DateRange } from "../../../analytics/utils";
import { UnitType } from "../../../constants/analytics";
import { CompareDurationType, TimeGranularity } from "../../../constants/enums";
import Box from "../../components/Box";
import EmptyPlaceholder from "../../components/EmptyPlaceholder";
import Flex from "../../components/Flex";
import Icon from "../../components/Icon";
import Text from "../../components/Text";
import copyText from "../../copyText";
import { getFormatForGranularity } from "../../utils/dates";
import {
  formatCurrency,
  formatKilograms,
  formatNumber,
  formatPercentage,
} from "../../utils/formatNumber";
import { Dimension, Measure, RawData, RawValue, SortRule } from "../types";
import getMergeState, {
  COMPARISON_KEY,
  PERCENT_DIFFERENCE_KEY,
  RAW_DIFFERENCE_KEY,
  formatMeasureValueWithUnit,
  formatTimestamp,
  getUniformUnitType,
} from "../utils";
import Table from "./Table";

const AVG_PIXEL_WIDTH_OF_CHARACTER = 4;
const MAX_ALLOWED_DATA_LENGTH = 50_000;
const MAX_ALLOWED_UI_ROWS = 100;
const MAX_ALLOWED_UI_COLUMNS = 50;
const MEASURE_INTERNAL_KEY = "selected_measure";

interface Props {
  compact?: boolean;
  compareDateRange?: DateRange;
  compareDurationType?: CompareDurationType | null;
  creditTypes?: string[];
  data: RawData[];
  dateRange?: DateRange;
  dimensions: Dimension[];
  footer?: boolean;
  isCumulativeMode?: boolean;
  isFiscalMode?: boolean;
  isInvoiceMonthMode?: boolean;
  isLoading: boolean;
  isServer?: boolean;
  limit?: number | null;
  maxRows?: number;
  measures: Measure[];
  pinnedColumns?: number;
  reverse?: boolean;
  sortable?: boolean;
  sortRule?: SortRule;
  timeSeriesGranularity: TimeGranularity;
  onInteraction?: (interaction: TimeSeriesDataTable.Interaction) => void;
}

interface State {
  sortRule: SortRule | undefined;
}

const columnHelper = createColumnHelper<RawData>();

export function TimeSeriesDataTable(props: Props): JSX.Element {
  const theme = useTheme();

  const [state, setState] = useState<State>({ sortRule: props.sortRule });

  const sortRule: SortRule | undefined = props.sortRule ?? state.sortRule;
  const mergeState = getMergeState(setState);

  const isDataSetTooLarge = props.data.length > MAX_ALLOWED_DATA_LENGTH;

  const uniformTableMeasureUnit = getUniformUnitType(props.measures);

  const hasFiscalDates =
    props.isFiscalMode &&
    fiscalGranularities.includes(props.timeSeriesGranularity);

  //
  // Data Pivots
  //

  const dateStringsForTimeSeries = useMemo(() => {
    if (isDataSetTooLarge && !props.isInvoiceMonthMode) {
      return [];
    }

    return getSortedTimestampsForTimeSeries(props.data, {
      isFiscalMode: props.isFiscalMode,
      isInvoiceMonthMode: props.isInvoiceMonthMode,
    });
  }, [props.data, props.limit, props.isInvoiceMonthMode, props.isFiscalMode]);

  const rowObjects: RawData[] = useMemo(() => {
    if (isDataSetTooLarge) return [];
    // NOTE: In time series data, each measure gets it's own row, even with shared dimensions
    return getRowObjects({
      data: props.data,
      dimensions: props.dimensions,
      isCumulativeMode: props.isCumulativeMode,
      isInvoiceMonthMode: props.isInvoiceMonthMode,
      measures: props.measures.filter(
        (measure) =>
          !measure.name.includes(COMPARISON_KEY) &&
          !measure.name.includes(RAW_DIFFERENCE_KEY)
      ),
      timeSeriesGranularity: props.timeSeriesGranularity,
    });
  }, [props.data, props.limit]);

  const maxCharacterWidthsByKey: { [key: string]: number } = useMemo(() => {
    // NOTE: this isused to dynamically size the table columns based on content
    return getMaxCharactersByKey(rowObjects);
  }, [rowObjects]);

  //
  // Table Setup
  //

  const timestampFormat = getFormatForGranularity(props.timeSeriesGranularity);

  const tableTotal = React.useMemo(
    () =>
      rowObjects.reduce((sum, datum) => {
        if (typeof datum.totals !== "number" || isNaN(datum.totals)) {
          return sum;
        }
        return datum.totals + sum;
      }, 0),
    [rowObjects]
  );

  function getTimestampColumns() {
    const timestamps = (
      props.reverse
        ? [...dateStringsForTimeSeries].reverse()
        : dateStringsForTimeSeries
    ).slice(0, MAX_ALLOWED_UI_COLUMNS);

    return [
      ...timestamps.map((timestamp, index) =>
        columnHelper.accessor((datum) => datum[timestamp], {
          id: timestamp,
          meta: { align: "right" },
          size: props.compareDurationType ? 200 : 150,
          header: () => {
            if (hasFiscalDates || props.isInvoiceMonthMode) {
              return timestamp;
            }

            if (props.compareDurationType) {
              return getComparionTimestampString(
                index,
                timestamp,
                props.timeSeriesGranularity,
                timestampFormat
              );
            }

            return formatTimestamp(timestamp, timestampFormat);
          },
          cell: ({ row, getValue }) => {
            const measure = props.measures.find((measure) => {
              return measure.name === row.original[MEASURE_INTERNAL_KEY];
            });

            const value = getValue();

            if (!measure) return "--";

            if (
              measure.name === PERCENT_DIFFERENCE_KEY &&
              typeof value === "number"
            ) {
              return formatPercentage(value);
            }

            const convertedValue = formatMeasure(value, measure.unit);

            return convertedValue ?? "--";
          },
          footer: ({ column, table }) => {
            const { rows } = table.getRowModel();
            const measure = props.measures.find((measure) => {
              const row = rows.find(
                (row) => row.original[column.id] !== undefined
              );

              if (!row) return null;

              return measure.name === row.original[MEASURE_INTERNAL_KEY];
            });

            if (!measure) return 0;

            const total = React.useMemo(
              () =>
                rows.reduce((sum, row) => {
                  const currentValue = row.original[timestamp] ?? 0;

                  const parsed = parseFloat(currentValue as string);

                  return isNaN(parsed) ? sum : sum + parsed;
                }, 0),
              [rows]
            );

            const nextTimestamp = dateStringsForTimeSeries[index + 1];

            const previousTotal = React.useMemo(
              () =>
                rows.reduce((sum, row) => {
                  const previousValue = row.original[nextTimestamp];
                  const parsed = parseFloat(previousValue as string);
                  return isNaN(parsed) ? sum : sum + parsed;
                }, 0),
              [rows]
            );

            const delta = total - previousTotal;

            return props.compareDurationType && props.measures.length === 3 ? ( // TODO:
              <>
                <Box>{displaySymbol(total, uniformTableMeasureUnit)}</Box>
                <Box>
                  {!timestamp.includes(COMPARISON_KEY)
                    ? `${displaySymbol(
                        delta,
                        uniformTableMeasureUnit
                      )} (${formatPercentage(delta / total)})`
                    : "--"}
                </Box>
              </>
            ) : (
              <Box>{displaySymbol(total, uniformTableMeasureUnit)}</Box>
            );
          },
          sortingFn: (rowA, rowB, columnID) => {
            const valueA = rowA.getValue(columnID);
            const valueB = rowB.getValue(columnID);

            if (typeof valueA !== "number") return -1;
            if (typeof valueB !== "number") return 1;

            if (valueA > valueB) {
              return 1;
            } else if (valueA < valueB) {
              return -1;
            } else {
              return 0;
            }
          },
        })
      ),
    ];
  }

  const dimensionColumns = props.dimensions.map((dimension) =>
    columnHelper.accessor((datum) => datum[dimension.name], {
      id: dimension.name,
      header: dimension.name,
      meta: { truncate: true },
      size: props.compareDurationType ? 200 : 150,
      sortingFn: (rowA, rowB, columnID) => {
        const valueA = rowA.getValue(columnID);
        const valueB = rowB.getValue(columnID);

        if (typeof valueA !== "string") return -1;
        if (typeof valueB !== "string") return 1;

        if (valueA.toLowerCase() > valueB.toLowerCase()) {
          return 1;
        } else if (valueA.toLowerCase() < valueB.toLowerCase()) {
          return -1;
        } else {
          return 0;
        }
      },
    })
  );

  const columns = React.useMemo(
    () => [
      ...dimensionColumns,

      // MEASURE NAME COLUMN
      columnHelper.accessor((datum) => datum[MEASURE_INTERNAL_KEY], {
        id: MEASURE_INTERNAL_KEY,
        header: copyText.dataAttributeMeasure,
        meta: { truncate: true },
        size: 200,
      }),

      ...getTimestampColumns(),

      // TOTALS COLUMN
      columnHelper.accessor((datum) => datum.totals, {
        id: "totals",
        meta: { align: "right" },
        cell: ({ row }) => {
          const unit = props.measures.find(
            (measure) => measure.name === row.original.selected_measure
          )?.unit;

          return formatMeasure(row.original.totals, unit);
        },
        footer: () => {
          return displaySymbol(tableTotal, uniformTableMeasureUnit);
        },
        header: copyText.dataTableTotalsHeader,
        sortingFn: "basic",
      }),
    ],

    [props.data, props.dimensions, maxCharacterWidthsByKey]
  );

  //
  // Event Handlers
  //

  function handleChangeSort(sortRules: SortRule[]): void {
    const newSortRule = sortRules.length
      ? { id: sortRules[0].id, desc: sortRules[0].desc }
      : undefined;

    mergeState({ sortRule: newSortRule });

    props.onInteraction?.({
      type: TimeSeriesDataTable.INTERACTION_SORT_TABLE_CLICKED,
      sortRule: newSortRule,
    });
  }

  //
  // JSX
  //

  const maxAllowedUIRows = props.maxRows ?? MAX_ALLOWED_UI_ROWS;

  const showRowTruncationMessage = rowObjects.length > maxAllowedUIRows;

  const showColumnTruncationMessage =
    props.timeSeriesGranularity &&
    dateStringsForTimeSeries.length > MAX_ALLOWED_UI_COLUMNS;

  function renderTable() {
    if (props.isLoading || props.data.length === 0) {
      return (
        <EmptyPlaceholder
          height={400}
          loading={props.isLoading}
          icon={faTable}
          text={copyText.chartEmptyPlaceholderText}
        />
      );
    }

    return (
      <Table
        compact={props.compact}
        columns={columns}
        data={rowObjects}
        footer={props.footer}
        footerDelta={
          !!props.compareDurationType &&
          !!props.timeSeriesGranularity &&
          props.measures.length === 3
        }
        initialState={{
          pagination: { pageSize: maxAllowedUIRows },
          ...(props.timeSeriesGranularity
            ? { sorting: [{ id: MEASURE_INTERNAL_KEY, desc: false }] }
            : sortRule?.id
              ? { sorting: [sortRule] }
              : {}),
        }}
        pinnedColumns={props.pinnedColumns}
        sortable={props.sortable}
        isLoading={props.isLoading}
        isColumnResizable
        truncateRows
        onChangeSortBy={handleChangeSort}
      />
    );
  }

  return (
    <Box width="100%">
      <Flex
        borderRadius={theme.borderRadius_2}
        maxHeight={props.pinnedColumns !== undefined ? 600 : undefined}
        overflow="auto"
      >
        {renderTable()}
        {showColumnTruncationMessage && (
          <Flex
            alignItems="center"
            marginLeft={theme.space_md}
            minWidth={"30rem"}
          >
            <Icon
              color={theme.secondary_color}
              icon={faExclamationTriangle}
              size="sm"
            />
            <Box marginLeft={theme.space_sm}>
              <Text>{copyText.dataTableColumnLimitReached}</Text>
            </Box>
          </Flex>
        )}
      </Flex>
      {showRowTruncationMessage && (
        <Flex alignItems="center" marginTop={theme.space_md} width="100%">
          {!props.isServer && (
            <Icon
              color={theme.secondary_color}
              icon={faExclamationTriangle}
              size="sm"
            />
          )}
          <Text
            marginBottom={theme.space_sm}
            marginLeft={theme.space_sm}
            marginRight={theme.space_xs}
          >
            {copyText.dataTableRowLimitReached}
          </Text>
        </Flex>
      )}
    </Box>
  );
}

function displaySymbol(total: number, unit: string | undefined) {
  switch (unit) {
    case UnitType.BYTES: {
      return prettyBytes(total);
    }
    case UnitType.CURRENCY: {
      return formatCurrency({ number: total });
    }
    case UnitType.KILOGRAMS: {
      return formatKilograms(total);
    }
    default: {
      return formatNumber(total);
    }
  }
}

function formatMeasure(value: RawValue, unit: string | undefined) {
  if (value === undefined) return "--";

  if (typeof value !== "number") {
    return value;
  }

  return formatMeasureValueWithUnit({ unit, value });
}

function getRowObjects(params: {
  data: RawData[];
  dimensions: Dimension[];
  isCumulativeMode?: boolean;
  isInvoiceMonthMode?: boolean;
  measures: Measure[];
  timeSeriesGranularity?: TimeGranularity;
}) {
  const rows: RawData[] = [];

  const DELIMITER = "-%%DELIM%%-";
  const dimensionNames = params.dimensions.map((dimension) => dimension.name);

  const dataGroupedByDimensionsAndSingleMeasure = params.data.reduce(
    (accum: { [key: string]: RawData[] }, datum) => {
      const dimensionsKey = dimensionNames
        .map((key) => datum[key] ?? "null")
        .join(DELIMITER);

      params.measures.forEach((measure) => {
        const key = dimensionsKey + DELIMITER + measure.name;

        if (accum[key]) {
          accum[key].push(datum);
        } else {
          accum[key] = [datum];
        }
      });

      return accum;
    },
    {}
  );

  Object.keys(dataGroupedByDimensionsAndSingleMeasure).forEach(
    (compositeKey) => {
      const arr = compositeKey.split(DELIMITER);
      const dimensionValues = arr.slice(0, -1);
      const measureKey = arr[arr.length - 1];

      const row: { [key: string]: RawValue } = {};

      row[MEASURE_INTERNAL_KEY] = measureKey;

      dimensionNames.forEach((key, i) => {
        row[key] = dimensionValues[i];
      });

      const datedEntities =
        dataGroupedByDimensionsAndSingleMeasure[compositeKey];

      const timeDimension = params.isInvoiceMonthMode
        ? "invoiceMonth"
        : "timestamp";

      datedEntities.forEach((datum) => {
        if (typeof datum[timeDimension] === "string") {
          const dateKey = measureKey.includes(COMPARISON_KEY)
            ? `${datum[timeDimension]} (${COMPARISON_KEY})`
            : `${datum[timeDimension]}`;
          row[dateKey] = datum[measureKey];
        }
      });

      if (params.isCumulativeMode) {
        row.totals = datedEntities[datedEntities.length - 1][measureKey];
      } else {
        row.totals = datedEntities.reduce((accum: number, entity) => {
          const measureValue = entity[measureKey];
          if (typeof measureValue === "number" && measureValue !== null) {
            return accum + measureValue;
          } else return accum;
        }, 0);
      }

      rows.push(row);
    }
  );

  return rows;
}

function getComparionTimestampString(
  index: number,
  timestamp: string,
  timeSeriesGranularity: TimeGranularity,
  timestampFormat: string
) {
  const timeGranularityLabel =
    timeSeriesGranularity.charAt(0).toUpperCase() +
    timeSeriesGranularity.slice(1).toLowerCase();

  return (
    formatTimestamp(
      timestamp.replace(` (${COMPARISON_KEY})`, ""),
      timestampFormat
    ) +
    (timestamp.includes(` (${COMPARISON_KEY})`)
      ? ` (${COMPARISON_KEY} - ${timeGranularityLabel} ${
          (index % 2 ? index - 1 : index + 2) / 2 + 1
        })`
      : ` (Current - ${timeGranularityLabel} ${
          (index % 2 ? index - 1 : index + 2) / 2
        })`)
  );
}

function getMaxCharactersByKey(rowObjects: RawData[]) {
  return rowObjects.reduce((accum: { [key: string]: number }, datum) => {
    Object.keys(datum).forEach((key) => {
      const existingLargestLengthForKey = accum[key];
      const value = datum[key];
      let stringValue = "";

      if (value === null) {
        stringValue = "null";
      }

      if (typeof value === "string") {
        stringValue = value;
      }

      if (typeof value === "number") {
        stringValue = value.toString();
      }

      // Note: For number type with value 0, we want to preserve largest key length
      if (value || value === 0) {
        if (stringValue.length > (existingLargestLengthForKey || 0)) {
          accum[key] = stringValue.length;
        }
      } else {
        accum[key] = stringValue.length;
      }
    });

    return accum;
  }, {});
}

function getSortedTimestampsForTimeSeries(
  filteredData: RawData[],
  options?: {
    isFiscalMode?: boolean;
    isInvoiceMonthMode?: boolean;
  }
): string[] {
  const dataGroupedByTimestamp = options?.isInvoiceMonthMode
    ? groupBy(filteredData, "invoiceMonth")
    : groupBy(filteredData, "timestamp");

  const timestamps = Object.keys(dataGroupedByTimestamp).filter(
    (key) =>
      options?.isFiscalMode ||
      !isNaN(Date.parse(key)) ||
      key.includes(COMPARISON_KEY)
  );

  if (options?.isFiscalMode || options?.isInvoiceMonthMode) {
    return timestamps;
  }

  return timestamps;
}

TimeSeriesDataTable.INTERACTION_SORT_TABLE_CLICKED =
  `TimeSeriesDataTable.INTERACTION_SORT_TABLE_CLICKED` as const;

interface InteractionSortTableClicked {
  type: typeof TimeSeriesDataTable.INTERACTION_SORT_TABLE_CLICKED;
  sortRule: SortRule | undefined;
}

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace TimeSeriesDataTable {
  export type Interaction = InteractionSortTableClicked;
}

export default TimeSeriesDataTable;
