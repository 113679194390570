export const Permission = {
  CREATE_ADMIN_SCOPED_VIEW: "CREATE_ADMIN_SCOPED_VIEW",
  CREATE_ALERT_RULE: "CREATE_ALERT_RULE",
  CREATE_BUDGET: "CREATE_BUDGET",
  CREATE_CASE_COMMENT: "CREATE_CASE_COMMENT",
  CREATE_CASE: "CREATE_CASE",
  CREATE_COST_COMPARE_BILL: "CREATE_COST_COMPARE_BILL",
  CREATE_CUSTOM_LABEL: "CREATE_CUSTOM_LABEL",
  CREATE_CUSTOM_METRIC: "CREATE_CUSTOM_METRIC",
  CREATE_DASHBOARD_SYSTEM: "CREATE_DASHBOARD_SYSTEM",
  CREATE_DASHBOARD: "CREATE_DASHBOARD",
  CREATE_DATA_INTEGRATION: "CREATE_DATA_INTEGRATION",
  CREATE_FEATURE_FLAG: "CREATE_FEATURE_FLAG",
  CREATE_INTEGRATION: "CREATE_INTEGRATION",
  CREATE_KUBERNETES_POD_LABEL: "CREATE_KUBERNETES_POD_LABEL",
  CREATE_LABEL_GROUPING_RULE: "CREATE_LABEL_GROUPING_RULE",
  CREATE_LABEL_PREFERENCE: "CREATE_LABEL_PREFERENCE",
  CREATE_MEASURE_PREFERENCE: "CREATE_MEASURE_PREFERENCE",
  CREATE_MSP_BILLING_INFO: "CREATE_MSP_BILLING_INFO",
  CREATE_MSP_BILLING_INVOICE: "CREATE_MSP_BILLING_INVOICE",
  CREATE_MSP_BILLING_MANIFEST: "CREATE_MSP_BILLING_MANIFEST",
  CREATE_MSP_BILLING_RULE_SET: "CREATE_MSP_BILLING_RULE_SET",
  CREATE_RAMP_PLAN: "CREATE_RAMP_PLAN",
  CREATE_REALLOCATION: "CREATE_REALLOCATION",
  CREATE_REPORT_SYSTEM: "CREATE_REPORT_SYSTEM",
  CREATE_REPORT: "CREATE_REPORT",
  CREATE_RESOURCE_SUBSCRIPTION: "CREATE_RESOURCE_SUBSCRIPTION",
  CREATE_SAVINGS_OPPORTUNITY_SYSTEM: "CREATE_SAVINGS_OPPORTUNITY_SYSTEM",
  CREATE_SAVINGS_OPPORTUNITY: "CREATE_SAVINGS_OPPORTUNITY",
  CREATE_TENANT: "CREATE_TENANT",
  CREATE_USER_GROUP_CONFIG: "CREATE_USER_GROUP_CONFIG",
  CREATE_USER: "CREATE_USER",
  DELETE_ADMIN_SCOPED_VIEW: "DELETE_ADMIN_SCOPED_VIEW",
  DELETE_BUDGET: "DELETE_BUDGET",
  DELETE_COST_COMPARE_BILL: "DELETE_COST_COMPARE_BILL",
  DELETE_CUSTOM_LABEL: "DELETE_CUSTOM_LABEL",
  DELETE_CUSTOM_METRIC: "DELETE_CUSTOM_METRIC",
  DELETE_DASHBOARD_SYSTEM: "DELETE_DASHBOARD_SYSTEM",
  DELETE_DASHBOARD: "DELETE_DASHBOARD",
  DELETE_DATA_INTEGRATION: "DELETE_DATA_INTEGRATION",
  DELETE_FEATURE_FLAG: "DELETE_FEATURE_FLAG",
  DELETE_INTEGRATION: "DELETE_INTEGRATION",
  DELETE_KUBERNETES_POD_LABEL: "DELETE_KUBERNETES_POD_LABEL",
  DELETE_LABEL_GROUPING_RULE: "DELETE_LABEL_GROUPING_RULE",
  DELETE_MSP_BILLING_INFO: "DELETE_MSP_BILLING_INFO",
  DELETE_MSP_BILLING_MANIFEST: "DELETE_MSP_BILLING_MANIFEST",
  DELETE_MSP_BILLING_RULE_SET: "DELETE_MSP_BILLING_RULE_SET",
  DELETE_RAMP_PLAN: "DELETE_RAMP_PLAN",
  DELETE_REALLOCATION: "DELETE_REALLOCATION",
  DELETE_REPORT_SYSTEM: "DELETE_REPORT_SYSTEM",
  DELETE_REPORT: "DELETE_REPORT",
  DELETE_RESOURCE_SUBSCRIPTION: "DELETE_RESOURCE_SUBSCRIPTION",
  DELETE_SAVINGS_OPPORTUNITY_SYSTEM: "DELETE_SAVINGS_OPPORTUNITY_SYSTEM",
  DELETE_SAVINGS_OPPORTUNITY: "DELETE_SAVINGS_OPPORTUNITY",
  DELETE_TENANT: "DELETE_TENANT",
  DELETE_USER_GROUP_CONFIG: "DELETE_USER_GROUP_CONFIG",
  DISTRIBUTE_SYSTEM_DASHBOARD: "DISTRIBUTE_SYSTEM_DASHBOARD",
  DISTRIBUTE_SYSTEM_REPORT: "DISTRIBUTE_SYSTEM_REPORT",
  GENERATE_COST_COMPARE_TAKEOUT_REPORT: "GENERATE_COST_COMPARE_TAKEOUT_REPORT",
  GRANT_SELF_TENANT_ACCESS: "GRANT_SELF_TENANT_ACCESS",
  GRANT_TENANT_ACCESS: "GRANT_TENANT_ACCESS",
  READ_ADMIN_SCOPED_VIEWS: "READ_ADMIN_SCOPED_VIEWS",
  READ_ALERT_RULE_EVENTS: "READ_ALERT_RULE_EVENTS",
  READ_ALERT_RULES: "READ_ALERT_RULES",
  READ_AUDIT_LOGS: "READ_AUDIT_LOGS",
  READ_AWS_RATE_RECOMMENDATIONS: "READ_AWS_RATE_RECOMMENDATIONS",
  READ_AZURE_RATE_RECOMMENDATIONS: "READ_AZURE_RATE_RECOMMENDATIONS",
  READ_BIGQUERY_METADATA: "READ_BIGQUERY_METADATA",
  READ_BUDGETS: "READ_BUDGETS",
  READ_CASES_SYSTEM: "READ_CASES_SYSTEM",
  READ_CASES: "READ_CASES",
  READ_COST_ALERTS: "READ_COST_ALERTS",
  READ_COST_COMPARE_BILLS: "READ_COST_COMPARE_BILLS",
  READ_CUD_RECOMMENDATIONS: "READ_CUD_RECOMMENDATIONS",
  READ_CUSTOM_LABELS: "READ_CUSTOM_LABELS",
  READ_CUSTOM_METRICS: "READ_CUSTOM_METRICS",
  READ_DASHBOARDS_SYSTEM: "READ_DASHBOARDS_SYSTEM",
  READ_DASHBOARDS: "READ_DASHBOARDS",
  READ_DATA_INTEGRATIONS: "READ_DATA_INTEGRATIONS",
  READ_DIMENSION_PREFERENCES: "READ_LABEL_PREFERENCE",
  READ_FEATURE_FLAGS: "READ_FEATURE_FLAGS",
  READ_FEATURE_FLAGS_SYSTEM: "READ_FEATURE_FLAGS_SYSTEM",
  READ_INTEGRATIONS: "READ_INTEGRATIONS",
  READ_KUBERNETES_POD_LABELS: "READ_KUBERNETES_POD_LABELS",
  READ_LABEL_GROUPING_RULES: "READ_LABEL_GROUPING_RULES",
  READ_LABEL_MAP: "READ_LABEL_MAP",
  READ_MEASURE_PREFERENCES: "READ_MEASURE_PREFERENCES",
  READ_MSP_BILLING_INFO: "READ_MSP_BILLING_INFO",
  READ_MSP_BILLING_INVOICE: "READ_MSP_BILLING_INVOICE",
  READ_MSP_BILLING_MANIFESTS: "READ_MSP_BILLING_MANIFESTS",
  READ_MSP_BILLING_RULE_SETS_SYSTEM: "READ_MSP_BILLING_RULE_SETS_SYSTEM",
  READ_MSP_BILLING_RULE_SETS: "READ_MSP_BILLING_RULE_SETS",
  READ_RAMP_PLANS: "READ_RAMP_PLANS",
  READ_REALLOCATION_JOBS: "READ_REALLOCATION_JOBS",
  READ_REALLOCATIONS: "READ_REALLOCATIONS",
  READ_RECOMMENDATIONS: "READ_RECOMMENDATIONS",
  READ_REPORT_DATA: "READ_REPORT_DATA",
  READ_REPORTS_SYSTEM: "READ_REPORTS_SYSTEM",
  READ_REPORTS: "READ_REPORTS",
  READ_RESOURCE_SUBSCRIPTIONS: "READ_RESOURCE_SUBSCRIPTIONS",
  READ_ROLES_SYSTEM: "READ_ROLES_SYSTEM",
  READ_ROLES: "READ_ROLES",
  READ_SAVINGS_OPPORTUNITIES_SYSTEM: "READ_SAVINGS_OPPORTUNITIES_SYSTEM",
  READ_SAVINGS_OPPORTUNITIES: "READ_SAVINGS_OPPORTUNITIES",
  READ_TENANTS_PARTNER: "READ_TENANTS_PARTNER",
  READ_TENANTS_SYSTEM: "READ_TENANTS_SYSTEM",
  READ_USER_GROUP_CONFIGS: "READ_USER_GROUP_CONFIGS",
  READ_USER_ROLES: "READ_USER_ROLES",
  READ_USER_ROLES_PARTNER: "READ_USER_ROLES_PARTNER",
  READ_USER_ROLES_SYSTEM: "READ_USER_ROLES_SYSTEM",
  READ_USER_TENANT_SETTINGS_SYSTEM: "READ_USER_TENANT_SETTINGS_SYSTEM",
  READ_USERS_PARTNER: "READ_USERS_PARTNER",
  READ_USERS_SYSTEM: "READ_USERS_SYSTEM",
  READ_USERS: "READ_USERS",
  REMOVE_SYSTEM_DASHBOARD: "REMOVE_SYSTEM_DASHBOARD",
  REMOVE_SYSTEM_REPORT: "REMOVE_SYSTEM_REPORT",
  REVOKE_GLOBAL_ACCESS: "REVOKE_GLOBAL_ACCESS",
  REVOKE_TENANT_ACCESS: "REVOKE_TENANT_ACCESS",
  TRIGGER_REALLOCATION: "TRIGGER_REALLOCATION",
  UPDATE_ADMIN_SCOPED_VIEW: "UPDATE_ADMIN_SCOPED_VIEW",
  UPDATE_ALERT_RULE: "UPDATE_ALERT_RULE",
  UPDATE_BUDGET: "UPDATE_BUDGET",
  UPDATE_CASE: "UPDATE_CASE",
  UPDATE_CUSTOM_LABEL: "UPDATE_CUSTOM_LABEL",
  UPDATE_CUSTOM_METRIC: "UPDATE_CUSTOM_METRIC",
  UPDATE_DASHBOARD_SYSTEM: "UPDATE_DASHBOARD_SYSTEM",
  UPDATE_DASHBOARD: "UPDATE_DASHBOARD",
  UPDATE_DATA_INTEGRATION: "UPDATE_DATA_INTEGRATION",
  UPDATE_INTEGRATION: "UPDATE_INTEGRATION",
  UPDATE_LABEL_GROUPING_RULE: "UPDATE_LABEL_GROUPING_RULE",
  UPDATE_LABEL_PREFERENCE: "UPDATE_LABEL_PREFERENCE",
  UPDATE_MEASURE_PREFERENCE: "UPDATE_MEASURE_PREFERENCE",
  UPDATE_MSP_BILLING_INFO: "UPDATE_MSP_BILLING_INFO",
  UPDATE_MSP_BILLING_INVOICE: "UPDATE_MSP_BILLING_INVOICE",
  UPDATE_MSP_BILLING_RULE_SET: "UPDATE_MSP_BILLING_RULE_SET",
  UPDATE_MSP_TENANT_SETTINGS: "UPDATE_MSP_TENANT_SETTINGS",
  UPDATE_RAMP_PLAN: "UPDATE_RAMP_PLAN",
  UPDATE_REALLOCATION: "UPDATE_REALLOCATION",
  UPDATE_RECOMMENDATION: "UPDATE_RECOMMENDATION",
  UPDATE_REPORT_SYSTEM: "UPDATE_REPORT_SYSTEM",
  UPDATE_REPORT: "UPDATE_REPORT",
  UPDATE_RESOURCE_SUBSCRIPTION: "UPDATE_RESOURCE_SUBSCRIPTION",
  UPDATE_SAVINGS_OPPORTUNITY_SYSTEM: "UPDATE_SAVINGS_OPPORTUNITY_SYSTEM",
  UPDATE_SAVINGS_OPPORTUNITY: "UPDATE_SAVINGS_OPPORTUNITY",
  UPDATE_TENANT: "UPDATE_TENANT",
  UPDATE_USER_GROUP_CONFIG: "UPDATE_USER_GROUP_CONFIG",
  UPDATE_USER_ROLES: "UPDATE_USER_ROLES",
  UPDATE_USER_TENANT_SETTINGS: "UPDATE_USER_TENANT_SETTINGS",
  VIEW_COMMITTED_USE_PAGE: "VIEW_COMMITTED_USE_PAGE",
} as const;

export type Permission = (typeof Permission)[keyof typeof Permission];

export default Permission;
