import { TableLegacy } from "@/ui-lib/components/Table";
import { Theme, useTheme } from "@emotion/react";
import {
  faEllipsisV,
  faPlayCircle,
  faWarning,
} from "@fortawesome/free-solid-svg-icons";
import {
  JobStatus,
  ReallocationInvocationType,
  ReallocationRebuildType,
  ReallocationStatus,
} from "@ternary/api-lib/constants/enums";
import Box from "@ternary/api-lib/ui-lib/components/Box";
import Button from "@ternary/api-lib/ui-lib/components/Button";
import Icon from "@ternary/api-lib/ui-lib/components/Icon";
import Tooltip from "@ternary/api-lib/ui-lib/components/Tooltip";
import { formatDate } from "@ternary/api-lib/ui-lib/utils/dates";
import { formatDistance } from "date-fns";
import { groupBy, isNil, keyBy } from "lodash";
import React, { useMemo } from "react";
import { Column } from "react-table";
import useGatekeeper from "../../../hooks/useGatekeeper";
import Dropdown from "../../../ui-lib/components/Dropdown";
import copyText from "../copyText";
import { getStringifiedRebuildType } from "../utils";

type ReallocationJob = {
  id: string;
  endTime: string | null;
  invocationType: ReallocationInvocationType;
  lineItemsCreated: number;
  sourceReallocationID: string;
  startTime: string;
  status: JobStatus;
};

type Reallocation = {
  id: string;
  createdByUserID: string;
  name: string;
  rebuildType: ReallocationRebuildType;
  status: ReallocationStatus;
};

type TableData = {
  id: string;
  isTriggeringReallocation: boolean;
  createdBy: string;
  jobInProgress: boolean;
  lastJob: ReallocationJob | null;
  lastSuccessfulJob: string | null;
  name: string;
  rebuildType: ReallocationRebuildType;
  status: ReallocationStatus;
};

type User = {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
};
interface Props {
  inProgressReallocationIDs: string[];
  isLoading: boolean;
  reallocationJobs: ReallocationJob[];
  reallocations: Reallocation[];
  users: User[];
  onInteraction: (interaction: ReallocationManagementTable.Interaction) => void;
}

export function ReallocationManagementTable(props: Props): JSX.Element {
  const gatekeeper = useGatekeeper();
  const theme = useTheme();

  const usersKeyedByID = keyBy(props.users, "id");

  const columns = useMemo(
    (): Column<TableData>[] => [
      {
        accessor: "name",
        align: "center",
        Cell: ({ row }) => {
          return (
            <Tooltip content={row.original.id}>{row.original.name}</Tooltip>
          );
        },
        Header: copyText.reallocationManagementTableHeader_name,
        width: 150,
      },
      {
        accessor: "status",
        align: "center",
        Cell: ({ value }) => {
          return renderStatusDot(value === ReallocationStatus.ACTIVE, theme);
        },
        Header: copyText.reallocationManagemenTableHeader_status,
        width: 40,
      },
      {
        accessor: "rebuildType",
        align: "center",
        Cell: ({ value }) => {
          return <>{getStringifiedRebuildType(value)}</>;
        },
        Header: copyText.reallocationManagementTableHeader_rebuildType,
        width: 100,
      },
      {
        accessor: "lastSuccessfulJob",
        align: "center",
        Cell: ({ value }) => {
          if (isNil(value)) {
            return <>{copyText.reallocationManagementTableNA}</>;
          }

          const lastTriggered = new Date(value);

          const now = new Date();

          const lastWeek = new Date(
            new Date().setDate(new Date().getDate() - 7)
          );

          const distance = formatDistance(lastTriggered, now);

          // display distance if less than a week ago, timestamp if more than a week ago
          if (
            Date.parse(lastTriggered.toLocaleString()) >
            Date.parse(lastWeek.toLocaleString())
          ) {
            return (
              <Tooltip content={new Date(value).toLocaleString()}>
                <>{copyText.timeDistanceText.replace("%distance%", distance)}</>
              </Tooltip>
            );
          }

          return <>{formatDate(new Date(value), "yyyy-MM-dd")}</>;
        },
        Header: copyText.reallocationManagementTableHeader_lastSuccessfulJob,
        width: 100,
      },
      {
        accessor: "createdBy",
        align: "center",
        Header: copyText.reallocationManagementTableHeader_createdBy,
        width: 100,
      },
      {
        id: "lastJobFailedWarning",
        align: "center",
        NoAccessorCell: function renderButton({ row }) {
          return row.original.lastJob?.status === JobStatus.FAILURE ? (
            <Tooltip
              content={copyText.reallocationFailedLastJobTooltipWarning.replace(
                "%TIMESTAMP%",
                new Date(row.original.lastJob?.startTime).toLocaleString()
              )}
            >
              <Icon color={theme.feedback_warn} icon={faWarning} />
            </Tooltip>
          ) : null;
        },
        width: 25,
      },
      {
        id: "runReallocationButton",
        align: "center",
        NoAccessorCell: function renderButton({ row }) {
          const disabled =
            !gatekeeper.canExecuteReallocations ||
            row.original.jobInProgress ||
            row.original.isTriggeringReallocation ||
            row.original.status === ReallocationStatus.INACTIVE;

          const runOptions = [
            {
              disabled,
              label: copyText.actionExecuteReallocation,
              onClick: () =>
                props.onInteraction({
                  type: ReallocationManagementTable.INTERACTION_TRIGGER_REALLOCATION_BUTTON_CLICKED,
                  reallocationID: row.original.id,
                }),
            },
          ];

          const tooltipText =
            row.original.status === ReallocationStatus.INACTIVE
              ? copyText.reallocationTriggerButtonDisabledInactiveTooltipText
              : row.original.jobInProgress
                ? copyText.reallocationTriggerButtonDisabledInProgressTooltipText.replace(
                    "%TIMESTAMP%",
                    new Date(
                      row.original.lastJob?.startTime ?? ""
                    ).toLocaleString()
                  )
                : copyText.reallocationTriggerButtonEnabledTooltipText;

          return (
            <Tooltip content={tooltipText}>
              <Dropdown
                disabled={disabled}
                options={runOptions}
                placement="bottom-start"
              >
                <Button
                  disabled={disabled}
                  iconStart={<Icon icon={faPlayCircle} />}
                  primary
                  size="tiny"
                />
              </Dropdown>
            </Tooltip>
          );
        },
        width: 40,
      },
      {
        id: "showActionMenu",
        align: "center",
        NoAccessorCell: function renderButton({ row }) {
          const actionMenuItems = [
            {
              disabled: !gatekeeper.canUpdateReallocations,
              label: copyText.actionEditReallocation,
              onClick: () =>
                props.onInteraction({
                  type: ReallocationManagementTable.INTERACTION_EDIT_BUTTON_CLICKED,
                  reallocationID: row.original.id,
                }),
            },
            {
              disabled: !gatekeeper.canCreateReallocations,
              label: copyText.actionCopyReallocation,
              onClick: () =>
                props.onInteraction({
                  type: ReallocationManagementTable.INTERACTION_COPY_BUTTON_CLICKED,
                  reallocationID: row.original.id,
                }),
            },
            ...(row.original.status === ReallocationStatus.ACTIVE
              ? [
                  {
                    disabled: !gatekeeper.canUpdateReallocations,
                    label: copyText.actionArchiveReallocation,
                    onClick: () =>
                      props.onInteraction({
                        type: ReallocationManagementTable.INTERACTION_ARCHIVE_BUTTON_CLICKED,
                        reallocationID: row.original.id,
                        status: ReallocationStatus.INACTIVE,
                      }),
                  },
                ]
              : [
                  {
                    disabled: !gatekeeper.canUpdateReallocations,
                    label: copyText.actionUnarchiveReallocation,
                    onClick: () =>
                      props.onInteraction({
                        type: ReallocationManagementTable.INTERACTION_ARCHIVE_BUTTON_CLICKED,
                        reallocationID: row.original.id,
                        status: ReallocationStatus.ACTIVE,
                      }),
                  },
                ]),
            {
              disabled: !gatekeeper.canDeleteReallocations,
              label: copyText.actionDeleteReallocation,
              onClick: () =>
                props.onInteraction({
                  type: ReallocationManagementTable.INTERACTION_DELETE_BUTTON_CLICKED,
                  reallocationID: row.original.id,
                }),
            },
          ];
          return (
            <Dropdown options={actionMenuItems} placement="bottom-end">
              <Button
                iconStart={<Icon icon={faEllipsisV} />}
                secondary
                size="tiny"
              />
            </Dropdown>
          );
        },
        width: 40,
      },
    ],
    [
      props.inProgressReallocationIDs,
      props.reallocationJobs,
      props.reallocations,
    ]
  );

  const reallocationJobsKeyedBySourceReallocationID = groupBy(
    props.reallocationJobs,
    "sourceReallocationID"
  );

  const data = useMemo(() => {
    return props.reallocations.map((reallocation) => {
      const reallocationJobs =
        reallocationJobsKeyedBySourceReallocationID[reallocation.id] ?? [];
      const lastJob = getLastJob(reallocationJobs);
      const lastSuccessfulJob = getLastSuccessfulJob(reallocationJobs);

      const user: User | undefined =
        usersKeyedByID[reallocation.createdByUserID];

      const createdBy = user ? `${user.firstName} ${user.lastName}` : "";

      return {
        id: reallocation.id,
        isTriggeringReallocation: props.inProgressReallocationIDs.some(
          (id) => id === reallocation.id
        ),
        createdBy,
        jobInProgress: lastJob?.status === JobStatus.IN_PROGRESS,
        lastJob,
        lastSuccessfulJob: lastSuccessfulJob?.endTime ?? null,
        name: reallocation.name,
        rebuildType: reallocation.rebuildType,
        status: reallocation.status,
      };
    });
  }, [
    props.inProgressReallocationIDs,
    props.reallocationJobs,
    props.reallocations,
  ]);

  return (
    <TableLegacy
      columns={columns}
      data={data}
      initialState={{ sortBy: [{ id: "lastSuccessfulJob", desc: true }] }}
      isLoading={props.isLoading}
      showEmptyTable
      showPagination
      sortable
    />
  );
}

function getLastJob(
  reallocationJobs: ReallocationJob[]
): ReallocationJob | null {
  const sortedJobs = reallocationJobs.sort((a, b) => {
    if (a.endTime === null) return 1;
    if (b.endTime === null) return -1;

    if (
      new Date(Date.parse(a.endTime)).getTime() <
      new Date(Date.parse(b.endTime)).getTime()
    ) {
      return -1;
    }
    if (
      new Date(Date.parse(a.endTime)).getTime() >
      new Date(Date.parse(b.endTime)).getTime()
    ) {
      return 1;
    }
    return 0;
  });

  return sortedJobs[sortedJobs.length - 1] ?? null;
}

function getLastSuccessfulJob(
  reallocationJobs: ReallocationJob[]
): ReallocationJob | null {
  const sortedJobs = reallocationJobs
    .filter((job) => job.status === JobStatus.SUCCESS)
    .sort((a, b) => {
      if (a.endTime === null) return 1;
      if (b.endTime === null) return -1;

      if (
        new Date(Date.parse(a.endTime)).getTime() <
        new Date(Date.parse(b.endTime)).getTime()
      ) {
        return -1;
      }
      if (
        new Date(Date.parse(a.endTime)).getTime() >
        new Date(Date.parse(b.endTime)).getTime()
      ) {
        return 1;
      }
      return 0;
    });

  return sortedJobs[sortedJobs.length - 1] ?? null;
}

function renderStatusDot(active: boolean, theme: Theme) {
  const text = active
    ? copyText.reallocationTableTooltipActive
    : copyText.reallocationTableTooltipInactive;

  return (
    <Tooltip content={text}>
      <Box
        padding="0.35rem"
        backgroundColor={active ? theme.feedback_positive : theme.feedback_warn}
        borderRadius="50%"
        margin="0.3rem"
      />
    </Tooltip>
  );
}

ReallocationManagementTable.INTERACTION_ARCHIVE_BUTTON_CLICKED =
  `ReallocationManagementTable.INTERACTION_ARCHIVE_BUTTON_CLICKED` as const;

ReallocationManagementTable.INTERACTION_COPY_BUTTON_CLICKED =
  `ReallocationManagementTable.INTERACTION_COPY_BUTTON_CLICKED` as const;

ReallocationManagementTable.INTERACTION_DELETE_BUTTON_CLICKED =
  `ReallocationManagementTable.INTERACTION_DELETE_BUTTON_CLICKED` as const;

ReallocationManagementTable.INTERACTION_EDIT_BUTTON_CLICKED =
  `ReallocationManagementTable.INTERACTION_EDIT_BUTTON_CLICKED` as const;

ReallocationManagementTable.INTERACTION_TRIGGER_REALLOCATION_BUTTON_CLICKED =
  `RuleManagementTable.INTERACTION_EXECUTE_REALLOCATION_BUTTON_CLICKED` as const;

interface InteractionArchiveButtonClicked {
  type: typeof ReallocationManagementTable.INTERACTION_ARCHIVE_BUTTON_CLICKED;
  reallocationID: string;
  status: ReallocationStatus;
}

interface InteractionCopyButtonClicked {
  type: typeof ReallocationManagementTable.INTERACTION_COPY_BUTTON_CLICKED;
  reallocationID: string;
}

interface InteractionDeleteButtonClicked {
  type: typeof ReallocationManagementTable.INTERACTION_DELETE_BUTTON_CLICKED;
  reallocationID: string;
}

interface InteractionEditButtonClicked {
  type: typeof ReallocationManagementTable.INTERACTION_EDIT_BUTTON_CLICKED;
  reallocationID: string;
}

interface InteractionTriggerReallocationButtonClicked {
  type: typeof ReallocationManagementTable.INTERACTION_TRIGGER_REALLOCATION_BUTTON_CLICKED;
  reallocationID: string;
}

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace ReallocationManagementTable {
  export type Interaction =
    | InteractionCopyButtonClicked
    | InteractionArchiveButtonClicked
    | InteractionDeleteButtonClicked
    | InteractionEditButtonClicked
    | InteractionTriggerReallocationButtonClicked;
}

export default ReallocationManagementTable;
