import FeedbackBar, { FeedbackBarVariants } from "@/components/FeedbackBar";
import { Input } from "@/types";
import Form from "@/ui-lib/components/Form";
import LoadingSpinner from "@/ui-lib/components/LoadingSpinner";
import Modal from "@/ui-lib/components/Modal";
import Select from "@/ui-lib/components/Select";
import Tabs from "@/ui-lib/components/Tabs";
import getMergeState from "@/utils/getMergeState";
import { CloudCapability } from "@ternary/api-lib/constants/enums";
import Button from "@ternary/api-lib/ui-lib/components/Button";
import Flex from "@ternary/web-ui-lib/components/Flex";
import Text from "@ternary/web-ui-lib/components/Text";
import { isEqual } from "lodash";
import React, { ChangeEvent, FormEvent, useState } from "react";
import copyText from "../../copyText";
import GCPIntegrationFormBasic from "./GCPIntegrationFormBasic";
import GCPIntegrationFormBigQuery from "./GCPIntegrationFormBigQuery";
import GCPIntegrationFormBilling from "./GCPIntegrationFormBilling";
import GCPIntegrationFormCarbon from "./GCPIntegrationFormCarbon";
import GCPIntegrationFormDetailedBillingExport from "./GCPIntegrationFormDetailedBillingExport";
import GCPCloudFormPricingExport from "./GCPIntegrationFormPricingExport";
import {
  BigQueryMonitoring,
  BillingExportSource,
  CarbonFootprintSource,
  GCPIntegration,
  PricingExportSource,
} from "./types";

// Conventionally, a billing account ID is 18 uppercase hexadecimal digits,
// in groups of 6, separated by hyphens.
export const BILLING_ACCOUNT_ID_REGEX = /[A-F0-9]{6}-[A-F0-9]{6}-[A-F0-9]{6}/;

// The dataset name can contain the following:
// * Letters (uppercase or lowercase), numbers, and underscores."
// https://cloud.google.com/bigquery/docs/datasets#dataset-naming
// https://cloud.google.com/bigquery/docs/tables#table_naming
export const BQ_FIELDS_REGEX = /[a-zA-Z0-9_]+/;

// "The project ID must be a unique string of 6 to 30 lowercase letters, digits, or
// hyphens. It must start with a letter, and cannot have a trailing hyphen. You
// cannot change a project ID once it has been created.
// https://cloud.google.com/resource-manager/docs/creating-managing-projects
export const GCP_PROJECT_ID_REGEX = /[a-z][a-z-0-9]{4,28}[a-z0-9]/;

// Root element reference.
export const ORG_ID_REGEX = /(organizations|projects|folders)\/[0-9]+/;

interface Props {
  isLoading: boolean;
  integration?: GCPIntegration;
  onInteraction: (interaction: GCPIntegrationForm.Interaction) => void;
}

interface State {
  // BigQuery Tab
  bigQueryMonitoringItems: BigQueryMonitoring[];

  // Billing Tab
  billingExportSource: BillingExportSource;

  inputBillingAccountId: string;
  inputCommitmentsSharingEnabled: boolean;

  //Carbon Tab
  carbonFootprintSource: BillingExportSource | null;

  // Detailed Export Tab
  detailedDatasetIDInput: Input<string>;
  detailedLocationInput: Input<string>;
  detailedProjectIDInput: Input<string>;
  detailedTableIDInput: Input<string>;

  // Basic Tab
  nameInput: string;
  inputOrganizationId: string;

  // Pricing Export Tab
  pricingDatasetIDInput: Input<string>;
  pricingLocationInput: Input<string>;
  pricingProjectIDInput: Input<string>;
  pricingTableIDInput: Input<string>;

  // Tab State
  tab: string;
}

const initialState: State = {
  // BigQuery tab's state
  bigQueryMonitoringItems: [],

  // Billing tab's state
  billingExportSource: {
    datasetID: "",
    location: "",
    projectID: "",
    tableID: "",
  },
  inputBillingAccountId: "",
  inputCommitmentsSharingEnabled: false,

  // Carbon tab's state
  carbonFootprintSource: {
    datasetID: "",
    location: "",
    projectID: "",
    tableID: "",
  },

  // Detailed Export tab's state
  detailedDatasetIDInput: { value: "", isValid: false, hasChanged: false },
  detailedLocationInput: { value: "", isValid: false, hasChanged: false },
  detailedProjectIDInput: { value: "", isValid: false, hasChanged: false },
  detailedTableIDInput: { value: "", isValid: false, hasChanged: false },

  // Basic tab's state
  nameInput: "",
  inputOrganizationId: "",

  // Pricing Export tab's state
  pricingDatasetIDInput: { value: "", isValid: false, hasChanged: false },
  pricingLocationInput: { value: "", isValid: false, hasChanged: false },
  pricingProjectIDInput: { value: "", isValid: false, hasChanged: false },
  pricingTableIDInput: { value: "", isValid: false, hasChanged: false },

  tab: "basic",
};

export const validBigQueryLocations = [
  // https://cloud.google.com/bigquery/docs/locations#locations_and_regions
  // Multi-regions
  "US",
  "EU",

  // Single zones
  "northamerica-northeast1",
  "northamerica-northeast2",
  "us-central1",
  "us-east1",
  "us-east4",
  "us-east5",
  "us-south1",
  "us-west1",
  "us-west2",
  "us-west3",
  "us-west4",
  "southamerica-east1",
  "southamerica-west1",
  "europe-central2",
  "europe-north1",
  "europe-southwest1",
  "europe-west1",
  "europe-west2",
  "europe-west3",
  "europe-west4",
  "europe-west6",
  "europe-west8",
  "europe-west9",
  "europe-west12",
  "asia-east1",
  "asia-east2",
  "asia-northeast1",
  "asia-northeast2",
  "asia-northeast3",
  "asia-south1",
  "asia-south2",
  "asia-southeast1",
  "asia-southeast2",
  "australia-southeast1",
  "australia-southeast2",
  "me-central1",
  "me-central2",
  "me-west1",
];

const validBQLocationOptions = validBigQueryLocations.map((location) => ({
  label: location,
  value: location,
}));

interface SelectProps {
  locationInput: Input<string>;
  targetName: string;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
}

export function GCPLocationSelect(props: SelectProps): JSX.Element {
  return (
    <Select
      isCreatable
      isSearchable
      options={validBQLocationOptions}
      placeholder={copyText.cloudAttributeLabelConfigurationPlaceholder}
      value={{
        label: props.locationInput.value,
        value: props.locationInput.value,
      }}
      onChange={(option) =>
        option &&
        props.onChange({
          target: { name: props.targetName, value: option.value },
        } as ChangeEvent<HTMLInputElement>)
      }
    />
  );
}

export function GCPIntegrationForm(props: Props): JSX.Element {
  //
  // State
  //

  const selectedIntegration = props.integration;

  const [state, setState] = useState<State>(
    selectedIntegration
      ? {
          ...initialState,
          bigQueryMonitoringItems:
            selectedIntegration.config.bigQueryMonitoring ?? [],
          billingExportSource:
            selectedIntegration.config.billingExportSource ??
            initialState.billingExportSource,
          carbonFootprintSource:
            selectedIntegration.config.carbonFootprintSource ??
            initialState.carbonFootprintSource,
          ...getBillingExportState(
            selectedIntegration.config.detailedBillingExportSource
          ),
          ...getPricingExportState(
            selectedIntegration.config.pricingExportSource
          ),
          inputBillingAccountId:
            selectedIntegration.config.billingAccountID ??
            initialState.inputBillingAccountId,
          inputCommitmentsSharingEnabled:
            selectedIntegration.config.cudSharingEnabled,
          nameInput: selectedIntegration.name,
          inputOrganizationId: selectedIntegration.config.rootElement ?? "",
        }
      : initialState
  );

  const mergeState = getMergeState(setState);

  function handleChangeDetailedExportForm(
    event: ChangeEvent<HTMLInputElement>
  ) {
    const { name, value } = event.target;

    let isValid = false;
    let hasChanged = false;

    switch (name) {
      case "detailedProjectID": {
        isValid = GCP_PROJECT_ID_REGEX.test(value);
        hasChanged =
          props.integration?.config.detailedBillingExportSource?.projectID !==
          value;
        break;
      }
      case "detailedDatasetID": {
        isValid = BQ_FIELDS_REGEX.test(value);
        hasChanged =
          props.integration?.config.detailedBillingExportSource?.datasetID !==
          value;
        break;
      }
      case "detailedTableID": {
        isValid = BQ_FIELDS_REGEX.test(value);
        hasChanged =
          props.integration?.config.detailedBillingExportSource?.tableID !==
          value;
        break;
      }
      case "detailedLocation": {
        isValid = true;
        hasChanged =
          props.integration?.config.detailedBillingExportSource?.location !==
          value;
        break;
      }
    }

    setState((currentState) => ({
      ...currentState,
      [`${name}Input`]: { value, isValid, hasChanged },
    }));
  }

  function handleChangePricingExportForm(event: ChangeEvent<HTMLInputElement>) {
    const { name, value } = event.target;

    let isValid = false;
    let hasChanged = false;

    switch (name) {
      case "pricingProjectID": {
        isValid = GCP_PROJECT_ID_REGEX.test(value);
        hasChanged =
          props.integration?.config.pricingExportSource?.projectID !== value;
        break;
      }
      case "pricingDatasetID": {
        isValid = BQ_FIELDS_REGEX.test(value);
        hasChanged =
          props.integration?.config.pricingExportSource?.datasetID !== value;
        break;
      }
      case "pricingTableID": {
        isValid = BQ_FIELDS_REGEX.test(value);
        hasChanged =
          props.integration?.config.pricingExportSource?.tableID !== value;
        break;
      }
      case "pricingLocation": {
        isValid = true;
        hasChanged =
          props.integration?.config.pricingExportSource?.location !== value;
        break;
      }
    }

    setState((currentState) => ({
      ...currentState,
      [`${name}Input`]: { value, isValid, hasChanged },
    }));
  }

  const isCarbonEnabled =
    props.integration?.config.carbonFootprintSource !== null;

  const containers = [
    {
      component: (
        <GCPIntegrationFormBasic
          loading={props.isLoading}
          integration={props.integration}
          onUpdate={(inputName: string, inputOrganizationId: string) =>
            mergeState({ nameInput: inputName, inputOrganizationId })
          }
        />
      ),
      label: copyText.cloudAdminBasicInfo,
      value: "basic",
    },
    {
      component: (
        <GCPIntegrationFormBilling
          loading={props.isLoading}
          integration={props.integration}
          onUpdate={onUpdateBilling}
        />
      ),
      label: copyText.cloudGCPAdminBillingData,
      value: "billing",
    },
    {
      component: (
        <GCPIntegrationFormCarbon
          carbonEnabled={isCarbonEnabled}
          isLoading={props.isLoading}
          integration={props.integration}
          onUpdate={onUpdateCarbon}
        />
      ),
      label: copyText.cloudGCPAdminCarbonData,
      value: "carbon",
    },
    {
      component: (
        <GCPIntegrationFormDetailedBillingExport
          isLoading={props.isLoading}
          integration={props.integration}
          datasetIDInput={state.detailedDatasetIDInput}
          locationInput={state.detailedLocationInput}
          projectIDInput={state.detailedProjectIDInput}
          tableIDInput={state.detailedTableIDInput}
          onChange={handleChangeDetailedExportForm}
        />
      ),
      label: copyText.cloudGCPAdminDetailedBillingExport,
      value: "detailed",
    },
    {
      component: (
        <GCPCloudFormPricingExport
          isLoading={props.isLoading}
          integration={props.integration}
          datasetIDInput={state.pricingDatasetIDInput}
          locationInput={state.pricingLocationInput}
          projectIDInput={state.pricingProjectIDInput}
          tableIDInput={state.pricingTableIDInput}
          onChange={handleChangePricingExportForm}
        />
      ),
      label: copyText.cloudGCPAdminPricingExport,
      value: "pricing",
    },
    {
      component: (
        <GCPIntegrationFormBigQuery
          loading={props.isLoading}
          integration={props.integration}
          onUpdate={(bigQueryMonitoringItems: BigQueryMonitoring[]) =>
            mergeState({ bigQueryMonitoringItems })
          }
        />
      ),
      label: copyText.cloudGCPAdminBigQuery,
      value: "bigquery",
    },
  ];

  function handleSubmit(event: FormEvent): void {
    event.preventDefault();

    if (props.integration) {
      const hasChangedDetailedExport =
        state.detailedDatasetIDInput.hasChanged ||
        state.detailedLocationInput.hasChanged ||
        state.detailedProjectIDInput.hasChanged ||
        state.detailedTableIDInput.hasChanged;

      const hasChangedPricingExport =
        state.pricingDatasetIDInput.hasChanged ||
        state.pricingLocationInput.hasChanged ||
        state.pricingProjectIDInput.hasChanged ||
        state.pricingTableIDInput.hasChanged;

      const hasConfigChanges =
        !isEqual(
          state.bigQueryMonitoringItems,
          props.integration.config.bigQueryMonitoring
        ) ||
        state.inputBillingAccountId !==
          props.integration.config.billingAccountID ||
        !isEqual(
          state.billingExportSource,
          props.integration.config.billingExportSource
        ) ||
        !isEqual(
          state.carbonFootprintSource,
          props.integration.config.carbonFootprintSource
        ) ||
        state.inputCommitmentsSharingEnabled !==
          props.integration.config.cudSharingEnabled ||
        state.inputOrganizationId !== props.integration.config.rootElement ||
        hasChangedDetailedExport ||
        hasChangedPricingExport;

      const detailedBillingExportSource = {
        location: state.detailedLocationInput.value,
        datasetID: state.detailedDatasetIDInput.value,
        projectID: state.detailedProjectIDInput.value,
        tableID: state.detailedTableIDInput.value,
      };

      const pricingExportSource = {
        location: state.pricingLocationInput.value,
        datasetID: state.pricingDatasetIDInput.value,
        projectID: state.pricingProjectIDInput.value,
        tableID: state.pricingTableIDInput.value,
      };

      props.onInteraction({
        type: GCPIntegrationForm.INTERACTION_SUBMIT_BUTTON_CLICKED_UPDATE,
        integrationID: props.integration.id,
        ...(hasConfigChanges
          ? {
              config: {
                bigQueryMonitoring: state.bigQueryMonitoringItems,
                billingAccountID: state.inputBillingAccountId,
                billingExportSource: state.billingExportSource,
                carbonFootprintSource: state.carbonFootprintSource,
                detailedBillingExportSource,
                pricingExportSource,
                cudSharingEnabled: state.inputCommitmentsSharingEnabled,
                rootElement: state.inputOrganizationId,
              },
            }
          : {}),
        ...(state.nameInput !== props.integration.name
          ? { name: state.nameInput }
          : {}),
      });
    } else {
      const isValidDetailedExportSource =
        state.detailedDatasetIDInput.isValid &&
        state.detailedLocationInput.isValid &&
        state.detailedProjectIDInput.isValid &&
        state.detailedTableIDInput.isValid;

      const isValidPricingExportSource =
        state.pricingDatasetIDInput.isValid &&
        state.pricingLocationInput.isValid &&
        state.pricingProjectIDInput.isValid &&
        state.pricingTableIDInput.isValid;

      props.onInteraction({
        type: GCPIntegrationForm.INTERACTION_SUBMIT_BUTTON_CLICKED_CREATE,
        ...(state.bigQueryMonitoringItems.length > 0
          ? { bigQueryMonitoring: state.bigQueryMonitoringItems }
          : {}),
        billingAccountID: state.inputBillingAccountId,
        billingExportSource: state.billingExportSource,
        ...(state.carbonFootprintSource
          ? { carbonFootprintSource: state.carbonFootprintSource }
          : {}),
        ...(isValidDetailedExportSource
          ? {
              detailedBillingExportSource: {
                location: state.detailedLocationInput.value,
                datasetID: state.detailedDatasetIDInput.value,
                projectID: state.detailedProjectIDInput.value,
                tableID: state.detailedTableIDInput.value,
              },
            }
          : {}),
        ...(isValidPricingExportSource
          ? {
              pricingExportSource: {
                location: state.pricingLocationInput.value,
                datasetID: state.pricingDatasetIDInput.value,
                projectID: state.pricingProjectIDInput.value,
                tableID: state.pricingTableIDInput.value,
              },
            }
          : {}),
        cudSharingEnabled: state.inputCommitmentsSharingEnabled,
        name: state.nameInput,
        rootElement: state.inputOrganizationId,
      });
    }
  }

  function canUpdate(): boolean {
    const completed =
      state.nameInput.trim().length > 0 &&
      state.inputBillingAccountId.trim().length > 0 &&
      isValidBigQueryTable(state.billingExportSource);

    if (!props.integration) return completed;

    const hasChangedText =
      state.nameInput.trim() !== props.integration.name ||
      state.inputBillingAccountId.trim() !==
        props.integration.config.billingAccountID ||
      !isEqualBigQueryTable(
        state.billingExportSource,
        props.integration.config.billingExportSource
      ) ||
      !isEqualBigQueryMonitoring(
        state.bigQueryMonitoringItems,
        props.integration.config.bigQueryMonitoring
      ) ||
      state.carbonFootprintSource?.datasetID !==
        props.integration.config.carbonFootprintSource?.datasetID ||
      state.carbonFootprintSource?.projectID !==
        props.integration.config.carbonFootprintSource?.projectID ||
      state.carbonFootprintSource?.tableID !==
        props.integration.config.carbonFootprintSource?.tableID ||
      state.detailedDatasetIDInput.hasChanged ||
      state.detailedLocationInput.hasChanged ||
      state.detailedProjectIDInput.hasChanged ||
      state.detailedTableIDInput.hasChanged ||
      state.pricingDatasetIDInput.hasChanged ||
      state.pricingLocationInput.hasChanged ||
      state.pricingProjectIDInput.hasChanged ||
      state.pricingTableIDInput.hasChanged;

    const isValid =
      state.detailedDatasetIDInput.isValid &&
      state.detailedLocationInput.isValid &&
      state.detailedProjectIDInput.isValid &&
      state.detailedTableIDInput.isValid;

    const hasChangedOther =
      state.inputCommitmentsSharingEnabled !==
        props.integration.config.cudSharingEnabled ||
      state.inputOrganizationId !== props.integration.config.rootElement;

    return (hasChangedText || hasChangedOther) && isValid;
  }

  return (
    <Modal
      closeOnClickOutside={false}
      isOpen
      showCloseButton
      onClose={() =>
        props.onInteraction({
          type: GCPIntegrationForm.INTERACTION_CANCEL_BUTTON_CLICKED,
        })
      }
    >
      <Modal.Header>
        <Text appearance="h4">
          {props.integration
            ? copyText.cloudGCPFormTitleUpdate
            : copyText.cloudGCPFormTitleCreate}
        </Text>
      </Modal.Header>
      <Modal.Body>
        {canUpdate() && (
          <FeedbackBar
            text={copyText.cloudAttributeBigQueryFeedbackWarning}
            title={copyText.cloudAttributeBigQueryFeedbackWarningTitle}
            variant={FeedbackBarVariants.warning}
          />
        )}
        <Flex height={450} width={640}>
          <Form autoComplete="off">
            <Tabs
              activeValue={state.tab}
              tabs={containers}
              onSelect={(tab: string) => mergeState({ tab })}
            />
          </Form>
        </Flex>
      </Modal.Body>
      <Modal.Footer>
        <Button
          disabled={!canUpdate() || props.isLoading}
          fullWidth
          primary
          onClick={handleSubmit}
        >
          {props.isLoading ? <LoadingSpinner /> : copyText.submitButtonLabel}
        </Button>
      </Modal.Footer>
    </Modal>
  );

  function onUpdateCarbon(inputCarbonSource: CarbonFootprintSource | null) {
    mergeState({
      carbonFootprintSource: inputCarbonSource,
    });
  }

  function onUpdateBilling(
    inputBillingAccountId: string,
    inputCommitmentsSharingEnabled: boolean,
    billingExportSource: BillingExportSource
  ) {
    mergeState({
      billingExportSource,
      inputBillingAccountId,
      inputCommitmentsSharingEnabled,
    });
  }
}

export function getErrorMessageIfPresent(
  integration: GCPIntegration | undefined,
  validationName: CloudCapability
): string | undefined {
  if (!integration) return undefined;

  if (integration.validations.length === 0) return "";

  const validation = integration.validations.find(
    (validation) => validation.name === validationName
  );

  if (!validation) return undefined;

  return validation.success ? undefined : validation.error;
}

export function isValidBigQueryTable(table: BillingExportSource): boolean {
  return (
    table.datasetID !== "" &&
    table.projectID !== "" &&
    table.tableID !== "" &&
    table.location !== ""
  );
}

export function isEqualBigQueryTable(
  a: BillingExportSource,
  b: BillingExportSource | null
): boolean {
  if (!b) {
    return false;
  }
  return (
    a.datasetID === b.datasetID &&
    a.projectID === b.projectID &&
    a.tableID === b.tableID &&
    a.location === b.location
  );
}

function stringArrayEqual(a: string[], b: string[]): boolean {
  if (a.length !== b.length) {
    return false;
  }

  for (const i in a) {
    if (a[i] !== b[i]) return false;
  }

  return true;
}

function isEqualBigQueryMonitoring(
  a: BigQueryMonitoring[],
  b: BigQueryMonitoring[] | null
): boolean {
  if (!b) {
    return false;
  }
  if (a?.length !== b?.length) {
    return false;
  }

  for (const i in a) {
    const isEqual =
      a[i].infoSchemaTable === b[i].infoSchemaTable &&
      a[i].projectID === b[i].projectID &&
      stringArrayEqual(a[i].regions, b[i].regions);

    if (!isEqual) {
      return false;
    }
  }

  return true;
}

export function renderRadioInput(
  params: { stateKey: string; value: string },
  labelText: { [key: string]: string },
  disabled: boolean,
  state: object,
  mergeState: (state) => void
): JSX.Element {
  return (
    <Flex key={params.value} alignItems="center" marginRight={"1rem"}>
      <input
        checked={state[params.stateKey] === params.value}
        disabled={disabled}
        type="radio"
        value={params.value}
        onChange={() => mergeState({ [params.stateKey]: params.value })}
      />
      <Text>{labelText[params.value]}</Text>
    </Flex>
  );
}

function getBillingExportState(exportSource: BillingExportSource | null) {
  if (!exportSource) {
    return {
      detailedDatasetIDInput: {
        value: "",
        isValid: true,
        hasChanged: false,
      },
      detailedLocationInput: { value: "", isValid: true, hasChanged: false },
      detailedProjectIDInput: {
        value: "",
        isValid: true,
        hasChanged: false,
      },
      detailedTableIDInput: { value: "", isValid: true, hasChanged: false },
    };
  }

  return {
    detailedDatasetIDInput: {
      value: exportSource.datasetID,
      isValid: true,
      hasChanged: false,
    },
    detailedLocationInput: {
      value: exportSource.location,
      isValid: true,
      hasChanged: false,
    },
    detailedProjectIDInput: {
      value: exportSource.projectID,
      isValid: true,
      hasChanged: false,
    },
    detailedTableIDInput: {
      value: exportSource.tableID,
      isValid: true,
      hasChanged: false,
    },
  };
}

function getPricingExportState(exportSource: PricingExportSource | null) {
  if (!exportSource) {
    return {
      pricingDatasetIDInput: {
        value: "",
        isValid: true,
        hasChanged: false,
      },
      pricingLocationInput: { value: "", isValid: true, hasChanged: false },
      pricingProjectIDInput: {
        value: "",
        isValid: true,
        hasChanged: false,
      },
      pricingTableIDInput: { value: "", isValid: true, hasChanged: false },
    };
  }

  return {
    pricingDatasetIDInput: {
      value: exportSource.datasetID,
      isValid: true,
      hasChanged: false,
    },
    pricingLocationInput: {
      value: exportSource.location,
      isValid: true,
      hasChanged: false,
    },
    pricingProjectIDInput: {
      value: exportSource.projectID,
      isValid: true,
      hasChanged: false,
    },
    pricingTableIDInput: {
      value: exportSource.tableID,
      isValid: true,
      hasChanged: false,
    },
  };
}
GCPIntegrationForm.INTERACTION_REFRESH_BUTTON_CLICKED =
  `GCPIntegrationForm.INTERACTION_REFRESH_BUTTON_CLICKED` as const;

GCPIntegrationForm.INTERACTION_SUBMIT_BUTTON_CLICKED_UPDATE =
  `GCPIntegrationForm.INTERACTION_SUBMIT_BUTTON_CLICKED_UPDATE` as const;

GCPIntegrationForm.INTERACTION_SUBMIT_BUTTON_CLICKED_CREATE =
  `GCPIntegrationForm.INTERACTION_SUBMIT_BUTTON_CLICKED_CREATE` as const;

GCPIntegrationForm.INTERACTION_CANCEL_BUTTON_CLICKED =
  `GCPIntegrationForm.INTERACTION_CANCEL_BUTTON_CLICKED` as const;

interface InteractionRefreshButtonClicked {
  type: typeof GCPIntegrationForm.INTERACTION_REFRESH_BUTTON_CLICKED;
  integrationID: string;
}

interface InteractionSubmitButtonClickedCreate {
  type: typeof GCPIntegrationForm.INTERACTION_SUBMIT_BUTTON_CLICKED_CREATE;
  bigQueryMonitoring?: BigQueryMonitoring[];
  billingAccountID: string;
  billingExportSource: BillingExportSource;
  carbonFootprintSource?: CarbonFootprintSource;
  cudSharingEnabled: boolean;
  detailedBillingExportSource?: BillingExportSource;
  pricingExportSource?: PricingExportSource;
  name: string;
  rootElement: string;
}

interface InteractionSubmitButtonClickedUpdate {
  type: typeof GCPIntegrationForm.INTERACTION_SUBMIT_BUTTON_CLICKED_UPDATE;
  integrationID: string;
  config?: {
    bigQueryMonitoring: BigQueryMonitoring[];
    billingAccountID: string;
    billingExportSource?: BillingExportSource;
    carbonFootprintSource: CarbonFootprintSource | null;
    cudSharingEnabled: boolean;
    detailedBillingExportSource: BillingExportSource | null;
    pricingExportSource: PricingExportSource | null;
    rootElement: string;
  };
  name?: string;
}

interface InteractionCancelButtonClicked {
  type: typeof GCPIntegrationForm.INTERACTION_CANCEL_BUTTON_CLICKED;
}

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace GCPIntegrationForm {
  export type Interaction =
    | InteractionRefreshButtonClicked
    | InteractionSubmitButtonClickedUpdate
    | InteractionSubmitButtonClickedCreate
    | InteractionCancelButtonClicked;
}

export default GCPIntegrationForm;
