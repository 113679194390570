import { add, sub } from "date-fns";
import { UnitType } from "../constants/analytics";
import {
  ChartType,
  DataSource,
  DurationType,
  MetricAggregate,
  Operator,
} from "../constants/enums";
import { Measure } from "../ui-lib/charts/types";
import {
  COMPARISON_KEY,
  DEFAULT_X_AXIS_KEY,
  PERCENT_DIFFERENCE_KEY,
  RAW_DIFFERENCE_KEY,
} from "../ui-lib/charts/utils";
import {
  MergeType,
  mergeRawDataIntoOther,
} from "../ui-lib/utils/ChartDataManager";
import { sortRawData } from "../ui-lib/utils/sort";
import {
  DataSourceFilter,
  getBillingMeasures,
  getComparisonData,
  getComparisonDateRangeFromReport,
  getCumulativeData,
  getDateRangeFromReport,
  getIsInvoiceMonthMode,
  getMappedAndFilteredData,
  getMeasureFromMetricAggregate,
  getNonUtilizationMeasures,
  getShouldApplyGranularity,
  getUtilizationMeasures,
  mergeRawData,
  mergeUtilizationData,
  removeOtherData,
} from "../utils/ReportUtils";
import getTopNRawData, {
  AnalyticsApiClient,
  Dependencies as GetTopNRawDataDependencies,
} from "./getTopNRawData";
import { LabelMap, Order, RawData, ReportDataConfig } from "./types";
import { getInvoiceMonthFilters, getMeasureUnit } from "./utils";

const initialReport: ReportDataConfig = {
  id: "",
  chartType: ChartType.STACKED_AREA,
  compareEndDate: null,
  compareStartDate: null,
  compareDurationType: null,
  dataSource: DataSource.BILLING,
  dimensions: [],
  durationType: DurationType.LAST_THIRTY_DAYS,
  endDate: null,
  excludedCreditTypes: [],
  excludeNegativeNumbers: false,
  excludeOther: false,
  filters: [],
  formula: null,
  formulaAlias: null,
  hiddenMeasures: [],
  invoiceMonthEnd: null,
  invoiceMonthStart: null,
  isCumulative: false,
  isFormulaHidden: false,
  isMetricHidden: false,
  limit: null,
  measures: [],
  metric: null,
  metricAggregate: null,
  metricFilters: [],
  nLookback: null,
  reverse: false,
  sortRule: null,
  startDate: null,
  timeGranularity: null,
  xAxisKey: null,
};

type ReportConfig = {
  report?: ReportDataConfig;
  searchText?: string;
};

export type ReportDataResult = {
  data: RawData[];
  isLargeDataSet: boolean;
};

export type Dependencies = {
  client: AnalyticsApiClient;
  fiscalPeriodMap: { [key: string]: string };
  globalFilters: DataSourceFilter[];
  integrationMetricMap: { [key: string]: { name: string } };
  isFiscalMode: boolean;
  labelMap: LabelMap;
};

export default async function getReportData(
  dependencies: Dependencies,
  config: ReportConfig
): Promise<ReportDataResult> {
  const getTopNRawDataDependencies: GetTopNRawDataDependencies = {
    client: dependencies.client,
    labelMap: dependencies.labelMap,
  };

  const report: ReportDataConfig = config.report ?? initialReport;

  const measures = report.measures.reduce((accum: Measure[], measure) => {
    const currentMeasure = {
      name: measure,
      unit: getMeasureUnit(measure, report.dataSource),
    };

    if (report.compareDurationType) {
      const previousMeasure = {
        name: `${measure}${COMPARISON_KEY}`,
        unit: getMeasureUnit(measure, report.dataSource),
      };

      const deltaMeasures: Measure[] = [];

      if (report.measures.length === 1) {
        deltaMeasures.push({
          name: RAW_DIFFERENCE_KEY,
          unit: getMeasureUnit(measure, report.dataSource),
        });
      }

      if (
        report.measures.length === 1 &&
        report.chartType === ChartType.TABLE
      ) {
        deltaMeasures.push({
          name: PERCENT_DIFFERENCE_KEY,
          unit: UnitType.STANDARD,
        });
      }

      return [...accum, currentMeasure, previousMeasure, ...deltaMeasures];
    }

    return [...accum, currentMeasure];
  }, []);

  const shouldApplyInvoiceMonthFilters =
    report.durationType === DurationType.INVOICE &&
    report.dataSource === DataSource.BILLING;

  let dateRange = getDateRangeFromReport(report);
  let compareDateRange = getComparisonDateRangeFromReport(report);

  const invoiceMonthFilters = shouldApplyInvoiceMonthFilters
    ? getInvoiceMonthFilters(dateRange)
    : [];
  const invoiceMonthCompareFilters =
    shouldApplyInvoiceMonthFilters && report.compareDurationType
      ? getInvoiceMonthFilters(compareDateRange)
      : [];

  if (shouldApplyInvoiceMonthFilters) {
    // Note: "invoiceMonth" filters are used to determine what data to include in the month.
    // dateRange padding is added to ensure nothing is left out

    dateRange = [
      sub(dateRange[0], { days: 10 }),
      add(dateRange[1], { days: 10 }),
    ];
  }

  if (shouldApplyInvoiceMonthFilters && compareDateRange.length > 0) {
    compareDateRange = [
      sub(compareDateRange[0], { days: 10 }),
      add(compareDateRange[1], { days: 10 }),
    ];
  }

  const qualifiedFilters = report.filters.filter(
    (filter) => !filter.values || (filter.values && filter.values.length > 0)
  );

  const excludeNegativesQueryFilter = report.excludeNegativeNumbers
    ? report.measures.map((measure) => {
        return { name: measure, operator: Operator.GTE, values: ["0"] };
      })
    : [];

  const queryFilters = [...qualifiedFilters, ...excludeNegativesQueryFilter];

  const hasMeasure = report.measures.length > 0;

  const isInvoiceMonthMode = getIsInvoiceMonthMode(report);

  const shouldApplyGranularity = getShouldApplyGranularity(report);

  const showUnitEconomics = !!report.formula || !!report.metric;

  const applyLimit =
    report.chartType === ChartType.TABLE && report.limit && report.measures[0];

  const dimensions =
    isInvoiceMonthMode && report.chartType !== ChartType.TABLE
      ? [...report.dimensions, "invoiceMonth"]
      : report.dimensions;

  const selectedMetric = report.metric
    ? dependencies.integrationMetricMap[report.metric]
    : undefined;

  const selectedMetricName = selectedMetric ? selectedMetric.name : undefined;

  //
  // FULL DATASET
  //

  const shouldFetchFullDataSet =
    !!config.report && hasMeasure && report.chartType === ChartType.TABLE;

  let fullDataSetMeasures: string[] = [];
  if (report.dataSource === DataSource.BILLING) {
    fullDataSetMeasures = getBillingMeasures(report.measures);
  } else if (report.dataSource === DataSource.AWS_COMPUTE_VISIBILITY) {
    fullDataSetMeasures = getNonUtilizationMeasures(report.measures);
  } else {
    fullDataSetMeasures = report.measures;
  }

  let fullDataSetPromise: Promise<RawData[]>;

  if (!shouldFetchFullDataSet) {
    fullDataSetPromise = Promise.resolve([]);
  } else {
    fullDataSetPromise = getTopNRawData(getTopNRawDataDependencies, {
      dataSource: report.dataSource,
      dateRange,
      dimensions,
      durationType: report.durationType,
      fiscalPeriodMap: dependencies.fiscalPeriodMap,
      isFiscalMode: dependencies.isFiscalMode && !isInvoiceMonthMode,
      granularity: shouldApplyGranularity
        ? (report.timeGranularity ?? undefined)
        : undefined,
      ...(applyLimit
        ? {
            limit: report.limit ?? undefined,
            order: { [report.measures[0]]: "desc" },
          }
        : {}),
      measures: fullDataSetMeasures,
      overflow: report.chartType === ChartType.TABLE,
      queryFilters: [
        ...dependencies.globalFilters,
        ...queryFilters,
        ...invoiceMonthFilters,
      ],
    });
  }

  const fullDataSet = await fullDataSetPromise;

  const isLargeDataSet = fullDataSet.length >= 50000;

  const defaultOrder: Order = {
    [report.measures[0]]: "desc",
  };

  const order: Order = report.sortRule
    ? {
        [report.sortRule.id]: report.sortRule.desc ? "desc" : "asc",
      }
    : defaultOrder;

  const searchText = config.searchText ?? "";

  const debouncedSearchFilters =
    searchText.length > 0
      ? report.dimensions.map((dimension) => {
          return {
            name: dimension,
            operator: Operator.CONTAINS,
            values: [searchText],
          };
        })
      : [];

  //
  // RAW DATA
  //

  const shouldFetchRawData = !!config.report && hasMeasure;

  let rawDataMeasures: string[] = [];
  if (report.dataSource === DataSource.BILLING) {
    rawDataMeasures = getBillingMeasures(report.measures);
  } else if (report.dataSource === DataSource.AWS_COMPUTE_VISIBILITY) {
    rawDataMeasures = getNonUtilizationMeasures(report.measures);
  } else {
    rawDataMeasures = report.measures;
  }

  let rawDataPromise: Promise<RawData[]>;

  if (!shouldFetchRawData) {
    rawDataPromise = Promise.resolve([]);
  } else {
    rawDataPromise = getTopNRawData(getTopNRawDataDependencies, {
      dataSource: report.dataSource,
      dateRange,
      dimensions: dimensions,
      durationType: report.durationType,
      fiscalPeriodMap: dependencies.fiscalPeriodMap,
      isFiscalMode: dependencies.isFiscalMode && !isInvoiceMonthMode,
      granularity: shouldApplyGranularity
        ? (report.timeGranularity ?? undefined)
        : undefined,
      ...(applyLimit
        ? {
            limit: report.limit ?? undefined,
            order: { [report.measures[0]]: "desc" },
          }
        : {}),
      measures: rawDataMeasures,
      order: isLargeDataSet ? order : defaultOrder,
      overflow: report.chartType === ChartType.TABLE,
      queryFilters: [
        ...dependencies.globalFilters,
        ...queryFilters,
        ...invoiceMonthFilters,
        ...(isLargeDataSet ? [{ or: debouncedSearchFilters }] : []),
      ],
    });
  }

  //
  // UTILIZATION DATA
  //

  const utilizationMeasures = getUtilizationMeasures(report.measures);

  const shouldFetchUtilizationData =
    !!config.report &&
    hasMeasure &&
    report.dataSource === DataSource.AWS_COMPUTE_VISIBILITY &&
    getUtilizationMeasures(report.measures).length > 0;

  let utilizationDataPromise: Promise<RawData[]>;

  if (!shouldFetchUtilizationData) {
    utilizationDataPromise = Promise.resolve([]);
  } else {
    utilizationDataPromise = getTopNRawData(getTopNRawDataDependencies, {
      dataSource: DataSource.AWS_COMPUTE_UTILIZATION,
      dateRange,
      dimensions: [],
      durationType: report.durationType,
      fiscalPeriodMap: dependencies.fiscalPeriodMap,
      isFiscalMode: dependencies.isFiscalMode && !isInvoiceMonthMode,
      granularity: shouldApplyGranularity
        ? (report.timeGranularity ?? undefined)
        : undefined,
      ...(applyLimit
        ? {
            limit: report.limit ?? undefined,
            order: { [report.measures[0]]: "desc" },
          }
        : {}),
      measures: utilizationMeasures,
      order: isLargeDataSet ? order : defaultOrder,
      overflow: report.chartType === ChartType.TABLE,
      queryFilters: [
        ...dependencies.globalFilters,
        ...queryFilters,
        ...invoiceMonthFilters,
        ...(isLargeDataSet ? [{ or: debouncedSearchFilters }] : []),
      ],
    });
  }

  //
  // COMPARE DATA
  //

  const shouldFetchCompareData =
    config.report && !!report.compareDurationType && hasMeasure;

  let compareDataMeasures: string[] = [];
  if (report.dataSource === DataSource.BILLING) {
    compareDataMeasures = getBillingMeasures(report.measures);
  } else if (report.dataSource === DataSource.AWS_COMPUTE_VISIBILITY) {
    compareDataMeasures = getNonUtilizationMeasures(report.measures);
  } else {
    compareDataMeasures = report.measures;
  }

  let compareDataPromise: Promise<RawData[]>;

  if (!shouldFetchCompareData) {
    compareDataPromise = Promise.resolve([]);
  } else {
    compareDataPromise = getTopNRawData(getTopNRawDataDependencies, {
      dataSource: report.dataSource,
      dateRange: compareDateRange,
      dimensions: dimensions,
      durationType: report.durationType,
      fiscalPeriodMap: dependencies.fiscalPeriodMap,
      isComparisonMode: true,
      isFiscalMode: dependencies.isFiscalMode && !isInvoiceMonthMode,
      granularity: shouldApplyGranularity
        ? (report.timeGranularity ?? undefined)
        : undefined,
      measures: compareDataMeasures,
      order: isLargeDataSet ? order : defaultOrder,
      overflow: report.chartType === ChartType.TABLE,
      queryFilters: [
        ...dependencies.globalFilters,
        ...queryFilters,
        ...invoiceMonthCompareFilters,
        ...(isLargeDataSet ? [{ or: debouncedSearchFilters }] : []),
      ],
    });
  }

  //
  // RAW DATA EXTERNAL METRIC
  //

  const qualifiedMetricFilters = report.metricFilters.filter(
    (metricFilter) =>
      !metricFilter.values ||
      (metricFilter.values && metricFilter.values.length > 0)
  );

  const shouldFetchRawDataExternalMetric =
    !!config.report &&
    (report.metric ?? "").length > 0 &&
    report.dimensions.length === 0;

  let rawDataExternalMetricPromise: Promise<RawData[]>;

  if (!shouldFetchRawDataExternalMetric) {
    rawDataExternalMetricPromise = Promise.resolve([]);
  } else {
    rawDataExternalMetricPromise = getTopNRawData(getTopNRawDataDependencies, {
      dataSource: DataSource.EXTERNAL_METRICS,
      dateRange,
      durationType: report.durationType,
      fiscalPeriodMap: dependencies.fiscalPeriodMap,
      isFiscalMode: dependencies.isFiscalMode && !isInvoiceMonthMode,
      granularity: shouldApplyGranularity
        ? (report.timeGranularity ?? undefined)
        : undefined,
      measures: [
        getMeasureFromMetricAggregate(
          report.metricAggregate ?? MetricAggregate.SUM
        ),
      ],
      ...(isLargeDataSet ? { order } : {}),
      overflow: report.chartType === ChartType.TABLE,
      queryFilters: [
        {
          name: "metric",
          operator: Operator.EQUALS,
          values: [report.metric ?? ""],
        },
        // invoiceMonth does not exist on ExternalMetrics so don't add it here
        ...qualifiedMetricFilters,
        ...(isLargeDataSet ? [{ or: debouncedSearchFilters }] : []),
      ],
    });
  }

  const [rawData, compareData, utilizationData, rawDataExternalMetric] =
    await Promise.all([
      rawDataPromise,
      compareDataPromise,
      utilizationDataPromise,
      rawDataExternalMetricPromise,
    ]);

  const data = (() => {
    if (!rawData && !rawDataExternalMetric) return [];

    if (rawData && report.dimensions.length > 0) {
      return getMappedAndFilteredData(rawData, report.excludedCreditTypes);
    }

    return rawData.length !== 0
      ? mergeRawData({
          data: getMappedAndFilteredData(rawData, report.excludedCreditTypes),
          externalData: rawDataExternalMetric ?? [],
          formula: report.formula ?? "",
          formulaAlias: report.formulaAlias ?? "",
          measures,
          metricAggregate: report.metricAggregate,
          selectedMetricName,
        })
      : getMappedAndFilteredData(
          showUnitEconomics &&
            rawDataExternalMetric &&
            report.dimensions.length === 0
            ? rawDataExternalMetric
            : [],
          report.excludedCreditTypes,
          report.metricAggregate,
          selectedMetricName
        );
  })();

  const xAxisKey = isInvoiceMonthMode
    ? "invoiceMonth"
    : report.xAxisKey
      ? report.xAxisKey
      : shouldApplyGranularity
        ? DEFAULT_X_AXIS_KEY
        : undefined;

  const sortedData = sortRawData({
    data: [...data],
    groupingKeys: report.dimensions,
    order: {
      xAxis: shouldApplyGranularity ? "asc" : "desc",
      yAxis: "desc",
    },
    xAxisKey,
    yAxisKeys: report.measures,
  });

  const sortedComparisonData = sortRawData({
    data: [
      ...(getMappedAndFilteredData(
        compareData ?? [],
        report.excludedCreditTypes
      ) ?? []),
    ],
    groupingKeys: report.dimensions,
    order: {
      xAxis: shouldApplyGranularity ? "asc" : "desc",
      yAxis: "desc",
    },
    xAxisKey,
    yAxisKeys: report.measures,
  });

  let modifiedData = sortedData;

  if (report.dataSource === DataSource.AWS_COMPUTE_VISIBILITY) {
    modifiedData = mergeUtilizationData({
      report,
      sourceData: sortedData ?? [],
      utilizationData: utilizationData ?? [],
    });
  }

  if (report.compareDurationType && sortedComparisonData) {
    modifiedData = getComparisonData({
      compareData: sortedComparisonData,
      compareDurationType: report.compareDurationType,
      data: modifiedData,
      dimensions: report.dimensions,
      measures: report.measures,
    });
  }

  if (report.limit && !applyLimit) {
    modifiedData = mergeRawDataIntoOther({
      data: modifiedData,
      nonOtherCount: report.limit,
      dimensions: report.dimensions,
      measures: report.measures,
      mergeType: shouldApplyGranularity
        ? MergeType.TOP_GROUPINGS
        : xAxisKey !== DEFAULT_X_AXIS_KEY
          ? MergeType.EACH_XAXIS_KEY
          : MergeType.TOP_ITEMS,
      xAxisKey: xAxisKey,
    });
  }

  if (report.excludeOther) {
    modifiedData = removeOtherData({
      data: modifiedData,
      dimensions,
    });
  }

  if (report.reverse) {
    modifiedData = sortRawData({
      data: [...data],
      groupingKeys: report.dimensions,
      order: {
        xAxis: shouldApplyGranularity ? "desc" : "asc",
        yAxis: "desc",
      },
      xAxisKey,
      yAxisKeys: report.measures,
    });
  }

  if (report.isCumulative) {
    modifiedData = getCumulativeData({
      data: modifiedData,
      dimensions: report.dimensions,
      measures: report.measures,
    });
  }

  return {
    data: modifiedData,
    isLargeDataSet,
  };
}
