import { useTheme } from "@emotion/react";
import {
  AzureCommitmentLookbackPeriod,
  AzureRateRecommendationTerm,
  AzureRateType,
} from "@ternary/api-lib/constants/enums";
import { AzureRateRecommendationEntity } from "@ternary/api-lib/core/types";
import Box from "@ternary/api-lib/ui-lib/components/Box";
import Button from "@ternary/api-lib/ui-lib/components/Button";
import Flex from "@ternary/api-lib/ui-lib/components/Flex";
import { noop } from "lodash";
import React from "react";
import Divider from "../../../../ui-lib/components/Divider";
import SelectDropdown from "../../../../ui-lib/components/SelectDropdown";
import { getPayerAccountIDs } from "../../aws/utils";
import copyText from "../../copyText";
import { AzureCommittedUseFilter } from "../types";

type Props = {
  cloudNamesKeyedByCloudID: Record<string, string>;
  committedUseType: AzureRateType;
  filters: AzureCommittedUseFilter;
  isLoading: boolean;
  reservedInstanceRecommendations: AzureRateRecommendationEntity[];
  savingsPlanRecommendations: AzureRateRecommendationEntity[];
  onInteraction: (
    interaction: AzureCommittedUseFilterControls.Interaction
  ) => void;
};

type Option = { label: string; value: string };

const typeOptions: Option[] = [
  { label: "Savings Plan", value: AzureRateType.SP },
  { label: "Reserved Instance", value: AzureRateType.RI },
];

const termOptions: Option[] = [
  { label: "1 year", value: AzureRateRecommendationTerm.ONE_YEAR },
  { label: "3 years", value: AzureRateRecommendationTerm.THREE_YEARS },
];

const lookbackPeriodOptions: Option[] = [
  { label: "7D", value: AzureCommitmentLookbackPeriod.SEVEN_DAYS },
  { label: "14D", value: AzureCommitmentLookbackPeriod.FOURTEEN_DAYS },
  { label: "30D", value: AzureCommitmentLookbackPeriod.THIRTY_DAYS },
  { label: "60D", value: AzureCommitmentLookbackPeriod.SIXTY_DAYS },
];
/* prettier-ignore */
const riCoverageTypeOptions: Option[] = [
  { label: "All", value: "All" },
  { label: "App Service", value: "AppService" },
  { label: "Block Blob", value: "BlockBlob" },
  { label: "Cache for Redis",value: "RedisCache"},
  { label: "MySQL", value: "MySQL" },
  { label: "PostgreSQL", value: "PostgreSQL" },
  { label: "Virtual Machines",value: "VirtualMachines"},
  { label: "SQL Databases",value: "SQLDatabases"},
];

const spCoverageTypeOptions: Option[] = [
  { label: "Compute SP", value: "Compute_Savings_Plan" },
];

function findMatchingOption(options: Option[], value: string) {
  const found = options.find((option) =>
    value === null ? option.value === "null" : value === option.value
  );

  return found ?? options[0];
}

function AzureCommittedUseFilterControls(props: Props) {
  const theme = useTheme();

  const selectedTypeOption = findMatchingOption(
    typeOptions,
    props.committedUseType
  );
  const selectedTermOption = findMatchingOption(
    termOptions,
    props.filters.term
  );
  const selectedLookbackPeriodOption = findMatchingOption(
    lookbackPeriodOptions,
    props.filters.lookbackPeriod
  );

  const selectedRICoverageTypeOption =
    riCoverageTypeOptions.find(
      (option) => option.value === props.filters.coverageType
    ) ?? riCoverageTypeOptions[0];

  const selectedSPCoverageTypeOption =
    spCoverageTypeOptions.find(
      (option) => option.value === props.filters.coverageType
    ) ?? spCoverageTypeOptions[0];

  // PAYER ACCOUNT IDS

  const allPayerAccountIDs = getPayerAccountIDs([
    ...props.reservedInstanceRecommendations,
    ...props.savingsPlanRecommendations,
  ]);

  const allPayerAccountOptions: Option[] = allPayerAccountIDs.map(
    (accountID) => ({
      label: props.cloudNamesKeyedByCloudID[accountID] ?? accountID,
      value: accountID,
    })
  );

  const selectedPayerAccountID = props.filters.payerAccountID;
  const selectedPayerAccountName =
    props.cloudNamesKeyedByCloudID[selectedPayerAccountID] ??
    selectedPayerAccountID;

  const coverageTypeOptions =
    props.committedUseType === AzureRateType.SP
      ? spCoverageTypeOptions
      : riCoverageTypeOptions;

  const selectedCoverageTypeOption =
    props.committedUseType === AzureRateType.SP
      ? selectedSPCoverageTypeOption
      : selectedRICoverageTypeOption;

  return (
    <Box
      backgroundColor={theme.panel_backgroundColor}
      borderRadius={theme.borderRadius_1}
      paddingVertical={theme.space_sm}
    >
      <Flex paddingHorizontal={theme.space_md} justifyContent="space-between">
        <Flex>
          <SelectDropdown
            hideSelectedOptions={false}
            options={allPayerAccountOptions}
            placement="bottom-start"
            selectedValues={[selectedPayerAccountID]}
            onChange={(value: string) =>
              props.onInteraction({
                type: AzureCommittedUseFilterControls.INTERACTION_CHANGE_PAYER_ACCOUNT_ID,
                accountID: value,
              })
            }
          >
            <Button marginRight={theme.space_md} secondary size="small">
              {getAccountCopyText(copyText.azureFiltersAccountIDsTypePayer, [
                selectedPayerAccountName,
              ])}
            </Button>
          </SelectDropdown>

          <SelectDropdown
            hideSelectedOptions={false}
            options={coverageTypeOptions}
            placement="bottom-start"
            selectedValues={[selectedCoverageTypeOption.value]}
            onChange={(value: string) =>
              props.onInteraction({
                type: AzureCommittedUseFilterControls.INTERACTION_COVERAGE_TYPE_CLICKED,
                coverageType: value,
              })
            }
          >
            <Button marginRight={theme.space_md} secondary size="small">
              {copyText.azureFiltersCoverageType.replace(
                "%VALUE%",
                selectedCoverageTypeOption.label
              )}
            </Button>
          </SelectDropdown>
        </Flex>

        <Box>
          {lookbackPeriodOptions.map((option) => (
            <Button
              key={option.value}
              marginLeft={theme.space_xs}
              primary={option.value === selectedLookbackPeriodOption.value}
              secondary={option.value !== selectedLookbackPeriodOption.value}
              size="small"
              onClick={() =>
                props.onInteraction({
                  type: AzureCommittedUseFilterControls.INTERACTION_CHANGE_LOOKBACK_PERIOD,
                  lookbackPeriod: option.value,
                })
              }
            >
              {option.label}
            </Button>
          ))}
        </Box>
      </Flex>

      <Box paddingHorizontal={theme.space_xs}>
        <Divider margin={theme.space_sm} />
      </Box>

      <Flex paddingHorizontal={theme.space_md}>
        <SelectDropdown
          hideSelectedOptions={false}
          options={typeOptions}
          placement="bottom-start"
          selectedValues={[selectedTypeOption.value]}
          onChange={(value: string) =>
            value === AzureRateType.RI || value === AzureRateType.SP
              ? props.onInteraction({
                  type: AzureCommittedUseFilterControls.INTERACTION_CHANGE_TYPE,
                  committedUseType: value,
                })
              : noop()
          }
        >
          <Button marginRight={theme.space_md} secondary size="small">
            {copyText.azureFiltersType.replace(
              "%VALUE%",
              selectedTypeOption.label
            )}
          </Button>
        </SelectDropdown>

        <SelectDropdown
          hideSelectedOptions={false}
          options={termOptions}
          placement="bottom-start"
          selectedValues={[selectedTermOption.value]}
          onChange={(value: string) =>
            props.onInteraction({
              type: AzureCommittedUseFilterControls.INTERACTION_CHANGE_TERM,
              term: value,
            })
          }
        >
          <Button marginRight={theme.space_md} secondary size="small">
            {copyText.azureFiltersTerm.replace(
              "%VALUE%",
              selectedTermOption.label
            )}
          </Button>
        </SelectDropdown>
      </Flex>
    </Box>
  );
}

function getAccountCopyText(type: string, items: string[]) {
  return copyText.azureFiltersAccountID
    .replace("%TYPE%", type)
    .replace("%VALUE%", items[0]);
}

AzureCommittedUseFilterControls.INTERACTION_CHANGE_LOOKBACK_PERIOD =
  `AzureCommittedUseFilterControls.INTERACTION_CHANGE_LOOKBACK_PERIOD` as const;
AzureCommittedUseFilterControls.INTERACTION_CHANGE_PAYER_ACCOUNT_ID =
  `AzureCommittedUseFilterControls.INTERACTION_CHANGE_PAYER_ACCOUNT_ID` as const;
AzureCommittedUseFilterControls.INTERACTION_CHANGE_TERM =
  `AzureCommittedUseFilterControls.INTERACTION_CHANGE_TERM` as const;
AzureCommittedUseFilterControls.INTERACTION_CHANGE_TYPE =
  `AzureCommittedUseFilterControls.INTERACTION_CHANGE_TYPE` as const;
AzureCommittedUseFilterControls.INTERACTION_COVERAGE_TYPE_CLICKED =
  `AzureCommittedUseFilterControls.INTERACTION_COVERAGE_TYPE_CLICKED` as const;

interface InteractionChangeLookbackPeriod {
  type: typeof AzureCommittedUseFilterControls.INTERACTION_CHANGE_LOOKBACK_PERIOD;
  lookbackPeriod: string;
}

interface InteractionChangePayerAccountID {
  type: typeof AzureCommittedUseFilterControls.INTERACTION_CHANGE_PAYER_ACCOUNT_ID;
  accountID: string;
}

interface InteractionChangeTerm {
  type: typeof AzureCommittedUseFilterControls.INTERACTION_CHANGE_TERM;
  term: string;
}

interface InteractionChangeType {
  type: typeof AzureCommittedUseFilterControls.INTERACTION_CHANGE_TYPE;
  committedUseType: AzureRateType;
}
interface InteractionChangeCoverage {
  type: typeof AzureCommittedUseFilterControls.INTERACTION_COVERAGE_TYPE_CLICKED;
  coverageType: string;
}

// eslint-disable-next-line @typescript-eslint/no-namespace
namespace AzureCommittedUseFilterControls {
  export type Interaction =
    | InteractionChangeLookbackPeriod
    | InteractionChangePayerAccountID
    | InteractionChangeTerm
    | InteractionChangeType
    | InteractionChangeCoverage;
}

export default AzureCommittedUseFilterControls;
