import { DateRange } from "@/utils/dates";
import getMergeState from "@/utils/getMergeState";
import { useTheme } from "@emotion/react";
import {
  faCheck,
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import {
  CompareDurationType,
  DurationType,
} from "@ternary/api-lib/constants/enums";
import Button from "@ternary/api-lib/ui-lib/components/Button";
import Icon from "@ternary/api-lib/ui-lib/components/Icon";
import timing from "@ternary/api-lib/ui-lib/constants/timing";
import Box from "@ternary/web-ui-lib/components/Box";
import Flex from "@ternary/web-ui-lib/components/Flex";
import Text from "@ternary/web-ui-lib/components/Text";
import { formatDate } from "@ternary/web-ui-lib/utils/dates";
import { add } from "date-fns";
import React, { useEffect, useMemo, useState } from "react";
import copyText from "../../copyText";
import Form from "../Form";
import TextInput from "../TextInput";
import DatePicker from "./DatePicker";
import Dropdown from "./Dropdown";
import { CompareOption } from "./styled";

type Props = React.PropsWithChildren<{
  dateRange?: DateRange | null;
  disabled?: boolean;
  compareDurationType?: CompareDurationType;
  durationType: DurationType;
  isOpen: boolean;
  maxDate?: Date;
  minDate?: Date;
  n?: number | null;
  numberOfDaysInRange: number;
  onChangeDateRange: (
    durationType: CompareDurationType,
    dateRange: DateRange | undefined
  ) => void;
  onClose: () => void;
  onOpen: () => void;
}>;

interface State {
  durationType?: CompareDurationType;
  startDate?: Date;
  startInput?: string;
  displayMonth?: "start" | "end";
  showCustomDatePicker: boolean;
}

export default function CompareDatePickerContent(props: Props): JSX.Element {
  const theme = useTheme();

  const [state, setState] = useState<State>({
    durationType: undefined,
    startDate: props.dateRange ? props.dateRange[0] : undefined,
    startInput: undefined,
    showCustomDatePicker: false,
  });

  const mergeState = getMergeState(setState);

  useEffect(() => {
    mergeState({
      startDate: props.dateRange ? props.dateRange[0] : undefined,
      startInput: undefined,
    });
  }, [JSON.stringify(props.dateRange)]);

  const dateFormat =
    props.durationType === DurationType.INVOICE ? "LLLL yyyy" : "MM/dd/yyyy";

  const endDate = useMemo(() => {
    if (state.startDate) {
      const endDate = add(new Date(state.startDate), {
        days: props.numberOfDaysInRange,
      });

      if (state.startDate === endDate) {
        endDate.setHours(23);
        endDate.setMinutes(59);
        endDate.setSeconds(59);
      }

      return endDate;
    } else {
      return undefined;
    }
  }, [props.numberOfDaysInRange, state.startDate]);

  //
  // Interaction Handlers
  //

  function handleResetCustomDateRange() {
    mergeState({
      startDate: undefined,
      startInput: undefined,
    });
  }

  function handleApplyCustomDateRange() {
    if (!state.startDate || !endDate) {
      return;
    }

    const dateRange = [state.startDate, endDate];

    if (!state.durationType) return;

    let durationType = state.durationType;

    if (state.durationType !== CompareDurationType.PREVIOUS_PERIOD) {
      if (props.durationType === DurationType.INVOICE) {
        durationType = CompareDurationType.INVOICE;
      } else {
        durationType = CompareDurationType.CUSTOM;
      }
    }
    props.onChangeDateRange(durationType, dateRange);

    props.onClose();
  }

  function handleChangeDateInput(event) {
    const input = event.target.value;

    setState((currentState) => {
      let newStartDate = currentState.startDate;

      if (isValidDateInput(input, state.durationType)) {
        newStartDate = getValidDate(input, state.durationType);
      } else {
        newStartDate = undefined;
      }

      const formattedInput = event.target.value;
      return {
        ...currentState,

        startInput: formattedInput,

        startDate: newStartDate,
      };
    });
  }

  function handleChangeDate(selectedDate: Date | null): void {
    if (!selectedDate) return;

    mergeState({ startDate: selectedDate });
  }

  const canReset = state.startDate || endDate || state.startInput;

  function canSave() {
    let isValidStart = false;
    let isValidEnd = false;

    isValidStart =
      state.startDate instanceof Date &&
      typeof state.startDate.getMonth === "function";

    isValidEnd =
      endDate instanceof Date && typeof endDate.getMonth === "function";

    return isValidStart && isValidEnd;
  }

  function renderHeader() {
    return (
      <>
        <Flex alignItems="center" justifyContent="space-between">
          <Flex>
            <Button
              iconStart={<Icon icon={faChevronLeft} size="sm" />}
              size="tiny"
              onClick={() => {
                mergeState({ showCustomDatePicker: false });
              }}
            />
          </Flex>
          <Flex>
            <Text>{copyText.datePickerCustomCompareHeader}</Text>
          </Flex>
          <Flex width={theme.space_md} />
        </Flex>
        <Flex
          marginBottom={theme.space_md}
          marginTop={theme.space_xs}
          paddingHorizontal={theme.space_md}
          justifyContent="center"
          margin={theme.space_jumbo}
          scrollable
        >
          <Box>
            <Text bold color={theme.primary_color_text} lineHeight={2}>
              {props.durationType === CompareDurationType.INVOICE
                ? copyText.datePickerTabInvoice
                : copyText.datePickerTabFixed}
            </Text>
            <Box
              borderBottom={`2px solid ${theme.primary_color_background}`}
              transition={`width ${timing.selectedDuration}ms`}
              width="100%"
            />
          </Box>
        </Flex>
      </>
    );
  }

  function renderFixedRangePicker() {
    const displayedStartDate = state.startDate
      ? formatDate(state.startDate, dateFormat)
      : state.startInput
        ? state.startInput
        : "";

    const displayedEndDate = endDate ? formatDate(endDate, dateFormat) : "";

    const selected = state.startDate;

    return (
      <>
        <Box>
          <Flex direction="column" justifyContent="center">
            <>
              <Box paddingBottom={theme.space_xs} width="256px">
                <TextInput
                  placeholder={copyText.datePickerSelectStartPlaceholderText}
                  autoFocus
                  value={displayedStartDate}
                  onChange={(e) => handleChangeDateInput(e)}
                  onClick={() => mergeState({ displayMonth: "start" })}
                />
              </Box>
              <Box paddingBottom={theme.space_xxs} width="256px">
                <TextInput
                  disabled
                  placeholder={copyText.datePickerSelectStartPlaceholderText}
                  value={displayedEndDate}
                  onClick={() => mergeState({ displayMonth: "end" })}
                />
              </Box>
            </>
            <DatePicker
              dateFormat={
                props.durationType === CompareDurationType.INVOICE
                  ? "MM/yyyy"
                  : undefined
              }
              inline
              maxDate={props.maxDate}
              minDate={props.minDate}
              selected={selected}
              startDate={state.startDate}
              endDate={endDate}
              select="start"
              showMonthYearPicker={
                props.durationType === CompareDurationType.INVOICE
              }
              onChange={(date) => handleChangeDate(date)}
            />
          </Flex>
        </Box>
      </>
    );
  }

  function renderCompareOptions() {
    return (
      <Flex direction="column">
        <CompareOption
          selected={
            props.compareDurationType === CompareDurationType.PREVIOUS_PERIOD
          }
          onClick={() => {
            props.onChangeDateRange(
              CompareDurationType.PREVIOUS_PERIOD,
              undefined
            );
          }}
        >
          <Text>{copyText.datePickerComparePreviousPeriodOption}</Text>
          {props.compareDurationType ===
            CompareDurationType.PREVIOUS_PERIOD && (
            <Icon color={theme.primary_color_text} icon={faCheck}></Icon>
          )}
        </CompareOption>
        <CompareOption
          justifyContent="space-between"
          selected={
            props.compareDurationType === CompareDurationType.CUSTOM ||
            props.compareDurationType === CompareDurationType.INVOICE
          }
          onClick={() =>
            mergeState({
              durationType:
                props.durationType === DurationType.INVOICE
                  ? CompareDurationType.INVOICE
                  : CompareDurationType.CUSTOM,
              showCustomDatePicker: true,
            })
          }
        >
          <Text>{copyText.datePickerCompareCustomOption}</Text>
          <Icon
            color={
              props.compareDurationType === CompareDurationType.CUSTOM ||
              props.compareDurationType === CompareDurationType.INVOICE
                ? theme.primary_color_text
                : theme.default_button_text_color
            }
            icon={faChevronRight}
          ></Icon>
        </CompareOption>
      </Flex>
    );
  }

  function renderDropdown() {
    return state.showCustomDatePicker ? (
      <Box>
        {renderHeader()}
        <Box paddingBottom={theme.space_sm}>
          <Form>{renderFixedRangePicker()}</Form>
        </Box>
      </Box>
    ) : (
      <Box width="222px">{renderCompareOptions()}</Box>
    );
  }

  return (
    <Dropdown
      cancelButtonDisabled={!canReset}
      cancelButtonText={copyText.datePickerButtonReset}
      disabled={props.disabled}
      hideInteractionButtons={!state.showCustomDatePicker}
      placement="bottom-end"
      renderContent={renderDropdown}
      submitButtonDisabled={!canSave()}
      submitButtonText={copyText.datePickerButtonApply}
      onCancel={handleResetCustomDateRange}
      onClose={props.onClose}
      onOpen={props.onOpen}
      onSubmit={handleApplyCustomDateRange}
    >
      {props.children}
    </Dropdown>
  );
}

// Checks if user inputted string is a valid date. only accepts 4 digit year.
function isValidDateInput(
  inputString: string,
  durationType?: CompareDurationType
): boolean {
  if (!durationType) return false;
  const [month = "", day = "", year = ""] = inputString.split("/");
  const [invoiceMonth = "", invoiceYear = ""] = inputString.split("/");

  let isValidDate = true;
  let isValidInvoiceDate = true;

  if (durationType === CompareDurationType.INVOICE) {
    if (invoiceMonth.length < 1 || invoiceYear.length < 4) {
      isValidInvoiceDate = false;
    }
  }

  if (month.length < 1 || day.length < 1 || year.length < 4) {
    isValidDate = false;
  }

  const potentialInvoiceDate = new Date(
    `${invoiceMonth} / 01 / ${invoiceYear}`
  );

  if (
    potentialInvoiceDate instanceof Date &&
    isNaN(potentialInvoiceDate.valueOf())
  ) {
    isValidInvoiceDate = false;
  }

  const potentialDate = new Date(inputString);

  if (potentialDate instanceof Date && isNaN(potentialDate.valueOf())) {
    isValidDate = false;
  }

  return durationType === CompareDurationType.INVOICE
    ? isValidDate || isValidInvoiceDate
    : isValidDate;
}

function getValidDate(inputString: string, durationType?: CompareDurationType) {
  if (!durationType) return;
  const [invoiceMonth = "", invoiceYear = ""] = inputString.split("/");

  return durationType === CompareDurationType.CUSTOM
    ? new Date(inputString)
    : new Date(`${invoiceMonth} / 01 / ${invoiceYear}`);
}
