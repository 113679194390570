import useGatekeeper from "@/hooks/useGatekeeper";
import Dropdown, { Option } from "@/ui-lib/components/Dropdown";
import LoadingSpinner from "@/ui-lib/components/LoadingSpinner";
import IconExclamationDiamond from "@/ui-lib/icons/IconExclamationDiamond";
import IconOracleCloud from "@/ui-lib/icons/IconOracleCloud";
import { useTheme } from "@emotion/react";
import { faEllipsisV } from "@fortawesome/free-solid-svg-icons";
import {
  CloudCapability,
  DataSource,
  DataStatusType,
} from "@ternary/api-lib/constants/enums";
import Button from "@ternary/api-lib/ui-lib/components/Button";
import Tooltip from "@ternary/api-lib/ui-lib/components/Tooltip";
import Box from "@ternary/web-ui-lib/components/Box";
import Flex from "@ternary/web-ui-lib/components/Flex";
import Icon from "@ternary/web-ui-lib/components/Icon";
import Text from "@ternary/web-ui-lib/components/Text";
import React from "react";
import copyText from "../copyText";
import DataStatusTooltip from "./DataStatusTooltip";

type Cloud = {
  id: string;
  config: {
    tenancyOCID: string | null;
    userOCID: string | null;
    region: string | null;
    storageBucket?: string | null;
    storageNamespace?: string | null;
  };
  dataStatus: {
    [DataSource.BILLING]: {
      latestRefresh: string | null;
      latestUpstreamCheck: string | null;
      latestUpstreamTimestamp: string | null;
      performanceMode: DataStatusType;
    };
  };
  name: string;
  validations: { error: string; name: string; success: boolean }[];
};

interface Props {
  integration: Cloud;
  isProcessing: boolean;
  onInteraction: (interaction: OracleIntegrationCard.Interaction) => void;
}

export function OracleIntegrationCard(props: Props): JSX.Element {
  const theme = useTheme();
  const gatekeeper = useGatekeeper();
  const validation = props.integration.validations.find(
    (validation) => validation.name === CloudCapability.BILL_DATA_OCI
  );
  const error = validation?.error ? validation?.error : null;

  const options: Option[] = [];

  if (gatekeeper.canUpdateDataIntegration) {
    options.push(
      {
        label: copyText.actionMenuItemEditCloud,
        onClick: () =>
          props.onInteraction({
            type: OracleIntegrationCard.INTERACTION_EDIT_BUTTON_CLICKED,
            integrationID: props.integration.id,
          }),
      },
      {
        label: props.isProcessing ? (
          <Flex alignItems="center" justifyContent="space-between">
            <Text>{copyText.actionMenuItemValidateCloud}</Text>
            <LoadingSpinner />
          </Flex>
        ) : (
          copyText.actionMenuItemValidateCloud
        ),
        locked: props.isProcessing,
        onClick: () =>
          props.onInteraction({
            type: OracleIntegrationCard.INTERACTION_REFRESH_BUTTON_CLICKED,
            integrationID: props.integration.id,
          }),
      }
    );
  }

  if (gatekeeper.canDeleteDataIntegration) {
    options.push({
      label: copyText.actionMenuItemDeleteCloud,
      onClick: () =>
        props.onInteraction({
          type: OracleIntegrationCard.INTERACTION_DELETE_BUTTON_CLICKED,
          integrationID: props.integration.id,
        }),
    });
  }

  return (
    <Flex
      backgroundColor={theme.panel_backgroundColor}
      borderRadius={theme.borderRadius_2}
      direction="column"
      justifyContent="space-between"
      marginBottom={theme.space_lg}
      marginRight={theme.space_lg}
      padding={theme.space_md}
      width={360}
      height={360}
      scrollable
    >
      <Box>
        <Flex
          alignItems="center"
          justifyContent="space-between"
          marginBottom={theme.space_sm}
        >
          <Flex alignItems="center">
            <Flex
              alignItems="center"
              backgroundColor="white"
              border={`1px solid ${theme.border_color}`}
              borderRadius="50%"
              height={48}
              marginRight={theme.space_sm}
              justifyContent="center"
              width={48}
            >
              <IconOracleCloud size={36} />
            </Flex>
            <Text fontSize={theme.h4_fontSize} marginRight={theme.space_xxs}>
              {props.integration.name}
            </Text>
            <DataStatusTooltip
              latestRefresh={props.integration.dataStatus.BILLING.latestRefresh}
              latestUpstream={
                props.integration.dataStatus.BILLING.latestUpstreamTimestamp
              }
            />
            {error && (
              <Tooltip content={error} width="300px">
                <IconExclamationDiamond size="13px" />
              </Tooltip>
            )}
          </Flex>
          <Dropdown options={options} placement="bottom-end">
            <Button iconEnd={<Icon icon={faEllipsisV} />} />
          </Dropdown>
        </Flex>
        <Box>
          <Box marginBottom={theme.space_md}>
            <Text bold>{copyText.cloudOCIAttributeTenancy}</Text>
            <Text>{props.integration.config.tenancyOCID}</Text>
          </Box>
          <Box marginBottom={theme.space_md}>
            <Text bold>{copyText.cloudOCIAttributeUser}</Text>
            <Text>{props.integration.config.userOCID}</Text>
          </Box>
          <Box marginBottom={theme.space_md}>
            <Text bold>{copyText.cloudOCIAttributeRegion}</Text>
            <Text>{props.integration.config.region}</Text>
          </Box>
          <Box marginBottom={theme.space_md}>
            <Text bold>{copyText.cloudOCIAttributeStorageBucket}</Text>
            <Text>{props.integration.config.storageBucket ?? ""}</Text>
          </Box>
          <Box marginBottom={theme.space_md}>
            <Text bold>{copyText.cloudOCIAttributeStorageNamespace}</Text>
            <Text>{props.integration.config.storageNamespace ?? ""}</Text>
          </Box>
        </Box>
      </Box>
    </Flex>
  );
}

OracleIntegrationCard.INTERACTION_EDIT_BUTTON_CLICKED =
  `OracleIntegrationCard.INTERACTION_EDIT_BUTTON_CLICKED` as const;

OracleIntegrationCard.INTERACTION_REFRESH_BUTTON_CLICKED =
  `OracleIntegrationCard.INTERACTION_REFRESH_BUTTON_CLICKED` as const;

OracleIntegrationCard.INTERACTION_DELETE_BUTTON_CLICKED =
  `OracleIntegrationCard.INTERACTION_DELETE_BUTTON_CLICKED` as const;

interface InteractionEditButtonClicked {
  type: typeof OracleIntegrationCard.INTERACTION_EDIT_BUTTON_CLICKED;
  integrationID: string;
}
interface InteractionRefreshButtonClicked {
  type: typeof OracleIntegrationCard.INTERACTION_REFRESH_BUTTON_CLICKED;
  integrationID: string;
}
interface InteractionDeleteButtonClicked {
  type: typeof OracleIntegrationCard.INTERACTION_DELETE_BUTTON_CLICKED;
  integrationID: string;
}

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace OracleIntegrationCard {
  export type Interaction =
    | InteractionEditButtonClicked
    | InteractionRefreshButtonClicked
    | InteractionDeleteButtonClicked;
}

export default OracleIntegrationCard;
