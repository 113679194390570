import CoreAPIClientError from "@/api/core/CoreAPIClientError";
import { useCoreAPIClient } from "@/api/core/CoreAPIClientProvider";
import { useQuery } from "@tanstack/react-query";
import { UseQueryOptions, UseQueryResult } from "../../../lib/react-query";
import copyText from "../copyText";

type Options = UseQueryOptions<number[], CoreAPIClientError>;
type Result = UseQueryResult<number[], CoreAPIClientError>;

export function useGenerateMspBillingInvoicePdf(
  manifestID: string,
  options?: Options
): Result {
  const client = useCoreAPIClient();

  return useQuery({
    queryKey: ["invoice", manifestID, "pdf"],
    queryFn: () => client.generateMspBillingInvoicePdf(manifestID),
    meta: { errorMessage: copyText.errorLoadingMspBillingInvoicePDFMessage },
    ...options,
  });
}
