import { useTheme } from "@emotion/react";
import Box from "@ternary/api-lib/ui-lib/components/Box";
import Text from "@ternary/api-lib/ui-lib/components/Text";
import { keyBy, sortBy } from "lodash";
import React, { useState } from "react";
import Modal from "../../../ui-lib/components/Modal";
import getMergeState from "../../../utils/getMergeState";
import copyText from "../copyText";
import useGetRoles from "../hooks/useGetRoles";
import useGetUsers from "../hooks/useGetUsers";
import RoleTable from "./RoleTable";

type Interaction = RoleTable.Interaction;

interface State {
  selectedRoleID: string | null;
  showModal: boolean;
}

const initialState: State = {
  selectedRoleID: null,
  showModal: false,
};

export function RoleManagementContainer() {
  //
  // State
  //

  const [state, setState] = useState<State>(initialState);
  const mergeState = getMergeState(setState);

  //
  // Queries
  //

  const { data: _roles = [], isLoading: isLoadingRoles } = useGetRoles();

  const { data: users = [], isLoading: isLoadingUsers } = useGetUsers();

  //
  // Computed Values
  //

  const usersKeyedByID = keyBy(users, "id");

  const roles = _roles.map((role) => {
    const createdByUser = usersKeyedByID[role.createdByID];

    const updatedByUser = role.updatedByID
      ? usersKeyedByID[role.updatedByID]
      : null;

    return {
      ...role,
      createdBy: createdByUser?.email ?? "--",
      updatedBy: updatedByUser ? updatedByUser.email : null,
    };
  });

  const rolesKeyedByID = keyBy(roles, "id");

  const selectedRole = state.selectedRoleID
    ? rolesKeyedByID[state.selectedRoleID]
    : null;

  //
  // Interaction Handlers
  //

  function handleInteraction(interaction: Interaction) {
    switch (interaction.type) {
      case RoleTable.INTERACTION_VIEW_PERMISSIONS_BUTTON_CLICKED: {
        mergeState({
          selectedRoleID: interaction.roleID,
          showModal: true,
        });
        return;
      }
    }
  }

  //
  // Render
  //

  const isLoading = isLoadingRoles || isLoadingUsers;

  return (
    <Box>
      {state.showModal && selectedRole ? (
        <PermissionModal
          permissions={selectedRole.permissions ?? []}
          onClose={() => mergeState({ selectedRoleID: null, showModal: false })}
        />
      ) : null}
      <Text appearance="h2">{copyText.rolesTabLabel}</Text>
      <RoleTable
        isLoading={isLoading}
        roles={roles}
        onInteraction={handleInteraction}
      />
    </Box>
  );
}

function PermissionModal(props: {
  permissions: { slug: string }[];
  onClose: () => void;
}) {
  const theme = useTheme();

  return (
    <Modal isOpen showCloseButton onClose={props.onClose}>
      <Modal.Header>
        <Text appearance="h4">{copyText.permissionModalTitle}</Text>
      </Modal.Header>
      <Modal.Body>
        <Box
          backgroundColor={theme.panel_backgroundColor}
          overflowY="scroll"
          height={500}
        >
          {sortBy(props.permissions, "slug").map((permission) => (
            <Text>{permission.slug}</Text>
          ))}
        </Box>
      </Modal.Body>
    </Modal>
  );
}
