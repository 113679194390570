import Select, { DefaultOption } from "@/ui-lib/components/Select";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import {
  DayOfWeek,
  SubscriptionFrequency,
  WeekOfMonth,
} from "@ternary/api-lib/constants/enums";
import { UserEntity } from "@ternary/api-lib/core/types";
import Flex from "@ternary/api-lib/ui-lib/components/Flex";
import Text from "@ternary/api-lib/ui-lib/components/Text";
import Tooltip from "@ternary/api-lib/ui-lib/components/Tooltip";
import React, { ChangeEvent } from "react";
import Form, { FormField } from "../../../ui-lib/components/Form";
import LegacyDatePicker from "../../../ui-lib/components/LegacyDatePicker";
import copyText from "../copyText";
import { Action } from "./ReportSubscriptionContainer";

type SubscriptionFormProps = {
  action: Action;
  dateTimeInput: Date;
  emails: string[];
  frequency: SubscriptionFrequency;
  selectedDayOfWeek: string | null;
  selectedWeekOfMonth: string | null;
  users: UserEntity[];
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
};

const frequencyOptions: DefaultOption[] = [
  {
    label: copyText.reportSubscriptionFormDailyLabel,
    value: SubscriptionFrequency.DAILY,
  },
  {
    label: copyText.reportSubscriptionFormWeeklyLabel,
    value: SubscriptionFrequency.WEEKLY,
  },
  {
    label: copyText.reportSubscriptionFormMonthlyLabel,
    value: SubscriptionFrequency.MONTHLY,
  },
];

export default function ReportSubscriptionForm(
  props: SubscriptionFormProps
): JSX.Element {
  const selectedFrequency = frequencyOptions.find(
    (option) => option.value === props.frequency
  );

  const userOptions = props.users.map((user) => ({
    label: user.email,
    value: user.email,
  }));

  function renderAdvancedOptions(): JSX.Element | null {
    const dayOfWeek = Object.values(DayOfWeek);
    const weekOfMonth = Object.values(WeekOfMonth);

    const dayOption = props.selectedDayOfWeek
      ? {
          label:
            props.selectedDayOfWeek.charAt(0) +
            props.selectedDayOfWeek.slice(1).toLowerCase(),
          value: props.selectedDayOfWeek,
        }
      : undefined;

    const weekOption = props.selectedWeekOfMonth
      ? {
          label:
            props.selectedWeekOfMonth.charAt(0) +
            props.selectedWeekOfMonth.slice(1).toLowerCase(),
          value: props.selectedWeekOfMonth,
        }
      : undefined;

    switch (props.frequency) {
      case SubscriptionFrequency.WEEKLY: {
        return (
          <FormField label={copyText.reportSubscriptionDayOfWeek}>
            <Select
              autoFocus
              options={dayOfWeek.map((day) => ({
                label: day.charAt(0) + day.slice(1).toLowerCase(),
                value: day,
              }))}
              placeholder={copyText.reportSubscriptionDayPlaceholder}
              value={dayOption}
              onChange={(option) =>
                option &&
                props.onChange({
                  target: {
                    name: "selectedDayOfWeek",
                    value: option.value,
                  },
                } as ChangeEvent<HTMLInputElement>)
              }
            />
          </FormField>
        );
      }
      case SubscriptionFrequency.MONTHLY: {
        return (
          <>
            <FormField label={copyText.reportSubscriptionDayOfWeek}>
              <Select
                autoFocus
                options={dayOfWeek.map((day) => ({
                  label: day.charAt(0) + day.slice(1).toLowerCase(),
                  value: day,
                }))}
                placeholder={copyText.reportSubscriptionDayPlaceholder}
                value={dayOption}
                onChange={(option) =>
                  option &&
                  props.onChange({
                    target: {
                      name: "selectedDayOfWeek",
                      value: option.value,
                    },
                  } as ChangeEvent<HTMLInputElement>)
                }
              />
            </FormField>
            <FormField label={copyText.reportSubscriptionWeekOfMonth}>
              <Select
                autoFocus
                options={weekOfMonth.map((week) => ({
                  label: week.charAt(0) + week.slice(1).toLowerCase(),
                  value: week,
                }))}
                placeholder={copyText.reportSubscriptionWeekPlaceholder}
                value={weekOption}
                onChange={(option) =>
                  option &&
                  props.onChange({
                    target: {
                      name: "selectedWeekOfMonth",
                      value: option.value,
                    },
                  } as ChangeEvent<HTMLInputElement>)
                }
              />
            </FormField>
          </>
        );
      }
      default: {
        return null;
      }
    }
  }

  return (
    <Flex direction="column" justifyContent="space-between">
      <Form>
        <FormField label={copyText.reportSubscriptionFormSubscribersLabel}>
          <Select
            autoFocus
            isCreatable
            isMulti
            isSearchable
            options={userOptions}
            placeholder={copyText.reportSubscriptionEmailPlaceholder}
            value={props.emails.map((email) => ({
              label: email,
              value: email,
            }))}
            onChange={(options) =>
              options &&
              props.onChange({
                target: {
                  name: "emails",
                  value: options.map((option) => option.value),
                },
              } as unknown as ChangeEvent<HTMLInputElement>)
            }
          />
        </FormField>
        <FormField label={copyText.reportSubscriptionFormFrequencyLabel}>
          <Select
            autoFocus
            options={frequencyOptions}
            placeholder={copyText.reportSubscriptionFrequencyPlaceholder}
            value={selectedFrequency}
            onChange={(selection) =>
              selection &&
              props.onChange({
                target: {
                  name: "frequency",
                  value: selection.value,
                },
              } as ChangeEvent<HTMLInputElement>)
            }
          />
        </FormField>

        <>{renderAdvancedOptions()}</>

        <FormField
          caption={copyText.reportSubscriptionFormTimezoneCaption.replace(
            "%TIME%",
            Intl.DateTimeFormat("default", {
              hour: "2-digit",
              minute: "numeric",
              timeZone: "UTC",
              timeZoneName: "short",
            }).format(props.dateTimeInput)
          )}
          label={
            <Flex alignItems="center">
              <Text>{copyText.reportSubscriptionFormHourOfDayLabel}</Text>
              <Tooltip
                icon={faInfoCircle}
                content={copyText.reportSubscriptionTimezoneMessage}
                placement="right"
              ></Tooltip>
            </Flex>
          }
        >
          <LegacyDatePicker
            dateFormat="hh:mm aa"
            selected={props.dateTimeInput}
            showTimeSelect
            showTimeSelectOnly
            timeIntervals={60}
            timeSelectWidth="500px"
            timeCaption={
              Intl.DateTimeFormat("default", { timeZoneName: "short" })
                .formatToParts()
                .find((p) => p.type === "timeZoneName")?.value
            }
            onChange={(dateTime) => {
              if (dateTime) {
                props.onChange({
                  target: {
                    name: "dateTime",
                    value: dateTime,
                  },
                } as unknown as ChangeEvent<HTMLInputElement>);
              }
            }}
          />
        </FormField>
      </Form>
    </Flex>
  );
}
