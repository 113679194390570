import { DateHelper } from "@/lib/dates";
import DateRangeControls from "@/ui-lib/components/DateRangeControls/DateRangeControls";
import { DateRange } from "@/utils/dates";
import { useTheme } from "@emotion/react";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { QueryFilter, RawValue } from "@ternary/api-lib/analytics/types";
import { getCubeDateRangeFromDurationType } from "@ternary/api-lib/analytics/utils";
import { UnitType } from "@ternary/api-lib/constants/analytics";
import {
  ChartType,
  DataSource,
  DurationType,
  Operator,
  TimeGranularity,
} from "@ternary/api-lib/constants/enums";
import AreaChart from "@ternary/api-lib/ui-lib/charts/AreaChart";
import Button from "@ternary/api-lib/ui-lib/components/Button";
import StackedBarChart from "@ternary/web-ui-lib/charts/StackedBarChart";
import Box from "@ternary/web-ui-lib/components/Box";
import Flex from "@ternary/web-ui-lib/components/Flex";
import Icon from "@ternary/web-ui-lib/components/Icon";
import Text from "@ternary/web-ui-lib/components/Text";
import { formatNumber } from "@ternary/web-ui-lib/utils/formatNumber";
import { differenceInHours, endOfDay } from "date-fns";
import prettyBytes from "pretty-bytes";
import React, { useMemo, useState } from "react";
import {
  DateParam,
  DecodedValueMap,
  JsonParam,
  StringParam,
  createEnumParam,
  useQueryParams,
  withDefault,
} from "use-query-params";
import { z } from "zod";
import useGetRawData from "../../../../api/analytics/useGetRawData";
import InsightsSelector from "../../../../components/InsightsSelector";
import useAvailableGlobalDate from "../../../../hooks/useAvailableGlobalDate";
import { createStructParam } from "../../../../lib/use-query-params";
import Dropdown, { Option } from "../../../../ui-lib/components/Dropdown";
import Grid from "../../../../ui-lib/components/Grid";
import LoadingSpinner from "../../../../ui-lib/components/LoadingSpinner";
import Modal from "../../../../ui-lib/components/Modal";
import { useDebounce } from "../../../../utils/timers";
import copyText from "../../copyText";
import useGetAWSKubernetesAccountIDs from "../hooks/useGetAWSKubernetesAccountIDs";
import useGetAWSKubernetesCostUsageSummary from "../hooks/useGetAWSKubernetesCostUsageSummary";
import useGetAWSKubernetesInstanceGroups from "../hooks/useGetAWSKubernetesInstanceGroups";
import useGetAWSKubernetesInstances from "../hooks/useGetAWSKubernetesInstances";
import {
  AWSKubernetesInstanceGroupEntity,
  AWSKubernetesInstanceGroupFilters,
} from "../types";
import AWSKubernetesInstanceGroupTable from "./AWSKubernetesInstanceGroupTable";
import AWSKubernetesInstanceGroupTableControls from "./AWSKubernetesInstanceGroupTableControls";
import AWSKubernetesInstanceTable from "./AWSKubernetesInstanceTable";
import AWSKubernetesMeters from "./AWSKubernetesMeters";

type Interaction =
  | AWSKubernetesInstanceGroupTable.Interaction
  | AWSKubernetesInstanceGroupTableControls.Interaction;

type QueryParams = DecodedValueMap<typeof queryParamConfigMap>;

type QueryParamState = {
  accountID: string;
  dateRange: DateRange;
  dateRangeGranularity: TimeGranularity;
  duration: DurationType;
  groupFilters: AWSKubernetesInstanceGroupFilters;
  selectedGroup: z.infer<typeof selectedInstanceGroupStruct> | null;
  usageMeasure: UsageMeasure;
};

const UsageMeasure = {
  CPU: "CPU",
  MEMORY: "MEMORY",
} as const;

type UsageMeasure = (typeof UsageMeasure)[keyof typeof UsageMeasure];

const ALL_ACCOUNTS_ID = "__ALL__";

const durationEnum = createEnumParam(Object.values(DurationType));
const usageMeasureEnum = createEnumParam(Object.values(UsageMeasure));

const instanceGroupFiltersStruct = z.object({
  accountID: z.nullable(z.string()),
  name: z.nullable(z.string()),
});

const instanceGroupFiltersDefault = {
  accountID: null,
  name: null,
};

const selectedInstanceGroupStruct = z.object({
  accountID: z.string(),
  name: z.string(),
});

const queryParamConfigMap = {
  account_id: withDefault(StringParam, ALL_ACCOUNTS_ID),
  date_range_end: DateParam,
  date_range_start: DateParam,
  duration: withDefault(durationEnum, DurationType.LAST_THIRTY_DAYS),
  instance_group_filters: createStructParam(instanceGroupFiltersStruct),
  instance_table_sort: JsonParam,
  selected_group: createStructParam(selectedInstanceGroupStruct),
  usage_measure: withDefault(usageMeasureEnum, UsageMeasure.CPU),
};

export default function AWSKubernetesContainer() {
  const theme = useTheme();
  const globalDate = useAvailableGlobalDate();

  //
  // STATE
  //

  const [queryParams, setQueryParams] = useQueryParams(queryParamConfigMap);
  const queryParamState = getQueryParamState(queryParams);

  const [searchText, setSearchText] = useState("");

  const debouncedSearchText = useDebounce(searchText);

  //
  // QUERIES
  //

  const dateRange = globalDate.date ?? queryParamState.dateRange;

  const accountIDFilter: QueryFilter[] = [];
  if (queryParamState.accountID !== ALL_ACCOUNTS_ID) {
    accountIDFilter.push({
      name: "accountId",
      operator: Operator.EQUALS,
      values: [queryParamState.accountID],
    });
  }

  const selectedInstanceGroupFilter: QueryFilter[] = [];
  if (queryParamState.selectedGroup !== null) {
    selectedInstanceGroupFilter.push(
      {
        name: "accountId",
        operator: Operator.EQUALS,
        values: [queryParamState.selectedGroup.accountID],
      },
      {
        name: "clusterName",
        operator: Operator.EQUALS,
        values: [queryParamState.selectedGroup.name],
      }
    );
  }

  const { data: accountIDs = [], isFetching: isLoadingAccountIDs } =
    useGetAWSKubernetesAccountIDs({
      dateRange,
    });

  const {
    data: costUsageSummary = null,
    isFetching: isLoadingCostUsageSummary,
  } = useGetAWSKubernetesCostUsageSummary({
    dateRange,
    queryFilters: accountIDFilter,
  });

  const costDimensions = ["clusterName"];
  const costMeasures = ["cost"];

  const { data: costData = [], isFetching: isLoadingCostData } = useGetRawData({
    dataSource: DataSource.AWS_KUBERNETES_NODE_COST,
    dateRange,
    dimensions: costDimensions,
    granularity: queryParamState.dateRangeGranularity,
    measures: costMeasures,
    queryFilters: accountIDFilter,
  });

  const usageMeasures =
    queryParamState.usageMeasure === UsageMeasure.CPU
      ? ["sum_RemainingCPUHour", "sum_UsedCPUHour"]
      : ["sum_RemainingMemoryByteHour", "sum_UsedMemoryByteHour"];

  const { data: usageData = [], isFetching: isLoadingUsageData } =
    useGetRawData({
      dataSource: DataSource.AWS_KUBERNETES_NODE_USAGE,
      dateRange,
      granularity: queryParamState.dateRangeGranularity,
      measures: usageMeasures,
      queryFilters: accountIDFilter,
    });

  const { data: instanceGroups = [], isFetching: isLoadingInstanceGroups } =
    useGetAWSKubernetesInstanceGroups({
      dateRange,
      queryFilters: accountIDFilter,
    });

  const { data: instances = [], isFetching: isLoadingInstances } =
    useGetAWSKubernetesInstances(
      {
        dateRange,
        queryFilters: selectedInstanceGroupFilter,
      },
      { enabled: queryParamState.selectedGroup !== null }
    );

  //
  // MODIFIED QUERY DATA
  //

  const filteredInstanceGroups = useMemo(() => {
    return getFilteredInstanceGroups({
      allInstanceGroups: instanceGroups ?? [],
      instanceGroupFilters: queryParamState.groupFilters,
      searchText: debouncedSearchText,
    });
  }, [debouncedSearchText, instanceGroups, queryParamState.groupFilters]);

  const hourCorrectedUsageData = usageData.map((datum) => {
    if (queryParamState.dateRangeGranularity === TimeGranularity.HOUR) {
      return datum;
    }

    const corrected = Object.entries(datum).map(
      ([key, value]): [string, RawValue] => {
        return [key, typeof value === "number" ? value / 24 : value];
      }
    );

    return Object.fromEntries(corrected);
  });

  const csvData = useMemo(
    () => getCSVData(filteredInstanceGroups),
    [filteredInstanceGroups]
  );

  //
  // INTERACTIONS
  //

  function handleInteraction(interaction: Interaction) {
    switch (interaction.type) {
      case AWSKubernetesInstanceGroupTable.INTERACTION_FILTER_CLICKED: {
        const nextFilters = { ...queryParamState.groupFilters };

        nextFilters[interaction.filterKey] = interaction.filterValue;

        setQueryParams({ instance_group_filters: nextFilters });
        break;
      }
      case AWSKubernetesInstanceGroupTableControls.INTERACTION_REMOVE_FILTER_CLICKED: {
        const nextFilters = { ...queryParamState.groupFilters };

        nextFilters[interaction.filterKey] = null;

        setQueryParams({ instance_group_filters: nextFilters });
        break;
      }
      case AWSKubernetesInstanceGroupTableControls.INTERACTION_SEARCH_TEXT_UPDATED: {
        setSearchText(interaction.searchText);

        break;
      }
    }
  }

  const costChartReportSnapshot = {
    chartType: ChartType.STACKED_BAR,
    dateRange: dateRange,
    dataSource: DataSource.AWS_KUBERNETES_NODE_COST,
    dimensions: costDimensions,
    durationType: DurationType.CUSTOM,
    isFiscalMode: false,
    fillMissingDates: true,
    fiscalPeriodMap: null,
    granularity: queryParamState.dateRangeGranularity,
    measures: costMeasures,
    queryFilters: accountIDFilter,
    name: copyText.gkeCostReportSnapshotName,
    xAxisKey: "timestamp",
  };

  const usageChartReportSnapshot = {
    chartType: ChartType.AREA,
    dateRange: dateRange,
    dataSource: DataSource.AWS_KUBERNETES_NODE_USAGE,
    dimensions: [],
    durationType: DurationType.CUSTOM,
    isFiscalMode: false,
    fillMissingDates: true,
    fiscalPeriodMap: null,
    granularity: queryParamState.dateRangeGranularity,
    measures: usageMeasures,
    queryFilters: accountIDFilter,
    name: copyText.gkeUsageReportSnapshotName,
    xAxisKey: "timestamp",
  };

  //
  // RENDER
  //

  const accountIDOptions: Option[] = [
    {
      label: copyText.awsKubernetesAccountsAllLabel,
      value: ALL_ACCOUNTS_ID,
      onClick: () =>
        setQueryParams({
          account_id: ALL_ACCOUNTS_ID,
          instance_group_filters: {
            ...queryParamState.groupFilters,
            accountID: null,
          },
        }),
    },
    ...(isLoadingAccountIDs
      ? [
          {
            label: copyText.awsKubernetesAccountsLoadingLabel,
            locked: true,
            value: "",
          },
        ]
      : []),
    ...accountIDs.map((accountID) => ({
      label: accountID,
      value: accountID,
      onClick: () => setQueryParams({ account_id: accountID }),
    })),
  ];

  function formatUsageMeasure(value: number) {
    if (queryParamState.usageMeasure === UsageMeasure.MEMORY) {
      return prettyBytes(value, { binary: true });
    }

    return formatNumber(value);
  }

  return (
    <Box width="100%" paddingTop={theme.space_md}>
      <Flex
        backgroundColor={theme.panel_backgroundColor}
        borderRadius={theme.borderRadius_1}
        justifyContent="space-between"
        marginBottom={theme.space_lg}
        padding={theme.space_md}
      >
        <Box>
          <Dropdown
            options={accountIDOptions}
            placement="bottom-start"
            selectedOption={accountIDOptions.find(
              (option) => option.value === queryParams.account_id
            )}
          >
            <Button
              iconEnd={
                isLoadingAccountIDs ? (
                  <LoadingSpinner />
                ) : (
                  <Icon icon={faChevronDown} />
                )
              }
              marginRight={theme.space_xs}
              secondary
              size="small"
            >
              {queryParams.account_id === ALL_ACCOUNTS_ID
                ? copyText.awsKubernetesAccountsAllLabel
                : queryParams.account_id}
            </Button>
          </Dropdown>
        </Box>

        <DateRangeControls
          dateRange={dateRange}
          durationType={queryParamState.duration}
          hiddenOptions={[
            DurationType.LAST_NINETY_DAYS,
            DurationType.QUARTER_TO_DATE,
            DurationType.YEAR_TO_DATE,
          ]}
          maxDate={new DateHelper().date}
          onChangeDateRange={(duration, newDateRange) => {
            setQueryParams({
              duration,
              ...(newDateRange && newDateRange[0] && newDateRange[1]
                ? {
                    date_range_start: newDateRange[0],
                    date_range_end: newDateRange[1],
                  }
                : {
                    date_range_start: null,
                    date_range_end: null,
                  }),
            });
          }}
        />
      </Flex>

      <Box
        backgroundColor={theme.panel_backgroundColor}
        borderRadius={theme.borderRadius_2}
        marginBottom={theme.space_lg}
        padding={theme.space_md}
      >
        <AWSKubernetesMeters
          cpuTotal={costUsageSummary?.cpuTotal ?? 0}
          cpuUsed={costUsageSummary?.cpuUsed ?? 0}
          isLoading={isLoadingCostUsageSummary}
          memoryTotal={costUsageSummary?.memoryTotal ?? 0}
          memoryUsed={costUsageSummary?.memoryUsed ?? 0}
          totalCost={costUsageSummary?.totalCost ?? 0}
        />
      </Box>

      <Grid
        gridColumnGap={theme.space_lg}
        gridTemplateColumns={`repeat(2, calc(50% - (${theme.space_lg} / 2) ))`}
      >
        <InsightsSelector
          resourceName={copyText.awsKubernetesCostReportSnapshotName}
          reportSnapshot={costChartReportSnapshot}
        >
          <Flex
            backgroundColor={theme.panel_backgroundColor}
            borderRadius={theme.borderRadius_2}
            direction="column"
            height={500}
            padding={theme.space_md}
          >
            <Flex
              justifyContent="space-between"
              alignItems="center"
              paddingBottom={theme.space_md}
            >
              <Text fontSize={theme.h3_fontSize}>
                {copyText.awsKubernetesChartLabelCost}
              </Text>
            </Flex>

            <Box flex="1 0 0">
              <StackedBarChart
                data={costData}
                dimensions={[{ name: "clusterName" }]}
                isLoading={isLoadingCostData}
                measures={[{ name: "cost", unit: UnitType.CURRENCY }]}
                showLegend
                showTooltip
                timeSeriesGranularity={queryParamState.dateRangeGranularity}
                xAxisKey="timestamp"
              />
            </Box>
          </Flex>
        </InsightsSelector>
        <InsightsSelector
          resourceName={copyText.awsKubernetesUsageReportSnapshotName}
          reportSnapshot={usageChartReportSnapshot}
        >
          <Flex
            backgroundColor={theme.panel_backgroundColor}
            borderRadius={theme.borderRadius_2}
            direction="column"
            height={500}
            padding={theme.space_md}
          >
            <Flex
              alignItems="center"
              justifyContent="space-between"
              paddingBottom={theme.space_md}
            >
              <Text fontSize={theme.h3_fontSize}>
                {copyText.awsKubernetesChartLabelUsage}
              </Text>

              <Box flex="0 0 auto">
                <Button
                  primary={queryParamState.usageMeasure === UsageMeasure.CPU}
                  secondary={queryParamState.usageMeasure !== UsageMeasure.CPU}
                  size="small"
                  width={140}
                  onClick={() =>
                    setQueryParams({ usage_measure: UsageMeasure.CPU })
                  }
                >
                  {copyText.awsKubernetesUsageOptionLabelCPU}
                </Button>
                <Button
                  marginLeft={theme.space_sm}
                  primary={queryParamState.usageMeasure === UsageMeasure.MEMORY}
                  secondary={
                    queryParamState.usageMeasure !== UsageMeasure.MEMORY
                  }
                  size="small"
                  width={140}
                  onClick={() =>
                    setQueryParams({ usage_measure: UsageMeasure.MEMORY })
                  }
                >
                  {copyText.awsKubernetesUsageOptionLabelMemory}
                </Button>
              </Box>
            </Flex>

            <Box flex="1 0 0">
              <AreaChart
                data={hourCorrectedUsageData}
                isLoading={isLoadingUsageData}
                measures={usageMeasures.map((name) => ({
                  name: name,
                }))}
                readableKeys={readableKeys}
                showLegend
                showTooltip
                stacked
                timeSeriesGranularity={queryParamState.dateRangeGranularity}
                tooltipFormatter={formatUsageMeasure}
                xAxisKey="timestamp"
                yAxisFormatter={formatUsageMeasure}
              />
            </Box>
          </Flex>
        </InsightsSelector>
      </Grid>

      <Box
        backgroundColor={theme.panel_backgroundColor}
        borderRadius={theme.borderRadius_1}
        marginVertical={theme.space_lg}
        padding={theme.space_md}
      >
        <AWSKubernetesInstanceGroupTableControls
          csvData={csvData}
          debouncedSearchText={debouncedSearchText}
          instanceGroupFilters={queryParamState.groupFilters}
          searchText={searchText}
          onInteraction={handleInteraction}
        />
      </Box>

      <Box width="100%" overflowX="auto">
        <AWSKubernetesInstanceGroupTable
          instanceGroups={filteredInstanceGroups}
          isLoadingInstanceGroups={isLoadingInstanceGroups}
          onInteraction={handleInteraction}
        />
      </Box>

      {queryParamState.selectedGroup !== null && (
        <Modal
          isOpen
          showCloseButton
          onClose={() =>
            setQueryParams({
              instance_table_sort: null,
              selected_group: null,
            })
          }
          minWidth={1100}
        >
          <Modal.Header>
            <Flex
              justifyContent="space-between"
              marginLeft={theme.space_sm}
              width="100%"
            >
              <Text fontSize={theme.h4_fontSize}>
                {copyText.awsKubernetesInstanceTableModalHeader}
              </Text>
            </Flex>
          </Modal.Header>
          <Modal.Body>
            <AWSKubernetesInstanceTable
              instances={instances}
              isLoadingInstances={isLoadingInstances}
            />
          </Modal.Body>
        </Modal>
      )}
    </Box>
  );
}

const readableKeys = {
  sum_RemainingCPUHour: "Remaining Cores",
  sum_RemainingMemoryByteHour: "Remaining Memory",
  sum_UsedCPUHour: "Used Cores",
  sum_UsedMemoryByteHour: "Used Memory",
};

const csvAccessors = [
  "name",
  "accountID",
  "totalCost",
  "onDemandCost",
  "coveredCost",
  "cpuUsed",
  "cpuTotal",
  "memoryUsed",
  "memoryTotal",
] as const;

type CSVData = {
  headers: { key: string; label: string }[];
  rows: Record<string, string | number>[];
};

function getCSVData(groups: AWSKubernetesInstanceGroupEntity[]): CSVData {
  if (!groups.length) {
    return { headers: [], rows: [] };
  }

  const rows = groups.map((group) => ({
    name: group.name,
    accountID: group.accountID,
    totalCost: group.totalCost,
    onDemandCost: group.onDemandCost,
    coveredCost: group.coveredCost,
    cpuUsed: group.cpuUsed,
    cpuTotal: group.cpuTotal,
    memoryUsed: group.memoryUsed,
    memoryTotal: group.memoryTotal,
  }));

  const headers = csvAccessors.map((csvAccessor) => {
    // ensure rows has a value for each accessor
    const key: keyof (typeof rows)[number] = csvAccessor;

    // ensure copyText has a value for each accessor
    const copyTextKey: keyof typeof copyText = `awsKubernetesInstanceGroupHeader_${csvAccessor}`;
    const label = copyText[copyTextKey];

    return { key, label };
  });

  return { headers, rows };
}

type GetFilteredInstanceGroupsParams = {
  allInstanceGroups: AWSKubernetesInstanceGroupEntity[];
  instanceGroupFilters: AWSKubernetesInstanceGroupFilters;
  searchText: string | null;
};

function getFilteredInstanceGroups(
  params: GetFilteredInstanceGroupsParams
): AWSKubernetesInstanceGroupEntity[] {
  const searchText = params.searchText ?? "";

  return params.allInstanceGroups.filter((instanceGroup) => {
    if (
      !isInstanceGroupPassingFilters(instanceGroup, params.instanceGroupFilters)
    ) {
      return false;
    }

    if (!isSearchTextInInstanceGroup(instanceGroup, searchText)) {
      return false;
    }

    return true;
  });
}

function isInstanceGroupPassingFilters(
  instanceGroup: AWSKubernetesInstanceGroupEntity,
  filters: AWSKubernetesInstanceGroupFilters
): boolean {
  if (
    filters.accountID !== null &&
    instanceGroup.accountID.toLowerCase().trim() !==
      filters.accountID.toLowerCase().trim()
  ) {
    return false;
  }
  if (
    filters.name !== null &&
    instanceGroup.name.toLowerCase().trim() !==
      filters.name.toLowerCase().trim()
  ) {
    return false;
  }

  return true;
}

function isSearchTextInInstanceGroup(
  instanceGroup: AWSKubernetesInstanceGroupEntity,
  searchText: string
): boolean {
  if (searchText === "") return true;

  const values = [instanceGroup.accountID, instanceGroup.name].map((value) =>
    value === "" ? "null" : value
  );

  return values.some((value) =>
    value.toLowerCase().trim().includes(searchText.toLowerCase().trim())
  );
}

function getQueryParamState(queryParams: QueryParams): QueryParamState {
  const dateRange =
    queryParams.date_range_start && queryParams.date_range_end
      ? [queryParams.date_range_start, queryParams.date_range_end]
      : getCubeDateRangeFromDurationType(queryParams.duration);

  const dateRangeDurationInHours =
    dateRange[0] && dateRange[1]
      ? differenceInHours(endOfDay(dateRange[1]), dateRange[0])
      : 0;

  const isSmallDateRange =
    dateRangeDurationInHours > 0 && dateRangeDurationInHours <= 48;

  const dateRangeGranularity = isSmallDateRange
    ? TimeGranularity.HOUR
    : TimeGranularity.DAY;

  return {
    accountID: queryParams.account_id,
    dateRange,
    dateRangeGranularity,
    duration: queryParams.duration,
    groupFilters:
      queryParams.instance_group_filters ?? instanceGroupFiltersDefault,
    selectedGroup: queryParams.selected_group ?? null,
    usageMeasure: queryParams.usage_measure,
  };
}
