import LoadingSpinner from "@/ui-lib/components/LoadingSpinner";
import TextInput from "@/ui-lib/components/TextInput";
import { useTheme } from "@emotion/react";
import {
  faFileExport,
  faSearch,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import Button from "@ternary/api-lib/ui-lib/components/Button";
import Box from "@ternary/web-ui-lib/components/Box";
import Flex from "@ternary/web-ui-lib/components/Flex";
import Icon from "@ternary/web-ui-lib/components/Icon";
import Text from "@ternary/web-ui-lib/components/Text";
import { format } from "date-fns";
import React from "react";
import { CSVLink } from "react-csv";
import copyText from "../../copyText";
import { GCPCloudRunServiceGroupFilters } from "../types";

type CSVData = {
  headers: { key: string; label: string }[];
  rows: Record<string, string | number>[];
};

type Props = {
  csvData: CSVData;
  debouncedSearchText: string;
  searchText: string;
  serviceGroupFilters: GCPCloudRunServiceGroupFilters;
  onInteraction: (
    interaction: GCPCloudRunServiceGroupTableControls.Interaction
  ) => void;
};

function GCPCloudRunServiceGroupTableControls(props: Props) {
  const theme = useTheme();

  return (
    <Flex alignItems="center" minHeight={30}>
      <Flex flex="1 0 0">
        {props.serviceGroupFilters.lowCarbon !== null ? (
          <Chip
            label={copyText.cloudRunTableServiceGroupHeader_lowCarbon}
            text={props.serviceGroupFilters.lowCarbon}
            onClick={() =>
              props.onInteraction({
                type: GCPCloudRunServiceGroupTableControls.INTERACTION_REMOVE_FILTER_CLICKED,
                filterKey: "lowCarbon",
              })
            }
          />
        ) : null}

        {props.serviceGroupFilters.projectId !== null ? (
          <Chip
            label={copyText.cloudRunTableServiceGroupHeader_projectId}
            text={props.serviceGroupFilters.projectId}
            onClick={() =>
              props.onInteraction({
                type: GCPCloudRunServiceGroupTableControls.INTERACTION_REMOVE_FILTER_CLICKED,
                filterKey: "projectId",
              })
            }
          />
        ) : null}

        {props.serviceGroupFilters.region !== null ? (
          <Chip
            label={copyText.cloudRunTableServiceGroupHeader_region}
            text={props.serviceGroupFilters.region}
            onClick={() =>
              props.onInteraction({
                type: GCPCloudRunServiceGroupTableControls.INTERACTION_REMOVE_FILTER_CLICKED,
                filterKey: "region",
              })
            }
          />
        ) : null}

        {props.serviceGroupFilters.regionalPriceTier !== null ? (
          <Chip
            label={copyText.cloudRunTableServiceGroupHeader_type}
            text={props.serviceGroupFilters.regionalPriceTier}
            onClick={() =>
              props.onInteraction({
                type: GCPCloudRunServiceGroupTableControls.INTERACTION_REMOVE_FILTER_CLICKED,
                filterKey: "regionalPriceTier",
              })
            }
          />
        ) : null}
      </Flex>

      <Box marginHorizontal={theme.space_md}>
        <TextInput
          iconEnd={
            props.searchText === props.debouncedSearchText ||
            props.searchText === "" ? (
              <Icon color={theme.text_color_secondary} icon={faSearch} />
            ) : (
              <LoadingSpinner />
            )
          }
          placeholder={copyText.searchInputPlaceholder}
          size="large"
          value={props.searchText}
          onChange={(e) =>
            props.onInteraction({
              type: GCPCloudRunServiceGroupTableControls.INTERACTION_SEARCH_TEXT_UPDATED,
              searchText: e.target.value,
            })
          }
        />
      </Box>

      <Box>
        <CSVLink
          data={props.csvData.rows}
          headers={props.csvData.headers}
          filename={`GCP-Cloud-Run-service-groups-${format(
            new Date(),
            "MM-dd-yyyy"
          )}`}
        >
          <Button
            iconStart={<Icon color="inherit" icon={faFileExport} />}
            secondary
            size="small"
          >
            {copyText.exportButtonLabel}
          </Button>
        </CSVLink>
      </Box>
    </Flex>
  );
}

type ChipProps = {
  text: string;
  label: string;
  onClick: () => void;
};

function Chip(props: ChipProps) {
  const theme = useTheme();

  return (
    <Flex
      alignItems="center"
      backgroundColor={theme.primary_color_background}
      backgroundColorOnHover={theme.tag_button_background_color_hover}
      borderRadius={theme.borderRadius_3}
      cursor="pointer"
      marginRight={theme.space_sm}
      paddingHorizontal={theme.space_sm}
      paddingRight={theme.space_sm}
      paddingVertical={theme.space_xxs}
      position="relative"
      onClick={props.onClick}
    >
      <Box>
        <Box height={8}>
          <Text
            bold
            color={theme.text_color_inverse}
            fontSize={theme.fontSize_xs}
          >
            {props.label}:
          </Text>
        </Box>

        <Box paddingLeft={theme.space_xxs}>
          <Text color={theme.text_color_inverse}>
            {props.text || copyText.cloudRunTableNull}
          </Text>
        </Box>
      </Box>

      <Box paddingLeft={theme.space_sm}>
        <Icon color={theme.text_color_inverse} icon={faTimes} size="xs" />
      </Box>
    </Flex>
  );
}

GCPCloudRunServiceGroupTableControls.INTERACTION_REMOVE_FILTER_CLICKED =
  `GCPCloudRunServiceGroupTableControls.INTERACTION_REMOVE_FILTER_CLICKED` as const;

GCPCloudRunServiceGroupTableControls.INTERACTION_SEARCH_TEXT_UPDATED =
  `GCPCloudRunServiceGroupTableControls.INTERACTION_SEARCH_TEXT_UPDATED` as const;

interface InteractionRemoveFilterClicked {
  type: typeof GCPCloudRunServiceGroupTableControls.INTERACTION_REMOVE_FILTER_CLICKED;
  filterKey: keyof GCPCloudRunServiceGroupFilters;
}

interface InteractionSearchTextUpdated {
  type: typeof GCPCloudRunServiceGroupTableControls.INTERACTION_SEARCH_TEXT_UPDATED;
  searchText: string;
}

// eslint-disable-next-line @typescript-eslint/no-namespace
namespace GCPCloudRunServiceGroupTableControls {
  export type Interaction =
    | InteractionRemoveFilterClicked
    | InteractionSearchTextUpdated;
}
export default GCPCloudRunServiceGroupTableControls;
