export const SYSTEM_ADMIN_ID = "00000000-0000-4000-b000-000000000000";

export const SYSTEM_TENANT_ID = "00000000-0000-5000-c000-000000000000";

export const DEFAULT_DASHBOARD_ID = "00000000-0000-1000-d000-000000000000";

export const systemUser = {
  id: SYSTEM_ADMIN_ID,
  avatarSrc: "/assets/img/user.png",
  email: "system-admin@ternary.app",
  firstName: "Ternary",
  lastName: "System",
};

export const SYSTEM_TAG = "System";

export default systemUser;
