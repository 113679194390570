import { DateHelper } from "@/lib/dates";
import { useCSVDownloader } from "@/ui-lib/components/CSVDownloader";
import DateRangeControls from "@/ui-lib/components/DateRangeControls/DateRangeControls";
import { DateRange } from "@/utils/dates";
import { useTheme } from "@emotion/react";
import { faChevronDown, faFileExport } from "@fortawesome/free-solid-svg-icons";
import { useQueryClient } from "@tanstack/react-query";
import { QueryFilter, RawValue } from "@ternary/api-lib/analytics/types";
import { getCubeDateRangeFromDurationType } from "@ternary/api-lib/analytics/utils";
import { UnitType } from "@ternary/api-lib/constants/analytics";
import {
  ChartType,
  DataSource,
  DurationType,
  Operator,
  TimeGranularity,
} from "@ternary/api-lib/constants/enums";
import AreaChart from "@ternary/api-lib/ui-lib/charts/AreaChart";
import Button from "@ternary/api-lib/ui-lib/components/Button";
import { formatNumber } from "@ternary/api-lib/ui-lib/utils/formatNumber";
import StackedBarChart from "@ternary/web-ui-lib/charts/StackedBarChart";
import Box from "@ternary/web-ui-lib/components/Box";
import Flex from "@ternary/web-ui-lib/components/Flex";
import Icon from "@ternary/web-ui-lib/components/Icon";
import Text from "@ternary/web-ui-lib/components/Text";
import { differenceInHours, endOfDay, format } from "date-fns";
import prettyBytes from "pretty-bytes";
import React, { useEffect, useMemo, useState } from "react";
import { CSVLink } from "react-csv";
import {
  DateParam,
  DecodedValueMap,
  JsonParam,
  StringParam,
  createEnumParam,
  useQueryParams,
  withDefault,
} from "use-query-params";
import { z } from "zod";
import useGetRawData from "../../../../api/analytics/useGetRawData";
import InsightsSelector from "../../../../components/InsightsSelector";
import useAvailableGlobalDate from "../../../../hooks/useAvailableGlobalDate";
import { createStructParam } from "../../../../lib/use-query-params";
import Dropdown, { Option } from "../../../../ui-lib/components/Dropdown";
import Grid from "../../../../ui-lib/components/Grid";
import LoadingSpinner from "../../../../ui-lib/components/LoadingSpinner";
import Modal from "../../../../ui-lib/components/Modal";
import { useDebounce } from "../../../../utils/timers";
import copyText from "../../copyText";
import useGetAzureAKSClusterGroups from "../hooks/useGetAzureAKSClusterGroups";
import useGetAzureAKSClusters from "../hooks/useGetAzureAKSClusters";
import useGetAzureAKSCostUsageSummary from "../hooks/useGetAzureAKSCostUsageSummary";
import {
  AzureAKSClusterGroupFilters,
  AzureAKSClustersEntity,
  AzureAKSClustersGroupEntity,
  AzureAKSDimensions,
  AzureAKSMeasures,
} from "../types";
import AzureKubernetesClusterGroupTable from "./AzureKubernetesClusterGroupTable";
import AzureKubernetesClusterGroupTableControls from "./AzureKubernetesClusterGroupTableControls";
import { AzureKubernetesClustersTable } from "./AzureKubernetesClustersTable";
import AzureKubernetesMeters from "./AzureKubernetesMeters";

type Interaction =
  | AzureKubernetesClusterGroupTable.Interaction
  | AzureKubernetesClusterGroupTableControls.Interaction;

type QueryParams = DecodedValueMap<typeof queryParamConfigMap>;

type QueryParamState = {
  cloudID: string;
  dateRange: DateRange;
  dateRangeGranularity: TimeGranularity;
  duration: DurationType;
  groupFilters: AzureAKSClusterGroupFilters;
  selectedGroup: z.infer<typeof selectedInstanceGroupStruct> | null;
  usageMeasure: UsageMeasure;
};

const UsageMeasure = {
  CPU: "CPU",
  MEMORY: "MEMORY",
} as const;

type UsageMeasure = (typeof UsageMeasure)[keyof typeof UsageMeasure];

const ALL_ACCOUNTS_ID = "__ALL__";

const durationEnum = createEnumParam(Object.values(DurationType));
const usageMeasureEnum = createEnumParam(Object.values(UsageMeasure));

const instanceGroupFiltersStruct = z.object({
  cloudId: z.nullable(z.string()),
  clusterName: z.nullable(z.string()),
  resourceGroupName: z.nullable(z.string()),
  subscriptionName: z.nullable(z.string()),
});

const instanceGroupFiltersDefault = {
  cloudId: null,
  clusterName: null,
  resourceGroupName: null,
  subscriptionName: null,
};

const selectedInstanceGroupStruct = z.object({
  cloudId: z.string(),
  clusterName: z.string(),
  resourceGroupName: z.string(),
  subscriptionName: z.string(),
});

const queryParamConfigMap = {
  cloud_id: withDefault(StringParam, ALL_ACCOUNTS_ID),
  date_range_end: DateParam,
  date_range_start: DateParam,
  duration: withDefault(durationEnum, DurationType.LAST_THIRTY_DAYS),
  instance_group_filters: createStructParam(instanceGroupFiltersStruct),
  instance_table_sort: JsonParam,
  selected_group: createStructParam(selectedInstanceGroupStruct),
  usage_measure: withDefault(usageMeasureEnum, UsageMeasure.CPU),
};

export default function AzureKubernetesContainer() {
  const theme = useTheme();
  const globalDate = useAvailableGlobalDate();
  const queryClient = useQueryClient();

  //
  // STATE
  //

  const [queryParams, setQueryParams] = useQueryParams(queryParamConfigMap);
  const queryParamState = getQueryParamState(queryParams);
  const [searchText, setSearchText] = useState("");

  const debouncedSearchText = useDebounce(searchText);
  const { downloadCSV, csvElement } = useCSVDownloader();

  //
  // QUERIES
  //

  const dateRange = globalDate.date ?? queryParamState.dateRange;

  const cloudIDFilter: QueryFilter[] = [];
  if (queryParamState.cloudID !== ALL_ACCOUNTS_ID) {
    cloudIDFilter.push({
      name: "cloudId",
      operator: Operator.EQUALS,
      values: [queryParamState.cloudID],
    });
  }

  const {
    data: costUsageSummary = null,
    isFetching: isLoadingCostUsageSummary,
  } = useGetAzureAKSCostUsageSummary({
    dateRange,
    queryFilters: cloudIDFilter,
  });

  const costDimensions = [AzureAKSDimensions.clusterName];
  const costMeasures = [AzureAKSMeasures.cost];

  const { data: costData = [], isFetching: isLoadingCostData } = useGetRawData({
    dataSource: DataSource.AZURE_KUBERNETES_NODE_USAGE,
    dateRange,
    dimensions: costDimensions,
    granularity: queryParamState.dateRangeGranularity,
    measures: costMeasures,
    queryFilters: cloudIDFilter,
  });

  const usageMeasures =
    queryParamState.usageMeasure === UsageMeasure.CPU
      ? [
          AzureAKSMeasures.estimatedCpuCoreHoursAvailable,
          AzureAKSMeasures.upperBoundCpuCoreHoursUsed,
        ]
      : [
          AzureAKSMeasures.estimatedRssByteHoursAvailable,
          AzureAKSMeasures.upperBoundRssByteHoursUsed,
        ];

  const { data: usageData = [], isFetching: isLoadingUsageData } =
    useGetRawData({
      dataSource: DataSource.AZURE_KUBERNETES_NODE_USAGE,
      dateRange,
      granularity: queryParamState.dateRangeGranularity,
      measures: usageMeasures,
      queryFilters: cloudIDFilter,
    });

  const { data: clusterGroups = [], isFetching: isLoadingClusterGroups } =
    useGetAzureAKSClusterGroups({
      dateRange,
    });

  const { data: clusters = [], isFetching: isLoadingClusters } =
    useGetAzureAKSClusters(
      {
        dateRange,
        queryFilters: queryParamState.selectedGroup
          ? getSelectedInstanceGroupQueryFilters(queryParamState.selectedGroup)
          : [],
      },
      { enabled: queryParamState.selectedGroup !== null }
    );

  const {
    data: allClusters = [],
    isFetching: isLoadingAllClusters,
    refetch: fetchAllClusters,
  } = useGetAzureAKSClusters(
    {
      dateRange,
      queryFilters: [],
    },
    { enabled: false, refetchInterval: false }
  );

  function clearAllClustersQuery() {
    queryClient.removeQueries({ queryKey: ["AzureAKSClustersCSV"] });
  }

  //
  // MODIFIED QUERY DATA
  //

  const filteredInstanceGroups = useMemo(() => {
    return getFilteredInstanceGroups({
      allInstanceGroups: clusterGroups ?? [],
      instanceGroupFilters: queryParamState.groupFilters,
      searchText: debouncedSearchText,
    });
  }, [debouncedSearchText, clusterGroups, queryParamState.groupFilters]);

  const hourCorrectedUsageData = usageData.map((datum) => {
    if (queryParamState.dateRangeGranularity === TimeGranularity.HOUR) {
      return datum;
    }

    const corrected = Object.entries(datum).map(
      ([key, value]): [string, RawValue] => {
        return [key, typeof value === "number" ? value / 24 : value];
      }
    );

    return Object.fromEntries(corrected);
  });

  const clusterGroupCSVData = useMemo(
    () => getClusterGroupCSVData(filteredInstanceGroups),
    [filteredInstanceGroups]
  );

  const clustersCSVData = useMemo(
    () => getClustersCSVData(clusters),
    [clusters]
  );

  const cloudIDs = clusterGroups.reduce((accum: string[], cluster) => {
    if (!accum.includes(cluster.cloudId)) {
      accum.push(cluster.cloudId);
    }
    return accum;
  }, []);

  //
  // EFFECTS
  //

  useEffect(() => {
    if (allClusters.length === 0) {
      return;
    }

    const allClustersCSVData = getClustersCSVData(allClusters);

    downloadCSV({
      data: allClustersCSVData.rows,
      fileName: `azure-all-kubernetes-clusters-${format(
        new Date(),
        "MM-dd-yyyy"
      )}`,
      headers: allClustersCSVData.headers,
    });

    // clear allClusters query from the cache.
    clearAllClustersQuery();
  }, [allClusters]);

  //
  // INTERACTIONS
  //

  function handleInteraction(interaction: Interaction) {
    switch (interaction.type) {
      case AzureKubernetesClusterGroupTable.INTERACTION_FILTER_CLICKED: {
        const nextFilters = { ...queryParamState.groupFilters };
        nextFilters[interaction.filterKey] = interaction.filterValue;

        setQueryParams({ instance_group_filters: nextFilters });
        break;
      }
      case AzureKubernetesClusterGroupTableControls.INTERACTION_DOWNLOAD_CLUSTERS_CLICKED: {
        fetchAllClusters();
        break;
      }
      case AzureKubernetesClusterGroupTableControls.INTERACTION_REMOVE_FILTER_CLICKED: {
        const nextFilters = { ...queryParamState.groupFilters };

        nextFilters[interaction.filterKey] = null;

        setQueryParams({ instance_group_filters: nextFilters });
        break;
      }
      case AzureKubernetesClusterGroupTableControls.INTERACTION_SEARCH_TEXT_UPDATED: {
        setSearchText(interaction.searchText);

        break;
      }
    }
  }

  const costChartReportSnapshot = {
    chartType: ChartType.STACKED_BAR,
    dateRange: dateRange,
    dataSource: DataSource.AZURE_KUBERNETES_NODE_USAGE,
    dimensions: costDimensions,
    durationType: DurationType.CUSTOM,
    isFiscalMode: false,
    fillMissingDates: true,
    fiscalPeriodMap: null,
    granularity: queryParamState.dateRangeGranularity,
    measures: costMeasures,
    queryFilters: cloudIDFilter,
    name: copyText.gkeCostReportSnapshotName,
    xAxisKey: "timestamp",
  };

  const usageChartReportSnapshot = {
    chartType: ChartType.AREA,
    dateRange: dateRange,
    dataSource: DataSource.AZURE_KUBERNETES_NODE_USAGE,
    dimensions: [],
    durationType: DurationType.CUSTOM,
    isFiscalMode: false,
    fillMissingDates: true,
    fiscalPeriodMap: null,
    granularity: queryParamState.dateRangeGranularity,
    measures: usageMeasures,
    queryFilters: cloudIDFilter,
    name: copyText.gkeUsageReportSnapshotName,
    xAxisKey: "timestamp",
  };

  //
  // RENDER
  //

  const cloudIDOptions: Option[] = [
    {
      label: copyText.azureKubernetesAccountsAllLabel,
      value: ALL_ACCOUNTS_ID,
      onClick: () =>
        setQueryParams({
          cloud_id: ALL_ACCOUNTS_ID,
          instance_group_filters: {
            ...queryParamState.groupFilters,
            cloudId: null,
          },
        }),
    },
    ...(isLoadingClusterGroups
      ? [
          {
            label: copyText.azureKubernetesAccountsLoadingLabel,
            locked: true,
            value: "",
          },
        ]
      : []),
    ...cloudIDs.map((cloudID) => ({
      label: cloudID,
      value: cloudID,
      onClick: () => setQueryParams({ cloud_id: cloudID }),
    })),
  ];

  function formatUsageMeasure(value: number) {
    if (queryParamState.usageMeasure === UsageMeasure.MEMORY) {
      return prettyBytes(value, { binary: true });
    }

    return formatNumber(value);
  }

  return (
    <Box width="100%" paddingTop={theme.space_md}>
      <Flex
        backgroundColor={theme.panel_backgroundColor}
        borderRadius={theme.borderRadius_1}
        justifyContent="space-between"
        marginBottom={theme.space_lg}
        padding={theme.space_md}
      >
        <Box>
          <Dropdown
            options={cloudIDOptions}
            placement="bottom-start"
            selectedOption={cloudIDOptions.find(
              (option) => option.value === queryParams.cloud_id
            )}
          >
            <Button
              iconEnd={
                isLoadingClusterGroups ? (
                  <LoadingSpinner />
                ) : (
                  <Icon icon={faChevronDown} />
                )
              }
              marginRight={theme.space_xs}
              secondary
              size="small"
            >
              {queryParams.cloud_id === ALL_ACCOUNTS_ID
                ? copyText.azureKubernetesAccountsAllLabel
                : queryParams.cloud_id}
            </Button>
          </Dropdown>
        </Box>

        <DateRangeControls
          dateRange={dateRange}
          durationType={queryParamState.duration}
          hiddenOptions={[
            DurationType.LAST_NINETY_DAYS,
            DurationType.QUARTER_TO_DATE,
            DurationType.YEAR_TO_DATE,
          ]}
          maxDate={new DateHelper().date}
          onChangeDateRange={(duration, newDateRange) => {
            setQueryParams({
              duration,
              ...(newDateRange && newDateRange[0] && newDateRange[1]
                ? {
                    date_range_start: newDateRange[0],
                    date_range_end: newDateRange[1],
                  }
                : {
                    date_range_start: null,
                    date_range_end: null,
                  }),
            });
          }}
        />
      </Flex>

      <Box
        backgroundColor={theme.panel_backgroundColor}
        borderRadius={theme.borderRadius_2}
        marginBottom={theme.space_lg}
        padding={theme.space_md}
      >
        <AzureKubernetesMeters
          cpuTotal={costUsageSummary?.estimatedCpuCoreHoursAvailable ?? 0}
          cpuUsed={costUsageSummary?.upperBoundCpuCoreHoursUsed ?? 0}
          isLoading={isLoadingCostUsageSummary}
          memoryTotal={costUsageSummary?.estimatedRssByteHoursAvailable ?? 0}
          memoryUsed={costUsageSummary?.upperBoundRssByteHoursUsed ?? 0}
          totalCost={costUsageSummary?.cost ?? 0}
        />
      </Box>

      <Grid
        gridColumnGap={theme.space_lg}
        gridTemplateColumns={`repeat(2, calc(50% - (${theme.space_lg} / 2) ))`}
      >
        <InsightsSelector
          resourceName={copyText.azureKubernetesCostReportSnapshotName}
          reportSnapshot={costChartReportSnapshot}
        >
          <Flex
            backgroundColor={theme.panel_backgroundColor}
            borderRadius={theme.borderRadius_2}
            direction="column"
            height={500}
            padding={theme.space_md}
          >
            <Flex
              justifyContent="space-between"
              alignItems="center"
              paddingBottom={theme.space_md}
            >
              <Text fontSize={theme.h3_fontSize}>
                {copyText.azureKubernetesChartLabelCost}
              </Text>
            </Flex>

            <Box flex="1 0 0">
              <StackedBarChart
                data={costData}
                dimensions={[{ name: "clusterName" }]}
                isLoading={isLoadingCostData}
                measures={[{ name: "cost", unit: UnitType.CURRENCY }]}
                showLegend
                showTooltip
                timeSeriesGranularity={queryParamState.dateRangeGranularity}
                xAxisKey="timestamp"
              />
            </Box>
          </Flex>
        </InsightsSelector>
        <InsightsSelector
          resourceName={copyText.azureKubernetesUsageReportSnapshotName}
          reportSnapshot={usageChartReportSnapshot}
        >
          <Flex
            backgroundColor={theme.panel_backgroundColor}
            borderRadius={theme.borderRadius_2}
            direction="column"
            height={500}
            padding={theme.space_md}
          >
            <Flex
              alignItems="center"
              justifyContent="space-between"
              paddingBottom={theme.space_md}
            >
              <Text fontSize={theme.h3_fontSize}>
                {copyText.azureKubernetesChartLabelUsage}
              </Text>

              <Box flex="0 0 auto">
                <Button
                  primary={queryParamState.usageMeasure === UsageMeasure.CPU}
                  secondary={queryParamState.usageMeasure !== UsageMeasure.CPU}
                  size="small"
                  width={140}
                  onClick={() =>
                    setQueryParams({ usage_measure: UsageMeasure.CPU })
                  }
                >
                  {copyText.azureKubernetesUsageOptionLabelCPU}
                </Button>
                <Button
                  marginLeft={theme.space_sm}
                  primary={queryParamState.usageMeasure === UsageMeasure.MEMORY}
                  secondary={
                    queryParamState.usageMeasure !== UsageMeasure.MEMORY
                  }
                  size="small"
                  width={140}
                  onClick={() =>
                    setQueryParams({ usage_measure: UsageMeasure.MEMORY })
                  }
                >
                  {copyText.azureKubernetesUsageOptionLabelMemory}
                </Button>
              </Box>
            </Flex>

            <Box flex="1 0 0">
              <AreaChart
                data={hourCorrectedUsageData}
                isLoading={isLoadingUsageData}
                measures={usageMeasures.map((name) => ({
                  name: name,
                  unit:
                    queryParamState.usageMeasure !== UsageMeasure.CPU
                      ? UnitType.BYTES
                      : UnitType.CORES,
                }))}
                readableKeys={readableKeys}
                showLegend
                showTooltip
                stacked
                timeSeriesGranularity={queryParamState.dateRangeGranularity}
                tooltipFormatter={formatUsageMeasure}
                xAxisKey="timestamp"
                yAxisFormatter={formatUsageMeasure}
              />
            </Box>
          </Flex>
        </InsightsSelector>
      </Grid>

      <Box
        backgroundColor={theme.panel_backgroundColor}
        borderRadius={theme.borderRadius_1}
        marginVertical={theme.space_lg}
        padding={theme.space_md}
      >
        <AzureKubernetesClusterGroupTableControls
          csvData={clusterGroupCSVData}
          debouncedSearchText={debouncedSearchText}
          instanceGroupFilters={queryParamState.groupFilters}
          isLoadingAllClusters={isLoadingAllClusters}
          searchText={searchText}
          onInteraction={handleInteraction}
        />
        {csvElement}
      </Box>

      <Box width="100%" overflowX="auto">
        <AzureKubernetesClusterGroupTable
          clusterGroups={filteredInstanceGroups}
          isLoadingClusterGroups={isLoadingClusterGroups}
          onInteraction={handleInteraction}
        />
      </Box>

      {queryParamState.selectedGroup !== null && (
        <Modal
          isOpen
          showCloseButton
          onClose={() =>
            setQueryParams({
              instance_table_sort: null,
              selected_group: null,
            })
          }
          minWidth={1100}
        >
          <Modal.Header>
            <Flex
              justifyContent="space-between"
              marginLeft={theme.space_sm}
              width="100%"
            >
              <Box>
                <Text fontSize={theme.h4_fontSize}>
                  {copyText.azureKubernetesClustersTableModalHeader}
                </Text>
              </Box>
              {clustersCSVData.rows.length > 0 && (
                <CSVLink
                  data={clustersCSVData.rows}
                  headers={clustersCSVData.headers}
                  filename={`azure-kubernetes-clusters-${format(
                    new Date(),
                    "MM-dd-yyyy"
                  )}`}
                >
                  <Button
                    iconStart={<Icon color="inherit" icon={faFileExport} />}
                    marginRight={theme.space_sm}
                    secondary
                    size="small"
                  >
                    {copyText.exportButtonLabel}
                  </Button>
                </CSVLink>
              )}
            </Flex>
          </Modal.Header>
          <Modal.Body>
            <AzureKubernetesClustersTable
              clusters={clusters}
              isLoadingClusters={isLoadingClusters}
            />
          </Modal.Body>
        </Modal>
      )}
    </Box>
  );
}

const readableKeys = {
  estimatedCpuCoreHoursAvailable: "Remaining Cores",
  estimatedRssByteHoursAvailable: "Remaining Memory",
  upperBoundCpuCoreHoursUsed: "Used Cores",
  upperBoundRssByteHoursUsed: "Used Memory",
};

const clustersGroupCSVAccessors = [
  "cloudId",
  "clusterName",
  "resourceGroupName",
  "subscriptionName",
  "cost",
  "instanceHours",
  "estimatedCpuCoreHoursAvailable",
  "upperBoundCpuCoreHoursUsed",
  "estimatedRssByteHoursAvailable",
  "upperBoundRssByteHoursUsed",
] as const;

type CSVData = {
  headers: { key: string; label: string }[];
  rows: Record<string, string | number>[];
};

function getClusterGroupCSVData(
  groups: AzureAKSClustersGroupEntity[]
): CSVData {
  if (!groups.length) {
    return { headers: [], rows: [] };
  }

  const rows = groups.map((group) => ({
    cloudId: group.cloudId,
    clusterName: group.clusterName,
    cost: group.cost,
    estimatedCpuCoreHoursAvailable: group.estimatedCpuCoreHoursAvailable,
    estimatedRssByteHoursAvailable: group.estimatedRssByteHoursAvailable,
    instanceHours: group.instanceHours,
    resourceGroupName: group.resourceGroupName,
    subscriptionName: group.subscriptionName,
    upperBoundCpuCoreHoursUsed: group.upperBoundCpuCoreHoursUsed,
    upperBoundRssByteHoursUsed: group.upperBoundRssByteHoursUsed,
  }));

  const headers = clustersGroupCSVAccessors.map((csvAccessor) => {
    // ensure rows has a value for each accessor
    const key: keyof (typeof rows)[number] = csvAccessor;

    // ensure copyText has a value for each accessor
    const copyTextKey: keyof typeof copyText = `azureKubernetesInstanceGroupHeader_${csvAccessor}`;
    const label = copyText[copyTextKey];

    return { key, label };
  });

  return { headers, rows };
}

const clustersCSVAccessors = [
  "cloudId",
  "clusterName",
  "resourceGroupName",
  "subscriptionName",
  "nodepool",
  "instanceType",
  "cost",
  "instanceHours",
  "estimatedCpuCoreHoursAvailable",
  "upperBoundCpuCoreHoursUsed",
  "estimatedRssByteHoursAvailable",
  "upperBoundRssByteHoursUsed",
] as const;

function getClustersCSVData(groups: AzureAKSClustersEntity[]): CSVData {
  if (!groups.length) {
    return { headers: [], rows: [] };
  }

  const rows = groups.map((group) => ({
    cloudId: group.cloudId,
    clusterName: group.clusterName,
    cost: group.cost,
    estimatedCpuCoreHoursAvailable: group.estimatedCpuCoreHoursAvailable,
    estimatedRssByteHoursAvailable: group.estimatedRssByteHoursAvailable,
    instanceHours: group.instanceHours,
    instanceType: group.instanceType,
    nodepool: group.nodepool,
    resourceGroupName: group.resourceGroupName,
    subscriptionName: group.subscriptionName,
    upperBoundCpuCoreHoursUsed: group.upperBoundCpuCoreHoursUsed,
    upperBoundRssByteHoursUsed: group.upperBoundRssByteHoursUsed,
  }));

  const headers = clustersCSVAccessors.map((csvAccessor) => {
    // ensure rows has a value for each accessor
    const key: keyof (typeof rows)[number] = csvAccessor;

    // ensure copyText has a value for each accessor
    const copyTextKey: keyof typeof copyText = `azureKubernetesTableInstanceHeader_${csvAccessor}`;
    const label = copyText[copyTextKey];

    return { key, label };
  });

  return { headers, rows };
}

type GetFilteredInstanceGroupsParams = {
  allInstanceGroups: AzureAKSClustersGroupEntity[];
  instanceGroupFilters: AzureAKSClusterGroupFilters;
  searchText: string | null;
};

function getFilteredInstanceGroups(
  params: GetFilteredInstanceGroupsParams
): AzureAKSClustersGroupEntity[] {
  const searchText = params.searchText ?? "";

  return params.allInstanceGroups.filter((instanceGroup) => {
    if (
      !isInstanceGroupPassingFilters(instanceGroup, params.instanceGroupFilters)
    ) {
      return false;
    }

    if (!isSearchTextInInstanceGroup(instanceGroup, searchText)) {
      return false;
    }

    return true;
  });
}

function getSelectedInstanceGroupQueryFilters(
  selectedInstanceGroup: z.infer<typeof selectedInstanceGroupStruct>
): QueryFilter[] {
  return [
    AzureAKSDimensions.cloudId,
    AzureAKSDimensions.clusterName,
    AzureAKSDimensions.resourceGroupName,
    AzureAKSDimensions.subscriptionName,
  ].map(
    (key): QueryFilter =>
      selectedInstanceGroup[key] === ""
        ? {
            name: key,
            operator: Operator.NOT_SET,
          }
        : {
            name: key,
            operator: Operator.EQUALS,
            values: [selectedInstanceGroup[key]],
          }
  );
}

function isInstanceGroupPassingFilters(
  instanceGroup: AzureAKSClustersGroupEntity,
  filters: AzureAKSClusterGroupFilters
): boolean {
  if (
    filters.cloudId !== null &&
    instanceGroup.cloudId.toLowerCase().trim() !==
      filters.cloudId.toLowerCase().trim()
  ) {
    return false;
  }
  if (
    filters.clusterName !== null &&
    instanceGroup.clusterName.toLowerCase().trim() !==
      filters.clusterName.toLowerCase().trim()
  ) {
    return false;
  }
  if (
    filters.resourceGroupName !== null &&
    instanceGroup.resourceGroupName.toLowerCase().trim() !==
      filters.resourceGroupName.toLowerCase().trim()
  ) {
    return false;
  }
  if (
    filters.subscriptionName !== null &&
    instanceGroup.subscriptionName.toLowerCase().trim() !==
      filters.subscriptionName.toLowerCase().trim()
  ) {
    return false;
  }

  return true;
}

function isSearchTextInInstanceGroup(
  instanceGroup: AzureAKSClustersGroupEntity,
  searchText: string
): boolean {
  if (searchText === "") return true;

  const values = [
    instanceGroup.cloudId,
    instanceGroup.clusterName,
    instanceGroup.resourceGroupName,
    instanceGroup.subscriptionName,
  ].map((value) => (value === "" ? "null" : value));

  return values.some((value) =>
    value.toLowerCase().trim().includes(searchText.toLowerCase().trim())
  );
}

function getQueryParamState(queryParams: QueryParams): QueryParamState {
  const dateRange =
    queryParams.date_range_start && queryParams.date_range_end
      ? [queryParams.date_range_start, queryParams.date_range_end]
      : getCubeDateRangeFromDurationType(queryParams.duration);

  const dateRangeDurationInHours =
    dateRange[0] && dateRange[1]
      ? differenceInHours(endOfDay(dateRange[1]), dateRange[0])
      : 0;

  const isSmallDateRange =
    dateRangeDurationInHours > 0 && dateRangeDurationInHours <= 48;

  const dateRangeGranularity = isSmallDateRange
    ? TimeGranularity.HOUR
    : TimeGranularity.DAY;

  return {
    cloudID: queryParams.cloud_id,
    dateRange,
    dateRangeGranularity,
    duration: queryParams.duration,
    groupFilters:
      queryParams.instance_group_filters ?? instanceGroupFiltersDefault,
    selectedGroup: queryParams.selected_group ?? null,
    usageMeasure: queryParams.usage_measure,
  };
}
