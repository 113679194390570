import { useTheme } from "@emotion/react";
import { faBinoculars } from "@fortawesome/free-solid-svg-icons";
import Button, {
  Props as ButtonProps,
} from "@ternary/api-lib/ui-lib/components/Button";
import Icon from "@ternary/web-ui-lib/components/Icon";
import React, { PropsWithChildren } from "react";

export type Variant = "custom" | "default" | "off";

interface Props extends Omit<ButtonProps, "variant"> {
  variant: Variant;
}

export default function GlobalFilterButton(
  props: PropsWithChildren<Props>
): JSX.Element {
  const theme = useTheme();

  const iconStart =
    props.variant === "off" ? <Icon icon={faBinoculars} /> : undefined;

  return (
    <Button
      disabled={props.disabled}
      iconStart={iconStart}
      locked={props.locked}
      marginRight={theme.space_md}
      primary={props.variant === "default" || props.variant === "custom"}
      secondary={props.variant === "off"}
      size="small"
      variant={props.variant === "custom" ? "danger" : undefined}
      onClick={props.onClick}
    >
      {props.children}
    </Button>
  );
}
