import { useTheme } from "@emotion/react";
import { faX } from "@fortawesome/free-solid-svg-icons";
import { MspBillingInvoiceEntity } from "@ternary/api-lib/core/types";
import getMergeState from "@ternary/api-lib/ui-lib/charts/utils";
import Box from "@ternary/api-lib/ui-lib/components/Box";
import Button from "@ternary/api-lib/ui-lib/components/Button";
import Flex from "@ternary/api-lib/ui-lib/components/Flex";
import Icon from "@ternary/api-lib/ui-lib/components/Icon";
import Text from "@ternary/api-lib/ui-lib/components/Text";
import React, { useState } from "react";
import LoadingSpinner from "../../../ui-lib/components/LoadingSpinner";
import Modal from "../../../ui-lib/components/Modal";
import TextArea from "../../../ui-lib/components/TextArea";
import copyText from "../copyText";

interface Props {
  invoice?: MspBillingInvoiceEntity;
  isLoading: boolean;
  onInteraction: (interaction: MspBillingInvoiceModal.Interaction) => void;
}

interface State {
  header: string;
  paymentInstructions: string;
}

const initialState: State = {
  header: "",
  paymentInstructions: "",
};

export function MspBillingInvoiceModal(props: Props) {
  const theme = useTheme();

  const [state, setState] = useState<State>(
    props.invoice
      ? {
          header: props.invoice.header,
          paymentInstructions: props.invoice.paymentInstructions,
        }
      : initialState
  );
  const mergeState = getMergeState(setState);

  function handleChangeHeader(header: string) {
    mergeState({ header });
  }

  function handleChangePaymentInstructions(paymentInstructions: string) {
    mergeState({ paymentInstructions });
  }

  return (
    <Modal isOpen={true} width={1000}>
      <Modal.Header>
        <Text appearance="h4">{copyText.modalTitleCustomizeInvoice}</Text>
        <Flex alignItems="center">
          <Button
            disabled={props.isLoading}
            iconStart={<Icon icon={faX} />}
            size="small"
            onClick={() =>
              props.onInteraction({
                type: MspBillingInvoiceModal.INTERACTION_CANCEL_BUTTON_CLICKED,
              })
            }
          />
        </Flex>
      </Modal.Header>
      <Modal.Body>
        <Flex>
          <Box marginRight={theme.space_md} width={400}>
            <Text>Header</Text>
            <TextArea
              resizeable
              rows={5}
              value={state.header}
              onChange={(e) => handleChangeHeader(e.target.value)}
            />
          </Box>
          <Box width={400}>
            <Text>Payment Instructions</Text>
            <TextArea
              resizeable
              rows={5}
              value={state.paymentInstructions}
              onChange={(e) => handleChangePaymentInstructions(e.target.value)}
            />
          </Box>
        </Flex>

        <Flex justifyContent="flex-end">
          <Button
            disabled={props.isLoading}
            primary
            onClick={() =>
              props.onInteraction({
                type: MspBillingInvoiceModal.INTERACTION_SUBMIT_BUTTON_CLICKED,
                header: state.header,
                paymentInstructions: state.paymentInstructions,
              })
            }
          >
            {props.isLoading ? <LoadingSpinner /> : copyText.submitButtonLabel}
          </Button>
        </Flex>
      </Modal.Body>
    </Modal>
  );
}

MspBillingInvoiceModal.INTERACTION_CANCEL_BUTTON_CLICKED =
  "InvoiceSettingsModal.INTERACTION_CANCEL_BUTTON_CLICKED" as const;
MspBillingInvoiceModal.INTERACTION_SUBMIT_BUTTON_CLICKED =
  "InvoiceSettingsModal.INTERACTION_SUBMIT_BUTTON_CLICKED" as const;

type InteractionCancelButtonClicked = {
  type: typeof MspBillingInvoiceModal.INTERACTION_CANCEL_BUTTON_CLICKED;
};

type InteractionSubmitButtonClicked = {
  type: typeof MspBillingInvoiceModal.INTERACTION_SUBMIT_BUTTON_CLICKED;
  header: string;
  paymentInstructions: string;
};

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace MspBillingInvoiceModal {
  export type Interaction =
    | InteractionCancelButtonClicked
    | InteractionSubmitButtonClicked;
}

export default MspBillingInvoiceModal;
