import LoadingSpinner from "@/ui-lib/components/LoadingSpinner";
import TextInput from "@/ui-lib/components/TextInput";
import { useTheme } from "@emotion/react";
import {
  faChevronDown,
  faFileExport,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";
import Button from "@ternary/api-lib/ui-lib/components/Button";
import Box from "@ternary/web-ui-lib/components/Box";
import Flex from "@ternary/web-ui-lib/components/Flex";
import Icon from "@ternary/web-ui-lib/components/Icon";
import { format } from "date-fns";
import React from "react";
import { useCSVDownloader } from "../../../../ui-lib/components/CSVDownloader";
import Chip from "../../../../ui-lib/components/Chip";
import Dropdown from "../../../../ui-lib/components/Dropdown";
import copyText from "../../copyText";
import {
  AzureComputeInstanceGroupFilters,
  azureVMComputeDimensions,
} from "../types";
import { AzureComputeCategoryOption } from "./AzureVMComputeContainer";

type CSVData = {
  headers: { key: string; label: string }[];
  rows: Record<string, string | number>[];
};

type Props = {
  csvData: CSVData;
  debouncedSearchText: string;
  instanceGroupFilters: AzureComputeInstanceGroupFilters;
  isLoadingAllInstances: boolean;
  selectedCategory: AzureComputeCategoryOption;
  searchText: string;
  onInteraction: (
    interaction: AzureVMComputeInstanceGroupTableControls.Interaction
  ) => void;
};

function AzureVMComputeInstanceGroupTableControls(props: Props) {
  const theme = useTheme();
  const { downloadCSV, csvElement } = useCSVDownloader();

  function handleDownloadInstanceGroupsCSV() {
    downloadCSV({
      data: props.csvData.rows,
      headers: props.csvData.headers,
      fileName: `azure-compute-instance-groups-${format(
        new Date(),
        "MM-dd-yyyy"
      )}`,
    });
  }

  const csvDropdownOptions = [
    {
      label: copyText.azureCSVDownloadInstance,
      value: copyText.azureCSVDownloadInstance,
      onClick: () =>
        props.onInteraction({
          type: AzureVMComputeInstanceGroupTableControls.INTERACTION_DOWNLOAD_INSTANCES_CLICKED,
        }),
    },
    {
      label: copyText.azureCSVDownloadInstanceGroup,
      value: copyText.azureCSVDownloadInstanceGroup,
      onClick: handleDownloadInstanceGroupsCSV,
    },
  ];

  const categoryDropdownOptions = [
    {
      label: copyText.azureCategoryFilterLabel_blockStorage,
      value: AzureComputeCategoryOption.BLOCK,
      onClick: () =>
        props.onInteraction({
          type: AzureVMComputeInstanceGroupTableControls.INTERACTION_FILTER_DROPDOWN_CLICKED,
          categoryKey: AzureComputeCategoryOption.BLOCK,
        }),
    },
    {
      label: copyText.azureCategoryFilterLabel_instances,
      value: AzureComputeCategoryOption.INSTANCE,
      onClick: () =>
        props.onInteraction({
          type: AzureVMComputeInstanceGroupTableControls.INTERACTION_FILTER_DROPDOWN_CLICKED,
          categoryKey: AzureComputeCategoryOption.INSTANCE,
        }),
    },
  ];

  const selectedDropdown = categoryDropdownOptions.find(
    (option) => option.value === props.selectedCategory
  );

  return (
    <Flex alignItems="center" minHeight={30}>
      <Flex flex="1 0 0">
        {[
          azureVMComputeDimensions.family,
          azureVMComputeDimensions.operatingSystem,
          azureVMComputeDimensions.instanceType,
          azureVMComputeDimensions.blockStorageClass,
          azureVMComputeDimensions.blockStorageType,
          azureVMComputeDimensions.location,
          azureVMComputeDimensions.projectId,
        ].map((instanceGroupFilterKey) => {
          let filterValue = props.instanceGroupFilters[instanceGroupFilterKey];

          if (filterValue === null) return;

          if (filterValue === "") {
            filterValue = "null";
          }

          const copyTextKey: keyof typeof copyText = `azureComputeTableHeader_${instanceGroupFilterKey}`;

          return (
            <Chip
              key={instanceGroupFilterKey}
              label={copyText[copyTextKey]}
              text={filterValue}
              onClick={() =>
                props.onInteraction({
                  type: AzureVMComputeInstanceGroupTableControls.INTERACTION_REMOVE_FILTER_CLICKED,
                  filterKey: instanceGroupFilterKey,
                })
              }
            />
          );
        })}
      </Flex>

      <Box>
        <Dropdown
          options={categoryDropdownOptions}
          setSelectedOption={false}
          placement="bottom-end"
          selectedOption={categoryDropdownOptions.find(
            (option) => option.value === props.selectedCategory
          )}
        >
          <Button
            iconEnd={<Icon icon={faChevronDown} />}
            marginLeft={theme.space_lg}
            secondary
            size="small"
          >
            {selectedDropdown?.label}
          </Button>
        </Dropdown>
      </Box>

      <Box marginHorizontal={theme.space_md}>
        <TextInput
          iconEnd={
            props.searchText === props.debouncedSearchText ||
            props.searchText === "" ? (
              <Icon color={theme.text_color_secondary} icon={faSearch} />
            ) : (
              <LoadingSpinner />
            )
          }
          placeholder={copyText.searchInputPlaceholder}
          size="large"
          value={props.searchText}
          onChange={(e) =>
            props.onInteraction({
              type: AzureVMComputeInstanceGroupTableControls.INTERACTION_SEARCH_TEXT_UPDATED,
              searchText: e.target.value,
            })
          }
        />
      </Box>

      <Box>
        {csvElement}

        <Dropdown
          options={csvDropdownOptions}
          setSelectedOption={false}
          placement="bottom-end"
        >
          <Button
            iconStart={
              props.isLoadingAllInstances ? (
                <LoadingSpinner
                  color={theme.feedback_neutral_outline}
                  size="xs"
                />
              ) : (
                <Icon color="inherit" icon={faFileExport} />
              )
            }
            marginLeft={theme.space_lg}
            secondary
            size="small"
          >
            {copyText.exportButtonLabel}
          </Button>
        </Dropdown>
      </Box>
    </Flex>
  );
}

AzureVMComputeInstanceGroupTableControls.INTERACTION_DOWNLOAD_INSTANCES_CLICKED =
  `AzureVMComputeInstanceGroupTableControls.INTERACTION_DOWNLOAD_INSTANCES_CLICKED` as const;

AzureVMComputeInstanceGroupTableControls.INTERACTION_REMOVE_FILTER_CLICKED =
  `AzureVMComputeInstanceGroupTableControls.INTERACTION_REMOVE_FILTER_CLICKED` as const;

AzureVMComputeInstanceGroupTableControls.INTERACTION_FILTER_DROPDOWN_CLICKED =
  `AzureVMComputeInstanceGroupTableControls.INTERACTION_FILTER_DROPDOWN_CLICKED` as const;

AzureVMComputeInstanceGroupTableControls.INTERACTION_SEARCH_TEXT_UPDATED =
  `AzureVMComputeInstanceGroupTableControls.INTERACTION_SEARCH_TEXT_UPDATED` as const;

interface InteractionDownloadInstancesClicked {
  type: typeof AzureVMComputeInstanceGroupTableControls.INTERACTION_DOWNLOAD_INSTANCES_CLICKED;
}

interface InteractionFilterTableByCategoryClicked {
  type: typeof AzureVMComputeInstanceGroupTableControls.INTERACTION_FILTER_DROPDOWN_CLICKED;
  categoryKey: AzureComputeCategoryOption;
}

interface InteractionRemoveFilterClicked {
  type: typeof AzureVMComputeInstanceGroupTableControls.INTERACTION_REMOVE_FILTER_CLICKED;
  filterKey: keyof AzureComputeInstanceGroupFilters;
}

interface InteractionSearchTextUpdated {
  type: typeof AzureVMComputeInstanceGroupTableControls.INTERACTION_SEARCH_TEXT_UPDATED;
  searchText: string;
}

// eslint-disable-next-line @typescript-eslint/no-namespace
namespace AzureVMComputeInstanceGroupTableControls {
  export type Interaction =
    | InteractionFilterTableByCategoryClicked
    | InteractionDownloadInstancesClicked
    | InteractionRemoveFilterClicked
    | InteractionSearchTextUpdated;
}
export default AzureVMComputeInstanceGroupTableControls;
