import { useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import { faStar as faStarSolid } from "@fortawesome/free-solid-svg-icons";
import { faStar as faStarOutline } from "@fortawesome/pro-light-svg-icons";
import Box, { StyleProps } from "@ternary/api-lib/ui-lib/components/Box";
import Button from "@ternary/api-lib/ui-lib/components/Button";
import Flex from "@ternary/api-lib/ui-lib/components/Flex";
import Icon from "@ternary/api-lib/ui-lib/components/Icon";
import Text from "@ternary/api-lib/ui-lib/components/Text";
import { noop } from "lodash";
import React from "react";
import copyText from "../copyText";

const iconSizes = {
  Button_iconOnlySize_jumbo: "1.25rem",
  Button_iconOnlySize_large: "1.25rem",
  Button_iconOnlySize_medium: "1.25rem",
  Button_iconOnlySize_small: "1rem",
  Button_iconOnlySize_tiny: "0.75rem",
  Button_paddingIconOnly_jumbo: "1.25rem",
  Button_paddingIconOnly_large: "0.75rem",
  Button_paddingIconOnly_medium: "0.5rem",
  Button_paddingIconOnly_small: "0.375rem",
  Button_paddingIconOnly_tiny: "0.25rem",
};

const Size = {
  jumbo: "jumbo",
  large: "large",
  medium: "medium",
  small: "small",
  tiny: "tiny",
} as const;
export type Size = keyof typeof Size;

const StyledButton = styled(Button)<
  StyleProps & { onlyIcon: boolean; isFavorite: boolean }
>(({ theme, isFavorite, disabled, size, onlyIcon }) => ({
  boxShadow: "none",
  cursor: disabled ? undefined : "pointer",
  padding: onlyIcon ? iconSizes[`Button_paddingIconOnly_${size}`] : undefined,
  background: onlyIcon ? "transparent" : undefined,

  "& svg": {
    color: isFavorite ? theme.report_favorite_color : theme.text_color,
    cursor: "pointer",
    height: iconSizes[`Button_iconOnlySize_${size}`],
    left: 0,
    position: "absolute",
    right: 0,
    transition: "opacity 0.2s ease, transform 0.2s ease",
    width: iconSizes[`Button_iconOnlySize_${size}`],
  },

  "& span > div > div": {
    height: iconSizes[`Button_iconOnlySize_${size}`],
    width: iconSizes[`Button_iconOnlySize_${size}`],
  },

  // solid star svg
  "& svg:first-of-type": {
    opacity: isFavorite ? 1 : 0,
    transform: isFavorite ? "scale(1)" : "scale(0.5)",
  },

  // outline star svg
  "& svg:last-of-type": {
    opacity: isFavorite ? 0 : 1,
    transform: isFavorite ? "scale(1.3)" : "scale(1)",
  },

  "&:hover svg": disabled
    ? {}
    : {
        color: theme.report_favorite_color,
      },

  "&:focus": {
    outline: 0,
    boxShadow: "none",
  },
}));

type FavoriteButtonProps = {
  color?: string;
  disabled?: boolean;
  favorited: boolean;
  iconOnly?: boolean;
  isLoading?: boolean;
  iconSize?: string;
  size?: Size;
  onClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
} & StyleProps;

export function FavoriteButton(props: FavoriteButtonProps) {
  const theme = useTheme();

  const onlyIcon = props.iconOnly ?? false;

  const buttonProps = {
    ...props,
    size: props.size ?? "medium",
    onlyIcon,
    isFavorite: props.favorited,
  };

  return (
    <StyledButton
      {...buttonProps}
      onClick={(e) => (props.disabled ? noop() : props.onClick(e))}
    >
      <Flex alignItems="center" justifyContent="center">
        <Box
          marginRight={props.iconOnly ? undefined : theme.space_xs}
          position="relative"
        >
          <Icon icon={faStarSolid} role="none" />

          <Icon icon={faStarOutline} role="none" />
        </Box>

        {!props.iconOnly && <Text>{copyText.favoriteButtonLabel}</Text>}
      </Flex>
    </StyledButton>
  );
}
