import { useQuery } from "@tanstack/react-query";
import { FeatureFlag } from "@ternary/api-lib/constants/feature-flags";
import {
  DataIntegrationEntity,
  MspTenantSettingsEntity,
  PermissionEntity,
} from "@ternary/api-lib/core/types";
import { UseQueryResult } from "../../lib/react-query";
import adminGates, { AdminGates } from "../../utils/gateKeeper/adminGates";
import alertGates, { AlertGates } from "../../utils/gateKeeper/alertGates";
import bigQueryGates, {
  BigQueryGates,
} from "../../utils/gateKeeper/bigQueryGates";
import budgetGates, { BudgetGates } from "../../utils/gateKeeper/budgetGates";
import carbonFootprintGates, {
  CarbonFootprintGates,
} from "../../utils/gateKeeper/carbonFootprintGates";
import caseGates, { CaseGates } from "../../utils/gateKeeper/caseGates";
import committedUseGates, {
  CommittedUseGates,
} from "../../utils/gateKeeper/committedUseGates";
import costAssistGates, {
  CostAssistGates,
} from "../../utils/gateKeeper/costAssistGates";
import costReportGates, {
  CostReportGates,
} from "../../utils/gateKeeper/costReportGates";
import cudGates, { CUDGates } from "../../utils/gateKeeper/cudGates";
import customMetricGates, {
  CustomMetricGates,
} from "../../utils/gateKeeper/customMetricGates";
import customPricingGates, {
  CustomPricingGates,
} from "../../utils/gateKeeper/customPricingGates";
import dashboardGates, {
  DashboardGates,
} from "../../utils/gateKeeper/dashboardGates";
import dataIntegrationGates, {
  DataIntegrationGates,
} from "../../utils/gateKeeper/dataIntegrationGates";
import globalAdminGates, {
  GlobalAdminGates,
} from "../../utils/gateKeeper/globalAdminGates";
import insightsGates, {
  InsightsGates,
} from "../../utils/gateKeeper/insightsGates";
import labelManagementGates, {
  LabelManagementGates,
} from "../../utils/gateKeeper/labelManagementGates";
import mspAdminGates, {
  MspAdminGates,
} from "../../utils/gateKeeper/mspAdminGates";
import mspBillingInfoGates, {
  MspBillingInfoGates,
} from "../../utils/gateKeeper/mspInvoicingGates";
import reportGates, { ReportGates } from "../../utils/gateKeeper/reportGates";
import savingOpportunityGates, {
  SavingOpportunityGates,
} from "../../utils/gateKeeper/savingOpportunityGates";
import CoreAPIClientError from "./CoreAPIClientError";
import { useCoreAPIClient } from "./CoreAPIClientProvider";
import { AuthenticatedUserEntity } from "./types";

export type GateGetterParameters = {
  _featureFlagsIncludeAllOf: (featureFlags: FeatureFlag[]) => boolean;
  _permissionsIncludeAllOf: (permissionIDs: string[]) => boolean;
  authenticatedUser: AuthenticatedUserEntity;
  integrations: DataIntegrationEntity[];
  mspTenantSettings?: MspTenantSettingsEntity;
  permissions: PermissionEntity[];
};

export type GateKeeper = AdminGates &
  AlertGates &
  BigQueryGates &
  BudgetGates &
  CarbonFootprintGates &
  CaseGates &
  CommittedUseGates &
  CostAssistGates &
  CostReportGates &
  CUDGates &
  LabelManagementGates &
  CustomMetricGates &
  CustomPricingGates &
  DashboardGates &
  DataIntegrationGates &
  GlobalAdminGates &
  InsightsGates &
  MspAdminGates &
  MspBillingInfoGates &
  SavingOpportunityGates &
  ReportGates;

export default function useGetGatekeeper(
  authenticatedUser: AuthenticatedUserEntity | undefined
): UseQueryResult<GateKeeper, CoreAPIClientError> {
  const client = useCoreAPIClient();

  return useQuery({
    queryKey: ["gatekeeper"],
    queryFn: async () => {
      if (!authenticatedUser) return undefined;

      const integrationsPromise = client.getDataIntegrationsByTenantID(
        authenticatedUser.tenant.id
      );

      const permissionPromise = client.getPermissionsByTenantID(
        authenticatedUser.tenant.id
      );

      const mspTenantSettingsPromise = authenticatedUser.parentTenantID
        ? client.getMspTenantSettingsByTenantID(
            authenticatedUser.parentTenantID
          )
        : undefined;

      const [integrations, permissions, mspTenantSettings] = await Promise.all([
        integrationsPromise,
        permissionPromise,
        mspTenantSettingsPromise,
      ]);

      function _permissionsIncludeAllOf(requiredPermissions: string[]) {
        let output = true;

        requiredPermissions.forEach((requiredPermission) => {
          const found = permissions.find((permission) => {
            return permission.slug === requiredPermission;
          });

          if (!found) {
            output = false;
          }
        });

        return output;
      }

      function _featureFlagsIncludeAllOf(
        requiredFeatureFlags: FeatureFlag[]
      ): boolean {
        let output = true;

        requiredFeatureFlags.forEach((requiredFeatureFlag) => {
          const found = authenticatedUser?.tenant.featureFlags.find(
            (featureFlag) => featureFlag.flag === requiredFeatureFlag
          );

          if (!found) {
            output = false;
          }
        });

        return output;
      }

      const getterParams: GateGetterParameters = {
        _permissionsIncludeAllOf,
        _featureFlagsIncludeAllOf,
        authenticatedUser,
        integrations,
        mspTenantSettings,
        permissions,
      };

      return {
        ...adminGates(getterParams),
        ...alertGates(getterParams),
        ...bigQueryGates(getterParams),
        ...budgetGates(getterParams),
        ...carbonFootprintGates(getterParams),
        ...caseGates(getterParams),
        ...committedUseGates(getterParams),
        ...costAssistGates(getterParams),
        ...costReportGates(getterParams),
        ...cudGates(getterParams),
        ...customMetricGates(getterParams),
        ...customPricingGates(getterParams),
        ...dashboardGates(getterParams),
        ...dataIntegrationGates(getterParams),
        ...globalAdminGates(getterParams),
        ...insightsGates(getterParams),
        ...labelManagementGates(getterParams),
        ...mspAdminGates(getterParams),
        ...mspBillingInfoGates(getterParams),
        ...savingOpportunityGates(getterParams),
        ...reportGates(getterParams),
      };
    },
    enabled: !!authenticatedUser,
  });
}
