import { add, lastDayOfMonth, startOfDay, sub, subMonths } from "date-fns";

export class DateHelper {
  date: Date;

  constructor(date?: Date) {
    if (date) {
      this.date = date;
    } else {
      this.date = new Date();
    }
  }

  convertToUTC(inputDate?: Date): Date {
    const date = inputDate ?? new Date();

    return new Date(
      Date.UTC(
        date.getUTCFullYear(),
        date.getUTCMonth(),
        date.getUTCDate(),
        date.getUTCHours(),
        date.getUTCMinutes(),
        date.getUTCSeconds()
      )
    );
  }

  firstOfMonth(): Date {
    const date = new Date(this.date.getTime());

    date.setDate(1);
    date.setHours(0);
    date.setMinutes(0);
    date.setSeconds(0);
    date.setMilliseconds(0);

    return date;
  }

  firstOfLastMonth(): Date {
    const date = new Date(this.date.getTime());

    date.setDate(1);

    return sub(date, { months: 1 });
  }

  firstOfLastMonthNoTimezone(): Date {
    const date = new Date(this.date.getTime());

    const lastMonthNumber = this.lastMonthNumber();

    date.setMonth(lastMonthNumber, 1);

    return startOfDay(date);
  }

  firstOfLastMonthMinusThreeDays(): Date {
    let date = this.firstOfLastMonth();

    date = sub(date, { days: 3 });

    return date;
  }

  firstDayOfNextMonth(): Date {
    const now = new Date();

    let current: Date;
    if (now.getMonth() === 11) {
      current = new Date(now.getFullYear() + 1, 0, 1);
    } else {
      current = new Date(now.getFullYear(), now.getMonth() + 1, 1);
    }

    return current;
  }

  firstOfYear(): Date {
    return new Date(this.date.getFullYear(), 0);
  }

  sameDayLastMonth(): Date {
    const res = new Date(this.date.getTime());

    return sub(res, { months: 1 });
  }

  lastDayLastMonth(): Date {
    let res = new Date(this.date.getTime());

    const lastDayNumberOfLastMonth = lastDayOfMonth(
      this.sameDayLastMonth()
    ).getDate();

    res = sub(res, { months: 1 });

    res.setDate(lastDayNumberOfLastMonth);

    return res;
  }

  lastDayLastMonthPlusThreeDays(): Date {
    let res = this.lastDayLastMonth();
    res = add(res, { days: 3 });

    return res;
  }

  lastDayThisMonth(): Date {
    const date = new Date(this.date.getTime());

    const lastDayNumberOfThisMonth = lastDayOfMonth(date.getMonth()).getDate();

    date.setDate(lastDayNumberOfThisMonth);

    return date;
  }

  lastMonthNumber(): number {
    return this.convertToUTC().getUTCMonth() - 1;
  }

  thirtyDaysAgo(): Date {
    let res = new Date(this.date.getTime());
    res = sub(res, { days: 30 });

    return res;
  }

  nMonthsAgoFirstOfMonth(n): Date {
    let res = new Date(this.date.getTime());
    res.setUTCDate(1);
    res = sub(res, { months: n });

    return res;
  }

  nDaysAgo(n: number): Date {
    const date = new Date(this.date.getTime());

    return sub(date, { days: n });
  }

  nMonthsAgo(n: number): Date {
    const date = new Date(this.date.getTime());

    return subMonths(date, n);
  }
}
