export default class CoreAPIClientError extends Error {
  public readonly reason?: string;
  public readonly type: string;

  public static readonly FAILED_ADDING_FEATURE_FLAG = `FAILED_ADDING_FEATURE_FLAG`;
  public static readonly FAILED_CREATING_ALERT_RULE = `FAILED_CREATING_ALERT_RULE`;
  public static readonly FAILED_CREATING_ALIBABA_INTEGRATION = `FAILED_CREATING_ALIBABA_INTEGRATION`;
  public static readonly FAILED_CREATING_API_KEY = `FAILED_CREATING_API_KEY`;
  public static readonly FAILED_CREATING_AWS_INTEGRATION = `FAILED_CREATING_AWS_INTEGRATION`;
  public static readonly FAILED_CREATING_AZURE_INTEGRATION = `FAILED_CREATING_AZURE_INTEGRATION`;
  public static readonly FAILED_CREATING_BILL = `FAILED_CREATING_BILL`;
  public static readonly FAILED_CREATING_BUDGET = `FAILED_CREATING_BUDGET`;
  public static readonly FAILED_CREATING_CASE = `FAILED_CREATING_CASE`;
  public static readonly FAILED_CREATING_CASE_COMMENT = `FAILED_CREATING_CASE_COMMENT`;
  public static readonly FAILED_CREATING_CUSTOM_LABEL = `FAILED_CREATING_CUSTOM_LABEL`;
  public static readonly FAILED_CREATING_CUSTOM_METRIC = `FAILED_CREATING_CUSTOM_METRIC`;
  public static readonly FAILED_CREATING_DASHBOARD = `FAILED_CREATING_DASHBOARD`;
  public static readonly FAILED_CREATING_DASHBOARD_SUBSCRIPTION = `FAILED_CREATING_DASHBOARD_SUBSCRIPTION`;
  public static readonly FAILED_CREATING_GCP_INTEGRATION = `FAILED_CREATING_GCP_INTEGRATION`;
  public static readonly FAILED_CREATING_JIRA_INTEGRATION = `FAILED_CREATING_JIRA_INTEGRATION`;
  public static readonly FAILED_CREATING_LABEL_GROUPING_RULE = `FAILED_CREATING_LABEL_GROUPING_RULE`;
  public static readonly FAILED_CREATING_LABEL_PREFERENCES = `FAILED_CREATING_LABEL_PREFERENCES`;
  public static readonly FAILED_CREATING_MSP_BILLING_INVOICE = `FAILED_CREATING_MSP_BILLING_INVOICE`;
  public static readonly FAILED_CREATING_MSP_BILLING_MANIFEST = `FAILED_CREATING_MSP_BILLING_MANIFEST`;
  public static readonly FAILED_CREATING_MEASURE_PREFERENCES = `FAILED_CREATING_MEASURE_PREFERENCES`;
  public static readonly FAILED_CREATING_MSP_BILLING_INFORMATION = `FAILED_CREATING_MSP_BILLING_INFORMATION`;
  public static readonly FAILED_CREATING_ORACLE_INTEGRATION = `FAILED_CREATING_ORACLE_INTEGRATION`;
  public static readonly FAILED_CREATING_RAMP_PLAN = `FAILED_CREATING_RAMP_PLAN`;
  public static readonly FAILED_CREATING_REALLOCATION = `FAILED_CREATING_REALLOCATION`;
  public static readonly FAILED_CREATING_REPORT = `FAILED_CREATING_REPORT`;
  public static readonly FAILED_CREATING_REPORT_SUBSCRIPTION = `FAILED_CREATING_REPORT_SUBSCRIPTION`;
  public static readonly FAILED_CREATING_RULE_SET = `FAILED_CREATING_RULE_SET`;
  public static readonly FAILED_CREATING_SAVINGS_OPPORTUNITY_FILTER = `FAILED_CREATING_SAVINGS_OPPORTUNITY_FILTER`;
  public static readonly FAILED_CREATING_SCOPED_VIEW = `FAILED_CREATING_SCOPED_VIEW`;
  public static readonly FAILED_CREATING_SNOWFLAKE_INTEGRATION = `FAILED_CREATING_SNOWFLAKE_INTEGRATION`;
  public static readonly FAILED_CREATING_TENANT = `FAILED_CREATING_TENANT`;
  public static readonly FAILED_CREATING_USER = `FAILED_CREATING_USER`;
  public static readonly FAILED_CREATING_USER_GROUP_CONFIG = `FAILED_CREATING_USER_GROUP_CONFIG`;
  public static readonly FAILED_DELETING_API_KEY = `FAILED_DELETING_API_KEY`;
  public static readonly FAILED_DELETING_BILL = `FAILED_DELETING_BILL`;
  public static readonly FAILED_DELETING_BUDGET = `FAILED_DELETING_BUDGET`;
  public static readonly FAILED_DELETING_CUSTOM_LABEL = `FAILED_DELETING_CUSTOM_LABEL`;
  public static readonly FAILED_DELETING_CUSTOM_METRIC = `FAILED_DELETING_CUSTOM_METRIC`;
  public static readonly FAILED_DELETING_DASHBOARD = `FAILED_DELETING_DASHBOARD`;
  public static readonly FAILED_DELETING_DASHBOARD_SUBSCRIPTION = `FAILED_DELETING_DASHBOARD_SUBSCRIPTION`;
  public static readonly FAILED_DELETING_DATA_INTEGRATION = `FAILED_DELETING_DATA_INTEGRATION`;
  public static readonly FAILED_DELETING_JIRA_INTEGRATION = `FAILED_DELETING_JIRA_INTEGRATION`;
  public static readonly FAILED_DELETING_LABEL_GROUPING_RULE = `FAILED_DELETING_LABEL_GROUPING_RULE`;
  public static readonly FAILED_DELETING_MSP_BILLING_INFORMATION = `FAILED_DELETING_MSP_BILLING_INFORMATION`;
  public static readonly FAILED_DELETING_RAMP_PLAN = `FAILED_DELETING_RAMP_PLAN`;
  public static readonly FAILED_DELETING_REALLOCATION = `FAILED_DELETING_REALLOCATION`;
  public static readonly FAILED_DELETING_REPORT = `FAILED_DELETING_REPORT`;
  public static readonly FAILED_DELETING_REPORT_SUBSCRIPTION = `FAILED_DELETING_REPORT_SUBSCRIPTION`;
  public static readonly FAILED_DELETING_RULE_SET = `FAILED_DELETING_RULE_SET`;
  public static readonly FAILED_DELETING_SAVING_OPPORTUNITY_FILTER = `FAILED_DELETING_SAVING_OPPORTUNITY_FILTER`;
  public static readonly FAILED_DELETING_SCOPED_VIEW = `FAILED_DELETING_SCOPED_VIEW`;
  public static readonly FAILED_DELETING_SLACK_INTEGRATION = `FAILED_DELETING_SLACK_INTEGRATION`;
  public static readonly FAILED_DELETING_USER_GROUP_CONFIG = `FAILED_DELETING_USER_GROUP_CONFIG`;
  public static readonly FAILED_GENERATING_ANALYTICS_TOKEN = `FAILED_GENERATING_ANALYTICS_TOKEN`;
  public static readonly FAILED_GENERATING_TAKEOUT_REPORT = `FAILED_GENERATING_TAKEOUT_REPORT`;
  public static readonly FAILED_GENERATING_ZENDESK_TOKEN = `FAILED_GENERATING_ZENDESK_TOKEN`;
  public static readonly FAILED_GRANTING_USERS_TENANT_ACCESS = `FAILED_GRANTING_USERS_TENANT_ACCESS`;
  public static readonly FAILED_LOADING_ALERT_RULE_EVENTS = `FAILED_LOADING_ALERT_RULE_EVENTS`;
  public static readonly FAILED_LOADING_ALERT_RULES = `FAILED_LOADING_ALERT_RULES`;
  public static readonly FAILED_LOADING_ALL_LABELS = `FAILED_LOADING_ALL_LABELS`;
  public static readonly FAILED_LOADING_ALL_TENANTS = `FAILED_LOADING_ALL_TENANTS`;
  public static readonly FAILED_LOADING_ANOMALIES = `FAILED_LOADING_ANOMALIES`;
  public static readonly FAILED_LOADING_API_KEYS = `FAILED_LOADING_API_KEYS`;
  public static readonly FAILED_LOADING_AUTHENTICATED_USER = `FAILED_LOADING_AUTHENTICATED_USER`;
  public static readonly FAILED_LOADING_AWS_COMMITTED_USE_RECOMMENDATIONS = `FAILED_LOADING_AWS_COMMITTED_USE_RECOMMENDATIONS`;
  public static readonly FAILED_LOADING_BIG_QUERY_METADATA = `FAILED_LOADING_BIG_QUERY_METADATA`;
  public static readonly FAILED_LOADING_BILLS = `FAILED_LOADING_BILLS`;
  public static readonly FAILED_LOADING_BUDGETS = `FAILED_LOADING_BUDGETS`;
  public static readonly FAILED_LOADING_CASE = `FAILED_LOADING_CASE`;
  public static readonly FAILED_LOADING_CASES = `FAILED_LOADING_CASES`;
  public static readonly FAILED_LOADING_COST_ALERT = `FAILED_LOADING_COST_ALERT`;
  public static readonly FAILED_LOADING_COST_ALERTS = `FAILED_LOADING_COST_ALERTS`;
  public static readonly FAILED_LOADING_CUD_RECOMMENDATIONS = `FAILED_LOADING_CUD_RECOMMENDATIONS`;
  public static readonly FAILED_LOADING_CUSTOM_LABELS = `FAILED_LOADING_CUSTOM_LABELS`;
  public static readonly FAILED_LOADING_CUSTOM_METRICS = `FAILED_LOADING_CUSTOM_METRICS`;
  public static readonly FAILED_LOADING_DASHBOARD = `FAILED_LOADING_DASHBOARD`;
  public static readonly FAILED_LOADING_DASHBOARDS = `FAILED_LOADING_DASHBOARDS`;
  public static readonly FAILED_LOADING_DATA_INTEGRATIONS = `FAILED_LOADING_DATA_INTEGRATIONS`;
  public static readonly FAILED_LOADING_DATADOG_INTEGRATION = `FAILED_LOADING_DATADOG_INTEGRATION`;
  public static readonly FAILED_LOADING_FEATURE_FLAGS = `FAILED_LOADING_FEATURE_FLAGS`;
  public static readonly FAILED_LOADING_GRANTS = `FAILED_LOADING_GRANTS`;
  public static readonly FAILED_LOADING_JIRA_INTEGRATION = `FAILED_LOADING_JIRA_INTEGRATION`;
  public static readonly FAILED_LOADING_LABEL_GROUPING_RULES = `FAILED_LOADING_LABEL_GROUPING_RULES`;
  public static readonly FAILED_LOADING_LABEL_MAPS = `FAILED_LOADING_LABEL_MAPS`;
  public static readonly FAILED_LOADING_LABEL_PREFERENCES = `FAILED_LOADING_LABEL_PREFERENCES`;
  public static readonly FAILED_LOADING_MSP_BILLING_MANIFEST = `FAILED_LOADING_MSP_BILLING_MANIFEST`;
  public static readonly FAILED_LOADING_MSP_BILLING_MANIFESTS = `FAILED_LOADING_MSP_BILLING_MANIFESTS`;
  public static readonly FAILED_LOADING_MEASURE_PREFERENCES = `FAILED_LOADING_MEASURE_PREFERENCES`;
  public static readonly FAILED_LOADING_MSP_CHILD_DATA_INTEGRATIONS = `FAILED_LOADING_MSP_CHILD_DATA_INTEGRATIONS`;
  public static readonly FAILED_LOADING_MSP_CHILD_RESOURCES = `FAILED_LOADING_MSP_CHILD_RESOURCES`;
  public static readonly FAILED_LOADING_MSP_CHILD_USERS = `FAILED_LOADING_MSP_CHILD_USERS`;
  public static readonly FAILED_LOADING_MSP_TENANT_SETTINGS = `FAILED_LOADING_MSP_TENANT_SETTINGS`;
  public static readonly FAILED_LOADING_PERMISSIONS = `FAILED_LOADING_PERMISSIONS`;
  public static readonly FAILED_LOADING_PRIVATE_SETTINGS = `FAILED_LOADING_PRIVATE_SETTINGS`;
  public static readonly FAILED_LOADING_RAMP_PLANS = `FAILED_LOADING_RAMP_PLANS`;
  public static readonly FAILED_LOADING_REALLOCATION_JOBS = `FAILED_LOADING_REALLOCATION_JOBS`;
  public static readonly FAILED_LOADING_REALLOCATIONS = `FAILED_LOADING_REALLOCATIONS`;
  public static readonly FAILED_LOADING_RECOMMENDATION = `FAILED_LOADING_RECOMMENDATION`;
  public static readonly FAILED_LOADING_RECOMMENDATION_TYPE = `FAILED_LOADING_RECOMMENDATION_TYPE`;
  public static readonly FAILED_LOADING_RECOMMENDATION_TYPES = `FAILED_LOADING_RECOMMENDATION_TYPES`;
  public static readonly FAILED_LOADING_RECOMMENDATIONS = `FAILED_LOADING_RECOMMENDATIONS`;
  public static readonly FAILED_LOADING_REPORT = `FAILED_LOADING_REPORT`;
  public static readonly FAILED_LOADING_REPORTS = `FAILED_LOADING_REPORTS`;
  public static readonly FAILED_LOADING_RULE_SETS = `FAILED_LOADING_RULE_SETS`;
  public static readonly FAILED_LOADING_SCOPED_VIEW_FILTERS = `FAILED_LOADING_SCOPED_VIEW_FILTERS`;
  public static readonly FAILED_LOADING_SCOPED_VIEWS = `FAILED_LOADING_SCOPED_VIEWS`;
  public static readonly FAILED_LOADING_SLACK_INTEGRATION = `FAILED_LOADING_SLACK_INTEGRATION`;
  public static readonly FAILED_LOADING_TENANT = `FAILED_LOADING_TENANT`;
  public static readonly FAILED_LOADING_TENANTS = `FAILED_LOADING_TENANTS`;
  public static readonly FAILED_LOADING_USER_GROUP_CONFIGS = `FAILED_LOADING_USER_GROUP_CONFIGS`;
  public static readonly FAILED_LOADING_USERS = `FAILED_LOADING_USERS`;
  public static readonly FAILED_REMOVING_FEATURE_FLAG = `FAILED_REMOVING_FEATURE_FLAG`;
  public static readonly FAILED_REVOKING_USER_TENANT_ACCESS = `FAILED_REVOKING_USER_TENANT_ACCESS`;
  public static readonly FAILED_TRIGGERING_REALLOCATION = `FAILED_TRIGGERING_REALLOCATION`;
  public static readonly FAILED_UNEXPECTEDLY = `FAILED_UNEXPECTEDLY`;
  public static readonly FAILED_UPDATING_ALERT_RULE = `FAILED_UPDATING_ALERT_RULE`;
  public static readonly FAILED_UPDATING_ALIBABA_INTEGRATION = `FAILED_UPDATING_ALIBABA_INTEGRATION`;
  public static readonly FAILED_UPDATING_AWS_INTEGRATION = `FAILED_UPDATING_AWS_INTEGRATION`;
  public static readonly FAILED_UPDATING_AZURE_INTEGRATION = `FAILED_UPDATING_AZURE_INTEGRATION`;
  public static readonly FAILED_UPDATING_BUDGET = `FAILED_UPDATING_BUDGET`;
  public static readonly FAILED_UPDATING_CASE = `FAILED_UPDATING_CASE`;
  public static readonly FAILED_UPDATING_CASES = `FAILED_UPDATING_CASES`;
  public static readonly FAILED_UPDATING_CUSTOM_LABEL = `FAILED_UPDATING_CUSTOM_LABEL`;
  public static readonly FAILED_UPDATING_CUSTOM_METRIC = `FAILED_UPDATING_CUSTOM_METRIC`;
  public static readonly FAILED_UPDATING_DASHBOARD = `FAILED_UPDATING_DASHBOARD`;
  public static readonly FAILED_UPDATING_DASHBOARD_SUBSCRIPTION = `FAILED_UPDATING_DASHBOARD_SUBSCRIPTION`;
  public static readonly FAILED_UPDATING_DASHBOARDS = `FAILED_UPDATING_DASHBOARDS`;
  public static readonly FAILED_UPDATING_GCP_INTEGRATION = `FAILED_UPDATING_GCP_INTEGRATION`;
  public static readonly FAILED_UPDATING_JIRA_INTEGRATION = `FAILED_UPDATING_JIRA_INTEGRATION`;
  public static readonly FAILED_UPDATING_LABEL_GROUPING_RULE = `FAILED_UPDATING_LABEL_GROUPING_RULE`;
  public static readonly FAILED_UPDATING_LABEL_PREFERENCES = `FAILED_UPDATING_LABEL_PREFERENCES`;
  public static readonly FAILED_UPDATING_MEASURE_PREFERENCES = `FAILED_UPDATING_MEASURE_PREFERENCES`;
  public static readonly FAILED_UPDATING_MSP_BILLING_INFORMATION = `FAILED_UPDATING_MSP_BILLING_INFORMATION`;
  public static readonly FAILED_UPDATING_MSP_BILLING_INVOICE = `FAILED_UPDATING_MSP_BILLING_INVOICE`;
  public static readonly FAILED_UPDATING_MSP_TENANT_SETTINGS = `FAILED_UPDATING_MSP_TENANT_SETTINGS`;
  public static readonly FAILED_UPDATING_ORACLE_INTEGRATION = `FAILED_UPDATING_ORACLE_INTEGRATION`;
  public static readonly FAILED_UPDATING_PERIOD_VERSION = `FAILED_UPDATING_PERIOD_VERSION`;
  public static readonly FAILED_UPDATING_RAMP_PLAN = `FAILED_UPDATING_RAMP_PLAN`;
  public static readonly FAILED_UPDATING_REALLOCATION = `FAILED_UPDATING_REALLOCATION`;
  public static readonly FAILED_UPDATING_RECOMMENDATION = `FAILED_UPDATING_RECOMMENDATION`;
  public static readonly FAILED_UPDATING_RECOMMENDATIONS = `FAILED_UPDATING_RECOMMENDATIONS`;
  public static readonly FAILED_UPDATING_REPORT = `FAILED_UPDATING_REPORT`;
  public static readonly FAILED_UPDATING_REPORT_SUBSCRIPTION = `FAILED_UPDATING_REPORT_SUBSCRIPTION`;
  public static readonly FAILED_UPDATING_REPORTS = `FAILED_UPDATING_REPORTS`;
  public static readonly FAILED_UPDATING_RULE_SET = `FAILED_UPDATING_RULE_SET`;
  public static readonly FAILED_UPDATING_SAVING_OPPORTUNITY_FILTER = `FAILED_UPDATING_SAVING_OPPORTUNITY_FILTER`;
  public static readonly FAILED_UPDATING_SCOPED_VIEW = `FAILED_UPDATING_SCOPED_VIEW`;
  public static readonly FAILED_UPDATING_SLACK_INTEGRATION = `FAILED_UPDATING_SLACK_INTEGRATION`;
  public static readonly FAILED_UPDATING_SNOWFLAKE_INTEGRATION = `FAILED_UPDATING_SNOWFLAKE_INTEGRATION`;
  public static readonly FAILED_UPDATING_TENANT = `FAILED_UPDATING_TENANT`;
  public static readonly FAILED_UPDATING_USER = `FAILED_UPDATING_PRIVATE_SETTINGS`;
  public static readonly FAILED_UPDATING_USER_GROUP_CONFIG = `FAILED_LOADING_USER_GROUP_CONFIG`;
  public static readonly FAILED_UPDATING_USER_SETTINGS = `FAILED_UPDATING_USER_SETTINGS`;
  public static readonly FAILED_UPDATING_USER_TENANT_PERMISSIONS = `FAILED_UPDATING_USER_TENANT_PERMISSIONS`;
  public static readonly FAILED_UPLOADING_FILE = `FAILED_UPLOADING_FILE`;
  public static readonly FAILED_VALIDATING_DATA_INTEGRATION = `FAILED_VALIDATING_CLOUD`;

  constructor(type: string, properties?: { reason?: string }) {
    super(
      CoreAPIClientError[type] ??
        CoreAPIClientError[CoreAPIClientError.FAILED_UNEXPECTEDLY]
    );

    if (properties?.reason) this.reason = properties.reason;
    this.type = type;
  }
}
