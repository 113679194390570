import styled from "@emotion/styled";

// Compare date range dropdown menu
export const CompareOption = styled("div")<{
  justifyContent?: string;
  selected?: boolean;
}>(({ justifyContent, selected, theme }) => ({
  padding: `${theme.space_xxs} ${theme.space_xs} ${theme.space_xxs} ${theme.space_xs}`,
  marginBottom: theme.space_xxs,
  borderRadius: theme.space_xs,
  backgroundColor: selected ? theme.date_picker_selected_highlight : "none",
  alignItems: "center",
  cursor: "pointer",
  display: "flex",
  flexDirection: "row",
  justifyContent: justifyContent
    ? justifyContent
    : selected
      ? "space-between"
      : "left",

  "&:hover": {
    backgroundColor: theme.secondary_color_background,
  },
}));

export const DatePickerWrapper = styled("div")<{
  timeSelectWidth?: string;
  dateFormat?: string | string[];
  hasStart: boolean;
  hasEnd: boolean;
}>(({ dateFormat, hasStart, hasEnd, theme, timeSelectWidth }) => ({
  ".react-datepicker": {
    backgroundColor: theme.panel_backgroundColor,
    border: "none",
    fontFamily: "Ternary",
  },

  ".react-datepicker-wrapper": {
    display: "inline-block",
  },

  ".react-datepicker__input-container": {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },

  ".react-datepicker__close-icon": {
    backgroundColor: theme.text_color_secondary,
    border: "none",
    position: "relative",
    left: "-25px",
  },

  ".react-datepicker-popper": {
    zIndex: theme.zIndex_1600,
  },

  ".react-datepicker__day": {
    alignItems: "center",
    color: theme.text_color,
    cursor: "pointer",
    display: "flex",
    height: "36px",
    justifyContent: "center",
    margin: "2px 0 2px 0",
    padding: "6px 8px 6px 8px",
    width: "100%",
    backgroundColor: "transparent",
    transition: "none",
    "&:hover": {
      boxShadow: `inset 0px 0px 0px 1px ${theme.primary_color_background}`,
      borderRadius: theme.borderRadius_2,
    },
  },

  ".react-datepicker__day--outside-month": {
    color: theme.text_color_secondary,
    visibility: "hidden",
  },

  ".react-datepicker__day--today": {
    fontWeight: "normal",
    borderRadius: theme.borderRadius_2,
    boxShadow: `inset 0px 0px 0px 1px ${theme.secondary_color}`,
  },

  ".react-datepicker__day--in-range:not(.react-datepicker__day-in-selecting-range):not(.react-datepicker__day--range-start):not(.react-datepicker__day--range-end)":
    {
      borderRadius: "0",
      backgroundColor: theme.date_picker_selected_highlight,
      color: theme.text_color,
      display: "flex",
      alignItems: "center",
    },

  ".react-datepicker__day--selected": {
    backgroundColor: theme.primary_color_background,
    borderRadius: theme.borderRadius_2,
    color: theme.text_color_inverse,
  },

  ".react-datepicker__day--in-selecting-range": {
    ...(hasStart && hasEnd
      ? {}
      : {
          alignItems: "center",
          backgroundColor: theme.date_picker_selected_highlight,
          borderRadius: "0",
          color: theme.text_color,
          display: "flex",
          transition: "none",
        }),
  },

  // start and end of selected range
  ".react-datepicker__day--range-start.react-datepicker__day--range-end": {
    backgroundColor: theme.primary_color_background,
    borderRadius: theme.borderRadius_2,
    color: theme.text_color_inverse,
    position: "relative",
    zIndex: 3,
  },

  ".react-datepicker__day--selecting-range-start.react-datepicker__day--selecting-range-end":
    {
      backgroundColor: theme.primary_color_background,
      boxShadow: `inset 0px 0px 0px 1px ${theme.primary_color_background}`,
      borderRadius: theme.borderRadius_2,
      color: theme.text_color_inverse,
      position: "relative",
      zIndex: 3,
    },

  // Start of the selected range and not end of the selected range
  // only applies when both start and end have been chosen.
  ".react-datepicker__day--range-start:not(.react-datepicker__day--range-end)":
    {
      backgroundColor: theme.primary_color_background,
      borderRadius: theme.borderRadius_2,
      color: theme.text_color_inverse,
      position: "relative",
      zIndex: 3,

      "&::before": {
        backgroundColor: theme.primary_color_background,
        borderRadius: theme.borderRadius_2,
        content: '""',
        height: "36px",
        left: "0",
        position: "absolute",
        top: "0",
        width: "100%",
        zIndex: -1,
      },

      "&::after": {
        backgroundColor: theme.date_picker_selected_highlight,
        content: '""',
        height: "36px",
        position: "absolute",
        right: "0px",
        top: "0",
        width: theme.space_xs,
        zIndex: -2,
      },
    },

  // start of the selecting range
  ".react-datepicker__day--selecting-range-start:not(.react-datepicker__day--range-end):not(.react-datepicker__day--range-start):not(.react-datepicker__day--selecting-range-end):not(.react-datepicker__day--in-range)":
    {
      ...(hasStart && hasEnd
        ? {}
        : {
            border: "0",
            borderRadius: theme.borderRadius_2,
            color: theme.text_color_inverse,
            position: "relative",
            zIndex: 3,

            "&::before": {
              backgroundColor: theme.primary_color_background,
              borderRadius: theme.borderRadius_2,
              content: '""',
              height: "36px",
              left: "0",
              position: "absolute",
              top: "0",
              width: "100%",
              zIndex: -1,
            },

            "&::after": {
              backgroundColor: theme.date_picker_selected_highlight,
              content: '""',
              height: "36px",
              position: "absolute",
              right: "0px",
              top: "0",
              width: theme.space_xs,
              zIndex: -2,
            },
          }),
    },

  // End of the selected range and not start of the selected range
  ".react-datepicker__day--range-end:not(.react-datepicker__day--range-start)":
    {
      backgroundColor: `${theme.primary_color_background} `,
      borderRadius: theme.borderRadius_2,
      color: theme.text_color_inverse,
      position: "relative",
      zIndex: 3,

      "&::after": {
        backgroundColor: theme.primary_color_background,
        borderRadius: theme.borderRadius_2,
        content: '""',
        height: "36px",
        left: "0",
        position: "absolute",
        top: "0",
        width: "100%",
        zIndex: -1,
      },

      "&::before": {
        backgroundColor: theme.date_picker_selected_highlight,
        content: '""',
        height: "36px",
        left: "0px",
        position: "absolute",
        top: "0",
        width: theme.space_md,
        zIndex: -2,
      },
    },

  // End of the selecting range
  ".react-datepicker__day--selecting-range-end:not(.react-datepicker__day--selecting-range-start):not(.react-datepicker__day--range-end)":
    {
      borderRadius: theme.borderRadius_2,
      color: theme.text_color,
      position: "relative",
      zIndex: 3,
      ...(hasStart && hasEnd
        ? {}
        : {
            "&::after": {
              backgroundColor: `${theme.panel_backgroundColor}`,
              borderRadius: theme.borderRadius_2,
              boxShadow: `inset 0px 0px 0px 1px ${theme.primary_color_background}`,
              content: '""',
              height: "100%",
              left: "0px",
              position: "absolute",
              top: "0px",
              width: "100%",
              zIndex: -1,
            },

            "&::before": {
              backgroundColor: theme.date_picker_selected_highlight,
              content: '""',
              height: "36px",
              left: "0px",
              position: "absolute",
              top: "0px",
              width: theme.space_xs,
              zIndex: -2,
            },
          }),
    },

  ".react-datepicker__day--in-selecting-range.react-datepicker__day--in-range.react-datepicker__day--selecting-range-end:not(.react-datepicker__day--range-start):not(.react-datepicker__day--range-end)":
    {
      borderRadius: "0",
      backgroundColor: theme.date_picker_selected_highlight,
      color: theme.text_color,
      display: "flex",
      alignItems: "center",

      "&::after": {
        backgroundColor: theme.date_picker_selected_highlight,
        borderRadius: theme.borderRadius_2,
        boxShadow: `inset 0px 0px 0px 1px ${theme.primary_color_background}`,
        content: '""',
        height: "100%",
        left: "0px",
        position: "absolute",
        top: "0px",
        width: "100%",
        zIndex: -1,
      },

      "&::before": {
        backgroundColor: theme.date_picker_selected_highlight,
        content: '""',
        height: "36px",
        left: "0px",
        position: "absolute",
        top: "0px",
        width: "100%",
        zIndex: -2,
      },
    },

  ".react-datepicker__day--in-selecting-range.react-datepicker__day--in-range.react-datepicker__day--selecting-range-start:not(.react-datepicker__day--range-start):not(.react-datepicker__day--range-end)":
    {
      borderRadius: "0",
      backgroundColor: theme.date_picker_selected_highlight,
      color: theme.text_color,
      display: "flex",
      alignItems: "center",
      position: "relative",
      zIndex: 3,

      "&::after": {
        backgroundColor: theme.date_picker_selected_highlight,
        borderRadius: theme.borderRadius_2,
        boxShadow: `inset 0px 0px 0px 1px ${theme.primary_color_background}`,
        content: '""',
        height: "100%",
        left: "0px",
        position: "absolute",
        top: "0px",
        width: "100%",
        zIndex: -1,
      },

      "&::before": {
        backgroundColor: theme.date_picker_selected_highlight,
        content: '""',
        height: "36px",
        left: "0px",
        position: "absolute",
        top: "0px",
        width: "100%",
        zIndex: -2,
      },
    },

  ".react-datepicker__day-name": {
    color: theme.date_picker_day_name_color,
    justifyContent: "center",
    width: "36px",
  },

  ".react-datepicker__day-names": {
    backgroundColor: theme.panel_backgroundColor,
    display: "flex",
    fontSize: "12px",
    justifyContent: "space-between",
    width: "256px",
    marginBottom: theme.space_xxs,
  },

  ".react-datepicker__header": {
    backgroundColor: theme.panel_backgroundColor,
    border: "none",
    margin: 0,
    padding:
      dateFormat === "MM/yyyy" ? `0 ${theme.space_md} 0 ${theme.space_md}` : 0,
  },

  ".react-datepicker__triangle": {
    display: "none",
  },

  ".react-datepicker__week": {
    display: "flex",
    whiteSpace: "nowrap",
    justifyContent: "space-between",
  },

  ".react-datepicker-year-header": {
    fontWeight: theme.fontWeight_bold,
    textAlign: "center",
  },

  ".react-datepicker__navigation": {
    outline: "none",
    cursor: "pointer",
    position: "absolute",
    border: "transparent",
    textIndent: "-999em",
    backgroundColor: "transparent",
    paddingTop: "0.25rem",
    paddingBottom: "0.25rem",
    paddingLeft: "0.25rem",
    paddingRight: "0.25rem",
    lineHeight: "unset",
    height: "0.5rem",
    transform: "translateX(-25%) rotate(45deg)",
    ":active": {
      ":hover": {
        borderWidth: "3px",
        borderColor: theme.text_color,
      },
    },
  },

  ".react-datepicker__navigation--previous": {
    top: "10px",
    left: "20px",
    borderLeft: `2px solid ${theme.text_color}`,
    borderBottom: `2px solid ${theme.text_color}`,
  },

  ".react-datepicker__navigation--next": {
    top: "10px",
    right: "10px",
    borderRight: `2px solid ${theme.text_color}`,
    borderTop: `2px solid ${theme.text_color}`,
  },

  ".react-datepicker__month": {
    color: theme.text_color,
    fontSize: theme.fontSize_ui,
    margin: "2px 0px 2px 0px",
    borderRadius: theme.borderRadius_2,
  },

  ".react-datepicker__month-text": {
    alignItems: "center",
    backgroundColor: theme.panel_backgroundColor,
    borderRadius: theme.borderRadius_2,
    color: theme.text_color,
    cursor: "pointer",
    display: "flex",
    height: "36px",
    justifyContent: "center",
    margin: 0,
    padding: 0,
    transition: "none",
    width: "100%",
    "&:hover": {
      borderRadius: theme.borderRadius_2,
      boxShadow: `inset 0px 0px 0px 1px ${theme.primary_color_background}`,
    },
  },

  ".react-datepicker__month-text--in-range:not(.react-datepicker__month-text-in-selecting-range):not(.react-datepicker__month-text--range-start):not(.react-datepicker__month-text--range-end)":
    {
      backgroundColor: theme.date_picker_selected_highlight,
      borderRadius: "0",
      color: theme.text_color,
    },

  ".react-datepicker__month-text--selected": {
    backgroundColor: theme.primary_color_background,
    borderRadius: theme.borderRadius_2,
    color: theme.text_color_inverse,
  },

  ".react-datepicker__month-text--in-selecting-range": {
    ...(hasStart && hasEnd
      ? {}
      : {
          alignItems: "center",
          backgroundColor: theme.date_picker_selected_highlight,
          borderRadius: "0",
          color: theme.text_color,
          display: "flex",
          transition: "none",
        }),
  },

  // start and end of selected range
  ".react-datepicker__month-text--range-start.react-datepicker__month-text--range-end":
    {
      backgroundColor: theme.primary_color_background,
      border: "0",
      borderRadius: theme.borderRadius_2,
      color: theme.text_color_inverse,
      position: "relative",
      zIndex: 3,
    },

  // Start of the selected range and not end of the selected range
  // only applies when both start and end have been chosen.
  ".react-datepicker__month-text--range-start:not(.react-datepicker__month-text--range-end)":
    {
      backgroundColor: theme.primary_color_background,
      borderRadius: theme.borderRadius_2,
      color: theme.text_color_inverse,
      position: "relative",
      zIndex: 3,

      "&::before": {
        backgroundColor: theme.primary_color_background,
        borderRadius: theme.borderRadius_2,
        content: '""',
        height: "36px",
        left: "0",
        position: "absolute",
        top: "0",
        width: "100%",
        zIndex: -1,
      },

      "&::after": {
        backgroundColor: theme.date_picker_selected_highlight,
        content: '""',
        height: "36px",
        position: "absolute",
        right: "0px",
        top: "0",
        width: theme.space_xs,
        zIndex: -2,
      },
    },

  // start of the selecting range
  ".react-datepicker__month-text--selecting-range-start:not(.react-datepicker__month-text--range-end):not(.react-datepicker__month-text--range-start):not(.react-datepicker__month-text--selecting-range-end):not(.react-datepicker__month-text--in-range)":
    {
      ...(hasStart && hasEnd
        ? {}
        : {
            border: "0",
            borderRadius: theme.borderRadius_2,
            color: theme.text_color_inverse,
            position: "relative",
            zIndex: 3,

            "&::before": {
              backgroundColor: theme.primary_color_background,
              borderRadius: theme.borderRadius_2,
              content: '""',
              height: "36px",
              left: "0",
              position: "absolute",
              top: "0",
              width: "100%",
              zIndex: -1,
            },

            "&::after": {
              backgroundColor: theme.date_picker_selected_highlight,
              content: '""',
              height: "36px",
              position: "absolute",
              right: "0px",
              top: "0",
              width: theme.space_xs,
              zIndex: -2,
            },
          }),
    },

  // End of the selected range and not start of the selected range
  ".react-datepicker__month-text--range-end:not(.react-datepicker__month-text--range-start)":
    {
      backgroundColor: theme.primary_color_background,
      border: "0",
      borderRadius: theme.borderRadius_2,
      color: theme.text_color_inverse,
      position: "relative",
      zIndex: 3,

      "&::after": {
        backgroundColor: theme.primary_color_background,
        borderRadius: theme.borderRadius_2,
        content: '""',
        height: "36px",
        left: "0",
        position: "absolute",
        top: "0",
        width: "100%",
        zIndex: -1,
      },

      "&::before": {
        backgroundColor: theme.date_picker_selected_highlight,
        content: '""',
        height: "36px",
        left: "0px",
        position: "absolute",
        top: "0",
        width: theme.space_md,
        zIndex: -2,
      },
    },

  // End of the selecting range
  ".react-datepicker__month-text--selecting-range-end:not(.react-datepicker__month-text--selecting-range-start):not(.react-datepicker__month-text--range-end)":
    {
      borderRadius: theme.borderRadius_2,
      color: theme.text_color,
      position: "relative",
      zIndex: 3,
      ...(hasStart && hasEnd
        ? {}
        : {
            "&::after": {
              backgroundColor: "transparent",
              borderRadius: theme.borderRadius_2,
              boxShadow: `inset 0px 0px 0px 1px ${theme.primary_color_background}`,
              content: '""',
              height: "100%",
              left: "0px",
              position: "absolute",
              top: "0px",
              width: "100%",
              zIndex: -1,
            },

            "&::before": {
              backgroundColor: theme.date_picker_selected_highlight,
              content: '""',
              height: "36px",
              left: "0px",
              position: "absolute",
              top: "0px",
              width: theme.space_xs,
              zIndex: -2,
            },
          }),
    },

  ".react-datepicker__month-text--in-selecting-range.react-datepicker__month-text--in-range.react-datepicker__month-text--selecting-range-end:not(.react-datepicker__month-text--range-start):not(.react-datepicker__month-text--range-end)":
    {
      alignItems: "center",
      backgroundColor: theme.date_picker_selected_highlight,
      borderRadius: "0",
      color: theme.text_color,
      display: "flex",

      "&::after": {
        backgroundColor: theme.date_picker_selected_highlight,
        borderRadius: theme.borderRadius_2,
        boxShadow: `inset 0px 0px 0px 1px ${theme.primary_color_background}`,
        content: '""',
        height: "100%",
        left: "0px",
        position: "absolute",
        top: "0px",
        width: "100%",
        zIndex: -1,
      },

      "&::before": {
        backgroundColor: theme.date_picker_selected_highlight,
        content: '""',
        height: "36px",
        left: "0px",
        position: "absolute",
        top: "0px",
        width: "100%",
        zIndex: -2,
      },
    },

  ".react-datepicker__month-text--in-selecting-range.react-datepicker__month-text--in-range.react-datepicker__month-text--selecting-range-start:not(.react-datepicker__month-text--range-start):not(.react-datepicker__month-text--range-end)":
    {
      alignItems: "center",
      backgroundColor: theme.date_picker_selected_highlight,
      borderRadius: "0",
      color: theme.text_color,
      display: "flex",
      position: "relative",
      zIndex: 3,

      "&::after": {
        backgroundColor: theme.date_picker_selected_highlight,
        borderRadius: theme.borderRadius_2,
        boxShadow: `inset 0px 0px 0px 1px ${theme.primary_color_background}`,
        content: '""',
        height: "100%",
        left: "0px",
        position: "absolute",
        top: "0px",
        width: "100%",
        zIndex: -1,
      },

      "&::before": {
        backgroundColor: theme.date_picker_selected_highlight,
        content: '""',
        height: "36px",
        left: "0px",
        position: "absolute",
        top: "0px",
        width: "100%",
        zIndex: -2,
      },
    },

  ".react-datepicker__month-text--today": {
    fontWeight: "normal",
    borderRadius: theme.borderRadius_2,
    boxShadow: `inset 0px 0px 0px 1px ${theme.secondary_color}`,
  },

  ".react-datepicker__month-container": {
    width: "256px",
    justifyContent: "center",
    padding: 0,
  },

  ".react-datepicker__month-wrapper": {
    alignItems: "center",
    display: "flex",
    height: "32px",
    justifyContent: "space-around",
    marginBottom: theme.space_xs,
    width: "100%",
  },

  ".react-datepicker-time__header": {
    display: "none",
  },

  ".react-datepicker.react-datepicker--time-only": {
    width: (() => {
      if (timeSelectWidth) {
        return timeSelectWidth;
      }
      return "inherit";
    })(),
  },

  ".react-datepicker__time-container": {
    backgroundColor: theme.input_background_color,
    border: "none",
    borderRadius: theme.borderRadius_2,
    boxShadow: `0 4px 8px ${theme.box_shadow}`,
    padding: `0 ${theme.space_xxs}`,
    width: "100%",
  },

  ".react-datepicker__time .react-datepicker__time-box": {
    textAlign: "left",
    width: "100%",
  },

  ".react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item":
    {
      display: "flex",
      alignItems: "center",
      backgroundColor: theme.input_background_color,
      color: theme.text_color,
      cursor: "pointer",
      fontSize: theme.fontSize_ui,
      margin: `${theme.space_xxs} 0`,
      transition: "none",

      "&:hover": {
        backgroundColor: theme.secondary_color_background,
      },
    },

  ".react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected":
    {
      backgroundColor: theme.primary_color_background,
      color: theme.text_color_inverse,
      fontWeight: "normal",

      "&:hover": {
        backgroundColor: theme.primary_color_background,
      },
    },

  ".react-datepicker__year": {
    color: theme.text_color,
    fontSize: theme.fontSize_ui,
    paddingBottom: theme.space_xs,
    paddingTop: theme.space_xs,
  },

  ".react-datepicker__year-text": {
    alignItems: "center",
    borderRadius: theme.borderRadius_2,
    cursor: "pointer",
    display: "flex",
    height: "32px",
    justifyContent: "center",

    "&:hover": {
      backgroundColor: theme.secondary_color_background,
    },
  },

  ".react-datepicker__year-text--selected": {
    backgroundColor: `${theme.primary_color_background} !important`,
    color: theme.text_color_inverse,
  },

  ".react-datepicker__year-wrapper": {
    display: "grid",
    gridTemplateColumns: "1fr 1fr 1fr",
    marginBottom: theme.space_xs,
    paddingRight: theme.space_xxs,
    paddingLeft: theme.space_xxs,
  },

  ".react-datepicker__year-text--disabled, .react-datepicker__day--disabled, .react-datepicker__month-text--disabled":
    {
      color: theme.text_color_disabled,
      cursor: "not-allowed",
    },

  ".react-datepicker__month--disabled": {
    backgroundColor: theme.background_color_disabled,
    color: theme.text_color_disabled,
    cursor: "not-allowed",
  },

  "& .react-datepicker__aria-live": {
    display: "block",
    height: 0,
    overflow: "hidden",
    position: "absolute",
  },
}));
