import { useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import {
  faCheckCircle,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import Flex from "@ternary/api-lib/ui-lib/components/Flex";
import Icon from "@ternary/web-ui-lib/components/Icon";
import { Theme } from "@ternary/web-ui-lib/theme/default";
import * as CSS from "csstype";
import React, {
  InputHTMLAttributes,
  RefObject,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { GroupBase, InputActionMeta, StylesConfig } from "react-select";
import _Select from "react-select/async";
import { CreatableProps as SelectProps } from "react-select/creatable";

//
// Theme
//

const textInputTheme = (baseTheme: Theme) => ({
  TextInput_borderColor_danger_focus: baseTheme.feedback_negative_outline,
  TextInput_borderColor_danger_hover: baseTheme.feedback_negative_outline,
  TextInput_borderColor_danger: baseTheme.feedback_negative,
  TextInput_borderColor_success_focus: baseTheme.feedback_positive_outline,
  TextInput_borderColor_success_hover: baseTheme.feedback_positive_outline,
  TextInput_borderColor_success: baseTheme.feedback_positive,
  TextInput_borderColor: baseTheme.secondary_color_border,
  TextInput_icon_color_danger: baseTheme.feedback_negative,
  TextInput_icon_color_success: baseTheme.feedback_positive,
  ...baseTheme,
});

//
// Styles
//

export type Size = "small" | "medium" | "large";
type Variant = "danger" | "success";

interface RootProps {
  cursor?: Props["cursor"];
  disabled?: Props["disabled"];
  variant?: Props["variant"];
  width?: Props["width"];
}

export const Root = styled("div")<RootProps>(({
  cursor,
  disabled,
  theme: baseTheme,
  variant,
  width,
}) => {
  const theme = textInputTheme(baseTheme);

  return {
    alignItems: "center",
    cursor: cursor ? cursor : disabled ? "default" : "text",
    display: "flex",
    fontSize: theme.fontSize_ui,
    position: "relative",
    borderColor:
      variant && !disabled
        ? theme[`TextInput_borderColor_${variant}`]
        : theme.TextInput_borderColor,

    borderStyle: "solid",
    borderWidth: "1px",
    borderRadius: theme.borderRadius_2,
    width: width ? width : "256px",
    justifyContent: "space-between",
    margin: 0,
    padding: 0,
    zIndex: theme.zIndex_100,

    '& [role="img"]': {
      ...(variant && { color: theme[`TextInput_icon_color_${variant}`] }),
      margin: `0 ${theme.space_sm}`,
    },

    "&:hover": {
      "& > div:last-child": {
        ...(!disabled && {
          borderColor: variant
            ? theme[`TextInput_borderColor_${variant}_hover`]
            : theme.primary_color_border,
        }),
      },
    },

    "&:focus": {
      "& > div:last-child": {
        ...(!disabled && { borderColor: "transparent" }),
        ...(!disabled && {
          boxShadow: variant
            ? `0 0 0 2px ${theme[`TextInput_borderColor_${variant}_focus`]}`
            : `0 0 0 2px ${theme.primary_color_focus}`,
        }),
      },
    },
  };
});

interface InputProps {
  autoSize?: Props["autoSize"];
  cursor?: Props["cursor"];
  disabled?: Props["disabled"];
  readOnly?: Props["readOnly"];
  size?: Props["size"];
  variant?: Props["variant"];
}

function shouldForwardProp(
  prop: PropertyKey
): prop is Exclude<keyof JSX.IntrinsicElements["input"], "size"> {
  return prop !== "size";
}

export const Input = styled("input", {
  shouldForwardProp,
})<InputProps>(({
  autoSize,
  cursor,
  disabled,
  height,
  readOnly,
  size,
  theme: baseTheme,
}) => {
  const theme = textInputTheme(baseTheme);

  return {
    backgroundColor: "transparent",
    border: "none",
    boxShadow: "none",
    color: (() => {
      if (disabled) return theme.text_color_disabled;
      if (readOnly) return theme.text_color_secondary;
      return theme.text_color;
    })(),
    ...(cursor ? { cursor } : {}),
    fontSize: theme.fontSize_ui,
    height: (() => {
      if (size === "small") return "28px";
      if (size === "medium") return "32px";
      if (height) return height;
      return "38px";
    })(),
    paddingLeft: theme.space_xs,
    paddingRight: theme.space_xs,
    outline: 0,
    width: autoSize ? "100%" : "none",

    "&::placeholder": {
      color: theme.text_color_placeholder,
      userSelect: "none",
    },
  };
});

export const Prefix = styled("span")(({ theme }) => ({
  color: theme.text_color,
  marginLeft: theme.space_xs,
}));

export const Suffix = styled("span")(({ theme }) => ({
  color: theme.text_color,
  marginRight: theme.space_xs,
}));

interface UnderlayProps {
  disabled?: Props["disabled"];
  readOnly?: Props["readOnly"];
  variant?: Props["variant"];
}

export const Underlay = styled("div")<UnderlayProps>(({
  disabled,
  readOnly,
  theme: baseTheme,
}) => {
  const theme = textInputTheme(baseTheme);

  return {
    backgroundColor:
      disabled || readOnly ? theme.background_color_disabled : "transparent",
    bottom: 0,
    left: 0,
    position: "absolute",
    right: 0,
    top: 0,
    zIndex: -1,
  };
});

//
// Main
//

const variantIcons = {
  danger: <Icon icon={faTimesCircle} size="lg" />,
  success: <Icon icon={faCheckCircle} size="lg" />,
};

export interface Props
  extends Omit<InputHTMLAttributes<HTMLInputElement>, "size"> {
  autoFocus?: boolean;
  autoSize?: boolean;
  cursor?: CSS.Property.Cursor;
  hideSelectedValue?: boolean;
  iconEnd?: JSX.Element;
  iconStart?: JSX.Element;
  inputRef?: RefObject<HTMLInputElement>;
  prefix?: string;
  selectOptions: DefaultOption[];
  selectValue?: string;
  size?: Size;
  suffix?: string;
  variant?: Variant;
  onChange: (event) => void;
  onChangeSelect: (event) => void;
}

// TODO: Find a way to consolidate this with other selects
export default function TextInputWithSelect({
  autoFocus,
  autoSize,
  cursor,
  disabled,
  hideSelectedValue,
  iconEnd,
  iconStart,
  inputRef,
  onChange,
  onChangeSelect,
  onClick,
  prefix,
  readOnly,
  selectValue,
  selectOptions,
  suffix,
  variant,
  width,
  ...restProps
}: Props): JSX.Element {
  const ref = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (autoFocus) ref.current?.focus();
  }, []);

  const rootProps = { cursor, disabled, onClick, variant };
  const underlayProps = { disabled, readOnly, variant };

  const inputProps = {
    autoSize,
    cursor,
    disabled,
    readOnly,
    ref: autoFocus ? ref : inputRef,
    variant,
    width,
    ...restProps,
  };

  iconEnd = variant ? variantIcons[variant] : iconEnd;

  const displayedOptions = hideSelectedValue
    ? selectOptions.filter((option) => option.value !== selectValue)
    : selectOptions;

  const value = selectOptions.find((value) => value.value === selectValue);

  return (
    <Root {...rootProps}>
      {iconStart && iconStart}
      {prefix && <Prefix {...restProps}>{prefix}</Prefix>}
      <Input {...inputProps} onChange={onChange} />
      <Flex>
        <Select
          autoSize={autoSize}
          disabled={disabled}
          value={value}
          options={displayedOptions}
          onChange={onChangeSelect}
        />
      </Flex>
      {suffix && <Suffix {...restProps}>{suffix}</Suffix>}
      {iconEnd && iconEnd}
      <Underlay {...underlayProps} />
    </Root>
  );
}

//
// Select
//

const MAX_OPTIONS = 250;

export type DefaultOption = { label: string; value: string };

export interface SelectDropdownProps<
  OptionType extends { value: unknown } = DefaultOption,
  IsMulti extends boolean = false,
  GroupType extends GroupBase<OptionType> = GroupBase<OptionType>,
> extends SelectProps<OptionType, IsMulti, GroupType> {
  autoSize?: boolean;
  compact?: boolean;
  disabled?: boolean;
  searchable?: boolean;
}

export function getStyles<
  OptionType extends { value: unknown } = DefaultOption,
  IsMulti extends boolean = false,
  GroupType extends GroupBase<OptionType> = GroupBase<OptionType>,
>(
  theme: Theme,
  autoSize?: boolean,
  compact?: boolean,
  hideMultiDeleteButton?: boolean
): Partial<StylesConfig<OptionType, IsMulti, GroupType>> {
  return {
    container: (styles) => ({
      ...styles,
      width: autoSize ? "max-content" : "100%",
    }),
    control: (styles, props) => ({
      ...styles,
      backgroundColor: props.isDisabled
        ? theme.background_color_disabled
        : "transparent",
      border: "none",
      cursor: "pointer",
      fontSize: theme.fontSize_ui,
      minHeight: compact ? "28px" : "38px",

      "&:hover": {
        borderColor: theme.primary_color_border,
      },
      "&:focus": {
        borderColor: theme.primary_color_border,
      },
    }),
    indicatorsContainer: (styles) => ({
      ...styles,
      minHeight: compact ? "28px" : "38px",

      "div:nth-of-type(1)": {
        ...(hideMultiDeleteButton ? { display: "none" } : {}),
      },

      "div:last-child": {
        padding: compact ? "4px" : styles.padding,
      },
    }),
    indicatorSeparator: (styles) => ({ ...styles, width: 0 }),
    input: (styles) => ({
      ...styles,
      color: `${theme.select_color}`,
    }),
    menu: (styles) => ({
      ...styles,
      backgroundColor: theme.input_background_color,
      border: "none",
      borderRadius: theme.borderRadius_2,
      boxShadow: `0 4px 8px ${theme.box_shadow}`,
      padding: `0 ${theme.space_xxs}`,
    }),
    menuList: (styles) => ({ ...styles, padding: 0 }),
    multiValue: (styles) => ({
      ...styles,
      backgroundColor: theme.secondary_color_background,
      borderRadius: theme.borderRadius_1,
      color: theme.secondary_color,
    }),
    option: (styles, props) => ({
      ...styles,
      backgroundColor: props.isSelected
        ? theme.primary_color_background
        : theme.input_background_color,
      opacity: 1,
      borderRadius: theme.borderRadius_2,
      color: props.isSelected ? theme.text_color_inverse : theme.text_color,
      cursor: "pointer",
      fontSize: theme.fontSize_ui,
      margin: `${theme.space_xxs} 0`,
      transition: "none",

      "&:hover": {
        backgroundColor: props.isSelected
          ? theme.primary_color_background
          : theme.secondary_color_background,
      },
    }),
    singleValue: (styles, props) => ({
      ...styles,
      color: props.isDisabled ? theme.text_color_disabled : theme.text_color,
    }),
    valueContainer: (styles) => ({
      ...styles,
      minHeight: compact ? "28px" : "38px",
      backgroundColor: "transparent",
    }),
    placeholder: (styles) => ({
      ...styles,
      color: "green",
      justifyContent: "flex-end",
    }),
  };
}

function Select<
  OptionType extends { value: unknown } = DefaultOption,
  IsMulti extends boolean = false,
  GroupType extends GroupBase<OptionType> = GroupBase<OptionType>,
>(props: SelectDropdownProps<OptionType, IsMulti, GroupType>): JSX.Element {
  const [stateInputValue, setInputValue] = useState("");

  const theme = useTheme();
  const disabled = props.disabled || props.isDisabled;
  const searchable = props.searchable || props.isSearchable || false;

  const inputValue = props.inputValue ?? stateInputValue;

  /*
  NOTE:
    react-select uses the same filterOption function for options ({ label, value })
    and groups ({ label, options: Option[] }). For an option within a group to pass
    the filter, filterOption must first return true for the group, then again for the
    option itself.
  */
  function filterOption(option: OptionType | GroupType, rawInput: string) {
    if (option === null) return false;

    if (
      typeof option === "object" &&
      "options" in option &&
      Array.isArray(option.options)
    ) {
      // Current option is a group of options
      const group = option;
      const foundMatchInGroup = group.options.some((groupOption: OptionType) =>
        filterOption(groupOption, rawInput)
      );
      return foundMatchInGroup;
    } else if (
      typeof option === "object" &&
      "label" in option &&
      typeof option.label === "string"
    ) {
      // Current option is a single option
      return inputMatchesText(rawInput, option.label);
    } else {
      // If option.label is not a string, it can't be filtered
      return true;
    }
  }

  function getFilteredOptions(): (OptionType | GroupType)[] {
    if (!props.options) return [];

    if (!searchable) return [...props.options];

    return props.options
      ?.filter((option) => filterOption(option, inputValue))
      .sort(sortForSelecting)
      .slice(0, MAX_OPTIONS);
  }

  /*
  NOTE:
    if 50k options is not enough, reach for server side filtering.
    on change, issue a new query
    add a filter to cube that says dimension INCLUDES inputValue
  */
  function loadOptions() {
    return new Promise<(OptionType | GroupType)[]>((resolve) => {
      resolve(getFilteredOptions());
    });
  }

  function sortForSelecting(optionA: OptionType | GroupType) {
    if (optionA && Array.isArray(props.value)) {
      if (typeof optionA === "object" && "value" in optionA) {
        const selected = props.value.find(
          (option) => option.value === optionA.value
        );

        if (selected) {
          return -1;
        }
      } else if (typeof optionA === "object" && "options" in optionA) {
        const selected = props.value.find((value) =>
          optionA.options.some((option) => option.value === value)
        );

        if (selected) {
          return -1;
        }
      }
    }

    return 0;
  }

  function handleInputChange(value: string, { action }: InputActionMeta): void {
    if (["input-blur", "set-value"].includes(action)) {
      return;
    }

    // Necessary for the value to actually be visible and not conflict with search text
    if (["menu-close"].includes(action)) {
      setInputValue("");
      return;
    }

    setInputValue(value);
  }

  const defaultOptions = useMemo(() => {
    if (!props.options) return [];

    if (props.options.length < MAX_OPTIONS) {
      return [...props.options].sort(sortForSelecting);
    }

    return getFilteredOptions().sort(sortForSelecting).slice(0, MAX_OPTIONS);
  }, [inputValue, props.menuIsOpen, props.options, props.isLoading]);

  const hideMultiDeleteButton =
    props.isMulti && Array.isArray(props.value) && props.value.length <= 1;

  const selectProps = {
    isDisabled: disabled,
    isSearchable: searchable,
    inputValue: inputValue,
    loadOptions: loadOptions,
    defaultOptions: defaultOptions,
    styles: getStyles<OptionType, IsMulti, GroupType>(
      theme,
      props.autoSize,
      props.compact,
      hideMultiDeleteButton
    ),
    onInputChange: props.onInputChange ?? handleInputChange,
    filterOption: filterOption,
    ...props,
  } as Omit<typeof props, "components">;

  return <_Select {...selectProps} key={JSON.stringify(props.isLoading)} />;
}

function inputMatchesText(input: string, text: string) {
  return text.toLowerCase().includes(input.toLowerCase());
}
