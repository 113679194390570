import { useQuery } from "@tanstack/react-query";
import DataQuery from "@ternary/api-lib/analytics/DataQuery";
import DataQueryExperimental from "@ternary/api-lib/analytics/DataQueryExperimental";
import {
  DataSource,
  Operator,
  TimeGranularity,
} from "@ternary/api-lib/constants/enums";
import { FORECASTED_KEY } from "@ternary/api-lib/ui-lib/charts/utils";
import { startOfToday } from "date-fns";
import { ANALYTICS_QUERY_GC_TIME } from "../../../constants";
import { useAnalyticsApiClient } from "../../../context/AnalyticsQueryLoaderProvider";
import useAuthenticatedUser from "../../../hooks/useAuthenticatedUser";
import useGatekeeper from "../../../hooks/useGatekeeper";
import { DateHelper } from "../../../lib/dates";

export function useGetForecastingTimeSeriesData(params: {
  lookBackDate: Date | null;
  dimensions: string[];
  measure: string;
  vendor: string | undefined;
}) {
  const authenticatedUser = useAuthenticatedUser();
  const client = useAnalyticsApiClient();
  const gatekeeper = useGatekeeper();

  const dateHelper = new DateHelper();

  const enabled = gatekeeper.hasConfiguredDataIntegration;

  const tenantID = authenticatedUser.tenant.fsDocID;

  //current month spend

  return useQuery({
    queryKey: ["forecastingTimeSeriesData", params],
    queryFn: async () => {
      const currentCostDataQuery = new DataQuery({
        dataSource: DataSource.BILLING,
        dateRange: params.lookBackDate
          ? [params.lookBackDate, startOfToday()]
          : [dateHelper.firstOfMonth(), startOfToday()],
        dimensions: params.dimensions,
        measures: [params.measure],
        granularity: TimeGranularity.DAY,
        ...(params.vendor
          ? {
              preAggFilters: [
                {
                  name: "vendor",
                  operator: Operator.EQUALS,
                  values: [params.vendor],
                },
              ],
            }
          : {}),
      });

      const currentCostDataQueryResult = await client.loadData(
        tenantID,
        currentCostDataQuery
      );

      const forecastedCostDataQuery = new DataQueryExperimental({
        dataSource: DataSource.BILLING,
        dateRange: params.lookBackDate
          ? [params.lookBackDate, dateHelper.date]
          : [dateHelper.firstOfLastMonth(), dateHelper.date],
        dimensions: params.dimensions,
        measures: [params.measure],
        granularity: TimeGranularity.DAY,
        forecasting: {
          algorithm: "linear-regression",
          dateRange: [dateHelper.date, dateHelper.firstDayOfNextMonth()],
          measure: params.measure,
        },
        ...(params.vendor
          ? {
              preAggFilters: [
                {
                  name: "vendor",
                  operator: Operator.EQUALS,
                  values: [params.vendor],
                },
              ],
            }
          : {}),
      });

      const _forecastedCostDataResult = await client.loadDataExperimental(
        tenantID,
        forecastedCostDataQuery
      );

      const timeSeriesForecastedCostDataResult = (
        _forecastedCostDataResult.forecast ?? []
      ) //Edge case for last day of month with no forecasting
        .map((entry) => {
          const value = entry[params.measure];
          delete entry[params.measure];

          return {
            ...entry,
            [`${params.measure}${FORECASTED_KEY}`]: value,
          };
        });
      return [
        ...currentCostDataQueryResult.response,
        ...timeSeriesForecastedCostDataResult,
      ];
    },
    enabled,
    gcTime: ANALYTICS_QUERY_GC_TIME,
  });
}
