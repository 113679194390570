import ErrorBoundary from "@/components/ErrorBoundary";
import Tabs from "@/ui-lib/components/Tabs";
import { TenantStatus } from "@ternary/api-lib/constants/enums";
import { actions } from "@ternary/api-lib/telemetry";
import React from "react";
import { Navigate } from "react-router-dom";
import {
  ArrayParam,
  StringParam,
  useQueryParams,
  withDefault,
} from "use-query-params";
import paths from "../../../constants/paths";
import { useActivityTracker } from "../../../context/ActivityTrackerProvider";
import useGatekeeper from "../../../hooks/useGatekeeper";
import { useMspStore } from "../../../lib/zustand";
import SelectCheckbox from "../../../ui-lib/components/SelectCheckbox";
import useGetTenantsByParentTenantID from "../../global-admin/hooks/useGetTenantsByParentTenantID";
import copyText from "../copyText";
import MspCostAlertViewContainer from "./MspCostAlertViewContainer";
import MspDataIntegrationViewContainer from "./MspDataIntegrationViewContainer";
import MspRecommendationViewContainer from "./MspRecommendationViewContainer";
import MspReportingContainer from "./MspReportingContainer";
import MspUserViewContainer from "./MspUserViewContainer";

export function MspDashboardPage(): JSX.Element {
  const activityTracker = useActivityTracker();
  const gatekeeper = useGatekeeper();

  const [searchParamState, setSearchParamState] = useQueryParams({
    tab: withDefault(StringParam, "reporting"),
    t_ids: withDefault(ArrayParam, []),
  });

  //
  // State
  //

  const mspStore = useMspStore();

  const { data: _mspTenants = [], isLoading: isLoadingMspTenants } =
    useGetTenantsByParentTenantID(mspStore.selectedParentTenantID ?? "", {
      enabled:
        gatekeeper.canReadTenantsPartner &&
        mspStore.selectedParentTenantID !== null,
    });

  function handleTenantFilterSelect(tenantIDs: string[]): void {
    activityTracker.captureAction(actions.SELECT_MSP_DASHBOARD_TENANT_FILTER, {
      tenantIDs,
    });

    setSearchParamState({
      t_ids: tenantIDs,
    });
  }

  //
  // Render
  //

  if (!gatekeeper.canAccessMspAdmin) {
    return <Navigate to={paths._home} replace />;
  }

  // parentTenantID will always be populated at this point.
  const parentTenantID = mspStore.selectedParentTenantID as string;

  const containers = [
    {
      component: <MspReportingContainer parentTenantID={parentTenantID} />,
      label: copyText.tabLabelReporting,
      value: "reporting",
    },
    {
      component: <MspCostAlertViewContainer parentTenantID={parentTenantID} />,
      label: copyText.tabLabelAnomalies,
      value: "anomalies",
    },
    {
      component: (
        <MspRecommendationViewContainer parentTenantID={parentTenantID} />
      ),
      label: copyText.tabLabelSavings,
      value: "savings",
    },
    {
      component: (
        <MspDataIntegrationViewContainer parentTenantID={parentTenantID} />
      ),
      label: copyText.tabLabelClouds,
      value: "clouds",
    },
    {
      component: <MspUserViewContainer parentTenantID={parentTenantID} />,
      label: copyText.tabLabelUsers,
      value: "users",
    },
  ];

  const activeTenants = _mspTenants.filter(
    (tenant) => tenant.status === TenantStatus.ACTIVE
  );

  const tenantFilterOption = activeTenants
    .map((tenant) => ({
      label: tenant.name,
      value: tenant.fsDocID,
    }))
    .sort((a, b) => (a.label.toLowerCase() < b.label.toLowerCase() ? -1 : 1));

  return (
    <>
      <ErrorBoundary boundaryName="MspAdminPage">
        <Tabs
          activeValue={searchParamState.tab}
          tabs={containers}
          filters={[
            <SelectCheckbox
              compact
              isClearable
              isLoading={isLoadingMspTenants}
              options={tenantFilterOption}
              placeholder={copyText.filterTenantsDropdownPlaceholder}
              selectedValues={parseArrayParam(searchParamState.t_ids)}
              width={300}
              onChange={handleTenantFilterSelect}
            />,
          ]}
          onSelect={(tab: string) => setSearchParamState({ tab })}
        />
      </ErrorBoundary>
    </>
  );
}

export function parseArrayParam(values: (string | null)[]): string[] {
  return values.filter((value): value is string => typeof value === "string");
}

export default MspDashboardPage;
