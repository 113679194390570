import { useQuery } from "@tanstack/react-query";
import { InternalApiClient } from "../../../api/internal/InternalApiClient";
import { useInternalApiClient } from "../../../api/internal/InternalApiClientProvider";
import { UseQueryOptions, UseQueryResult } from "../../../lib/react-query";

type UserEntity = Awaited<ReturnType<InternalApiClient["getUsers"]>>[number];

export default function useGetUsers(
  options?: UseQueryOptions<UserEntity[], Error>
): UseQueryResult<UserEntity[], Error> {
  const client = useInternalApiClient();

  return useQuery({
    queryKey: ["users"],
    queryFn: () => client.getUsers(),
    ...options,
  });
}
