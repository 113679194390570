import { Theme, useTheme } from "@emotion/react";
import { faEllipsisV } from "@fortawesome/free-solid-svg-icons";
import { createColumnHelper } from "@tanstack/react-table";
import { AlertRuleStatus } from "@ternary/api-lib/constants/enums";
import Table from "@ternary/api-lib/ui-lib/charts/Table/Table";
import Box from "@ternary/api-lib/ui-lib/components/Box";
import Button from "@ternary/api-lib/ui-lib/components/Button";
import Tooltip from "@ternary/api-lib/ui-lib/components/Tooltip";
import Icon from "@ternary/web-ui-lib/components/Icon";
import { formatDate } from "@ternary/web-ui-lib/utils/dates";
import { formatDistance } from "date-fns";
import { groupBy, isNil, keyBy } from "lodash";
import React, { useMemo } from "react";
import useAuthenticatedUser from "../../../hooks/useAuthenticatedUser";
import useGatekeeper from "../../../hooks/useGatekeeper";
import Dropdown from "../../../ui-lib/components/Dropdown";
import { getFullName } from "../../../utils/UserUtils";
import copyText from "../copyText";
import { CostAlertLegacySourceRule } from "../types";

type TableData = {
  alertsTriggered: number;
  createdAt: string | null;
  createdBy: string | null;
  id: string;
  lastTriggered: string | null;
  name: string;
  status: AlertRuleStatus;
};

type User = {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
};

type Rule = {
  id: string;
  createdAt: string;
  createdByUserID: string;
  name: string;
  status: AlertRuleStatus;
};

type CostAlert = {
  createdAt: string;
};

interface Props {
  alerts: CostAlert[];
  isLoading: boolean;
  rules: Rule[];
  users: User[];
  onInteraction: (interaction: AlertRuleTable.Interaction) => void;
}

const columnHelper = createColumnHelper<TableData>();

export function AlertRuleTable(props: Props): JSX.Element {
  const theme = useTheme();
  const authenticatedUser = useAuthenticatedUser();
  const gatekeeper = useGatekeeper();

  const usersKeyedByID = keyBy(props.users, "id");

  const columns = useMemo(
    () => [
      columnHelper.accessor("name", {
        header: copyText.alertRuleTableHeaderRuleName,
        meta: { align: "center" },
        size: 100,
      }),
      columnHelper.accessor("status", {
        cell: ({ getValue }) =>
          renderStatusDot(getValue() === AlertRuleStatus.ACTIVE, theme),
        header: copyText.alertRuleTableHeaderRuleStatus,
        meta: { align: "center" },
        size: 80,
      }),
      columnHelper.accessor("alertsTriggered", {
        header: copyText.alertRuleTableHeaderRuleAlertsTriggered,
        meta: { align: "center" },
        size: 80,
      }),
      columnHelper.accessor("createdBy", {
        cell: ({ getValue }) => {
          if (isNil(getValue())) return <>{copyText.naText}</>;
          return <>{getValue()}</>;
        },
        header: copyText.alertRuleTableHeaderCreatedBy,
        meta: { align: "center" },
        size: 100,
      }),
      columnHelper.accessor("createdAt", {
        cell: ({ getValue }) => {
          const value = getValue();
          if (isNil(value)) return <>{copyText.naText}</>;
          return <>{formatDate(new Date(value), "yyyy-MM-dd")}</>;
        },
        header: copyText.alertRuleTableHeaderCreatedAt,
        meta: { align: "center" },
        size: 80,
      }),
      columnHelper.accessor("lastTriggered", {
        cell: ({ getValue }) => {
          const value = getValue();
          if (isNil(value)) return <>{copyText.naText}</>;
          const lastTriggered = new Date(value);

          const now = new Date();

          const lastWeek = new Date(
            new Date().setDate(new Date().getDate() - 7)
          );

          const distance = formatDistance(lastTriggered, now);

          // display distance if less than a week ago, timestamp if more than a week ago
          if (
            Date.parse(lastTriggered.toLocaleString()) >
            Date.parse(lastWeek.toLocaleString())
          ) {
            return (
              <> {copyText.timeDistanceText.replace("%distance%", distance)}</>
            );
          }

          return <>{formatDate(new Date(value), "yyyy-MM-dd")}</>;
        },
        header: copyText.alertRuleTableHeaderRuleLastTriggered,
        meta: { align: "center" },
        size: 80,
      }),
      columnHelper.display({
        id: "actionMenu",
        cell: function renderButton({ row }) {
          const ruleOptions = [
            {
              label: copyText.actionViewAlerts,
              disabled:
                !gatekeeper.canListCostAlerts ||
                !gatekeeper.canListAlertRuleEvents,
              onClick: () =>
                props.onInteraction({
                  type: AlertRuleTable.INTERACTION_SELECT_BUTTON_CLICKED,
                  ruleID: row.original.id,
                }),
            },
            {
              disabled: !gatekeeper.canUpdateAlertRules,
              label: copyText.actionEdit,
              onClick: () =>
                props.onInteraction({
                  type: AlertRuleTable.INTERACTION_EDIT_BUTTON_CLICKED,
                  ruleID: row.original.id,
                }),
            },
            {
              disabled: !gatekeeper.canCreateAlertRules,
              label: copyText.actionCopy,
              onClick: () =>
                props.onInteraction({
                  type: AlertRuleTable.INTERACTION_COPY_BUTTON_CLICKED,
                  ruleID: row.original.id,
                }),
            },
            ...(row.original.status === AlertRuleStatus.ACTIVE
              ? [
                  {
                    disabled: !gatekeeper.canUpdateAlertRules,
                    label: copyText.actionArchive,
                    onClick: () =>
                      props.onInteraction({
                        type: AlertRuleTable.INTERACTION_ARCHIVE_BUTTON_CLICKED,
                        ruleID: row.original.id,
                        status: AlertRuleStatus.INACTIVE,
                      }),
                  },
                ]
              : [
                  {
                    disabled: !gatekeeper.canUpdateAlertRules,
                    label: copyText.actionUnarchive,
                    onClick: () =>
                      props.onInteraction({
                        type: AlertRuleTable.INTERACTION_ARCHIVE_BUTTON_CLICKED,
                        ruleID: row.original.id,
                        status: AlertRuleStatus.ACTIVE,
                      }),
                  },
                ]),
          ];
          return (
            <Dropdown
              disabled={
                row.original.id ===
                  CostAlertLegacySourceRule.LEGACY_BIGQUERY_ANOMALY_ML ||
                row.original.id ===
                  CostAlertLegacySourceRule.LEGACY_BILLING_ANOMALY_ML
              }
              options={ruleOptions}
              placement="bottom"
            >
              <Button
                iconStart={<Icon icon={faEllipsisV} />}
                secondary
                size="tiny"
              />
            </Dropdown>
          );
        },
        size: 30,
      }),
    ],
    [props.rules, authenticatedUser]
  );
  const alertsGroupedByAlertRuleID = groupBy(props.alerts, "alertRuleID");

  const data: TableData[] = useMemo(() => {
    if (props.isLoading) return [];

    const userCreatedRules = props.rules.map((rule) => {
      const alerts = alertsGroupedByAlertRuleID[rule.id];

      const alertsTriggered: number = alerts?.length ?? 0;

      const lastTriggered: string =
        alerts?.sort((a, b) => {
          return (
            new Date(Date.parse(b.createdAt)).getTime() -
            new Date(Date.parse(a.createdAt)).getTime()
          );
        })[0]?.createdAt ?? null;

      return {
        ...rule,
        createdBy: getFullName(usersKeyedByID[rule.createdByUserID]),
        alertsTriggered,
        lastTriggered,
      };
    });

    const bigQueryEvents =
      alertsGroupedByAlertRuleID[
        CostAlertLegacySourceRule.LEGACY_BIGQUERY_ANOMALY_ML
      ];

    const lastTriggeredBigQueryEvent: string =
      bigQueryEvents?.sort((a, b) => {
        return (
          new Date(Date.parse(b.createdAt)).getTime() -
          new Date(Date.parse(a.createdAt)).getTime()
        );
      })[0]?.createdAt ?? null;

    const billingEvents =
      alertsGroupedByAlertRuleID[
        CostAlertLegacySourceRule.LEGACY_BILLING_ANOMALY_ML
      ];

    const lastTriggeredBillingEvent: string =
      billingEvents?.sort((a, b) => {
        return (
          new Date(Date.parse(b.createdAt)).getTime() -
          new Date(Date.parse(a.createdAt)).getTime()
        );
      })[0]?.createdAt ?? null;

    const legacyRules = [
      {
        alertsTriggered: bigQueryEvents?.length ?? 0,
        createdAt: null,
        createdBy: copyText.systemGeneratedRuleText,
        id: CostAlertLegacySourceRule.LEGACY_BIGQUERY_ANOMALY_ML,
        lastTriggered: lastTriggeredBigQueryEvent,
        name: copyText.stringifiedLegacyBigQueryAnomalySourceAlert,
        status: AlertRuleStatus.ACTIVE,
      },
      {
        alertsTriggered: billingEvents?.length ?? 0,
        createdAt: null,
        createdBy: copyText.systemGeneratedRuleText,
        id: CostAlertLegacySourceRule.LEGACY_BILLING_ANOMALY_ML,
        lastTriggered: lastTriggeredBillingEvent,
        name: copyText.stringifiedLegacyBillingAnomalySourceAlert,
        status: AlertRuleStatus.ACTIVE,
      },
    ];

    return [...userCreatedRules, ...legacyRules];
  }, [props.rules, alertsGroupedByAlertRuleID, props.isLoading === false]);

  return (
    <Table
      columns={columns}
      data={data}
      disableRow={(row) => {
        return (
          row.original.id ===
            CostAlertLegacySourceRule.LEGACY_BIGQUERY_ANOMALY_ML ||
          row.original.id ===
            CostAlertLegacySourceRule.LEGACY_BILLING_ANOMALY_ML
        );
      }}
      initialState={{ sorting: [{ id: "lastTriggered", desc: true }] }}
      isLoading={props.isLoading}
      showPagination
      sortable
    />
  );
}

AlertRuleTable.INTERACTION_ARCHIVE_BUTTON_CLICKED =
  `RuleManagementTable.INTERACTION_ARCHIVE_BUTTON_CLICKED` as const;
AlertRuleTable.INTERACTION_COPY_BUTTON_CLICKED =
  `RuleManagementTable.INTERACTION_COPY_BUTTON_CLICKED` as const;
AlertRuleTable.INTERACTION_EDIT_BUTTON_CLICKED =
  `RuleManagementTable.INTERACTION_EDIT_BUTTON_CLICKED` as const;
AlertRuleTable.INTERACTION_SELECT_BUTTON_CLICKED =
  `RuleManagementTable.INTERACTION_SELECT_BUTTON_CLICKED` as const;

interface InteractionArchiveRuleClicked {
  type: typeof AlertRuleTable.INTERACTION_ARCHIVE_BUTTON_CLICKED;
  ruleID: string;
  status: AlertRuleStatus;
}
interface InteractionCopyRuleClicked {
  type: typeof AlertRuleTable.INTERACTION_COPY_BUTTON_CLICKED;
  ruleID: string;
}
interface InteractionEditRuleClicked {
  type: typeof AlertRuleTable.INTERACTION_EDIT_BUTTON_CLICKED;
  ruleID: string;
}

interface InteractionSelectRuleClicked {
  type: typeof AlertRuleTable.INTERACTION_SELECT_BUTTON_CLICKED;
  ruleID: string;
}

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace AlertRuleTable {
  export type Interaction =
    | InteractionArchiveRuleClicked
    | InteractionCopyRuleClicked
    | InteractionEditRuleClicked
    | InteractionSelectRuleClicked;
}

export default AlertRuleTable;

function renderStatusDot(active: boolean, theme: Theme) {
  const text = active
    ? copyText.alertRuleTableTooltipActive
    : copyText.alertRuleTableTooltipInactive;

  return (
    <Tooltip content={text}>
      <Box
        padding="0.35rem"
        backgroundColor={active ? theme.feedback_positive : theme.feedback_warn}
        borderRadius="50%"
        margin="0.3rem"
      />
    </Tooltip>
  );
}
