import Icon, { Props as IconProps } from "@ternary/web-ui-lib/components/Icon";
import React from "react";

export default function IconAzure(props: IconProps): JSX.Element {
  return (
    <Icon {...props}>
      <svg viewBox="0 0 59.242 47.271" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M32.368 0 14.9 15.145 0 41.895h13.437zm2.323 3.543-7.454 21.008 14.291 17.956L13.8 47.271h45.442z"
          fill="#0072c6"
        />
      </svg>
    </Icon>
  );
}
