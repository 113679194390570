import { useTheme } from "@emotion/react";
import { faFileExport } from "@fortawesome/free-solid-svg-icons";
import { QueryFilter } from "@ternary/api-lib/analytics/types";
import { getCubeDateRangeFromDurationType } from "@ternary/api-lib/analytics/utils";
import { DurationType, Operator } from "@ternary/api-lib/constants/enums";
import Box from "@ternary/api-lib/ui-lib/components/Box";
import Button from "@ternary/api-lib/ui-lib/components/Button";
import Flex from "@ternary/api-lib/ui-lib/components/Flex";
import Icon from "@ternary/api-lib/ui-lib/components/Icon";
import Text from "@ternary/api-lib/ui-lib/components/Text";
import { format } from "date-fns";
import React, { useMemo, useState } from "react";
import { CSVLink } from "react-csv";
import {
  DateParam,
  DecodedValueMap,
  StringParam,
  createEnumParam,
  useQueryParam,
  useQueryParams,
  withDefault,
} from "use-query-params";
import { z } from "zod";
import useRefIfEqual from "../../../../hooks/useRefIfEqual";
import { DateHelper } from "../../../../lib/dates";
import { createStructParam } from "../../../../lib/use-query-params";
import DateRangeControls from "../../../../ui-lib/components/DateRangeControls/DateRangeControls";
import Modal from "../../../../ui-lib/components/Modal";
import copyText from "../../copyText";
import useGetAWSCommitmentAllocation from "../hooks/useGetAWSCommitmentAllocation";
import {
  AWSCommitmentAllocationDatum,
  AWSCommittedUseAllocationType,
  AWSCommittedUseType,
} from "../types";
import AWSAllocationChart from "./AWSAllocationChart";
import AWSAllocationControls from "./AWSAllocationControls";
import AWSAllocationFilterControls from "./AWSAllocationFilterControls";
import AWSAllocationTable, {
  columnKeys,
  columnLabels,
  getTableData,
} from "./AWSAllocationTable";

type Interaction = AWSAllocationControls.Interaction;

const queryParamConfigMap = {
  arn: StringParam,
  account_id: StringParam,
  date_range_end: DateParam,
  date_range_start: DateParam,
  duration: withDefault(
    createEnumParam(Object.values(DurationType)),
    DurationType.LAST_THIRTY_DAYS
  ),
  al_type: createEnumParam(Object.values(AWSCommittedUseAllocationType)),
  type: createEnumParam(Object.values(AWSCommittedUseType)),
};

const empty = [];

const accountTableDimensions = [
  "billPayerAccountId",
  "commitmentType",
  "lineItemUsageAccountId",
];

const accountTableMeasures = [
  "coveredUsageHours",
  "percentOfCommitments",
  "effectiveCost",
  "netEffectiveCost",
  "countDistinctARN",
  "amortizedCost",
];

const accountTableColumns = [
  ...accountTableDimensions,
  ...accountTableMeasures,
];

const accountInstanceTableDimensions = [
  "commitmentARN",
  "commitmentType",
  "instanceId",
];

const accountInstanceTableMeasures = [
  "coveredUsageHours",
  "effectiveCost",
  "netEffectiveCost",
];

const accountInstanceTableColumns = [
  ...accountInstanceTableDimensions,
  ...accountInstanceTableMeasures,
];

const resourceTableDimensions = [
  "commitmentARN",
  "commitmentType",
  "coverageType",
  "offeringType",
  "region",
];

const resourceTableMeasures = [
  "percentOfCommitments",
  "effectiveCost",
  "netEffectiveCost",
  "amortizedCost",
];

const resourceTableColumns = [
  ...resourceTableDimensions,
  ...resourceTableMeasures,
];

const resourceInstanceTableDimensions = [
  "instanceId",
  "lineItemUsageAccountId",
];

const resourceInstanceTableMeasures = [
  "coveredUsageHours",
  "percentOfCommitments",
  "effectiveCost",
  "netEffectiveCost",
];

const resourceInstanceTableColumns = [
  ...resourceInstanceTableDimensions,
  ...resourceInstanceTableMeasures,
];

const hasInstanceQueryFilter: QueryFilter = {
  name: "instanceId",
  operator: Operator.SET,
};

const hasCommitmentTypeQueryFilter: QueryFilter = {
  name: "commitmentType",
  operator: Operator.SET,
};

export default function AWSAllocationContainer() {
  const [allocationsInTablePage, setAllocationsInTablePage] = useState(
    [] as AWSCommitmentAllocationDatum[]
  );
  const [queryParams, setQueryParams] = useQueryParams(queryParamConfigMap);
  const [filtersParam = null, setFiltersParam] = useQueryParam(
    "filters",
    createStructParam(z.record(z.string(), z.string()))
  );
  const filters = useRefIfEqual(filtersParam);
  const queryParamState = getQueryParamState(queryParams);
  const dateRange = queryParamState.dateRange;
  const theme = useTheme();

  const dimensions =
    queryParamState.allocationType === AWSCommittedUseAllocationType.ACCOUNT
      ? accountTableDimensions
      : resourceTableDimensions;

  const instanceDimensions =
    queryParamState.allocationType === AWSCommittedUseAllocationType.ACCOUNT
      ? accountInstanceTableDimensions
      : resourceInstanceTableDimensions;

  const {
    data: commitmentAllocations = empty,
    isLoading: isLoadingCommitmentAllocations,
  } = useGetAWSCommitmentAllocation({
    dateRange,
    dimensions,
    queryFilters: [hasCommitmentTypeQueryFilter],
  });

  const queryFilters =
    queryParamState.accountID &&
    queryParamState.allocationType === AWSCommittedUseAllocationType.ACCOUNT
      ? [
          hasInstanceQueryFilter,
          hasCommitmentTypeQueryFilter,
          {
            name: "lineItemUsageAccountId",
            operator: Operator.EQUALS,
            values: [queryParamState.accountID],
          },
        ]
      : queryParamState.arn &&
          queryParamState.allocationType ===
            AWSCommittedUseAllocationType.RESOURCE
        ? [
            hasInstanceQueryFilter,
            hasCommitmentTypeQueryFilter,
            {
              name: "commitmentARN",
              operator: Operator.EQUALS,
              values: [queryParamState.arn],
            },
          ]
        : [];

  const canViewInstanceLevel =
    queryParamState.allocationType === AWSCommittedUseAllocationType.ACCOUNT
      ? !!queryParamState.accountID
      : !!queryParamState.arn;

  const {
    data: commitmentInstanceAllocations = empty,
    isLoading: isLoadingCommitmentInstanceAllocations,
  } = useGetAWSCommitmentAllocation(
    {
      dimensions: instanceDimensions,
      dateRange,
      queryFilters,
    },
    {
      enabled: canViewInstanceLevel,
    }
  );

  const filteredAllocations = useMemo(() => {
    const appliedFilters = filters ? { ...filters } : {};

    if (queryParamState.committedUseType === AWSCommittedUseType.RI) {
      appliedFilters.commitmentType = "Reserved Instance";
    }

    if (queryParamState.committedUseType === AWSCommittedUseType.SP) {
      appliedFilters.commitmentType = "Savings Plan";
    }

    return filterAllocations(commitmentAllocations, appliedFilters);
  }, [commitmentAllocations, filters, queryParamState.committedUseType]);

  function handleInteraction(interaction: Interaction) {
    switch (interaction.type) {
      case AWSAllocationControls.INTERACTION_CHANGE_ALLOCATION_TYPE:
        setQueryParams({ al_type: interaction.allocationType });
        break;
      case AWSAllocationControls.INTERACTION_CHANGE_TYPE:
        setQueryParams({ type: interaction.committedUseType });
        break;
      default:
        break;
    }
  }

  function updateFilter(key: string, value: string | null) {
    const updatedFilters = filters ? { ...filters } : {};

    if (value === null) {
      delete updatedFilters[key];
    } else {
      updatedFilters[key] = value;
    }

    setFiltersParam(
      Object.keys(updatedFilters).length > 0 ? updatedFilters : null
    );
  }

  const mainCSVData = useMemo(
    () =>
      getCSVData(
        queryParamState.allocationType === AWSCommittedUseAllocationType.ACCOUNT
          ? accountTableColumns
          : resourceTableColumns,
        filteredAllocations
      ),
    [filteredAllocations, queryParamState.allocationType]
  );

  const instanceCSVData = useMemo(
    () =>
      getCSVData(
        queryParamState.allocationType === AWSCommittedUseAllocationType.ACCOUNT
          ? accountInstanceTableColumns
          : resourceInstanceTableColumns,
        commitmentInstanceAllocations
      ),
    [commitmentInstanceAllocations, queryParamState.allocationType]
  );

  const isLoading = isLoadingCommitmentAllocations;

  return (
    <Box>
      <Modal
        isOpen={canViewInstanceLevel}
        closeOnClickOutside
        showCloseButton
        onClose={() => setQueryParams({ arn: null, account_id: null })}
      >
        <Modal.Header>
          <Flex justifyContent="space-between" alignItems="center">
            {queryParamState.allocationType ===
            AWSCommittedUseAllocationType.ACCOUNT ? (
              <Text appearance="h4">
                {queryParamState.accountID &&
                  copyText.awsAllocationLinkedAccountID.replace(
                    "%VALUE%",
                    queryParamState.accountID
                  )}
              </Text>
            ) : (
              <Text appearance="h4">{queryParamState.arn}</Text>
            )}

            <Box marginLeft={theme.space_sm}>
              <CSVLink
                data={instanceCSVData.rows}
                headers={instanceCSVData.headers}
                filename={`AWS-instance-allocation-${format(
                  new Date(),
                  "MM-dd-yyyy"
                )}`}
              >
                <Button
                  iconStart={<Icon color="inherit" icon={faFileExport} />}
                  secondary
                  size="small"
                >
                  {copyText.exportButtonLabel}
                </Button>
              </CSVLink>
            </Box>
          </Flex>
        </Modal.Header>
        <Modal.Body>
          <Box marginBottom={theme.space_lg}>
            <Box overflowX="auto">
              {queryParamState.allocationType ===
              AWSCommittedUseAllocationType.ACCOUNT ? (
                <AWSAllocationTable
                  visibleColumns={accountInstanceTableColumns}
                  allocations={commitmentInstanceAllocations}
                  isLoading={isLoadingCommitmentInstanceAllocations}
                />
              ) : (
                <AWSAllocationTable
                  visibleColumns={resourceInstanceTableColumns}
                  allocations={commitmentInstanceAllocations}
                  isLoading={isLoadingCommitmentInstanceAllocations}
                />
              )}
            </Box>
          </Box>
        </Modal.Body>
      </Modal>

      <Flex
        backgroundColor={theme.panel_backgroundColor}
        borderRadius={theme.borderRadius_1}
        marginBottom={theme.space_lg}
        paddingHorizontal={theme.space_md}
        paddingVertical={theme.space_sm}
        justifyContent="space-between"
      >
        <AWSAllocationControls
          allocationType={queryParamState.allocationType}
          committedUseType={queryParamState.committedUseType}
          isLoading={isLoading}
          onInteraction={handleInteraction}
        />

        <DateRangeControls
          dateRange={queryParamState.dateRange}
          durationType={queryParamState.duration}
          hiddenOptions={[DurationType.QUARTER_TO_DATE, DurationType.YESTERDAY]}
          maxDate={new DateHelper().date}
          onChangeDateRange={(duration, newDateRange) => {
            setQueryParams({
              duration,
              ...(newDateRange && newDateRange[0] && newDateRange[1]
                ? {
                    date_range_start: newDateRange[0],
                    date_range_end: newDateRange[1],
                  }
                : {
                    date_range_start: null,
                    date_range_end: null,
                  }),
            });
          }}
        />
      </Flex>

      <Flex direction="column" height={500} marginBottom={theme.space_lg}>
        <Flex
          flex="1 0 0"
          justifyContent="center"
          paddingVertical={theme.space_sm}
        >
          <Box
            backgroundColor={theme.panel_backgroundColor}
            borderRadius={theme.borderRadius_1}
            padding={theme.space_sm}
            paddingBottom={theme.space_md}
            width={
              queryParamState.allocationType ===
              AWSCommittedUseAllocationType.ACCOUNT
                ? "100%"
                : 600
            }
          >
            <AWSAllocationChart
              data={allocationsInTablePage}
              isLoading={isLoading}
            />
          </Box>
        </Flex>
      </Flex>

      <Box marginBottom={theme.space_lg}>
        <Flex
          alignItems="center"
          minHeight={30}
          padding={theme.space_sm}
          borderRadius={theme.borderRadius_1}
          backgroundColor={theme.panel_backgroundColor}
          marginBottom={theme.space_md}
          justifyContent="space-between"
        >
          <AWSAllocationFilterControls
            filters={filters ?? {}}
            onChangeFilter={updateFilter}
          />

          <Box>
            <CSVLink
              data={mainCSVData.rows}
              headers={mainCSVData.headers}
              filename={`AWS-allocation-${format(new Date(), "MM-dd-yyyy")}`}
            >
              <Button
                iconStart={<Icon color="inherit" icon={faFileExport} />}
                secondary
                size="small"
              >
                {copyText.exportButtonLabel}
              </Button>
            </CSVLink>
          </Box>
        </Flex>

        <Box overflowX="auto">
          {queryParamState.allocationType ===
          AWSCommittedUseAllocationType.ACCOUNT ? (
            <AWSAllocationTable
              pageSize={20}
              visibleColumns={["select", ...accountTableColumns]}
              allocations={filteredAllocations}
              isLoading={isLoading}
              onAddFilter={updateFilter}
              onChangePage={setAllocationsInTablePage}
              onSelectAllocation={(allocation) =>
                setQueryParams({
                  account_id: allocation.lineItemUsageAccountId,
                })
              }
            />
          ) : (
            <AWSAllocationTable
              pageSize={20}
              visibleColumns={["select", ...resourceTableColumns]}
              allocations={filteredAllocations}
              isLoading={isLoading}
              onAddFilter={updateFilter}
              onChangePage={setAllocationsInTablePage}
              onSelectAllocation={(allocation) =>
                setQueryParams({ arn: allocation.commitmentARN })
              }
            />
          )}
        </Box>
      </Box>
    </Box>
  );
}

type CSVData = {
  headers: { key: string; label: string }[];
  rows: Record<string, string | number>[];
};

function getCSVData(
  visibleColumns: string[],
  data: AWSCommitmentAllocationDatum[]
): CSVData {
  const tableData = getTableData(data);

  const headers = columnKeys
    .filter((key) => visibleColumns.includes(key))
    .map((key) => ({
      key,
      label: columnLabels[key],
    }));

  const rows = tableData.map((row) => ({
    ...row,
    instanceId: row.instanceId ?? "",
  }));

  return {
    headers,
    rows,
  };
}

function getQueryParamState(
  params: DecodedValueMap<typeof queryParamConfigMap>
) {
  const dateRange =
    params.date_range_start && params.date_range_end
      ? [params.date_range_start, params.date_range_end]
      : getCubeDateRangeFromDurationType(params.duration);

  return {
    duration: params.duration,
    dateRange,
    allocationType: params.al_type ?? AWSCommittedUseAllocationType.ACCOUNT,
    committedUseType: params.type ?? null,
    arn: params.arn ?? null,
    accountID: params.account_id ?? null,
  };
}

function filterAllocations(
  data: AWSCommitmentAllocationDatum[],
  filters: Record<string, string>
) {
  const filterKeys = Object.keys(filters);

  return data.filter((datum) => {
    for (const key of filterKeys) {
      if (key in datum && datum[key] !== filters[key]) {
        return false;
      }
    }

    return true;
  });
}
