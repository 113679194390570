import { UpdateRecommendationsParameters } from "@/api/core/types";
import useGatekeeper from "@/hooks/useGatekeeper";
import LoadingSpinner from "@/ui-lib/components/LoadingSpinner";
import Modal from "@/ui-lib/components/Modal";
import { useTheme } from "@emotion/react";
import { faLock } from "@fortawesome/free-solid-svg-icons";
import { createColumnHelper } from "@tanstack/react-table";
import Table from "@ternary/api-lib/ui-lib/charts/Table/Table";
import Button from "@ternary/api-lib/ui-lib/components/Button";
import Icon from "@ternary/api-lib/ui-lib/components/Icon";
import Box from "@ternary/web-ui-lib/components/Box";
import Flex from "@ternary/web-ui-lib/components/Flex";
import Text from "@ternary/web-ui-lib/components/Text";
import { keyBy } from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import Label from "../../../components/Label";
import { DateHelper } from "../../../lib/dates";
import Checkbox from "../../../ui-lib/components/Checkbox";
import { FormField } from "../../../ui-lib/components/Form";
import LegacyDatePicker from "../../../ui-lib/components/LegacyDatePicker";
import Select from "../../../ui-lib/components/Select";
import getMergeState from "../../../utils/getMergeState";
import copyText from "../copyText";

export const SIDE_DRAWER_WIDTH = "55rem";

type RecommendationType = {
  id: string;
  detailTemplate: string;
  displayName: string;
};

type Recommendation = {
  id: string;
  number: string;
  projectID: string;
  snoozeUntil: string | null;
  state: string;
  typeID: string;
};

type State = {
  status: string;
  snoozeUntil: string | null;
};

interface Props {
  isOpen: boolean;
  loadingSubmit: boolean;
  selectedRecommendations: Recommendation[];
  recommendationTypes: RecommendationType[];
  onClose: () => void;
  onSubmitModification: (
    params: UpdateRecommendationsParameters["paramsArray"]
  ) => void;
}

type TableData = {
  number: string;
  projectID: string;
  status: string;
  type: string | null;
};

const columnHelper = createColumnHelper<TableData>();

export const recommendationStatusText = {
  accepted: copyText.recommendationStatusAccepted,
  asDesigned: copyText.recommendationStatusAsDesigned,
  implemented: copyText.recommendationStatusImplemented,
  inaccurate: copyText.recommendationStatusInaccurate,
  new: copyText.recommendationStatusNew,
  unchanged: copyText.recommendationsBulkEditUnchanged,
};

const statusOptions = Object.keys(recommendationStatusText).map((key) => ({
  value: key,
  label: String(recommendationStatusText[key]),
}));

const defaultState: State = {
  status: "unchanged",
  snoozeUntil: null,
};

export default function RecommendationsBulkEditForm(props: Props) {
  const theme = useTheme();

  const gatekeeper = useGatekeeper();

  const [state, setState] = useState<State>(defaultState);
  const mergeState = getMergeState(setState);

  const now = new DateHelper();

  useEffect(() => {
    if (props.isOpen === false) {
      setState(defaultState);
    }
  }, [props.isOpen]);

  const selectedStatus = statusOptions.find(
    (status) => status.value === state.status
  );

  function canSubmitModifiedRec(): boolean {
    if (!props.selectedRecommendations) return false;

    return (
      state.status !== defaultState.status ||
      state.snoozeUntil !== defaultState.snoozeUntil
    );
  }

  function handleSubmit() {
    if (!props.selectedRecommendations.length) return;

    if (canSubmitModifiedRec()) {
      const updates = props.selectedRecommendations.map((recommendation) => {
        const update: UpdateRecommendationsParameters["paramsArray"][number] = {
          recommendationID: recommendation.id,
        };

        if (
          state.status !== defaultState.status &&
          state.status !== recommendation.state
        ) {
          update.state = state.status;
        }

        if (
          state.snoozeUntil &&
          state.snoozeUntil !== defaultState.snoozeUntil &&
          state.snoozeUntil !== recommendation.snoozeUntil
        ) {
          update.snoozeUntil = state.snoozeUntil;
        }

        return update;
      });

      props.onSubmitModification(updates);
    }
  }

  const columns = useMemo(
    () => [
      columnHelper.accessor("number", {
        header: copyText.recommendationsTableHeaderId,
        size: 50,
      }),
      columnHelper.accessor("type", {
        cell: function renderStatus(cell) {
          const value = cell.getValue();
          return value ? value : "---";
        },
        header: copyText.recommendationsTableHeaderTypeRecommendation,
      }),
      columnHelper.accessor("projectID", {
        header: copyText.recommendationsTableHeaderTypeRecommendation,
      }),
      columnHelper.accessor("status", {
        cell: function renderStatus(cell) {
          return <Label text={cell.getValue()} />;
        },
        header: copyText.recommendationsTableHeaderState,
        meta: { align: "center" },
        size: 130,
      }),
    ],
    []
  );

  const tableData = useMemo((): TableData[] => {
    const recommendationTypesKeyedByID = keyBy(props.recommendationTypes, "id");

    return props.selectedRecommendations.map((recommendation) => ({
      number: recommendation.number,
      projectID: recommendation.projectID,
      status: recommendationStatusText[recommendation.state] as string,
      type:
        recommendationTypesKeyedByID[recommendation.typeID]?.displayName ??
        null,
    }));
  }, [props.recommendationTypes, props.selectedRecommendations]);

  const canUpdate = gatekeeper.canUpdateRecommendations;

  return (
    <Modal
      isOpen={props.isOpen}
      title={
        props.selectedRecommendations.length === 1
          ? copyText.recommendationsBulkEditSingle
          : copyText.recommendationsBulkEditMulti.replace(
              "%COUNT%",
              String(props.selectedRecommendations.length)
            )
      }
      width={SIDE_DRAWER_WIDTH}
      onClose={props.onClose}
    >
      <Flex
        direction="column"
        height={450}
        minWidth={`calc(${SIDE_DRAWER_WIDTH} - ${theme.space_jumbo})`}
        overflow="auto"
      >
        <Box flex="1 0 auto">
          <Table
            columns={columns}
            compact
            data={tableData}
            initialState={{ pagination: { pageSize: 4 } }}
            isLoading={props.loadingSubmit}
            showPagination
          />
        </Box>

        {/* STATUS CONTROLS */}
        <Flex alignItems="center" justifyContent="space-between" width="100%">
          <Box width="48%">
            {!canUpdate && (
              <Box marginRight={theme.space_sm}>
                <Icon icon={faLock} />
              </Box>
            )}
            <FormField label={copyText.recommendationDetailsHeaderChangeStatus}>
              <Box flexGrow={1}>
                <Select
                  disabled={props.loadingSubmit || !canUpdate}
                  menuPlacement="top"
                  options={statusOptions}
                  placeholder={
                    copyText.recommendationDetailsPlaceholderSelectStatus
                  }
                  value={selectedStatus}
                  onChange={(option) =>
                    option && mergeState({ status: option.value })
                  }
                />
              </Box>
            </FormField>
          </Box>
        </Flex>

        {/* SNOOZE CONTROLS */}
        <Flex alignItems="center" minHeight={theme.space_xxl}>
          {canUpdate ? (
            <Checkbox
              checked={Boolean(state.snoozeUntil)}
              disabled={props.loadingSubmit || !canUpdate}
              label={
                state.snoozeUntil
                  ? copyText.recommendationDetailsSnoozeLabel
                  : copyText.recommendationDetailsSnoozeToggle
              }
              onChange={(e) => {
                if (!e.target.checked) {
                  mergeState({ snoozeUntil: null });
                } else {
                  mergeState({ snoozeUntil: now.date.toISOString() });
                }
              }}
            />
          ) : (
            <>
              <Box marginRight={theme.space_sm}>
                <Icon icon={faLock} />
              </Box>
              <Text marginBottom="0 !important">
                {state.snoozeUntil
                  ? copyText.recommendationDetailsSnoozeLabel
                  : copyText.recommendationDetailsSnoozeToggle}
              </Text>
            </>
          )}
          {state.snoozeUntil && (
            <Box marginLeft={theme.space_md}>
              <LegacyDatePicker
                dateFormat="MM/dd/yyyy"
                disabled={props.loadingSubmit || !canUpdate}
                minDate={now.date}
                selected={
                  typeof state.snoozeUntil === "string"
                    ? new Date(state.snoozeUntil)
                    : null
                }
                onChange={(date) => {
                  mergeState({
                    snoozeUntil: date ? date.toISOString() : null,
                  });
                }}
              />
            </Box>
          )}
        </Flex>

        <Flex justifyContent="flex-end">
          <Button
            disabled={props.loadingSubmit}
            secondary
            onClick={props.onClose}
          >
            {copyText.actionClose}
          </Button>
          <Button
            disabled={
              !canSubmitModifiedRec() || props.loadingSubmit || !canUpdate
            }
            marginLeft={theme.space_md}
            primary
            width={100}
            onClick={handleSubmit}
          >
            {props.loadingSubmit ? <LoadingSpinner /> : copyText.actionSubmit}
          </Button>
        </Flex>
      </Flex>
    </Modal>
  );
}
