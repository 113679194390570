import { TableLegacy } from "@/ui-lib/components/Table";
import { useTheme } from "@emotion/react";
import { faCircleInfo, faLeaf } from "@fortawesome/free-solid-svg-icons";
import Button from "@ternary/api-lib/ui-lib/components/Button";
import Tooltip from "@ternary/api-lib/ui-lib/components/Tooltip";
import Flex from "@ternary/web-ui-lib/components/Flex";
import Icon from "@ternary/web-ui-lib/components/Icon";
import Text from "@ternary/web-ui-lib/components/Text";
import {
  formatCurrency,
  formatNumber,
} from "@ternary/web-ui-lib/utils/formatNumber";
import prettyBytes from "pretty-bytes";
import React, { useMemo } from "react";
import { Column } from "react-table";
import copyText from "../../copyText";
import { Bucket, StorageTableData } from "../types";

type TableData = {
  buckets: Bucket[];
  bucketCount: number;
  egress: number;
  ingress: number;
  lowCarbon: boolean;
  network: number;
  operations: number;
  other: number;
  processing: number;
  projectId: string;
  recommendation: string[];
  region: string;
  storage: number;
};

interface Props {
  isLoading: boolean;
  resources: StorageTableData[];
  selectedResourceID?: string;
  onInteraction: (interaction: GCPStorageResourceTable.Interaction) => void;
}

export function GCPStorageResourceTable(props: Props): JSX.Element {
  const theme = useTheme();

  function formatSelectCategory(type: string, value: string) {
    return (
      <Text
        color={theme.primary_color_text}
        cursor="pointer"
        onClick={() =>
          props.onInteraction({
            type: GCPStorageResourceTable.INTERACTION_FILTERS_CHANGED,
            filter: {
              type,
              value,
            },
          })
        }
      >
        {value}
      </Text>
    );
  }

  function formatLowCarbon(isLowCarbon: boolean) {
    return isLowCarbon ? (
      <Tooltip
        width={"200px"}
        content={copyText.gcpStorageResourceTableLowCarbonToolTip}
      >
        <Icon
          clickable
          color={theme.feedback_positive}
          icon={faLeaf}
          onClick={() =>
            props.onInteraction({
              type: GCPStorageResourceTable.INTERACTION_FILTERS_CHANGED,
              filter: {
                type: "carbon",
                value: isLowCarbon.toString(),
              },
            })
          }
        />
      </Tooltip>
    ) : (
      <Tooltip
        width={"150px"}
        content={copyText.gcpStorageResourceTableHighCarbonToolTip}
      >
        <Icon
          clickable
          color={theme.feedback_neutral}
          icon={faLeaf}
          onClick={() =>
            props.onInteraction({
              type: GCPStorageResourceTable.INTERACTION_FILTERS_CHANGED,
              filter: {
                type: "carbon",
                value: null,
              },
            })
          }
        />
      </Tooltip>
    );
  }

  function selectSubTable(value: Bucket[]): JSX.Element {
    return (
      <Flex alignItems="center">
        <Tooltip content={copyText.gcpStorageSubTableViewBuckets}>
          <Button
            onClick={() =>
              props.onInteraction({
                type: GCPStorageResourceTable.INTERACTION_SELECT_BUCKETS_CLICKED,
                selectedBuckets: value,
              })
            }
            iconStart={<Icon icon={faCircleInfo} />}
            primary
            size="tiny"
          />
        </Tooltip>
      </Flex>
    );
  }

  const columns = useMemo(
    (): Column<TableData>[] => [
      {
        accessor: "lowCarbon",
        Cell: ({ value }) => formatLowCarbon(value),
        width: 25,
      },
      {
        accessor: "buckets",
        Cell: ({ value }) => selectSubTable(value),
        width: 40,
      },
      {
        accessor: "projectId",
        Header: copyText.gcpStorageResourceTableProject,
        Cell: ({ value }) => formatSelectCategory("projectID", value),
        width: 100,
      },
      {
        accessor: "region",
        Header: copyText.gcpStorageResourceTableRegion,
        Cell: ({ value }) => formatSelectCategory("region", value),
        width: 80,
      },
      {
        accessor: "bucketCount",
        align: "center",
        Header: copyText.gcpStorageResourceTableBuckets,
        width: 80,
      },
      {
        accessor: "storage",
        align: "center",
        Header: copyText.gcpStorageResourceTableStorage,
        Cell: ({ value }) => <>{formatCost(value)}</>,
        width: 80,
      },
      {
        accessor: "processing",
        align: "center",
        Header: copyText.gcpStorageResourceTableProcessing,
        Cell: ({ value }) => <>{formatCost(value)}</>,
        width: 90,
      },
      {
        accessor: "network",
        align: "center",
        Header: copyText.gcpStorageResourceTableNetwork,
        Cell: ({ value }) => <>{formatCost(value)}</>,
        width: 80,
      },
      {
        accessor: "other",
        align: "center",
        Header: copyText.gcpStorageResourceTableOther,
        Cell: ({ value }) => <>{formatCost(value)}</>,
        width: 80,
      },
      {
        accessor: "ingress",
        align: "center",
        Header: copyText.gcpStorageResourceTableIngress,
        Cell: ({ value }) => <>{prettyBytes(value)}</>,

        width: 80,
      },
      {
        accessor: "egress",
        align: "center",
        Header: copyText.gcpStorageResourceTableEgress,
        Cell: ({ value }) => <>{prettyBytes(value)}</>,

        width: 80,
      },
      {
        accessor: "operations",
        align: "center",
        Header: copyText.gcpStorageResourceTableOperation,
        Cell: ({ value }) => <>{formatNumber(value)}</>,

        width: 80,
      },
      {
        accessor: "recommendation",
        align: "center",
        Header: copyText.gcpStorageResourceTableRecommendation,
        Cell: ({ value }) => <>{value.length}</>,

        width: 80,
      },
    ],
    [props.resources]
  );

  const data: TableData[] = useMemo(() => {
    const tableData = props.resources.map((item) => {
      return {
        buckets: item.buckets,
        bucketCount: item.bucketCount,
        projectId: item.projectID ?? "null",
        region: item.region ?? "null",
        lowCarbon: item.lowCarbon,
        network: item.network,
        processing: item.processing,
        storage: item.storage,
        other: item.other,
        ingress: item.ingress,
        egress: item.egress,
        operations: item.operations,
        recommendation: item.recommendation,
      };
    });
    return tableData;
  }, [props.resources]);

  return (
    <TableLegacy
      columns={columns}
      data={data}
      isLoading={props.isLoading}
      selectedRowID={props.selectedResourceID}
      showPagination
      sortable
    />
  );
}

const formatCost = (cost: number | null) => {
  if (cost === null) return copyText.cloudSQLResourceTableLabelNotAvailable;
  return formatCurrency({ number: cost });
};

GCPStorageResourceTable.INTERACTION_FILTERS_CHANGED =
  `GCPStorageResourceTable.INTERACTION_FILTERS_CHANGES` as const;

GCPStorageResourceTable.INTERACTION_SELECT_BUCKETS_CLICKED =
  `GCPStorageResourceTable.INTERACTION_SELECT_BUCKETS_CLICKED` as const;

interface InteractionFiltersChanged {
  type: typeof GCPStorageResourceTable.INTERACTION_FILTERS_CHANGED;
  filter: {
    type: string;
    value: string | null;
  };
}

interface InteractionSelectBucketsClicked {
  type: typeof GCPStorageResourceTable.INTERACTION_SELECT_BUCKETS_CLICKED;
  selectedBuckets: Bucket[];
}

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace GCPStorageResourceTable {
  export type Interaction =
    | InteractionFiltersChanged
    | InteractionSelectBucketsClicked;
}

export default GCPStorageResourceTable;
