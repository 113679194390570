import { useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import { uniq } from "lodash";
import React, { PropsWithChildren } from "react";
import { LegendProps } from "recharts";
import Box from "../components/Box";
import Flex from "../components/Flex";
import ChartDataManager from "../utils/ChartDataManager";
import { RAW_DIFFERENCE_KEY, getIsDashedMeasure } from "./utils";

type Props = {
  dataManager: ChartDataManager;
  height?: number;
  isServer?: boolean;
  readableKeys?: { [key: string]: string };
  onInteraction: (interaction: LegendTable.Interaction) => void;
} & LegendProps;

function LegendTable(props: Props) {
  const theme = useTheme();

  if (!props.payload) return null;

  const groupingTable = props.dataManager.getGroupingTable();

  const colorKeyedByChartKey: { [chartKey: string]: string } = {};

  props.payload.map(({ value, color = theme.background_color_disabled }) => {
    if (typeof value !== "string") return;

    const chartKey = value;
    colorKeyedByChartKey[chartKey] = color;
  });

  const getReadableKey = (key: string) => {
    const readableKeys = props.readableKeys ?? {};
    if (key in readableKeys) return readableKeys[key];
    return key;
  };

  function getExcludedKeysAfterGroupingToggle(chartKeysInGrouping: string[]) {
    const removeAll = chartKeysInGrouping.some((chartKey) =>
      props.dataManager.isExcluded(chartKey)
    );

    if (removeAll) {
      return props.dataManager.excludedChartKeys.filter(
        (currentChartKey) => !chartKeysInGrouping.includes(currentChartKey)
      );
    }

    return uniq([
      ...props.dataManager.excludedChartKeys,
      ...chartKeysInGrouping,
    ]);
  }

  function getExcludedKeysAfterGroupingMeasureToggle(chartKey: string) {
    return props.dataManager.isExcluded(chartKey)
      ? props.dataManager.excludedChartKeys.filter((key) => key !== chartKey)
      : [...props.dataManager.excludedChartKeys, chartKey];
  }

  const scrollWrapper = (content: JSX.Element) =>
    props.isServer ? (
      <Box overflowY="visible">{content}</Box>
    ) : (
      <Flex
        justifyContent="center"
        maxHeight={props.height ?? 80}
        position="relative"
        width="100%"
      >
        <Box maxHeight="100%" width="100%" overflowX="auto" overflowY="auto">
          {content}
        </Box>
      </Flex>
    );

  return scrollWrapper(
    <StyledTable>
      <thead>
        <tr>
          {groupingTable.measureHeaders
            .filter((measure) => measure.name !== RAW_DIFFERENCE_KEY)
            .map((measure, index) => (
              <HeaderCell align="center" key={`${measure.name}-${index}`}>
                {getReadableKey(measure.name)}
              </HeaderCell>
            ))}

          {groupingTable.dimensionHeaders.map((dimension, index) => (
            <HeaderCell align="left" key={`${dimension.name}-${index}`}>
              {getReadableKey(dimension.name)}
            </HeaderCell>
          ))}
        </tr>
      </thead>

      <tbody>
        {groupingTable.groupingKeys.map((groupingKey, rowIndex) => {
          const dimensionRow = groupingTable.dimensionRows[rowIndex];
          const measureRow = groupingTable.measureRows[rowIndex];

          return (
            <Row key={dimensionRow.map(({ value }) => value).join(" - ")}>
              {measureRow.map(({ measure, chartKey }) => {
                if (measure.name === RAW_DIFFERENCE_KEY) return null;

                const background = getIsDashedMeasure(measure.name)
                  ? `repeating-linear-gradient(45deg,${colorKeyedByChartKey[chartKey]}, ${colorKeyedByChartKey[chartKey]} 5px, white 5px, white 10px)`
                  : colorKeyedByChartKey[chartKey];

                return (
                  <MeasureCell
                    key={`${groupingKey}-${measure.name}`}
                    onClick={() =>
                      props.onInteraction({
                        type: LegendTable.INTERACTION_CHART_EXCLUSION_CHANGED,
                        excludedChartKeys:
                          getExcludedKeysAfterGroupingMeasureToggle(chartKey),
                      })
                    }
                  >
                    <Flex alignItems="center" justifyContent="center">
                      <Box
                        background={background}
                        borderRadius="3px"
                        height={theme.fontSize_small}
                        width={12}
                      />
                    </Flex>
                  </MeasureCell>
                );
              })}

              {dimensionRow.map((dimensionWithValue, dimensionIndex) => (
                <DimensionCell
                  key={`${dimensionWithValue.value}-${dimensionIndex}`}
                  onClick={() => {
                    const chartKeysInGrouping =
                      props.dataManager.getChartKeysFromGroupingKey(
                        groupingKey
                      );

                    props.onInteraction({
                      type: LegendTable.INTERACTION_CHART_EXCLUSION_CHANGED,
                      excludedChartKeys:
                        getExcludedKeysAfterGroupingToggle(chartKeysInGrouping),
                    });
                  }}
                >
                  {dimensionWithValue.value}
                </DimensionCell>
              ))}
            </Row>
          );
        })}
      </tbody>
    </StyledTable>
  );
}

LegendTable.INTERACTION_CHART_EXCLUSION_CHANGED =
  `LegendTable.INTERACTION_CHART_EXCLUSION_CHANGED` as const;

interface InteractionChartExclusionChanged {
  type: typeof LegendTable.INTERACTION_CHART_EXCLUSION_CHANGED;
  excludedChartKeys: string[];
}

// eslint-disable-next-line @typescript-eslint/no-namespace
namespace LegendTable {
  export type Interaction = InteractionChartExclusionChanged;
}

export default LegendTable;

const StyledTable = styled("table")(({ theme }) => ({
  minWidth: "100%",
  borderSpacing: 0,
  fontSize: theme.fontSize_small,
}));

type StyledTHProps = { align?: "center" | "left" | "right" };
const StyledTH = styled("th")<StyledTHProps>(({ align, theme }) => {
  const verticalPaddingMax = theme.space_xxs;
  const verticalPaddingMin = 2;

  return {
    backgroundColor: theme.chart_legend_header,
    paddingLeft: theme.space_sm,
    paddingRight: theme.space_sm,
    position: "sticky",
    top: `calc(${verticalPaddingMax} * -2)`,
    whiteSpace: "nowrap",

    "> div": {
      minHeight: `calc(${theme.h6_fontSize} + ${verticalPaddingMin}px + ${verticalPaddingMax} * 2)`,
      display: "flex",
      alignItems: "center",
      justifyContent: (() => {
        switch (align) {
          case "center":
            return "center";
          case "left":
            return "flex-start";
          case "right":
            return "flex-end";
        }
      })(),
    },

    "> div > div": {
      color: theme.text_color,
      fontSize: theme.h6_fontSize,
      fontWeight: theme.fontWeight_semiBold,
      position: "sticky",
      top: verticalPaddingMin,
    },
  };
});

function HeaderCell(props: PropsWithChildren<StyledTHProps>) {
  return (
    <StyledTH align={props.align}>
      <Box>
        <Box>{props.children}</Box>
      </Box>
    </StyledTH>
  );
}

const Cell = styled("td")(({ theme }) => ({
  color: theme.text_color,
  paddingBottom: theme.space_xxs,
  paddingLeft: theme.space_sm,
  paddingRight: theme.space_sm,
  paddingTop: theme.space_xxs,
  whiteSpace: "nowrap",
}));

type CellProps = {
  onClick: () => void;
};

const DimensionCell = (props: PropsWithChildren<CellProps>) => (
  <Cell className="dimension" onClick={props.onClick}>
    {props.children}
  </Cell>
);

const MeasureCell = (props: PropsWithChildren<CellProps>) => (
  <Cell className="measure" onClick={props.onClick}>
    {props.children}
  </Cell>
);

const Row = styled("tr")(({ theme }) => ({
  "&:hover:has(> .dimension:hover)": {
    backgroundColor: theme.background_color_disabled,
    cursor: "pointer",
  },
  "& > .measure:hover": {
    backgroundColor: theme.background_color_disabled,
    cursor: "pointer",
  },
}));
