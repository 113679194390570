import useGetUsersByTenantID from "@/api/core/hooks/useGetUsersByTenantID";
import SideDrawer from "@/components/SideDrawer";
import useAuthenticatedUser from "@/hooks/useAuthenticatedUser";
import { useMatchPath } from "@/lib/react-router";
import Tabs from "@/ui-lib/components/Tabs";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { useQueryClient } from "@tanstack/react-query";
import { CaseStatus } from "@ternary/api-lib/constants/enums";
import { actions } from "@ternary/api-lib/telemetry";
import Button from "@ternary/api-lib/ui-lib/components/Button";
import { useCaseManagementStore } from "@ternary/api-lib/ui-lib/context/CaseManagementStoreProvider";
import Box from "@ternary/web-ui-lib/components/Box";
import Icon from "@ternary/web-ui-lib/components/Icon";
import React, { useEffect, useState } from "react";
import paths, { locationStateKeyedByPath } from "../../../constants/paths";
import { useActivityTracker } from "../../../context/ActivityTrackerProvider";
import useGatekeeper from "../../../hooks/useGatekeeper";
import { AlertType, postAlert } from "../../../utils/alerts";
import getMergeState from "../../../utils/getMergeState";
import useGetJiraIntegrationByTenantID from "../../admin/hooks/useGetJiraIntegrationByTenantID";
import copyText from "../copyText";
import queryKeys from "../hooks/queryKeys";
import useCreateCase from "../hooks/useCreateCase";
import useCreateCaseComment from "../hooks/useCreateCaseComment";
import useCreateInsightsCase from "../hooks/useCreateInsightsCase";
import useGetCasesByTenantID from "../hooks/useGetCasesByTenantID";
import AddResourceCommentToCaseForm from "./AddResourceCommentToCaseForm";
import CreateCaseForm from "./CreateCaseForm";

type Interaction =
  | AddResourceCommentToCaseForm.Interaction
  | CreateCaseForm.Interaction;

const TAB_ADD = "ADD";
const TAB_CREATE = "CREATE";

interface Props {
  isEnabled: boolean;
}

interface State {
  key: number;
  isOpen: boolean;
  selectedTab: string;
}

export default function CaseFormContainer(props: Props): JSX.Element {
  const activityTracker = useActivityTracker();
  const authenticatedUser = useAuthenticatedUser();
  const currentPath = useMatchPath();
  const gatekeeper = useGatekeeper();
  const queryClient = useQueryClient();
  const resourceSelectionModeState = useCaseManagementStore();

  const locationState = locationStateKeyedByPath[currentPath];

  const enableAddToCaseTab =
    typeof locationState?.enableCaseManagement === "object"
      ? locationState?.enableCaseManagement?.enableAddToCase === true
      : false;

  //
  // State
  //

  const initialState: State = {
    key: 0,
    isOpen: false,
    selectedTab: gatekeeper.canCreateCase ? TAB_CREATE : TAB_ADD,
  };

  const [state, setState] = useState(initialState);
  const mergeState = getMergeState(setState);

  //
  // Queries
  //

  const {
    data: cases = [],
    isLoading: isLoadingCases,
    refetch: refetchCases,
  } = useGetCasesByTenantID(authenticatedUser.tenant.id);

  const { data: users = [], isLoading: isLoadingUsers } = useGetUsersByTenantID(
    authenticatedUser.tenant.id
  );

  const { data: jiraIntegration } = useGetJiraIntegrationByTenantID(
    authenticatedUser.tenant.fsDocID
  );

  //
  // Mutations
  //

  const { isPending: isCreatingCase, mutate: createCase } = useCreateCase({
    onError: () => {
      postAlert({
        message: copyText.errorCreatingCaseMessage,
        type: AlertType.ERROR,
      });
    },
    onMutate: () => {
      activityTracker.captureAction(actions.CREATE_CASE);
    },
    onSuccess: (caseID) => {
      setState((currentState) => ({
        ...currentState,
        isOpen: false,
        key: currentState.key + 1,
      }));

      resourceSelectionModeState.reset();

      refetchCases();

      postAlert({
        message: copyText.successCreatingCaseMessage,
        shortcut: paths._case.replace(":caseID", caseID),
        type: AlertType.SUCCESS,
      });
    },
  });

  const { isPending: isCreatingInsightCase, mutate: createInsightsCase } =
    useCreateInsightsCase({
      onError: () => {
        postAlert({
          message: copyText.errorCreatingCaseMessage,
          type: AlertType.ERROR,
        });
      },
      onMutate: () => {
        activityTracker.captureAction(actions.CREATE_INSIGHTS_CASE);
      },
      onSuccess: (caseID) => {
        setState((currentState) => ({
          ...currentState,
          isOpen: false,
          key: currentState.key + 1,
        }));

        resourceSelectionModeState.reset();

        refetchCases();

        postAlert({
          message: copyText.successCreatingCaseMessage,
          shortcut: paths._case.replace(":caseID", caseID),
          type: AlertType.SUCCESS,
        });
      },
    });

  const { isPending: isCreatingCaseActivity, mutate: createCaseActivity } =
    useCreateCaseComment({
      onError: () => {
        postAlert({
          message: copyText.errorCreatingCaseCommentMessage,
          type: AlertType.ERROR,
        });
      },
      onMutate: () => {
        activityTracker.captureAction(actions.CREATE_CASE_ACTIVITY);
      },
      onSuccess: (_, { caseID }) => {
        setState((currentState) => ({
          ...currentState,
          isOpen: false,
          key: currentState.key + 1,
        }));

        resourceSelectionModeState.reset();

        refetchCases();

        queryClient.invalidateQueries({ queryKey: queryKeys.case(caseID) });

        postAlert({
          message: copyText.successCreatingCaseCommentMessage,
          shortcut: paths._case.replace(":caseID", caseID),
          type: AlertType.SUCCESS,
        });
      },
    });

  //
  // Interaction Handlers
  //

  function handleChangeTab(selectedTab: string) {
    mergeState({ selectedTab });

    resourceSelectionModeState.reset();
  }

  function handleClose() {
    mergeState({ isOpen: false });
    resourceSelectionModeState.reset();
  }

  function handleInteraction(interaction: Interaction) {
    switch (interaction.type) {
      case AddResourceCommentToCaseForm.INTERACTION_SUBMIT_BUTTON_CLICKED: {
        createCaseActivity({
          caseID: interaction.caseID,
          text: interaction.text,
          ...(resourceSelectionModeState.selectedResourceID
            ? { reportID: resourceSelectionModeState.selectedResourceID }
            : {}),
        });
        break;
      }
      case CreateCaseForm.INTERACTION_ADD_RESOURCE_BUTTON_CLICKED: {
        resourceSelectionModeState.set({
          isResourceSelectionMode: true,
          isSideDrawerOpen: false,
        });
        break;
      }
      case CreateCaseForm.INTERACTION_CANCEL_BUTTON_CLICKED: {
        mergeState({ isOpen: false });
        break;
      }
      case CreateCaseForm.INTERACTION_SUBMIT_BUTTON_CLICKED: {
        if (!resourceSelectionModeState.selectedResourceType) {
          return;
        }

        if (
          !resourceSelectionModeState.selectedResourceID &&
          resourceSelectionModeState.reportSnapshot
        ) {
          createInsightsCase({
            tenantID: authenticatedUser.tenant.id,
            description: interaction.description,
            name: interaction.name,
            reportSnapshot: resourceSelectionModeState.reportSnapshot,
            resourceType: resourceSelectionModeState.selectedResourceType,
            type: interaction.caseType,
            ...(resourceSelectionModeState.selectedResourceContext
              ? { context: resourceSelectionModeState.selectedResourceContext }
              : {}),
            ...(interaction.assigneeIDs
              ? { assigneeIDs: interaction.assigneeIDs }
              : {}),
            ...(interaction.isFollowing
              ? { followerIDs: [authenticatedUser.id] }
              : {}),
          });
          break;
        }

        if (!resourceSelectionModeState.selectedResourceID) {
          return;
        }

        createCase({
          tenantID: authenticatedUser.tenant.id,
          resourceID: resourceSelectionModeState.selectedResourceID,
          description: interaction.description,
          name: interaction.name,
          resourceType: resourceSelectionModeState.selectedResourceType,
          type: interaction.caseType,
          ...(resourceSelectionModeState.selectedResourceContext
            ? { context: resourceSelectionModeState.selectedResourceContext }
            : {}),
          ...(interaction.assigneeIDs
            ? { assigneeIDs: interaction.assigneeIDs }
            : {}),
          ...(interaction.isFollowing
            ? { followerIDs: [authenticatedUser.id] }
            : {}),
          ...(interaction.linkToJira
            ? { linkToJira: interaction.linkToJira }
            : {}),
        });
        break;
      }
    }
  }

  //
  // Side Effects
  //

  useEffect(() => {
    if (state.selectedTab === TAB_ADD && !enableAddToCaseTab) {
      mergeState({ selectedTab: TAB_CREATE });
    }
  }, [currentPath]);

  //
  // Render
  //

  const openCases = cases.filter((_case) => _case.status === CaseStatus.OPEN);

  const tabs = [
    {
      component: (
        <CreateCaseForm
          key={state.key}
          hasJiraIntegration={!!jiraIntegration}
          isLoading={isLoadingUsers}
          isProcessing={isCreatingCase || isCreatingInsightCase}
          users={users}
          onInteraction={handleInteraction}
        />
      ),
      disabled: !gatekeeper.canCreateCase,
      label: copyText.tabCreateCase,
      value: TAB_CREATE,
    },
    ...(enableAddToCaseTab
      ? [
          {
            component: (
              <AddResourceCommentToCaseForm
                key={state.key}
                cases={openCases}
                isLoading={isLoadingCases}
                isProcessing={isCreatingCaseActivity}
                onClose={() => mergeState({ isOpen: false })}
                onInteraction={handleInteraction}
              />
            ),
            disabled: !gatekeeper.canCreateCaseComment,
            label: copyText.tabAddToCase,
            value: TAB_ADD,
          },
        ]
      : []),
  ];

  return (
    <>
      <Box>
        {props.isEnabled &&
          !resourceSelectionModeState.isResourceSelectionMode && (
            <Button
              iconStart={<Icon icon={faPlus} />}
              secondary
              size="small"
              onClick={() => mergeState({ isOpen: true })}
            >
              {copyText.buttonLabelCreateCase}
            </Button>
          )}
      </Box>
      <SideDrawer
        isOpen={
          (state.isOpen &&
            !resourceSelectionModeState.isResourceSelectionMode) ||
          resourceSelectionModeState.isSideDrawerOpen
        }
        title={
          state.selectedTab === TAB_CREATE
            ? copyText.tabCreateCase
            : copyText.tabAddToCase
        }
        renderContent={() => (
          <Tabs
            activeValue={state.selectedTab}
            tabs={tabs}
            onSelect={handleChangeTab}
          />
        )}
        width="500px"
        onClose={handleClose}
      />
    </>
  );
}
