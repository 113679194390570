import Chip from "@/ui-lib/components/Chip";
import LoadingSpinner from "@/ui-lib/components/LoadingSpinner";
import TextInput from "@/ui-lib/components/TextInput";
import { useTheme } from "@emotion/react";
import { faFileExport, faSearch } from "@fortawesome/free-solid-svg-icons";
import Button from "@ternary/api-lib/ui-lib/components/Button";
import Box from "@ternary/web-ui-lib/components/Box";
import Flex from "@ternary/web-ui-lib/components/Flex";
import Icon from "@ternary/web-ui-lib/components/Icon";
import { format } from "date-fns";
import React from "react";
import { CSVLink } from "react-csv";
import copyText from "../../copyText";
import { EBStoreGroupFilters } from "../types";

type CSVData = {
  headers: { key: string; label: string }[];
  rows: Record<string, string | number>[];
};

type Props = {
  ebStoreGroupFilters: EBStoreGroupFilters;
  csvData: CSVData;
  debouncedSearchText: string;
  searchText: string;
  onInteraction: (
    interaction: AWSStorageEBSGroupTableControls.Interaction
  ) => void;
};

function AWSStorageEBSGroupTableControls(props: Props) {
  const theme = useTheme();

  return (
    <Flex alignItems="center" minHeight={30}>
      <Flex flex="1 0 0">
        {props.ebStoreGroupFilters.billPayerAccountId !== null ? (
          <Chip
            label={copyText.ebsTableGroupHeader_billPayerAccountId}
            text={props.ebStoreGroupFilters.billPayerAccountId}
            onClick={() =>
              props.onInteraction({
                type: AWSStorageEBSGroupTableControls.INTERACTION_REMOVE_FILTER_CLICKED,
                filterKey: "billPayerAccountId",
              })
            }
          />
        ) : null}

        {props.ebStoreGroupFilters.region !== null ? (
          <Chip
            label={copyText.ebsTableGroupHeader_region}
            text={props.ebStoreGroupFilters.region}
            onClick={() =>
              props.onInteraction({
                type: AWSStorageEBSGroupTableControls.INTERACTION_REMOVE_FILTER_CLICKED,
                filterKey: "region",
              })
            }
          />
        ) : null}
      </Flex>

      <Box marginHorizontal={theme.space_md}>
        <TextInput
          iconEnd={
            props.searchText === props.debouncedSearchText ||
            props.searchText === "" ? (
              <Icon color={theme.text_color_secondary} icon={faSearch} />
            ) : (
              <LoadingSpinner />
            )
          }
          placeholder={copyText.searchInputPlaceholder}
          size="large"
          value={props.searchText}
          onChange={(e) =>
            props.onInteraction({
              type: AWSStorageEBSGroupTableControls.INTERACTION_SEARCH_TEXT_UPDATED,
              searchText: e.target.value,
            })
          }
        />
      </Box>

      <Box>
        <CSVLink
          data={props.csvData.rows}
          headers={props.csvData.headers}
          filename={`EBS-groups-${format(new Date(), "MM-dd-yyyy")}`}
        >
          <Button
            iconStart={<Icon color="inherit" icon={faFileExport} />}
            secondary
            size="small"
          >
            {copyText.exportButtonLabel}
          </Button>
        </CSVLink>
      </Box>
    </Flex>
  );
}

AWSStorageEBSGroupTableControls.INTERACTION_REMOVE_FILTER_CLICKED =
  `AWSStorageEBSGroupTableControls.INTERACTION_REMOVE_FILTER_CLICKED` as const;

AWSStorageEBSGroupTableControls.INTERACTION_SEARCH_TEXT_UPDATED =
  `AWSStorageEBSGroupTableControls.INTERACTION_SEARCH_TEXT_UPDATED` as const;

interface InteractionRemoveFilterClicked {
  type: typeof AWSStorageEBSGroupTableControls.INTERACTION_REMOVE_FILTER_CLICKED;
  filterKey: keyof EBStoreGroupFilters;
}
interface InteractionSearchTextUpdated {
  type: typeof AWSStorageEBSGroupTableControls.INTERACTION_SEARCH_TEXT_UPDATED;
  searchText: string;
}
// eslint-disable-next-line @typescript-eslint/no-namespace
namespace AWSStorageEBSGroupTableControls {
  export type Interaction =
    | InteractionRemoveFilterClicked
    | InteractionSearchTextUpdated;
}
export default AWSStorageEBSGroupTableControls;
