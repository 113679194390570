import { useTheme } from "@emotion/react";
import Box from "@ternary/api-lib/ui-lib/components/Box";
import Text from "@ternary/web-ui-lib/components/Text";
import React from "react";
import copyText from "../common.copyText";
import Grid from "../ui-lib/components/Grid";

type DimensionValue = {
  key: string;
  value: string | null;
};

type Props = {
  dimensionGrouping: DimensionValue[];
};

export default function GroupingTooltipContent(props: Props): JSX.Element {
  const theme = useTheme();

  return (
    <Grid
      gridColumnGap={theme.space_sm}
      gridRowGap={theme.space_xs}
      gridTemplateColumns="auto auto"
    >
      <Box>
        <Text
          align="left"
          bold
          color={theme.tooltip_text_color}
          fontSize={theme.fontSize_small}
        >
          {copyText.costAlertToolTipTableHeaderName}
        </Text>
      </Box>
      <Box>
        <Text
          align="left"
          bold
          color={theme.tooltip_text_color}
          fontSize={theme.fontSize_small}
        >
          {copyText.costAlertToolTipTableHeaderValue}
        </Text>
      </Box>
      {props.dimensionGrouping.map((dimension) => {
        return (
          <>
            <Box paddingRight={theme.space_sm}>
              <Text align="left" color={theme.tooltip_text_color}>
                {dimension.key}
              </Text>
            </Box>
            <Box>
              <Text truncate align="left" color={theme.tooltip_text_color}>
                {dimension.value}
              </Text>
            </Box>
          </>
        );
      })}
    </Grid>
  );
}
