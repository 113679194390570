import Form from "@/ui-lib/components/Form";
import LoadingSpinner from "@/ui-lib/components/LoadingSpinner";
import Modal from "@/ui-lib/components/Modal";
import Tabs from "@/ui-lib/components/Tabs";
import getMergeState from "@/utils/getMergeState";
import { useTheme } from "@emotion/react";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import {
  AzureExportType,
  AzureIntegrationType,
  CloudCapability,
} from "@ternary/api-lib/constants/enums";
import Button from "@ternary/api-lib/ui-lib/components/Button";
import Flex from "@ternary/web-ui-lib/components/Flex";
import Icon from "@ternary/web-ui-lib/components/Icon";
import Text from "@ternary/web-ui-lib/components/Text";
import { isEqual } from "lodash";
import React, { ChangeEvent, FormEvent, useState } from "react";
import { validate as isUUID } from "uuid";
import copyText from "../../copyText";
import { AzureBillingFields } from "../../types";
import AzureReportsForm from "./AzureBillingExport";
import AzureFormBasic from "./AzureFormBasic";
import { AzureIntegration, BillingExport } from "./types";

interface Props {
  isLoading: boolean;
  selectedCloud?: AzureIntegration;
  onInteraction: (interaction: AzureIntegrationForm.Interaction) => void;
}

interface State {
  //  S3 Bucket Tab
  billingExportFields: AzureBillingFields[];

  // Basic Tab
  appIDInput: string;
  azureTypeInput: AzureIntegrationType;
  directoryIDInput: string;
  nameInput: string;

  // Tab State
  isUpdate: boolean;
  tab: string;
}

const initialState: State = {
  // S3 Bucket tab's state
  billingExportFields: [
    {
      serviceURLInput: "",
      storageContainerInput: "",
      storagePrefixInput: "",
      strictStoragePrefixInput: "false",
      startDateInput: "",
      endDateInput: "",
      dateColumnInput: "",
      exportTypeInput: AzureExportType.ACTUALS,
    },
  ],
  // Basic tab state
  appIDInput: "",
  nameInput: "",
  directoryIDInput: "",
  isUpdate: false,
  tab: "basic",
  azureTypeInput: AzureIntegrationType.AUTO,
};

export function AzureIntegrationForm(props: Props): JSX.Element {
  const theme = useTheme();

  const selectedCloud = props.selectedCloud;
  const [state, setState] = useState<State>(
    selectedCloud
      ? {
          ...initialState,
          billingExportFields: selectedCloud.config.billingExports
            ? selectedCloud.config.billingExports.map((billingExport) => ({
                dateColumnInput: billingExport.dateColumn ?? "",
                endDateInput: billingExport.endDate ?? "",
                exportTypeInput:
                  billingExport.exportType ?? AzureExportType.ACTUALS,
                serviceURLInput: billingExport.serviceURL,
                startDateInput: billingExport.startDate ?? "",
                storageContainerInput: billingExport.storageContainer,
                storagePrefixInput: billingExport.storagePrefix ?? "",
                strictStoragePrefixInput:
                  billingExport.strictStoragePrefix === true ? "true" : "false",
              }))
            : initialState.billingExportFields,
          appIDInput: selectedCloud.config.appID ?? "",
          isUpdate: true,
          nameInput: selectedCloud.name,
          directoryIDInput: selectedCloud.config.directoryID ?? "",
          azureTypeInput:
            selectedCloud.config.type ?? AzureIntegrationType.AUTO,
        }
      : initialState
  );
  const mergeState = getMergeState(setState);

  const containers = [
    {
      component: (
        <AzureFormBasic
          nameInput={state.nameInput}
          appIDInput={state.appIDInput}
          directoryIDInput={state.directoryIDInput}
          azureTypeInput={state.azureTypeInput}
          onUpdate={(event: ChangeEvent<HTMLInputElement>) =>
            mergeState({ [`${event.target.name}Input`]: event.target.value })
          }
          isUpdate={state.isUpdate}
        />
      ),
      label: copyText.cloudAdminBasicInfo,
      value: "basic",
    },
    {
      component: (
        <AzureReportsForm
          billingExportFields={state.billingExportFields}
          onDelete={(index: number) =>
            setState((currentState) => {
              const billingExport = [...currentState.billingExportFields];
              billingExport.splice(index, 1);
              return {
                ...currentState,
                billingExportFields: billingExport,
              };
            })
          }
          onUpdate={(event: ChangeEvent<HTMLInputElement>, index: number) => {
            const newValue = state.billingExportFields.map((datum, i2) =>
              index === i2
                ? {
                    ...datum,
                    ...{ [`${event.target.name}Input`]: event.target.value },
                  }
                : datum
            );

            mergeState({ billingExportFields: newValue });
          }}
        />
      ),
      label: copyText.cloudAzureAdminBilingExports,
      value: "billingExports",
    },
  ];

  function handleSubmit(event: FormEvent): void {
    event.preventDefault();

    if (props.selectedCloud) {
      const billingExportState = state.billingExportFields.map((billing) => ({
        dateColumn: billing.dateColumnInput,
        endDate: billing.endDateInput,
        exportType: billing.exportTypeInput,
        serviceURL: billing.serviceURLInput,
        startDate: billing.startDateInput,
        storageContainer: billing.storageContainerInput,
        storagePrefix: billing.storagePrefixInput,
        strictStoragePrefix:
          billing.strictStoragePrefixInput === "true" ? true : false,
      }));

      const hasConfigChanges =
        !isEqual(
          billingExportState,
          props.selectedCloud.config.billingExports
        ) ||
        state.azureTypeInput !== props.selectedCloud.config.type ||
        state.appIDInput !== props.selectedCloud.config.appID ||
        state.directoryIDInput !== props.selectedCloud.config.directoryID;

      props.onInteraction({
        type: AzureIntegrationForm.INTERACTION_SUBMIT_BUTTON_CLICKED_UPDATE,
        ...(hasConfigChanges
          ? {
              config: {
                appID: state.appIDInput,
                azureType: state.azureTypeInput,
                billingExports: billingExportState,
                directoryID: state.directoryIDInput,
              },
            }
          : {}),
        ...(state.nameInput !== props.selectedCloud.name
          ? { name: state.nameInput }
          : {}),
      });
    } else {
      props.onInteraction({
        type: AzureIntegrationForm.INTERACTION_SUBMIT_BUTTON_CLICKED_CREATE,
        appID: state.appIDInput,
        billingExports: state.billingExportFields.map((billing) => ({
          dateColumn: billing.dateColumnInput,
          endDate: billing.endDateInput,
          exportType: billing.exportTypeInput,
          serviceURL: billing.serviceURLInput,
          startDate: billing.startDateInput,
          storageContainer: billing.storageContainerInput,
          storagePrefix: billing.storagePrefixInput,
          strictStoragePrefix:
            billing.strictStoragePrefixInput === "true" ? true : false,
        })),
        name: state.nameInput,
        directoryID: state.directoryIDInput,
        azureType: state.azureTypeInput,
      });
    }
  }

  function canUpdate(): boolean {
    const currentState = state.billingExportFields.map((billing) => ({
      exportType: billing.exportTypeInput,
      serviceURL: billing.serviceURLInput,
      storageContainer: billing.storageContainerInput,
      storagePrefix: billing.storagePrefixInput,
      strictStoragePrefix: billing.strictStoragePrefixInput,
      dateRange: {
        startDate: billing.startDateInput,
        endDate: billing.endDateInput,
        dateColumn: billing.dateColumnInput,
      },
    }));
    const completed =
      state.nameInput.trim().length > 0 &&
      isUUID(state.appIDInput.trim()) &&
      isUUID(state.directoryIDInput.trim()) &&
      isValidReportTable(state.billingExportFields);

    if (!props.selectedCloud) return completed;

    const hasChangedText =
      state.nameInput.trim() !== props.selectedCloud.name ||
      !isEqual(currentState, props.selectedCloud.config.billingExports);

    return completed && hasChangedText;
  }

  return (
    <Modal
      closeOnClickOutside={false}
      isOpen
      showCloseButton
      onClose={() =>
        props.onInteraction({
          type: AzureIntegrationForm.INTERACTION_CANCEL_BUTTON_CLICKED,
        })
      }
    >
      <Modal.Header>
        <Flex justifyContent="space-between" alignItems="center" width="100%">
          <Text appearance="h4">
            {props.selectedCloud
              ? copyText.cloudAzureFormTitleUpdate
              : copyText.cloudAzureFormTitleCreate}
          </Text>
          <Flex>
            {state.tab === "billingExports" && (
              <Button
                iconStart={<Icon icon={faPlus} />}
                size="small"
                type="button"
                marginRight={theme.space_sm}
                primary
                onClick={() =>
                  setState((currentState) => ({
                    ...currentState,
                    billingExportFields: [
                      ...currentState.billingExportFields,
                      initialState.billingExportFields[0],
                    ],
                  }))
                }
              >
                {copyText.createBillingExportButtonLabel}
              </Button>
            )}
          </Flex>
        </Flex>
      </Modal.Header>
      <Modal.Body>
        <Flex height={450} minWidth={400} scrollable>
          <Form>
            <Tabs
              activeValue={state.tab}
              tabs={containers}
              onSelect={(tab: string) => mergeState({ tab })}
            />
          </Form>
        </Flex>
      </Modal.Body>
      <Modal.Footer>
        <Button
          disabled={!canUpdate() || props.isLoading}
          fullWidth
          primary
          onClick={handleSubmit}
        >
          {props.isLoading ? <LoadingSpinner /> : copyText.submitButtonLabel}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export function getErrorMessageIfPresent(
  integration: AzureIntegration | undefined,
  validationName: CloudCapability
): string | undefined {
  if (!integration) return undefined;

  if (integration.validations.length === 0) return "";

  const validation = integration.validations.find(
    (validation) => validation.name === validationName
  );

  if (!validation) return "";

  return validation.success ? undefined : validation.error;
}

export function isValidReportTable(table: AzureBillingFields[]): boolean {
  const result = table.map((datum) => {
    return (
      datum.serviceURLInput !== "" &&
      datum.storageContainerInput !== "" &&
      datum.storagePrefixInput !== ""
    );
  });
  return !result.includes(false);
}

AzureIntegrationForm.INTERACTION_SUBMIT_BUTTON_CLICKED_CREATE =
  `AzureIntegrationForm.INTERACTION_SUBMIT_BUTTON_CLICKED_CREATE` as const;

AzureIntegrationForm.INTERACTION_SUBMIT_BUTTON_CLICKED_UPDATE =
  `AzureIntegrationForm.INTERACTION_SUBMIT_BUTTON_CLICKED_UPDATE` as const;

AzureIntegrationForm.INTERACTION_REFRESH_BUTTON_CLICKED =
  `AzureIntegrationForm.INTERACTION_REFRESH_BUTTON_CLICKED` as const;

AzureIntegrationForm.INTERACTION_CANCEL_BUTTON_CLICKED =
  `AzureIntegrationForm.INTERACTION_CANCEL_BUTTON_CLICKED` as const;

interface InteractionRefreshButtonClicked {
  type: typeof AzureIntegrationForm.INTERACTION_REFRESH_BUTTON_CLICKED;
  integrationID: string;
}

interface InteractionSubmitButtonClickedCreate {
  type: typeof AzureIntegrationForm.INTERACTION_SUBMIT_BUTTON_CLICKED_CREATE;
  billingExports: BillingExport[];
  appID: string;
  directoryID: string;
  name: string;
  azureType: AzureIntegrationType;
}
interface InteractionSubmitButtonClickedUpdate {
  type: typeof AzureIntegrationForm.INTERACTION_SUBMIT_BUTTON_CLICKED_UPDATE;
  config?: {
    appID: string;
    azureType: AzureIntegrationType;
    billingExports: BillingExport[];
    directoryID: string;
  };
  name?: string;
}

interface InteractionCancelButtonClicked {
  type: typeof AzureIntegrationForm.INTERACTION_CANCEL_BUTTON_CLICKED;
}

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace AzureIntegrationForm {
  export type Interaction =
    | InteractionRefreshButtonClicked
    | InteractionSubmitButtonClickedCreate
    | InteractionSubmitButtonClickedUpdate
    | InteractionCancelButtonClicked;
}

export default AzureIntegrationForm;
