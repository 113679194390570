import UError from "unilib-error";
import z from "zod";

export abstract class Message {
  protected _parse<SchemaType extends z.ZodTypeAny>(
    inputs: unknown,
    schema: SchemaType
  ): z.infer<SchemaType> {
    const result = schema.safeParse(inputs, { errorMap: customErrorMap });

    if (!result.success) {
      const error = result.error.errors[0];

      throw new UError(
        `${this.constructor.name}.constructor/ERROR_INVALID_PARAMS`,
        {
          context: {
            inputs,
            reason: error.message,
            ...(error.code === z.ZodIssueCode.custom && error.params
              ? { value: error.params.value }
              : {}),
          },
        }
      );
    }

    return result.data;
  }
}

/**  @deprecated This is being removed in favor of extending from a a traditional
 *                Message class.
 */
export function NewMessage<MessageType extends z.ZodTypeAny>(
  schema?: MessageType
) {
  // eslint-disable-next-line @typescript-eslint/no-extraneous-class
  return class {
    constructor(params?: unknown) {
      if (!schema) return;

      const result = schema.safeParse(params, { errorMap: customErrorMap });

      if (!result.success) {
        const error = result.error.errors[0];

        throw new UError(
          `${this.constructor.name}.constructor/ERROR_INVALID_PARAMS`,
          {
            context: {
              params,
              reason: error.message,
              ...(error.code === z.ZodIssueCode.custom && error.params
                ? { value: error.params.value }
                : {}),
            },
          }
        );
      }

      Object.assign(this, result.data);
    }
  } as { new (params?: unknown): z.infer<MessageType> };
}

const customErrorMap: z.ZodErrorMap = (error, ctx) => {
  switch (error.code) {
    case z.ZodIssueCode.invalid_type: {
      const path = error.path.join(".");

      return {
        message: `Invalid field - '${path}'. Expected a ${error.expected}, but received: ${error.received}`,
      };
    }
    case z.ZodIssueCode.invalid_union_discriminator: {
      const path = error.path.join(".");

      return {
        message: `Invalid field - '${path}'. Expected one of ${error.options}.`,
      };
    }
  }

  return { message: ctx.defaultError };
};

export abstract class Failure {
  public readonly reason: string;

  public constructor({ reason }: { reason: string }) {
    if (typeof reason !== "string" || (reason = reason.trim()) === "") {
      throw new UError(`${this.constructor.name}.constructor/INVALID_REASON`, {
        context: { reason },
      });
    }

    this.reason = reason;
  }
}
