import SuperiorSelect from "@/components/SuperiorSelect";
import { FormField } from "@/ui-lib/components/Form";
import TextInput from "@/ui-lib/components/TextInput";
import getMergeState from "@/utils/getMergeState";
import { useTheme } from "@emotion/react";
import { faCloud, faFolder, faTimes } from "@fortawesome/free-solid-svg-icons";
import Button from "@ternary/api-lib/ui-lib/components/Button";
import Box from "@ternary/web-ui-lib/components/Box";
import Flex from "@ternary/web-ui-lib/components/Flex";
import Icon from "@ternary/web-ui-lib/components/Icon";
import Text from "@ternary/web-ui-lib/components/Text";

import React, { useEffect, useState } from "react";
import copyText from "../../copyText";
import {
  GCP_PROJECT_ID_REGEX,
  renderRadioInput,
  validBigQueryLocations,
} from "./GCPIntegrationForm";
import { BigQueryMonitoring, GCPIntegration } from "./types";

export const textForInfoSchemaTable = {
  JOBS_BY_FOLDER: "In the folder containing this project",
  JOBS_BY_PROJECT: "In this project only",
};

interface Props {
  loading: boolean;
  integration?: GCPIntegration;
  onUpdate: (items: BigQueryMonitoring[]) => void;
}

interface State {
  count: number;
  inputBigQueryConfigurations: BigQueryMonitoring[];
  inputBigQueryScope: string;
  inputBigQueryRegions: string[];
  inputBigQueryRegionsText: string;
  inputBigQueryUsageDataProject: string;
}

const initialState: State = {
  count: 0,
  inputBigQueryConfigurations: [],
  inputBigQueryScope: "",
  inputBigQueryRegions: [],
  inputBigQueryRegionsText: "",
  inputBigQueryUsageDataProject: "",
};

export default function GCPCloudFormBigQuery(props: Props): JSX.Element {
  const theme = useTheme();
  const [state, setState] = useState<State>(initialState);
  const mergeState = getMergeState(setState);

  useEffect(() => {
    if (!props.integration) {
      mergeState(initialState);
      return;
    }

    const bQ = props.integration.config.bigQueryMonitoring;

    if (bQ) {
      mergeState({
        count: bQ.length,
        inputBigQueryConfigurations: bQ,
      });
    }
  }, [props.integration]);

  useEffect(() => {
    props.onUpdate(state.inputBigQueryConfigurations);
  }, [state.inputBigQueryConfigurations]);

  function handleSubmitUpdate(e: React.MouseEvent<HTMLButtonElement>) {
    e.preventDefault();

    const bqMonitoring = {
      regions: state.inputBigQueryRegions,
      infoSchemaTable: state.inputBigQueryScope,
      projectID: state.inputBigQueryUsageDataProject,
    };

    mergeState({
      count: state.count + 1,
      inputBigQueryRegions: [],
      inputBigQueryUsageDataProject: "",
      inputBigQueryScope: "",
      inputBigQueryConfigurations: state.inputBigQueryConfigurations.concat([
        bqMonitoring,
      ]),
    });
  }

  function removeItem(i: number) {
    mergeState({
      count: state.count - 1,
      inputBigQueryConfigurations: state.inputBigQueryConfigurations.flatMap(
        (config, j) => (i === j ? [] : [config])
      ),
    });
  }

  return (
    <Box height={416} padding={theme.space_xs} scrollable>
      <FormField
        disabled={props.loading}
        input={TextInput}
        label={copyText.cloudAttributeBigQueryDataProject}
        value={state.inputBigQueryUsageDataProject}
        variant={
          GCP_PROJECT_ID_REGEX.test(state.inputBigQueryUsageDataProject)
            ? "success"
            : "danger"
        }
        onChange={(e) =>
          mergeState({ inputBigQueryUsageDataProject: e.target.value })
        }
      />
      <FormField label={copyText.cloudAttributeBigQueryDataRegions}>
        <SuperiorSelect
          inputValue={state.inputBigQueryRegionsText}
          isMulti
          placeholder={copyText.cloudAttributeBigQueryDataRegions}
          suggestions={validBigQueryLocations.map(toRegionQualifier)}
          value={state.inputBigQueryRegions}
          onChange={(val: string[]) =>
            mergeState({ inputBigQueryRegions: val })
          }
          onInputChange={(val: string) =>
            mergeState({ inputBigQueryRegionsText: val })
          }
        />
      </FormField>
      <FormField label={copyText.cloudAttributeBigQueryDataQueryScope}>
        <Flex>
          {[
            { stateKey: "inputBigQueryScope", value: "JOBS_BY_PROJECT" },
            { stateKey: "inputBigQueryScope", value: "JOBS_BY_FOLDER" },
          ].map((params) =>
            renderRadioInput(
              params,
              textForInfoSchemaTable,
              props.loading,
              state,
              mergeState
            )
          )}
        </Flex>
      </FormField>
      <Button
        disabled={
          state.inputBigQueryRegions.length === 0 ||
          !state.inputBigQueryUsageDataProject ||
          !state.inputBigQueryScope
        }
        marginBottom={theme.space_sm}
        primary
        size="small"
        onClick={handleSubmitUpdate}
      >
        {copyText.cloudAttributeBigQueryAddConfiguration}
      </Button>
      <Text appearance="h4">
        {copyText.cloudAttributeBigQueryConfigurations} ({state.count}):
      </Text>
      <Box>
        {state.inputBigQueryConfigurations.length === 0 && (
          <Box
            backgroundColor={theme.feedback_warn_background}
            borderRadius={theme.borderRadius_2}
            marginTop={theme.space_sm}
            padding={theme.space_sm}
          >
            <Text fontSize={theme.h6_fontSize}>
              {copyText.cloudAttributeBigQueryNotConfigured}
            </Text>
          </Box>
        )}
        {state.inputBigQueryConfigurations.map((config, i) => (
          <Box key={i} marginVertical={theme.space_xs}>
            <Flex alignItems="center" justifyContent="space-between">
              <Box>
                <Flex alignItems="center">
                  <Icon
                    color={theme.text_color}
                    icon={
                      config.infoSchemaTable === "JOBS_BY_FOLDER"
                        ? faFolder
                        : faCloud
                    }
                  />
                  <Text marginLeft={theme.space_xs}>{config.projectID}</Text>
                </Flex>
                <Text>{config.regions.join(", ")}</Text>
              </Box>
              <Button
                circular
                iconEnd={
                  <Icon color={theme.text_color_secondary} icon={faTimes} />
                }
                size="small"
                type="reset"
                onClick={() => removeItem(i)}
              />
            </Flex>
          </Box>
        ))}
      </Box>
    </Box>
  );
}

function toRegionQualifier(region: string): string {
  return "region-" + region.toLowerCase();
}
