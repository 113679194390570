import { TenantStatus, ThemeMode } from "@ternary/api-lib/constants/enums";
import CaseManagementStoreProvider from "@ternary/api-lib/ui-lib/context/CaseManagementStoreProvider";
import { darkTheme } from "@ternary/api-lib/ui-lib/theme/dark";
import { defaultTheme } from "@ternary/api-lib/ui-lib/theme/default";
import ThemeProvider from "@ternary/api-lib/ui-lib/theme/ThemeProvider";
import React, { useEffect, useState } from "react";
import "react-toastify/dist/ReactToastify.css";
import useGetAuthenticatedUser from "./api/core/hooks/useGetAuthenticatedUser";
import useGenerateAnalyticsToken from "./api/core/useGenerateAnalyticsToken";
import useGenerateZendeskToken from "./api/core/useGenerateZendeskToken";
import useGetGatekeeper from "./api/core/useGetGatekeeper";
import AppRouter from "./AppRouter";
import ErrorBoundary from "./components/ErrorBoundary";
import ErrorPage from "./components/ErrorPage";
import { useAnalyticsApiClient } from "./context/AnalyticsQueryLoaderProvider";
import { useFilterStore } from "./context/FilterStoreProvider";
import GlobalDateStoreProvider from "./context/GlobalDateProvider";
import InternalAdminStoreProvider from "./context/InternalAdminStoreProvider";
import useInitFilterStore from "./hooks/useInitFilterStore";
import { initStatuspage } from "./lib/statuspage";
import { initZendesk } from "./lib/zendesk";
import { useMspStore } from "./lib/zustand";
import AlertContainer from "./ui-lib/components/AlertContainer";
import { removeAppLoader } from "./utils/document";
import {
  setDateFormatLocale,
  storage,
  STORAGE_KEY_MSP_TENANT_ID,
} from "./utils/window";

const REASON_INACTIVE_TENANT = "INACTIVE_TENANT";
const THEME_STORAGE_KEY = "theme";

export interface Props {
  statusPageURL?: string;
  zendeskURL?: string;
}

export default function App(props: Props): JSX.Element | null {
  const analyticsClient = useAnalyticsApiClient();
  const filterStore = useFilterStore();

  //
  // State
  //

  const mspStore = useMspStore();

  const [isInitialized, setIsInitialized] = useState(false);

  //
  // Queries
  //

  const { data: authenticatedUser, error: errorLoadingAuthenticatedUser } =
    useGetAuthenticatedUser();

  const { data: gatekeeper, error: errorLoadingGatekeeper } =
    useGetGatekeeper(authenticatedUser);

  const { data: analyticsToken, error: errorLoadingAnalyticsToken } =
    useGenerateAnalyticsToken(authenticatedUser?.tenant.id ?? "", {
      enabled: !!authenticatedUser,
    });

  const { data: zendeskToken } = useGenerateZendeskToken({
    enabled: !!authenticatedUser && !!props.zendeskURL,
  });

  useInitFilterStore(authenticatedUser);

  //
  // Side Effects
  //

  useEffect(() => {
    if (
      !authenticatedUser ||
      !gatekeeper ||
      isInitialized ||
      !filterStore.isInitialized
    ) {
      return;
    }

    // Figure out which MSP to scope the user into.
    if (authenticatedUser.parentTenantID) {
      // If the user is a System Admin. We take first check local storage
      // for the MSP Tenant ID. If local storage value is set, use it.
      // If it's not set, use the default  MSP Tenant ID on the user.
      const mspTenantID = gatekeeper.canAccessInternalAdmin
        ? storage.getItem(STORAGE_KEY_MSP_TENANT_ID)
        : null;

      const parentTenantID = mspTenantID ?? authenticatedUser.parentTenantID;

      mspStore.setParentTenantID(parentTenantID);
    }

    storage.setItem(THEME_STORAGE_KEY, authenticatedUser.themeMode);

    if (props.statusPageURL) {
      initStatuspage(props.statusPageURL);
    }

    setDateFormatLocale();

    setIsInitialized(true);

    removeAppLoader();
  }, [authenticatedUser, gatekeeper, filterStore.isInitialized]);

  useEffect(() => {
    if (!authenticatedUser || !zendeskToken || !props.zendeskURL) {
      return;
    }

    initZendesk(zendeskToken, authenticatedUser, props.zendeskURL);
  }, [zendeskToken]);

  useEffect(() => {
    if (analyticsToken) {
      analyticsClient.setToken(analyticsToken);
    }
  }, [analyticsToken]);

  //
  // Render
  //

  const theme =
    authenticatedUser?.themeMode === ThemeMode.DARK ? darkTheme : defaultTheme;

  const error = [
    errorLoadingAuthenticatedUser,
    errorLoadingAnalyticsToken,
    errorLoadingGatekeeper,
  ].find((error) => error !== null);

  if (error) {
    removeAppLoader();

    const reason = error.reason ?? "UNEXPECTED";

    return (
      <ThemeProvider theme={theme}>
        <ErrorPage error={error} reason={reason} />
      </ThemeProvider>
    );
  }

  if (authenticatedUser?.tenant.status === TenantStatus.INACTIVE) {
    return (
      <ThemeProvider theme={theme}>
        <ErrorPage reason={REASON_INACTIVE_TENANT} />
      </ThemeProvider>
    );
  }

  return isInitialized && authenticatedUser && analyticsToken ? (
    <ErrorBoundary boundaryName="App">
      <ThemeProvider theme={theme}>
        <InternalAdminStoreProvider>
          <GlobalDateStoreProvider>
            <AlertContainer />
            <CaseManagementStoreProvider>
              <AppRouter />
            </CaseManagementStoreProvider>
          </GlobalDateStoreProvider>
        </InternalAdminStoreProvider>
      </ThemeProvider>
    </ErrorBoundary>
  ) : null;
}
