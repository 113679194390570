import { useTheme } from "@emotion/react";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import Button from "@ternary/api-lib/ui-lib/components/Button";
import Flex from "@ternary/web-ui-lib/components/Flex";
import Icon from "@ternary/web-ui-lib/components/Icon";
import Text from "@ternary/web-ui-lib/components/Text";
import { formatDate } from "@ternary/web-ui-lib/utils/dates";
import {
  add,
  addMonths,
  addYears,
  format,
  startOfMonth,
  startOfYear,
  sub,
} from "date-fns";
import React, { useEffect, useState } from "react";
import ReactDatePicker, { ReactDatePickerProps } from "react-datepicker";
import { Size } from "../TextInput";
import { DatePickerWrapper } from "./styled";

interface Props extends ReactDatePickerProps<string> {
  size?: Size;
  timeSelectWidth?: string;
  onChange: (date) => void;
  onClick?: () => void;
  select?: "start" | "end";
}

export default function DatePicker(props: Props): JSX.Element {
  const [select, setSelect] = useState(props.select);

  useEffect(() => {
    setSelect(props.select);
  }, [props.select]);

  return (
    <DatePickerWrapper
      dateFormat={props.dateFormat}
      hasStart={!!props.startDate}
      hasEnd={!!props.endDate}
      timeSelectWidth={props.timeSelectWidth}
      onClick={props.onClick}
    >
      <ReactDatePicker
        dateFormat={props.dateFormat ?? "MM/dd/yyyy"}
        renderCustomHeader={(params) => (
          <CustomHeader
            {...params}
            maxDate={props.maxDate}
            showMonthYearPicker={props.showMonthYearPicker}
            showYearPicker={props.showYearPicker}
          />
        )}
        {...props}
        allowSameDay
        onMonthMouseLeave={() => setSelect(undefined)}
        onDayMouseEnter={() => setSelect(props.select)}
        selectsStart={select === "start"}
        selectsEnd={select === "end"}
        onChange={(date) => {
          props.onChange(date);
        }}
      />
    </DatePickerWrapper>
  );
}

interface CustomHeaderProps {
  date: Date;
  maxDate?: Date | null;
  decreaseMonth: () => void;
  decreaseYear: () => void;
  increaseMonth: () => void;
  increaseYear: () => void;
  changeYear: (year: number) => void;
  showMonthYearPicker?: boolean;
  showYearPicker?: boolean;
}

function CustomHeader(props: CustomHeaderProps): JSX.Element {
  const theme = useTheme();

  function handleClickLeft() {
    if (props.showYearPicker) {
      props.changeYear(Number(format(sub(props.date, { years: 12 }), "yyyy")));
      return;
    }

    if (props.showMonthYearPicker) {
      props.decreaseYear();
      return;
    }

    props.decreaseMonth();
  }

  function handleClickRight() {
    if (props.showYearPicker) {
      props.changeYear(Number(format(add(props.date, { years: 12 }), "yyyy")));
      return;
    }

    if (props.showMonthYearPicker) {
      props.increaseYear();
      return;
    }

    props.increaseMonth();
  }

  const canClickRight = props.showMonthYearPicker
    ? !!props.maxDate && startOfYear(addYears(props.date, 1)) <= props.maxDate
    : !!props.maxDate &&
      startOfMonth(addMonths(props.date, 1)) <= props.maxDate;

  return (
    <Flex
      alignItems="center"
      backgroundColor={theme.panel_backgroundColor}
      justifyContent="space-between"
      padding={theme.space_sm}
    >
      <Button
        iconEnd={<Icon icon={faChevronLeft} />}
        size="tiny"
        type="button"
        onClick={handleClickLeft}
        onMouseDown={(e) => {
          e.preventDefault();
        }}
      />
      <Text>
        {formatDate(
          props.date,
          props.showMonthYearPicker ? "yyyy" : "MMMM yyyy"
        )}
      </Text>
      <Button
        disabled={!canClickRight}
        iconEnd={<Icon icon={faChevronRight} />}
        size="tiny"
        type="button"
        onClick={handleClickRight}
        onMouseDown={(e) => {
          e.preventDefault();
        }}
      />
    </Flex>
  );
}
