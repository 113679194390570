import * as Sentry from "@sentry/react";
import { browserTracingIntegration } from "@sentry/react";
import {
  QueryCache,
  QueryClient,
  QueryClientProvider,
} from "@tanstack/react-query";
import mixpanel from "mixpanel-browser";
import React from "react";
import { BrowserRouter } from "react-router-dom";
import registry from "unilib-registry/instance";
import { QueryParamProvider } from "use-query-params";
import { ReactRouter6Adapter } from "use-query-params/adapters/react-router-6";
import App from "./App";
import AuthHandler from "./api/AuthHandler";
import AuthHandlerProvider from "./api/AuthHandlerProvider";
import HTTPRequestSender from "./api/HTTPRequestSender";
import AnalyticsApiClient from "./api/analytics/AnalyticsApiClient";
import CoreAPIClientImpl from "./api/core/CoreAPIClientImpl";
import CoreAPIClientProvider from "./api/core/CoreAPIClientProvider";
import { InternalApiClient } from "./api/internal/InternalApiClient";
import InternalApiClientProvider from "./api/internal/InternalApiClientProvider";
import { config } from "./config";
import ActivityTrackerProvider from "./context/ActivityTrackerProvider";
import AnalyticsApiClientProvider from "./context/AnalyticsQueryLoaderProvider";
import EventReporterProvider from "./context/EventReporterProvider";
import FilterStoreProvider from "./context/FilterStoreProvider";
import adminQueryKeys from "./features/admin/hooks/queryKeys";
import treQueryKeys from "./features/reporting-engine/hooks/queryKeys";
import ActivityTrackerImpl from "./utils/ActivityTracker";
import EventReporter from "./utils/EventReporter";
import { AlertType, postAlert } from "./utils/alerts";
import { storage } from "./utils/window";

if (config.MIXPANEL_TOKEN) {
  mixpanel.init(config.MIXPANEL_TOKEN, {}, "");
}

if (config.SENTRY_ENV && config.SENTRY_RELEASE_HASH) {
  Sentry.init({
    dsn: config.SENTRY_DSN,
    environment: config.SENTRY_ENV,
    integrations: [browserTracingIntegration()],
    maxValueLength: 2000,
    release: config.SENTRY_RELEASE_HASH,
    tracesSampleRate: 1,
    ignoreErrors: [
      "ResizeObserver loop completed with undelivered notifications.",
      "ResizeObserver loop limit exceeded",
    ],
    initialScope: (scope) => {
      scope.setTags({ app: "core-web-ui" });
      return scope;
    },
    beforeSend(event) {
      if (event.tags?.ignore === true) {
        return null; // Don't send the event to Sentry
      }

      return event;
    },
  });
}

//
// Auth
//

const authHandler = new AuthHandler({
  authBaseURL: config.AUTH_BASE_URL,
  storage,
});

//
// Telemetry
//

const eventReporter = new EventReporter({
  devMode: config.ENV === "development",
  enableMixpanel: !!config.MIXPANEL_TOKEN,
  enableSentry: !!config.SENTRY_ENV && !!config.SENTRY_RELEASE_HASH,
});

registry.register(EventReporter.name, (): EventReporter => eventReporter);

const activityTracker = new ActivityTrackerImpl({
  enableMixpanel: !!config.MIXPANEL_TOKEN,
  enableSentry: !!config.SENTRY_ENV && !!config.SENTRY_RELEASE_HASH,
  eventReporter,
});

//
// Core API
//

const coreRequestSender = new HTTPRequestSender({
  baseURL: config.CORE_API_BASE_URL,
  redirectOnAuthError: () => authHandler.redirectOnAuthError(),
});

const coreAPIClient = new CoreAPIClientImpl({
  eventReporter,
  sender: coreRequestSender,
});

//
// Internal API
//

const internalRequestSender = new HTTPRequestSender({
  baseURL: config.INTERNAL_API_BASE_URL,
  redirectOnAuthError: () => authHandler.redirectOnAuthError(),
});

const internalApiClient = new InternalApiClient({
  eventReporter,
  sender: internalRequestSender,
});

//
// Analytics API
//

const analyticsRequestSender = new HTTPRequestSender({
  baseURL: config.ANALYTICS_API_BASE_URL,
  redirectOnAuthError: () => authHandler.redirectOnAuthError(),
});

const analyticsApiClient = new AnalyticsApiClient({
  analyticsSender: analyticsRequestSender,
  devMode: config.ENV === "development",
  eventReporter,
});

//
// React Query
//

// Have to cast this to unknown because keys are too strict as const.
const disableNotFoundQueries = [
  adminQueryKeys.jiraIntegration,
  adminQueryKeys.slackIntegration,
  treQueryKeys.datadogIntegration,
] as unknown[];

const reactQueryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      staleTime: 30_000, // 30 Seconds
    },
  },
  queryCache: new QueryCache({
    onError: (error, query) => {
      if (!query.meta?.errorMessage) return;

      // It's expeced that these will return 404 if they haven't
      // been set up yet. So we don't want to show an error toast.
      if (
        disableNotFoundQueries.includes(query.queryKey) &&
        error.reason === "NOT_FOUND"
      ) {
        return;
      }

      postAlert({ message: query.meta.errorMessage, type: AlertType.ERROR });
    },
  }),
});

registry.register("ReactQueryClient", (): QueryClient => reactQueryClient);

//
// Root
//

// prettier-ignore
const providers = [
  { component: ActivityTrackerProvider, props: { tracker: activityTracker } },
  { component: AnalyticsApiClientProvider, props: { loader: analyticsApiClient } },
  { component: AuthHandlerProvider, props: { handler: authHandler } },
  { component: BrowserRouter, props: {} },
  { component: CoreAPIClientProvider, props: { client: coreAPIClient }, },
  { component: EventReporterProvider, props: { reporter: eventReporter } },
  { component: FilterStoreProvider, props: {} },
  { component: InternalApiClientProvider, props: { client: internalApiClient }, },
  { component: QueryClientProvider, props: { client: reactQueryClient } },
  { component: QueryParamProvider, props: { adapter: ReactRouter6Adapter } },
];

export const Root = providers.reduceRight(
  (accum, provider) => {
    return React.createElement(provider.component, provider.props, accum);
  },
  React.createElement(App, {
    statusPageURL: config.STATUSPAGE_URL,
    zendeskURL: config.ZENDESK_URL,
  })
);
