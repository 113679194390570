import { useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import React from "react";
import { LegendProps } from "recharts";
import Box from "../components/Box";
import Flex from "../components/Flex";
import Text from "../components/Text";
import copyText from "../copyText";
import ChartDataManager from "../utils/ChartDataManager";
import { getIsDashedMeasure } from "./utils";

type Props = {
  align?: "center" | "left";
  dataManager: ChartDataManager;
  height?: number;
  isServer?: boolean;
  maxKeys?: number;
  readableKeys?: { [key: string]: string };
  onInteraction: (interaction: LegendSimple.Interaction) => void;
} & LegendProps;

type LengendEntry = {
  color: string;
  chartKey: string;
  measure: string;
  text: string;
};

function LegendSimple(props: Props) {
  const theme = useTheme();

  if (!props.payload) return null;

  const entries: LengendEntry[] = [];

  [...props.payload]
    .reverse()
    .forEach(({ value, color = theme.background_color_disabled }) => {
      if (typeof value !== "string") return;

      const measure = props.dataManager.getMeasure(value)?.name;

      if (!measure) return;

      entries.push({
        color,
        chartKey: value,
        measure,
        text: getEntryText(props.dataManager, value, props.readableKeys ?? {}),
      });
    });

  const align = props.align ?? "center";
  const maxKeys = props.maxKeys ?? entries.length;
  const visibleEntries = entries.slice(0, maxKeys);
  const hiddenEntries = entries.slice(maxKeys);

  const scrollWrapper = (content: JSX.Element) =>
    props.isServer ? (
      <Box overflowY="visible">{content}</Box>
    ) : (
      <Flex
        justifyContent="center"
        maxHeight={props.height ?? 60}
        position="relative"
        width="100%"
      >
        <Box maxHeight="100%" width="100%" overflowX="auto" overflowY="auto">
          {content}
        </Box>
      </Flex>
    );

  function getExcludedKeysAfterToggle(chartKey: string) {
    return props.dataManager.isExcluded(chartKey)
      ? props.dataManager.excludedChartKeys.filter((key) => key !== chartKey)
      : [...props.dataManager.excludedChartKeys, chartKey];
  }

  return scrollWrapper(
    <Flex
      flexWrap="wrap"
      justifyContent={align === "left" ? "flex-start" : "center"}
    >
      {visibleEntries.map((entry) => {
        const background = getIsDashedMeasure(entry.measure)
          ? `repeating-linear-gradient(45deg,${entry.color}, ${entry.color} 5px, white 5px, white 10px)`
          : entry.color;

        return (
          <StyledFlex
            key={entry.chartKey}
            alignItems="center"
            paddingHorizontal={theme.space_xs}
            paddingVertical={theme.space_xxs}
            onClick={() =>
              props.onInteraction({
                type: LegendSimple.INTERACTION_CHART_EXCLUSION_CHANGED,
                excludedChartKeys: getExcludedKeysAfterToggle(entry.chartKey),
              })
            }
          >
            <Box
              background={background}
              borderRadius="3px"
              height={theme.fontSize_small}
              marginRight={theme.space_xxs}
              width={12}
            />

            <Text fontSize={theme.fontSize_small}>{entry.text}</Text>
          </StyledFlex>
        );
      })}

      {!!hiddenEntries.length && (
        <Flex
          alignItems="center"
          paddingHorizontal={theme.space_xs}
          paddingVertical={theme.space_xxs}
        >
          <Box height={theme.fontSize_small} />

          <Text fontSize={theme.fontSize_small}>
            {copyText.numRowsHidden.replace(
              "%COUNT%",
              String(hiddenEntries.length)
            )}
          </Text>
        </Flex>
      )}
    </Flex>
  );
}

LegendSimple.INTERACTION_CHART_EXCLUSION_CHANGED =
  `LegendSimple.INTERACTION_CHART_EXCLUSION_CHANGED` as const;

interface InteractionChartExclusionChanged {
  type: typeof LegendSimple.INTERACTION_CHART_EXCLUSION_CHANGED;
  excludedChartKeys: string[];
}

// eslint-disable-next-line @typescript-eslint/no-namespace
namespace LegendSimple {
  export type Interaction = InteractionChartExclusionChanged;
}

export default LegendSimple;

const StyledFlex = styled(Flex)(({ theme }) => ({
  "&:hover": {
    backgroundColor: theme.background_color_disabled,
    cursor: "pointer",
  },
}));

function getEntryText(
  dataManager: ChartDataManager,
  chartKey: string,
  readableKeys: { [key: string]: string }
): string {
  const dimensionValues = dataManager.getDimensionValuesFromChartKey(chartKey);
  const measure = dataManager.getMeasure(chartKey);

  const readable = (key: string) => {
    if (key in readableKeys) return readableKeys[key];
    return key;
  };

  if (!measure) return "---";

  if (dimensionValues.length === 0) {
    return readable(measure.name);
  }

  let grouping = "";

  if (dimensionValues.length === 1) {
    grouping = dimensionValues[0].value;
  } else {
    grouping = dimensionValues
      .map(({ dimension, value }) => `${readable(dimension.name)}: ${value}`)
      .join(" / ");
  }

  if (dataManager.measures.length === 1) {
    return grouping;
  }

  return `${readable(measure.name)} - ${grouping}`;
}
