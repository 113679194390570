import LoadingSpinner from "@/ui-lib/components/LoadingSpinner";
import TextInput from "@/ui-lib/components/TextInput";
import { useTheme } from "@emotion/react";
import { faFileExport, faSearch } from "@fortawesome/free-solid-svg-icons";
import Button from "@ternary/api-lib/ui-lib/components/Button";
import Box from "@ternary/web-ui-lib/components/Box";
import Flex from "@ternary/web-ui-lib/components/Flex";
import Icon from "@ternary/web-ui-lib/components/Icon";
import { format } from "date-fns";
import React from "react";
import { CSVLink } from "react-csv";
import Chip from "../../../../ui-lib/components/Chip";
import copyText from "../../copyText";
import {
  AzureStorageBBDimension,
  azureStorageBBDimensions,
  AzureStorageBBTableFilters,
} from "../types";

type CSVData = {
  headers: { key: string; label: string }[];
  rows: Record<string, string | number>[];
};

type Props = {
  csvData: CSVData;
  debouncedSearchText: string;
  tableFilters: AzureStorageBBTableFilters;
  searchText: string;
  onInteraction: (interaction: AzureStorageBBTableControls.Interaction) => void;
};

const MAX_CHIP_TEXT_LENGTH = 40;

function AzureStorageBBTableControls(props: Props) {
  const theme = useTheme();

  return (
    <Flex alignItems="center" minHeight={30}>
      <Flex flex="1 0 0">
        {[
          azureStorageBBDimensions.storageAccount,
          azureStorageBBDimensions.accountId,
          azureStorageBBDimensions.pricingModel,
          azureStorageBBDimensions.projectId,
          azureStorageBBDimensions.resourceId,
          azureStorageBBDimensions.skuDescription,
        ].map((tableFilterKey) => {
          let filterValue = props.tableFilters[tableFilterKey];

          if (filterValue === null) return;

          if (filterValue === "") {
            filterValue = "null";
          }

          const copyTextKey: keyof typeof copyText = `azureStorageBBTableHeader_${tableFilterKey}`;

          if (filterValue.length > MAX_CHIP_TEXT_LENGTH) {
            filterValue =
              filterValue.slice(0, MAX_CHIP_TEXT_LENGTH / 2) +
              " ... " +
              filterValue.slice(-MAX_CHIP_TEXT_LENGTH / 2);
          }

          return (
            <Chip
              key={tableFilterKey}
              label={copyText[copyTextKey]}
              text={filterValue}
              onClick={() =>
                props.onInteraction({
                  type: AzureStorageBBTableControls.INTERACTION_REMOVE_FILTER_CLICKED,
                  filterKey: tableFilterKey,
                })
              }
            />
          );
        })}
      </Flex>

      <Box marginHorizontal={theme.space_md}>
        <TextInput
          iconEnd={
            props.searchText === props.debouncedSearchText ||
            props.searchText === "" ? (
              <Icon color={theme.text_color_secondary} icon={faSearch} />
            ) : (
              <LoadingSpinner />
            )
          }
          placeholder={copyText.searchInputPlaceholder}
          size="large"
          value={props.searchText}
          onChange={(e) =>
            props.onInteraction({
              type: AzureStorageBBTableControls.INTERACTION_SEARCH_TEXT_UPDATED,
              searchText: e.target.value,
            })
          }
        />
      </Box>

      <Box>
        <CSVLink
          data={props.csvData.rows}
          headers={props.csvData.headers}
          filename={`azure-blob-storage-${format(new Date(), "MM-dd-yyyy")}`}
        >
          <Button
            iconStart={<Icon color="inherit" icon={faFileExport} />}
            secondary
            size="small"
          >
            {copyText.exportButtonLabel}
          </Button>
        </CSVLink>
      </Box>
    </Flex>
  );
}

AzureStorageBBTableControls.INTERACTION_DOWNLOAD_INSTANCES_CLICKED =
  `AzureStorageBBTableControls.INTERACTION_DOWNLOAD_INSTANCES_CLICKED` as const;

AzureStorageBBTableControls.INTERACTION_REMOVE_FILTER_CLICKED =
  `AzureStorageBBTableControls.INTERACTION_REMOVE_FILTER_CLICKED` as const;

AzureStorageBBTableControls.INTERACTION_SEARCH_TEXT_UPDATED =
  `AzureStorageBBTableControls.INTERACTION_SEARCH_TEXT_UPDATED` as const;

interface InteractionDownloadInstancesClicked {
  type: typeof AzureStorageBBTableControls.INTERACTION_DOWNLOAD_INSTANCES_CLICKED;
}

interface InteractionRemoveFilterClicked {
  type: typeof AzureStorageBBTableControls.INTERACTION_REMOVE_FILTER_CLICKED;
  filterKey: AzureStorageBBDimension;
}

interface InteractionSearchTextUpdated {
  type: typeof AzureStorageBBTableControls.INTERACTION_SEARCH_TEXT_UPDATED;
  searchText: string;
}

// eslint-disable-next-line @typescript-eslint/no-namespace
namespace AzureStorageBBTableControls {
  export type Interaction =
    | InteractionDownloadInstancesClicked
    | InteractionRemoveFilterClicked
    | InteractionSearchTextUpdated;
}
export default AzureStorageBBTableControls;
