import { isPlainObject } from "lodash";
import UError from "unilib-error";
import z from "zod";
import { Failure, NewMessage } from "../Message";
import { DatadogIntegrationEntity as _DatadogIntegrationEntity } from "../types";

//
// Queries
//

const findDatadogIntegrationByTenantIDQuerySchema = z.object({
  tenantID: z.string().nonempty(),
});

export class FindDatadogIntegrationByTenantIDQuery extends NewMessage(
  findDatadogIntegrationByTenantIDQuerySchema
) {}

export type FindDatadogIntegrationQuery = FindDatadogIntegrationByTenantIDQuery;

//
// Result
//

type DatadogIntegrationEntity = Omit<
  _DatadogIntegrationEntity,
  "apiKey" | "appKey" | "name"
>;

export class FindDatadogIntegrationQueryResult {
  public readonly integration: DatadogIntegrationEntity;

  public constructor({
    integration,
  }: {
    integration: DatadogIntegrationEntity;
  }) {
    if (!isPlainObject(integration)) {
      throw new UError(
        `${this.constructor.name}.constructor/INVALID_INTEGRATION`,
        { context: { integration } }
      );
    }

    this.integration = integration;
  }
}

//
// Failure
//

export class FindDatadogIntegrationQueryFailure extends Failure {
  public constructor({ reason }: { reason: string }) {
    super({ reason });
  }

  public static readonly REASON_INTEGRATION_NOT_FOUND = `${FindDatadogIntegrationQueryFailure.name}/INTEGRATION_NOT_FOUND`;
  public static readonly REASON_PERMISSION_DENIED = `${FindDatadogIntegrationQueryFailure.name}/PERMISSION_DENIED`;
  public static readonly REASON_TENANT_NOT_FOUND = `${FindDatadogIntegrationQueryFailure.name}/TENANT_NOT_FOUND`;
  public static readonly REASON_UNEXPECTED = `${FindDatadogIntegrationQueryFailure.name}/UNEXPECTED`;
}
