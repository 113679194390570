import { useTheme } from "@emotion/react";
import { faCircleInfo, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { createColumnHelper } from "@tanstack/react-table";
import {
  GcpCommitmentScope,
  GcpCommitmentType,
  GcpTargetCommitmentType,
} from "@ternary/api-lib/constants/enums";
import Table from "@ternary/api-lib/ui-lib/charts/Table/Table";
import Button from "@ternary/api-lib/ui-lib/components/Button";
import Icon from "@ternary/api-lib/ui-lib/components/Icon";
import Tooltip from "@ternary/api-lib/ui-lib/components/Tooltip";
import { formatDate } from "@ternary/api-lib/ui-lib/utils/dates";
import {
  formatCurrencyRounded,
  formatPercentage,
} from "@ternary/api-lib/ui-lib/utils/formatNumber";
import Box from "@ternary/web-ui-lib/components/Box";
import Text from "@ternary/web-ui-lib/components/Text";
import React, { useMemo } from "react";
import externalLinks from "../../../../constants/externalLinks";
import copyText from "../../copyText";
import {
  CudRecommendation,
  CudRecommendationFilters,
  CudRecommendationTableDatum,
  InsightRecommendation,
} from "../types";
import {
  getFormattedTargetCommitmentValue,
  getReadableGcpCommitmentServiceTypeStrings,
  getReadableGcpCommitmentTierStrings,
} from "../utils";
import ContraryRecommendationWarning from "./ContraryRecommendationWarning";

export const INSTANCE_FAMILY_GENERAL = "GENERAL";
export const INSTANCE_FAMILY_COMPUTEOPTIMIZED = "COMPUTEOPTIMIZED";

interface Props {
  cudRecommendations: CudRecommendation[];
  commitmentType: GcpCommitmentType;
  insightRecommendations: InsightRecommendation[];
  isLoading: boolean;
  onInteraction: (interaction: CudRecommendationTable.Interaction) => void;
}

const contraryInsightRecommendationTypes = [
  "gcp/rightsizeVM",
  "gcp/rightsizeInstanceGroup",
  "gcp/unusedVM",
  "gcp/terminateIdleDatabaseInstance",
  "gcp/downgradeDatabaseInstanceClass",
  "kubernetes/nodepoolUtilization",
  "kubernetes/overprovisionedNodeInstanceTypes",
  "aws/rightsizeVM",
  "aws/terminateVM",
  "aws/rightsizeVolume",
  "aws/reservationPurchaseRDS",
  "aws/terminateIdleDatabaseInstance",
  "aws/downgradeDatabaseInstanceClass",
  "aws/upgradeDatabaseInstanceClass",
];

const columnHelper = createColumnHelper<CudRecommendationTableDatum>();

export function CudRecommendationTable(props: Props): JSX.Element {
  const theme = useTheme();

  const cudRecommendations = props.cudRecommendations.map(
    (cudRecommendation) => {
      const matches = props.insightRecommendations?.filter(
        (insightRecommendation) =>
          cudRecommendation.projectID === insightRecommendation.projectID &&
          insightRecommendation.applicable &&
          contraryInsightRecommendationTypes.includes(
            insightRecommendation.typeID
          )
      );

      return {
        ...cudRecommendation,
        contraryInsightRecommendation: matches[0],
      };
    }
  );

  const columns = useMemo(
    () => [
      columnHelper.display({
        id: "showActionMenu",
        meta: { align: "center" },
        cell: function renderButton({ row }) {
          return (
            <Tooltip content={copyText.actionMenuViewRecommendation}>
              <Button
                iconStart={<Icon icon={faCircleInfo} />}
                primary
                size="tiny"
                onClick={() => {
                  props.onInteraction({
                    type: CudRecommendationTable.INTERACTION_VIEW_RECOMMENDATION_CLICKED,
                    recommendationID: row.original.id,
                    contraryInsightRecommendation:
                      row.original.contraryInsightRecommendation,
                  });
                }}
              />
            </Tooltip>
          );
        },
        size: 50,
      }),
      ...[
        props.commitmentType === GcpCommitmentType.SPEND
          ? columnHelper.accessor("billingAccountID", {
              meta: { align: "center" },
              header: copyText.cudVisRecommendationHeader_billingAccountID,
              cell: ({ getValue }) => {
                const value = getValue();
                if (value.length === 0) {
                  return copyText.notAvailable;
                }
                return (
                  <Text
                    color={theme.primary_color_text}
                    cursor="pointer"
                    marginRight={theme.space_xs}
                    onClick={() =>
                      props.onInteraction({
                        type: CudRecommendationTable.INTERACTION_FILTER_CLICKED,
                        filterKey: "billingAccountID",
                        filterValue: value,
                      })
                    }
                  >
                    {value}
                  </Text>
                );
              },
            })
          : columnHelper.accessor("projectID", {
              meta: { align: "center" },
              header: copyText.cudVisRecommendationHeader_projectID,
              cell: ({ getValue }) => {
                const value = getValue();
                if (value.length === 0) {
                  return copyText.notAvailable;
                }
                return (
                  <Text
                    color={theme.primary_color_text}
                    cursor="pointer"
                    marginRight={theme.space_xs}
                    onClick={() =>
                      props.onInteraction({
                        type: CudRecommendationTable.INTERACTION_FILTER_CLICKED,
                        filterKey: "projectID",
                        filterValue: value,
                      })
                    }
                  >
                    {value}
                  </Text>
                );
              },
            }),
      ],
      columnHelper.accessor("serviceType", {
        meta: { align: "center" },
        header: copyText.cudVisRecommendationHeader_serviceType,
        cell: ({ row }) => {
          return (
            <>
              <Tooltip
                content={copyText.cudInventoryTableServiceTypeFilterTooltip}
              >
                <Text
                  color={theme.primary_color_text}
                  cursor="pointer"
                  marginRight={theme.space_xs}
                  onClick={() =>
                    props.onInteraction({
                      type: CudRecommendationTable.INTERACTION_FILTER_CLICKED,
                      filterKey: "serviceType",
                      filterValue: row.original.serviceType,
                    })
                  }
                >
                  {getReadableGcpCommitmentServiceTypeStrings(
                    row.original.serviceType
                  )}
                </Text>
              </Tooltip>
              <ContraryRecommendationWarning
                insightRecommendation={
                  row.original.contraryInsightRecommendation
                }
              />
            </>
          );
        },
        size: 200,
      }),
      columnHelper.accessor("monthlySpend", {
        meta: { align: "center" },
        header: copyText.cudVisRecommendationHeader_estimatedMonthlySpend,
        cell: ({ row, getValue }) => (
          <>
            {row.original.targetCommitmentType !==
            GcpTargetCommitmentType.CURRENCY
              ? copyText.notAvailable
              : formatCurrencyRounded({
                  currencyCode: row.original.estimatedCurrency,
                  number: getValue(),
                })}
          </>
        ),
      }),
      columnHelper.accessor("estimatedMonthlySavings", {
        meta: { align: "center" },
        header: copyText.cudVisRecommendationHeader_estimatedMonthlySavings,
        cell: ({ row, getValue }) =>
          formatCurrencyRounded({
            currencyCode: row.original.estimatedCurrency,
            number: getValue(),
          }),
        size: 160,
      }),
      columnHelper.accessor("scope", {
        meta: { align: "center" },
        header: copyText.cudVisRecommendationHeader_scope,
        cell: ({ getValue }) =>
          getValue() === GcpCommitmentScope.PROJECT
            ? copyText.cudRecScopeProject
            : copyText.cudRecScopeBillingShared,
        size: 100,
      }),
      columnHelper.accessor("region", {
        meta: { align: "center" },
        header: copyText.cudVisRecommendationHeader_region,
        cell: ({ getValue }) => (
          <Text
            color={theme.primary_color_text}
            cursor="pointer"
            marginRight={theme.space_xs}
            onClick={() =>
              props.onInteraction({
                type: CudRecommendationTable.INTERACTION_FILTER_CLICKED,
                filterKey: "region",
                filterValue: getValue(),
              })
            }
          >
            {getValue()}
          </Text>
        ),
      }),
      columnHelper.accessor("targetCommitmentValue", {
        meta: { align: "center" },
        header: copyText.cudVisRecommendationHeader_targetCommitmentValue,
        cell: ({ row, getValue }) =>
          getFormattedTargetCommitmentValue({
            estimatedCurrency: row.original.estimatedCurrency,
            type: row.original.targetCommitmentType,
            value: getValue(),
          }),
        size: 100,
      }),
      ...(cudRecommendations[0]?.targetCommitmentType !==
      GcpTargetCommitmentType.CURRENCY
        ? [
            columnHelper.accessor("targetCommitmentInstanceFamily", {
              meta: { align: "center" },
              header:
                copyText.cudVisRecommendationHeader_targetCommitmentInstanceFamily,
              cell: ({ getValue }) => {
                const value = getValue();
                if (!value || value.length === 0) {
                  return copyText.notAvailable;
                }
                return (
                  <>
                    <Text
                      color={theme.primary_color_text}
                      cursor="pointer"
                      marginRight={theme.space_xs}
                      onClick={() =>
                        props.onInteraction({
                          type: CudRecommendationTable.INTERACTION_FILTER_CLICKED,
                          filterKey: "targetCommitmentInstanceFamily",
                          filterValue: value,
                        })
                      }
                    >
                      {value}
                    </Text>
                    {(value === INSTANCE_FAMILY_GENERAL ||
                      value === INSTANCE_FAMILY_COMPUTEOPTIMIZED) &&
                      renderToolTip(value)}
                  </>
                );
              },
              size: 100,
            }),
          ]
        : []),
      ...(props.commitmentType === GcpCommitmentType.SPEND
        ? [
            columnHelper.accessor("commitmentUtilization", {
              meta: { align: "center" },
              header: copyText.cudVisRecommendationHeader_utilization,
              cell: ({ getValue }) => {
                return formatPercentage(getValue() ?? 0);
              },
              size: 150,
            }),
          ]
        : []),
      ...(props.commitmentType === GcpCommitmentType.SPEND
        ? [
            columnHelper.accessor("tier", {
              meta: { align: "center" },
              header: () => {
                return (
                  <Tooltip
                    content={copyText.cudVisRecommendationTierTooltip}
                    width="300px"
                  >
                    {copyText.cudVisRecommendationHeader_tier}
                  </Tooltip>
                );
              },
              cell: ({ getValue }) => {
                return getReadableGcpCommitmentTierStrings(getValue());
              },
              size: 150,
            }),
          ]
        : []),
      columnHelper.accessor("lastRefreshTime", {
        meta: { align: "center" },
        header: copyText.cudVisRecommendationHeader_lastRefreshTime,
        cell: ({ getValue }) => {
          return formatDate(new Date(getValue()), "MM/dd/yyyy hh:mm a");
        },
        size: 170,
      }),
    ],
    [cudRecommendations]
  );

  const tableData = useMemo(
    (): CudRecommendationTableDatum[] =>
      cudRecommendations.map((rec) => ({
        id: rec.id,
        billingAccountID: rec.billingAccountID,
        contraryInsightRecommendation: rec.contraryInsightRecommendation,
        coverageSetting: rec.coverageSetting,
        estimatedCurrency: rec.estimatedCurrency,
        estimatedMonthlySavings: rec.estimatedMonthlySavings,
        lastRefreshTime: rec.lastRefreshTime,
        monthlySpend: rec.monthlySpend,
        projectID: rec.projectID,
        scope: rec.scope,
        serviceType: rec.serviceType,
        targetCommitmentInstanceFamily:
          rec.targetCommitmentInstanceFamily ?? "",
        targetCommitmentType: rec.targetCommitmentType,
        targetCommitmentValue: rec.targetCommitmentValue,
        region: rec.region,
        commitmentUtilization: rec.commitmentUtilization,
        term: rec.term,
        tier: rec.tier,
      })),
    [cudRecommendations]
  );

  return (
    <Box overflow="scroll">
      <Table
        columns={columns}
        data={tableData}
        isLoading={props.isLoading}
        initialState={{
          sorting: [{ id: "estimatedMonthlySavings", desc: true }],
        }}
        showPagination
        sortable
      />
    </Box>
  );
}

function renderToolTip(value: string): JSX.Element {
  const theme = useTheme();
  const copyTextToolTip =
    value === INSTANCE_FAMILY_GENERAL
      ? copyText.cudRecommendationGeneralPurposeTooltip
      : copyText.cudRecommendationComputeOptimizedTooltip;
  const copyTextToolTipHref =
    value === INSTANCE_FAMILY_GENERAL
      ? externalLinks.gcpGeneralPurposeCommitmentsDocumentation
      : externalLinks.gcpComputeOptimizedCommitmentsDocumentation;
  return (
    <Tooltip
      content={
        <>
          <Text color={theme.text_color_inverse}>
            {copyTextToolTip.split("%here%")[0]}
          </Text>
          <a
            href={copyTextToolTipHref}
            target="_blank"
            rel="noreferrer"
            onClick={(e) => e.stopPropagation()}
          >
            {copyText.cudRecommendationTooltipLink}
          </a>
        </>
      }
      icon={faInfoCircle}
      width="20rem"
    />
  );
}

CudRecommendationTable.INTERACTION_FILTER_CLICKED =
  `CudRecommendationTable.INTERACTION_FILTER_CLICKED` as const;

CudRecommendationTable.INTERACTION_VIEW_RECOMMENDATION_CLICKED =
  `CudRecommendationTable.INTERACTION_VIEW_RECOMMENDATION_CLICKED` as const;

interface InteractionFilterClicked {
  type: typeof CudRecommendationTable.INTERACTION_FILTER_CLICKED;
  filterKey: keyof CudRecommendationFilters;
  filterValue: string;
}

interface InteractionViewRecommendationClicked {
  type: typeof CudRecommendationTable.INTERACTION_VIEW_RECOMMENDATION_CLICKED;
  recommendationID: string;
  contraryInsightRecommendation?: InsightRecommendation;
}

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace CudRecommendationTable {
  export type Interaction =
    | InteractionFilterClicked
    | InteractionViewRecommendationClicked;
}

export default CudRecommendationTable;
