import { DateHelper } from "@/lib/dates";
import { createStructParam } from "@/lib/use-query-params";
import Dropdown from "@/ui-lib/components/Dropdown";
import Grid from "@/ui-lib/components/Grid";
import { DateRange } from "@/utils/dates";
import { useDebounce } from "@/utils/timers";
import { useTheme } from "@emotion/react";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { QueryFilter } from "@ternary/api-lib/analytics/types";
import { getDateRangeFromDurationType } from "@ternary/api-lib/analytics/utils";
import { UnitType } from "@ternary/api-lib/constants/analytics";
import {
  ChartType,
  DataSource,
  DurationType,
  Operator,
  TimeGranularity,
} from "@ternary/api-lib/constants/enums";
import Button from "@ternary/api-lib/ui-lib/components/Button";
import useReferenceIfEqual from "@ternary/api-lib/ui-lib/hooks/useReferenceIfEqual";
import AreaChart from "@ternary/web-ui-lib/charts/AreaChart";
import StackedBarChart from "@ternary/web-ui-lib/charts/StackedBarChart";
import Box from "@ternary/web-ui-lib/components/Box";
import Flex from "@ternary/web-ui-lib/components/Flex";
import Icon from "@ternary/web-ui-lib/components/Icon";
import Text from "@ternary/web-ui-lib/components/Text";
import { differenceInHours, endOfDay } from "date-fns";
import React, { useMemo, useState } from "react";
import {
  DateParam,
  DecodedValueMap,
  createEnumParam,
  useQueryParams,
  withDefault,
} from "use-query-params";
import { z } from "zod";
import useGetRawData from "../../../../api/analytics/useGetRawData";
import InsightsSelector from "../../../../components/InsightsSelector";
import useAvailableGlobalDate from "../../../../hooks/useAvailableGlobalDate";
import DateRangeControls from "../../../../ui-lib/components/DateRangeControls/DateRangeControls";
import copyText from "../../copyText";
import useGetAWSDatabaseElastiCacheGroups from "../hooks/useGetAWSDatabaseElastiCacheGroups";
import useGetAWSDatabaseElastiCacheSpendSummaries from "../hooks/useGetAWSDatabaseElastiCacheSpendSummaries";
import {
  AWSDatabaseElastiCacheGroup,
  AWSDatabaseElastiCacheInstanceGroupFilters,
  awsDatabaseElastiCacheDimensions,
  awsDatabaseElastiCacheMeasures,
} from "../types";
import AWSDatabaseElastiCacheGroupTable from "./AWSDatabaseElastiCacheGroupTable";
import AWSDatabaseElastiCacheGroupTableControls from "./AWSDatabaseElastiCacheGroupTableControls";
import AWSDatabaseElastiCacheInstanceMeters from "./AWSDatabaseElastiCacheInstanceMeters";

const AWSDatabaseElastiCacheCostChartOption = {
  USAGE_TYPE: "USAGE_TYPE",
  USAGE_ACCOUNT_ID: "USAGE_ACCOUNT_ID",
  REGION: "REGION",
  CACHE_ENGINE: "CACHE_ENGINE",
  INSTANCE_TYPE: "INSTANCE_TYPE",
  CLUSTER_ID: "CLUSTER_ID",
} as const;

type AWSDatabaseElastiCacheCostChartOption =
  (typeof AWSDatabaseElastiCacheCostChartOption)[keyof typeof AWSDatabaseElastiCacheCostChartOption];

const AWSDatabaseElastiCacheUsageChartOption = {
  BACKUP_STORAGE: "BACKUP_STORAGE",
  NODE_USAGE: "NODE_USAGE",
} as const;

type AWSDatabaseElastiCacheUsageChartOption =
  (typeof AWSDatabaseElastiCacheUsageChartOption)[keyof typeof AWSDatabaseElastiCacheUsageChartOption];

type Interaction =
  | AWSDatabaseElastiCacheGroupTable.Interaction
  | AWSDatabaseElastiCacheGroupTableControls.Interaction;

type QueryParams = DecodedValueMap<typeof queryParamConfigMap>;

type QueryParamState = {
  dateRange: DateRange;
  dateRangeGranularity: TimeGranularity;
  duration: DurationType;
  instanceGroupFilters: AWSDatabaseElastiCacheInstanceGroupFilters;
  selectedCostChartOption: AWSDatabaseElastiCacheCostChartOption;
  selectedUsageChartOption: AWSDatabaseElastiCacheUsageChartOption;
};

const instanceGroupFiltersDefault = {
  clusterId: null,
  payerAccountId: null,
  usageAccountId: null,
  instanceType: null,
  cacheEngine: null,
  instanceMemory: null,
  instanceVcpu: null,
  region: null,
};

const instanceGroupFiltersStruct = z.object({
  clusterId: z.nullable(z.string()),
  payerAccountId: z.nullable(z.string()),
  usageAccountId: z.nullable(z.string()),
  instanceType: z.nullable(z.string()),
  cacheEngine: z.nullable(z.string()),
  instanceMemory: z.nullable(z.string()),
  instanceVcpu: z.nullable(z.string()),
  region: z.nullable(z.string()),
});

const costChartOptionEnum = createEnumParam(
  Object.values(AWSDatabaseElastiCacheCostChartOption)
);
const durationEnum = createEnumParam(Object.values(DurationType));
const usageChartOptionEnum = createEnumParam(
  Object.values(AWSDatabaseElastiCacheUsageChartOption)
);

const queryParamConfigMap = {
  dre: DateParam,
  drs: DateParam,
  duration: withDefault(durationEnum, DurationType.LAST_THIRTY_DAYS),
  filters: createStructParam(instanceGroupFiltersStruct),
  cco: withDefault(
    costChartOptionEnum,
    AWSDatabaseElastiCacheCostChartOption.CLUSTER_ID
  ),
  uco: withDefault(
    usageChartOptionEnum,
    AWSDatabaseElastiCacheUsageChartOption.BACKUP_STORAGE
  ),
};

export default function AWSDatabaseElastiCacheContainer() {
  const theme = useTheme();
  const globalDate = useAvailableGlobalDate();

  //
  // STATE
  //

  const [queryParams, setQueryParams] = useQueryParams(queryParamConfigMap);

  const queryParamState = useReferenceIfEqual(getQueryParamState(queryParams));

  const [searchText, setSearchText] = useState("");

  const debouncedSearchText = useDebounce(searchText);

  //
  // QUERIES
  //

  const dateRange = globalDate.date ?? queryParamState.dateRange;

  const costDimensions = getCostDimensionsFromOption(
    queryParamState.selectedCostChartOption
  );

  const costMeasures = getCostMeasuresFromOption(
    queryParamState.selectedCostChartOption
  );

  const usageDimensions = getUsageDimensionsFromOption(
    queryParamState.selectedUsageChartOption
  );

  const usageMeasures = getUsageMeasuresFromOption(
    queryParamState.selectedUsageChartOption
  );

  const { data: costChartData = [], isFetching: isLoadingCostChartData } =
    useGetRawData({
      dataSource: DataSource.AWS_DATABASE_ELASTICACHE,
      dateRange,
      dimensions: costDimensions,
      granularity: queryParamState.dateRangeGranularity,
      measures: costMeasures,
      queryFilters: [
        ...getCostFiltersFromOption(queryParamState.selectedCostChartOption),
        ...getInstanceGroupQueryFilters(queryParamState.instanceGroupFilters),
      ],
    });

  const AWSDatabaseElastiCacheSpendSummaries =
    useGetAWSDatabaseElastiCacheSpendSummaries({});

  const [{ data: currentMTD }, { data: lastMonthFull }, { data: lastMTD }] =
    AWSDatabaseElastiCacheSpendSummaries;

  const isLoadingSpendSummaries = AWSDatabaseElastiCacheSpendSummaries.some(
    (summary) => summary.isFetching
  );

  const { data: usageChartData = [], isFetching: isLoadingUsageChartData } =
    useGetRawData({
      dataSource: DataSource.AWS_DATABASE_ELASTICACHE,
      dateRange,
      dimensions: usageDimensions,
      granularity: queryParamState.dateRangeGranularity,
      measures: usageMeasures,
      queryFilters: getInstanceGroupQueryFilters(
        queryParamState.instanceGroupFilters
      ),
    });

  const { data: instanceGroups, isFetching: isLoadingInstanceGroups } =
    useGetAWSDatabaseElastiCacheGroups({
      dateRange,
      filters: [
        {
          name: awsDatabaseElastiCacheDimensions.clusterId,
          operator: Operator.SET,
        },
      ],
    });

  //
  // MODIFIED QUERY DATA
  //

  const filteredInstanceGroups = useMemo(() => {
    return getFilteredInstanceGroups({
      allInstanceGroups: instanceGroups ?? [],
      instanceGroupFilters: queryParamState.instanceGroupFilters,
      searchText: debouncedSearchText,
    });
  }, [
    debouncedSearchText,
    queryParamState.instanceGroupFilters,
    instanceGroups,
  ]);

  const instanceGroupCSVData = useMemo(
    () => getInstanceGroupCSVData(filteredInstanceGroups),
    [filteredInstanceGroups]
  );

  //
  // INTERACTIONS
  //

  function handleInteraction(interaction: Interaction) {
    switch (interaction.type) {
      case AWSDatabaseElastiCacheGroupTable.INTERACTION_FILTER_CLICKED: {
        const nextFilters = { ...queryParamState.instanceGroupFilters };

        nextFilters[interaction.filterKey] = interaction.filterValue;

        setQueryParams({ filters: nextFilters });
        break;
      }
      case AWSDatabaseElastiCacheGroupTableControls.INTERACTION_REMOVE_FILTER_CLICKED: {
        const nextFilters = { ...queryParamState.instanceGroupFilters };

        nextFilters[interaction.filterKey] = null;

        if (Object.values(nextFilters).every((value) => value === null)) {
          setQueryParams({ filters: null });
        } else {
          setQueryParams({ filters: nextFilters });
        }
        break;
      }
      case AWSDatabaseElastiCacheGroupTableControls.INTERACTION_SEARCH_TEXT_UPDATED: {
        setSearchText(interaction.searchText);
        break;
      }
    }
  }

  // If demo tenant, must pass in absolute date range to override date range snapshot in the reportContentContainer
  const costChartReportSnapshot = {
    chartType: ChartType.STACKED_BAR,
    dateRange:
      queryParamState.duration === DurationType.CUSTOM || globalDate
        ? queryParamState.dateRange
        : null,
    dataSource: DataSource.AWS_DATABASE_ELASTICACHE,
    dimensions: costDimensions,
    durationType: queryParamState.duration,
    isFiscalMode: false,
    fillMissingDates: true,
    fiscalPeriodMap: null,
    granularity: queryParamState.dateRangeGranularity,
    measures: costMeasures,
    queryFilters: [
      {
        name: costDimensions[0],
        operator: Operator.NOT_EQUALS,
        values: ["null"],
      },
    ],
    name: copyText.awsComputeCostReportSnapshotName,
    xAxisKey: "timestamp",
  };

  const usageChartReportSnapshot = {
    chartType: ChartType.AREA,
    dateRange:
      queryParamState.duration === DurationType.CUSTOM || globalDate
        ? queryParamState.dateRange
        : null,
    dataSource: DataSource.AWS_DATABASE_ELASTICACHE,
    dimensions: [],
    durationType: queryParamState.duration,
    isFiscalMode: false,
    fillMissingDates: true,
    fiscalPeriodMap: null,
    granularity: queryParamState.dateRangeGranularity,
    measures: usageMeasures,
    name: copyText.awsComputeUsageReportSnapshotName,
    xAxisKey: "timestamp",
  };

  return (
    <Box width="100%" paddingTop={theme.space_md}>
      <Box
        backgroundColor={theme.panel_backgroundColor}
        borderRadius={theme.borderRadius_2}
        marginBottom={theme.space_lg}
        padding={theme.space_md}
      >
        <AWSDatabaseElastiCacheInstanceMeters
          isLoading={isLoadingSpendSummaries}
          lastMonthSpend={lastMonthFull?.totalCost ?? 0}
          lastMTDSpend={lastMTD?.totalCost ?? 0}
          thisMTDSpend={currentMTD?.totalCost ?? 0}
        />
      </Box>
      <Flex
        backgroundColor={theme.panel_backgroundColor}
        borderRadius={theme.borderRadius_1}
        justifyContent="flex-end"
        marginBottom={theme.space_lg}
        padding={theme.space_md}
      >
        <DateRangeControls
          dateRange={dateRange}
          durationType={queryParamState.duration}
          hiddenOptions={[
            DurationType.LAST_NINETY_DAYS,
            DurationType.QUARTER_TO_DATE,
            DurationType.YEAR_TO_DATE,
          ]}
          maxDate={new DateHelper().date}
          onChangeDateRange={(duration, newDateRange) => {
            setQueryParams({
              duration,
              ...(newDateRange && newDateRange[0] && newDateRange[1]
                ? {
                    drs: newDateRange[0],
                    dre: newDateRange[1],
                  }
                : {
                    drs: null,
                    dre: null,
                  }),
            });
          }}
        />
      </Flex>

      <Grid
        gridColumnGap={theme.space_lg}
        gridTemplateColumns={`repeat(2, calc(50% - (${theme.space_lg} / 2) ))`}
      >
        <InsightsSelector
          resourceName={copyText.awsComputeCostReportSnapshotName}
          reportSnapshot={costChartReportSnapshot}
        >
          <Flex
            backgroundColor={theme.panel_backgroundColor}
            borderRadius={theme.borderRadius_2}
            direction="column"
            height={500}
            padding={theme.space_md}
          >
            <Flex justifyContent="space-between" paddingBottom={theme.space_md}>
              <Text fontSize={theme.h3_fontSize}>
                {copyText.awsComputeOptimizationsChartTitleCost}
              </Text>

              {/* COST DROPDOWN */}
              <Dropdown
                options={costChartOptions.map((option) => ({
                  ...option,
                  onClick: () =>
                    setQueryParams({
                      cco: option.value,
                    }),
                }))}
                placement="bottom-end"
                selectedOption={
                  costChartOptions.find(
                    (option) =>
                      option.value === queryParamState.selectedCostChartOption
                  ) ?? costChartOptions[0]
                }
              >
                <Button
                  iconEnd={<Icon icon={faChevronDown} />}
                  secondary
                  size="small"
                  width={140}
                >
                  {getOptionLabel(queryParamState.selectedCostChartOption)}
                </Button>
              </Dropdown>
            </Flex>

            {/* COST CHART */}
            <Box flex="1 0 0">
              <StackedBarChart
                data={costChartData}
                dimensions={costDimensions.map((dimension) => ({
                  name: dimension,
                  isDate: false,
                }))}
                isLoading={isLoadingCostChartData}
                maxGroupings={7}
                measures={costMeasures.map(getMeasureWithUnit)}
                showLegend
                showTooltip
                timeSeriesGranularity={queryParamState.dateRangeGranularity}
                xAxisKey="timestamp"
              />
            </Box>
          </Flex>
        </InsightsSelector>
        <InsightsSelector
          resourceName={copyText.awsComputeUsageReportSnapshotName}
          reportSnapshot={usageChartReportSnapshot}
        >
          <Flex
            backgroundColor={theme.panel_backgroundColor}
            borderRadius={theme.borderRadius_2}
            direction="column"
            height={500}
            padding={theme.space_md}
          >
            <Flex justifyContent="space-between" paddingBottom={theme.space_md}>
              <Text fontSize={theme.h3_fontSize}>
                {copyText.awsComputeOptimizationsChartTitleUsage}
              </Text>

              <Box flex="0 1 100%" />

              {/* USAGE DROPDOWN */}
              <Dropdown
                options={usageChartOptions.map((option) => ({
                  ...option,
                  onClick: () =>
                    setQueryParams({
                      uco: option.value,
                    }),
                }))}
                placement="bottom-end"
                selectedOption={
                  usageChartOptions.find(
                    (option) =>
                      option.value === queryParamState.selectedUsageChartOption
                  ) ?? usageChartOptions[0]
                }
              >
                <Button
                  iconEnd={<Icon icon={faChevronDown} />}
                  secondary
                  size="small"
                  width={140}
                >
                  {getOptionLabel(queryParamState.selectedUsageChartOption)}
                </Button>
              </Dropdown>
            </Flex>

            {/* USAGE CHART */}
            <Box flex="1 0 0">
              <AreaChart
                data={usageChartData}
                hideTotal
                dimensions={usageDimensions.map((dimension) => ({
                  name: dimension,
                  isDate: false,
                }))}
                isLoading={isLoadingUsageChartData}
                measures={usageMeasures.map(getMeasureWithUnit)}
                readableKeys={readableMeasures}
                showLegend
                showTooltip
                stacked
                timeSeriesGranularity={queryParamState.dateRangeGranularity}
                yAxisLabel={getUsageYAxisLabelFromOption(
                  queryParamState.selectedUsageChartOption
                )}
                xAxisKey="timestamp"
              />
            </Box>
          </Flex>
        </InsightsSelector>
      </Grid>

      <Box
        backgroundColor={theme.panel_backgroundColor}
        borderRadius={theme.borderRadius_1}
        marginVertical={theme.space_lg}
        padding={theme.space_md}
      >
        <AWSDatabaseElastiCacheGroupTableControls
          csvData={instanceGroupCSVData}
          debouncedSearchText={debouncedSearchText}
          instanceGroupFilters={queryParamState.instanceGroupFilters}
          searchText={searchText}
          onInteraction={handleInteraction}
        />
      </Box>

      <Box width="100%" overflowX="auto">
        <AWSDatabaseElastiCacheGroupTable
          instanceGroups={filteredInstanceGroups}
          isLoadingInstanceGroups={isLoadingInstanceGroups}
          onInteraction={handleInteraction}
        />
      </Box>
    </Box>
  );
}

// COST OPTIONS
const costChartOptions = [
  AWSDatabaseElastiCacheCostChartOption.USAGE_TYPE,
  AWSDatabaseElastiCacheCostChartOption.USAGE_ACCOUNT_ID,
  AWSDatabaseElastiCacheCostChartOption.REGION,
  AWSDatabaseElastiCacheCostChartOption.CACHE_ENGINE,
  AWSDatabaseElastiCacheCostChartOption.INSTANCE_TYPE,
  AWSDatabaseElastiCacheCostChartOption.CLUSTER_ID,
].map((costOption) => ({
  label: getOptionLabel(costOption),
  value: costOption,
}));

// USAGE OPTIONS
const usageChartOptions = [
  AWSDatabaseElastiCacheUsageChartOption.BACKUP_STORAGE,
  AWSDatabaseElastiCacheUsageChartOption.NODE_USAGE,
].map((usageOption) => ({
  label: getOptionLabel(usageOption),
  value: usageOption,
}));

function getCostFiltersFromOption(
  option: AWSDatabaseElastiCacheCostChartOption
): QueryFilter[] {
  switch (option) {
    case AWSDatabaseElastiCacheCostChartOption.CACHE_ENGINE:
      return [
        {
          name: awsDatabaseElastiCacheDimensions.cacheEngine,
          operator: Operator.SET,
        },
      ];
    case AWSDatabaseElastiCacheCostChartOption.CLUSTER_ID:
      return [
        {
          name: awsDatabaseElastiCacheDimensions.clusterId,
          operator: Operator.SET,
        },
      ];
    case AWSDatabaseElastiCacheCostChartOption.INSTANCE_TYPE:
      return [
        {
          name: awsDatabaseElastiCacheDimensions.instanceType,
          operator: Operator.SET,
        },
      ];
    case AWSDatabaseElastiCacheCostChartOption.REGION:
    case AWSDatabaseElastiCacheCostChartOption.USAGE_ACCOUNT_ID:
    case AWSDatabaseElastiCacheCostChartOption.USAGE_TYPE:
      return [];
    default: {
      const _exhaustiveCheck: never = option;
      return _exhaustiveCheck;
    }
  }
}

function getCostDimensionsFromOption(
  option: AWSDatabaseElastiCacheCostChartOption
) {
  switch (option) {
    case AWSDatabaseElastiCacheCostChartOption.CACHE_ENGINE:
      return [awsDatabaseElastiCacheDimensions.cacheEngine];
    case AWSDatabaseElastiCacheCostChartOption.CLUSTER_ID:
      return [awsDatabaseElastiCacheDimensions.clusterId];
    case AWSDatabaseElastiCacheCostChartOption.INSTANCE_TYPE:
      return [awsDatabaseElastiCacheDimensions.instanceType];
    case AWSDatabaseElastiCacheCostChartOption.REGION:
      return [awsDatabaseElastiCacheDimensions.region];
    case AWSDatabaseElastiCacheCostChartOption.USAGE_ACCOUNT_ID:
      return [awsDatabaseElastiCacheDimensions.usageAccountId];
    case AWSDatabaseElastiCacheCostChartOption.USAGE_TYPE:
      return [];
    default: {
      const _exhaustiveCheck: never = option;
      return _exhaustiveCheck;
    }
  }
}

function getCostMeasuresFromOption(
  option: AWSDatabaseElastiCacheCostChartOption
) {
  switch (option) {
    case AWSDatabaseElastiCacheCostChartOption.CACHE_ENGINE:
      return [awsDatabaseElastiCacheMeasures.cost];
    case AWSDatabaseElastiCacheCostChartOption.CLUSTER_ID:
      return [awsDatabaseElastiCacheMeasures.cost];
    case AWSDatabaseElastiCacheCostChartOption.INSTANCE_TYPE:
      return [awsDatabaseElastiCacheMeasures.cost];
    case AWSDatabaseElastiCacheCostChartOption.REGION:
      return [awsDatabaseElastiCacheMeasures.cost];
    case AWSDatabaseElastiCacheCostChartOption.USAGE_ACCOUNT_ID:
      return [awsDatabaseElastiCacheMeasures.cost];
    case AWSDatabaseElastiCacheCostChartOption.USAGE_TYPE:
      return [
        awsDatabaseElastiCacheMeasures.nodeCost,
        awsDatabaseElastiCacheMeasures.backupStorageCost,
      ];
    default: {
      const _exhaustiveCheck: never = option;
      return _exhaustiveCheck;
    }
  }
}

const measuresWithUnit: { name: string; unit: UnitType }[] = [
  {
    name: awsDatabaseElastiCacheMeasures.backupStorageCost,
    unit: UnitType.CURRENCY,
  },
  {
    name: awsDatabaseElastiCacheMeasures.backupStorageUsage,
    unit: UnitType.BINARY_BYTES,
  },
  {
    name: awsDatabaseElastiCacheMeasures.cost,
    unit: UnitType.CURRENCY,
  },
  {
    name: awsDatabaseElastiCacheMeasures.nodeCost,
    unit: UnitType.CURRENCY,
  },
  {
    name: awsDatabaseElastiCacheMeasures.nodeUsage,
    unit: UnitType.STANDARD,
  },
];

function getMeasureWithUnit(measure: string) {
  return (
    [...measuresWithUnit].find((other) => other.name === measure) ?? {
      name: measure,
      unit: UnitType.STANDARD,
    }
  );
}

type CSVData = {
  headers: { key: string; label: string }[];
  rows: Record<string, string | number>[];
};

const instanceGroupCSVAccessors = [
  awsDatabaseElastiCacheDimensions.payerAccountId,
  awsDatabaseElastiCacheDimensions.usageAccountId,
  awsDatabaseElastiCacheDimensions.cacheEngine,
  awsDatabaseElastiCacheDimensions.instanceFamily,
  awsDatabaseElastiCacheDimensions.instanceType,
  awsDatabaseElastiCacheDimensions.instanceMemory,
  awsDatabaseElastiCacheDimensions.instanceVcpu,
  awsDatabaseElastiCacheDimensions.instanceNetworkPerformance,
  awsDatabaseElastiCacheDimensions.region,
  awsDatabaseElastiCacheMeasures.cost,
  awsDatabaseElastiCacheMeasures.backupStorageCost,
  awsDatabaseElastiCacheMeasures.nodeCost,
  awsDatabaseElastiCacheMeasures.backupStorageUsage,
  awsDatabaseElastiCacheMeasures.nodeUsage,
] as const;

function getInstanceGroupCSVData(
  groups: AWSDatabaseElastiCacheGroup[]
): CSVData {
  if (!groups.length) {
    return { headers: [], rows: [] };
  }

  const rows = groups.map((group) => ({
    payerAccountId: group.payerAccountId,
    usageAccountId: group.usageAccountId,
    cacheEngine: group.cacheEngine,
    instanceFamily: group.instanceFamily,
    instanceType: group.instanceType,
    instanceMemory: group.instanceMemory,
    instanceVcpu: group.instanceVcpu,
    instanceNetworkPerformance: group.instanceNetworkPerformance,
    region: group.region,
    cost: group.cost,
    backupStorageCost: group.backupStorageCost,
    nodeCost: group.nodeCost,
    backupStorageUsage: group.backupStorageUsage,
    nodeUsage: group.nodeUsage,
  }));

  const headers = instanceGroupCSVAccessors.map((csvAccessor) => {
    // ensure rows has a value for each accessor
    const key: keyof (typeof rows)[number] = csvAccessor;

    // ensure copyText has a value for each accessor
    const copyTextKey: keyof typeof copyText = `awsDatabaseElastiCacheTableHeader_${csvAccessor}`;
    const label = copyText[copyTextKey];

    return { key, label };
  });

  return { headers, rows };
}

type GetFilteredInstanceGroupsParams = {
  allInstanceGroups: AWSDatabaseElastiCacheGroup[];
  instanceGroupFilters: AWSDatabaseElastiCacheInstanceGroupFilters;
  searchText: string | null;
};

function getFilteredInstanceGroups(
  params: GetFilteredInstanceGroupsParams
): AWSDatabaseElastiCacheGroup[] {
  const searchText = (params.searchText ?? "").toLowerCase().trim();

  return params.allInstanceGroups.filter((instanceGroup) => {
    if (
      !isInstanceGroupPassingFilters(instanceGroup, params.instanceGroupFilters)
    ) {
      return false;
    }

    if (!isSearchTextInInstanceGroup(instanceGroup, searchText)) {
      return false;
    }

    return true;
  });
}

function getInstanceGroupQueryFilters(
  instanceGroupFilters: AWSDatabaseElastiCacheInstanceGroupFilters
) {
  const queryFilters: QueryFilter[] = [];

  [
    awsDatabaseElastiCacheDimensions.clusterId,
    awsDatabaseElastiCacheDimensions.payerAccountId,
    awsDatabaseElastiCacheDimensions.usageAccountId,
    awsDatabaseElastiCacheDimensions.instanceType,
    awsDatabaseElastiCacheDimensions.cacheEngine,
    awsDatabaseElastiCacheDimensions.instanceMemory,
    awsDatabaseElastiCacheDimensions.instanceVcpu,
    awsDatabaseElastiCacheDimensions.region,
  ].forEach((key) => {
    const filterValue = instanceGroupFilters[key];

    if (filterValue === null) return;

    if (filterValue === "") {
      queryFilters.push({
        name: key,
        operator: Operator.NOT_SET,
      });

      return;
    }

    queryFilters.push({
      name: key,
      operator: Operator.EQUALS,
      values: [filterValue],
    });
  });

  return queryFilters;
}

function getOptionLabel(
  option:
    | AWSDatabaseElastiCacheCostChartOption
    | AWSDatabaseElastiCacheUsageChartOption
) {
  const copyTextKey: keyof typeof copyText = `awsDatabaseElastiCacheChartOption_${option}`;

  return copyText[copyTextKey];
}

function getQueryParamState(queryParams: QueryParams): QueryParamState {
  const dateRange =
    queryParams.drs && queryParams.dre
      ? [queryParams.drs, queryParams.dre]
      : getDateRangeFromDurationType(queryParams.duration);

  const dateRangeDurationInHours =
    dateRange[0] && dateRange[1]
      ? differenceInHours(endOfDay(dateRange[1]), dateRange[0])
      : 0;

  const isSmallDateRange =
    dateRangeDurationInHours > 0 && dateRangeDurationInHours <= 48;

  const dateRangeGranularity = isSmallDateRange
    ? TimeGranularity.HOUR
    : TimeGranularity.DAY;

  return {
    dateRange,
    dateRangeGranularity,
    duration: queryParams.duration,
    instanceGroupFilters: queryParams.filters ?? instanceGroupFiltersDefault,
    selectedCostChartOption: queryParams.cco,
    selectedUsageChartOption: queryParams.uco,
  };
}

const readableMeasures = {
  productVCPUChart: "productVCPU",
  productMemoryBytesChart: "productMemoryBytes",
};

function getUsageDimensionsFromOption(
  option: AWSDatabaseElastiCacheUsageChartOption
) {
  switch (option) {
    case AWSDatabaseElastiCacheUsageChartOption.BACKUP_STORAGE:
      return [awsDatabaseElastiCacheDimensions.usageAccountId];
    case AWSDatabaseElastiCacheUsageChartOption.NODE_USAGE:
      return [
        awsDatabaseElastiCacheDimensions.clusterId,
        awsDatabaseElastiCacheDimensions.usageAccountId,
      ];
    default: {
      const _exhaustiveCheck: never = option;
      return _exhaustiveCheck;
    }
  }
}

function getUsageMeasuresFromOption(
  option: AWSDatabaseElastiCacheUsageChartOption
) {
  switch (option) {
    case AWSDatabaseElastiCacheUsageChartOption.BACKUP_STORAGE:
      return [awsDatabaseElastiCacheMeasures.backupStorageUsage];
    case AWSDatabaseElastiCacheUsageChartOption.NODE_USAGE:
      return [awsDatabaseElastiCacheMeasures.nodeUsage];
    default: {
      const _exhaustiveCheck: never = option;
      return _exhaustiveCheck;
    }
  }
}

function getUsageYAxisLabelFromOption(
  option: AWSDatabaseElastiCacheUsageChartOption
): string {
  switch (option) {
    case AWSDatabaseElastiCacheUsageChartOption.BACKUP_STORAGE:
      return copyText.unitByteMonths;
    case AWSDatabaseElastiCacheUsageChartOption.NODE_USAGE:
      return copyText.unitNodeHours;
    default: {
      const _exhaustiveCheck: never = option;
      return _exhaustiveCheck;
    }
  }
}

function isInstanceGroupPassingFilters(
  instanceGroup: AWSDatabaseElastiCacheGroup,
  filters: AWSDatabaseElastiCacheInstanceGroupFilters
): boolean {
  const filterKeys = [
    awsDatabaseElastiCacheDimensions.clusterId,
    awsDatabaseElastiCacheDimensions.payerAccountId,
    awsDatabaseElastiCacheDimensions.usageAccountId,
    awsDatabaseElastiCacheDimensions.instanceType,
    awsDatabaseElastiCacheDimensions.cacheEngine,
    awsDatabaseElastiCacheDimensions.instanceMemory,
    awsDatabaseElastiCacheDimensions.instanceVcpu,
    awsDatabaseElastiCacheDimensions.region,
  ];

  for (const filterKey of filterKeys) {
    const filterValue = filters[filterKey];
    if (
      filterValue !== null &&
      instanceGroup[filterKey].toLowerCase().trim() !==
        filterValue.toLowerCase().trim()
    ) {
      return false;
    }
  }

  return true;
}

function isSearchTextInInstanceGroup(
  instanceGroup: AWSDatabaseElastiCacheGroup,
  searchText: string
): boolean {
  if (searchText === "") return true;

  const values = [
    instanceGroup.clusterId,
    instanceGroup.payerAccountId,
    instanceGroup.usageAccountId,
    instanceGroup.instanceType,
    instanceGroup.cacheEngine,
    instanceGroup.instanceMemory,
    instanceGroup.instanceVcpu,
    instanceGroup.region,
  ].map((value) => (value === "" ? "null" : value));

  return values.some((value) =>
    value.toLowerCase().trim().includes(searchText)
  );
}
