import { useActivityTracker } from "@/context/ActivityTrackerProvider";
import ConfirmationModal from "@/ui-lib/components/ConfirmationModal";
import { AlertType, postAlert } from "@/utils/alerts";
import { useTheme } from "@emotion/react";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { useQueryClient } from "@tanstack/react-query";
import { CaseStatus, CaseType } from "@ternary/api-lib/constants/enums";
import { FindUsersQueryResult } from "@ternary/api-lib/core/queries/FindUsersQuery";
import { actions } from "@ternary/api-lib/telemetry";
import Button from "@ternary/api-lib/ui-lib/components/Button";
import Box from "@ternary/web-ui-lib/components/Box";
import Flex from "@ternary/web-ui-lib/components/Flex";
import Icon from "@ternary/web-ui-lib/components/Icon";
import { keyBy, uniq } from "lodash";
import React, { useState } from "react";
import useGetUsersByTenantID from "../../../api/core/hooks/useGetUsersByTenantID";
import paths from "../../../constants/paths";
import useAuthenticatedUser from "../../../hooks/useAuthenticatedUser";
import { useNavigateWithSearchParams } from "../../../lib/react-router";
import ButtonGroup from "../../../ui-lib/components/ButtonGroup";
import SelectCheckbox from "../../../ui-lib/components/SelectCheckbox";
import TextInput from "../../../ui-lib/components/TextInput";
import { getFullName } from "../../../utils/UserUtils";
import getMergeState from "../../../utils/getMergeState";
import copyText from "../copyText";
import queryKeys from "../hooks/queryKeys";
import useGetCasesByTenantID from "../hooks/useGetCasesByTenantID";
import useUpdateCases from "../hooks/useUpdateCases";
import { CaseCategory } from "../types";
import CaseManagementTable from "./CaseTable";

interface State {
  categoryFilter: string;
  typeFilters: string[];
  showUpdateCasesModal: boolean;
  searchText: string;
  selectedCaseID: string;
  selectedCaseIDs: string[];
}

const initialState: State = {
  categoryFilter: CaseCategory.PERSONAL,
  typeFilters: [],
  showUpdateCasesModal: false,
  searchText: "",
  selectedCaseID: "",
  selectedCaseIDs: [],
};

type Interaction = CaseManagementTable.Interaction;

export default function CaseManagementContainer() {
  const activityTracker = useActivityTracker();
  const authenticatedUser = useAuthenticatedUser();
  const theme = useTheme();
  const queryClient = useQueryClient();

  const navigate = useNavigateWithSearchParams();

  //
  // State
  //

  const [state, setState] = useState<State>(initialState);
  const mergeState = getMergeState(setState);

  //
  // Queries
  //

  const {
    data: _cases = [],
    isLoading: isLoadingCases,
    refetch: refetchCase,
  } = useGetCasesByTenantID(authenticatedUser.tenant.id);

  const { data: users = [], isLoading: isLoadingUsers } = useGetUsersByTenantID(
    authenticatedUser.tenant.id
  );

  const usersKeyedByIDs = keyBy(users, "id");

  function filterRevokedUsers(userIDs: string[]) {
    return userIDs.reduce<FindUsersQueryResult["users"]>((accum, userID) => {
      const user = usersKeyedByIDs[userID];

      return user ? [...accum, user] : accum;
    }, []);
  }

  let cases = _cases.map((_case) => ({
    ..._case,
    assignees: filterRevokedUsers(_case.assigneeIDs),
    followers: filterRevokedUsers(_case.followerIDs),
    user: usersKeyedByIDs[_case.createdByID]
      ? usersKeyedByIDs[_case.createdByID]
      : null,
  }));

  cases = (() => {
    switch (state.categoryFilter) {
      case CaseCategory.PERSONAL: {
        const userCases = cases.filter(
          (userCase) =>
            userCase.status === CaseStatus.OPEN &&
            (userCase.createdByID === authenticatedUser.id ||
              (userCase.assignees &&
                userCase.assignees.some(
                  (assingee) =>
                    getFullName(assingee) === getFullName(authenticatedUser)
                )) ||
              (userCase.followers &&
                userCase.followers.some(
                  (follower) =>
                    getFullName(follower) === getFullName(authenticatedUser)
                )))
        );

        return userCases;
      }
      case CaseCategory.ALL: {
        return cases.filter((caseItem) => caseItem.status === CaseStatus.OPEN);
      }
      case CaseCategory.CLOSED: {
        return cases.filter(
          (caseItem) => caseItem.status === CaseStatus.CLOSED
        );
      }
      default: {
        return cases.filter((caseItem) => caseItem.status === CaseStatus.OPEN);
      }
    }
  })();

  //
  // Mutations
  //

  const { isPending: isUpdatingCases, mutate: updateCases } = useUpdateCases({
    onError: () => {
      postAlert({
        message: copyText.errorUpdatingCaseMessage,
        type: AlertType.ERROR,
      });
    },
    onMutate: () => {
      activityTracker.captureAction(actions.UPDATE_CASE);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: queryKeys.cases });

      postAlert({
        message: copyText.successUpdatingCaseMessage,
        type: AlertType.SUCCESS,
      });

      refetchCase();

      mergeState({ showUpdateCasesModal: false, selectedCaseIDs: [] });
    },
  });

  //
  // Handlers
  //

  function handleInteraction(interaction: Interaction): void {
    switch (interaction.type) {
      case CaseManagementTable.INTERACTION_SELECT_BUTTON_CLICKED: {
        const caseID = interaction.caseID;
        navigate(paths._case.replace(":caseID", caseID));
        return;
      }
      case CaseManagementTable.INTERACTION_CHECKBOX_CLICKED: {
        const caseID = interaction.caseID;
        setState((prevState) => {
          const updatedSeletedCase = interaction.isSelected
            ? [...prevState.selectedCaseIDs, caseID]
            : prevState.selectedCaseIDs.filter((id) => id !== caseID);
          return {
            ...prevState,
            selectedCaseIDs: updatedSeletedCase,
          };
        });
        return;
      }
      case CaseManagementTable.INTERACTION_SELECT_ALL_CHECKBOX_CLICKED: {
        setState((prevState) => {
          const updatedSeletedCase = interaction.isSelected
            ? uniq([...prevState.selectedCaseIDs, ...interaction.caseIDs])
            : [];
          return {
            ...prevState,
            selectedCaseIDs: updatedSeletedCase,
            selectedAllCasesToEdit: interaction.isSelected,
          };
        });
        return;
      }
    }
  }

  function handleClickCaseCategory(categoryFilter: string): void {
    mergeState({ categoryFilter: categoryFilter, selectedCaseIDs: [] });
  }

  function handleUpdateCases(): void {
    const selectedCasesToUpdate: { caseID: string; status: CaseStatus }[] = [];
    cases.map((_case) =>
      state.selectedCaseIDs.includes(_case.id)
        ? selectedCasesToUpdate.push({
            caseID: _case.id,
            status: CaseStatus.CLOSED,
          })
        : null
    );
    updateCases({ paramsArray: selectedCasesToUpdate });
  }

  function handleChangeTypeFilter(values: string[]): void {
    mergeState({ typeFilters: values });
  }

  //
  // Render
  //

  if (state.searchText.length > 0) {
    cases = cases.filter((_case) => {
      const str = state.searchText.toLowerCase();

      const caseName = _case.name.toLowerCase();
      const caseType = _case.type.toLowerCase();
      const status = _case.status.toLowerCase();
      const createdBy =
        _case.user !== null ? getFullName(_case.user).toLowerCase() : "";
      const assignees = _case.assignees
        .map((assignee) => getFullName(assignee))
        .join("")
        .toLowerCase();

      return (
        caseName.includes(str) ||
        caseType.includes(str) ||
        status.includes(str) ||
        createdBy.includes(str) ||
        assignees.includes(str)
      );
    });
  }

  if (state.typeFilters.length > 0) {
    cases = cases.filter((_case) => state.typeFilters.includes(_case.type));
  }

  const items = [
    {
      label: copyText.buttonGroupMyCases,
      type: CaseCategory.PERSONAL,
    },
    {
      label: copyText.buttonGroupAllCases,
      type: CaseCategory.ALL,
    },
    {
      label: copyText.buttonGroupClosedCases,
      type: CaseCategory.CLOSED,
    },
  ];

  const caseTypeOptions = [
    {
      label: copyText.caseType_INVESTIGATION,
      value: CaseType.INVESTIGATION,
    },
    {
      label: copyText.caseType_OPTIMIZATION,
      value: CaseType.OPTIMIZATION,
    },
    {
      label: copyText.caseType_TASK,
      value: CaseType.TASK,
    },
  ];

  return (
    <Flex direction="column">
      <Flex alignItems="center" justifyContent="center">
        <ButtonGroup
          defaultChecked={0}
          items={items}
          width={120}
          onChange={handleClickCaseCategory}
        />
      </Flex>
      <Flex
        alignItems="center"
        justifyContent={
          state.selectedCaseIDs.length ? "space-between" : "flex-end"
        }
        backgroundColor={theme.panel_backgroundColor}
        borderRadius={theme.borderRadius_1}
        marginTop={theme.space_md}
        padding={theme.space_md}
      >
        {state.selectedCaseIDs.length &&
        state.categoryFilter !== CaseCategory.CLOSED ? (
          <Box marginRight={theme.space_md}>
            <Button
              primary
              variant="danger"
              size="small"
              onClick={() => mergeState({ showUpdateCasesModal: true })}
            >
              {`${copyText.selectedCasesButtonLabel} ${state.selectedCaseIDs.length}`}
            </Button>
          </Box>
        ) : null}
        <Flex alignItems="center" justifyContent="flex-end">
          <SelectCheckbox
            isClearable
            isLoading={isLoadingCases}
            options={caseTypeOptions}
            placeholder={copyText.caseManagementTypeFilterPlaceholer}
            selectedValues={state.typeFilters}
            width={150}
            onChange={handleChangeTypeFilter}
          />
          <Box width={300} marginLeft={theme.space_sm}>
            <TextInput
              iconEnd={
                <Icon color={theme.text_color_secondary} icon={faSearch} />
              }
              placeholder={copyText.searchInputPlaceholder}
              size="large"
              value={state.searchText}
              onChange={(event) =>
                mergeState({ searchText: event.target.value })
              }
            />
          </Box>
        </Flex>
      </Flex>

      <Box marginTop={theme.space_md}>
        <CaseManagementTable
          cases={cases}
          categoryFilter={state.categoryFilter}
          isLoading={isLoadingCases || isLoadingUsers}
          selectedCaseIDs={state.selectedCaseIDs}
          onInteraction={handleInteraction}
        />
      </Box>
      {state.showUpdateCasesModal && (
        <ConfirmationModal
          isLoading={isUpdatingCases}
          message={copyText.closeCaseModalMessage.replace(
            "%Count%",
            `${state.selectedCaseIDs.length}`
          )}
          title={copyText.closeCaseConfirmationTitle}
          variant="danger"
          onCancel={() => mergeState({ showUpdateCasesModal: false })}
          onConfirm={handleUpdateCases}
        />
      )}
    </Flex>
  );
}
