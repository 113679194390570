import ErrorBoundary from "@/components/ErrorBoundary";
import Tabs from "@/ui-lib/components/Tabs";
import React from "react";
import { Navigate } from "react-router-dom";
import { StringParam, useQueryParams, withDefault } from "use-query-params";
import paths from "../../../constants/paths";
import useGatekeeper from "../../../hooks/useGatekeeper";
import DashboardManagementContainer from "../../reporting-engine/components/DashboardManagementContainer";
import ReportManagementContainer from "../../reporting-engine/components/ReportManagementContainer";
import copyText from "../copyText";
import GlobalGrantManagementContainer from "./GlobalGrantManagementContainer";
import { RoleManagementContainer } from "./RoleManagementContainer";
import TenantManagementContainer from "./TenantManagementContainer";

export function InternalAdminPage(): JSX.Element {
  const gatekeeper = useGatekeeper();

  const [searchParamState, setSearchParamState] = useQueryParams({
    tab: withDefault(StringParam, "tenants"),
  });

  //
  // Render
  //

  if (!gatekeeper.canAccessInternalAdmin) {
    return <Navigate to={paths._home} replace />;
  }

  const containers = [
    {
      component: <TenantManagementContainer isInternal />,
      label: copyText.tenantsTabLabel,
      value: "tenants",
    },
    {
      component: <GlobalGrantManagementContainer />,
      label: copyText.globalRolesTabLabel,
      value: "grants",
    },
    {
      component: <DashboardManagementContainer isInternal />,
      label: copyText.dashboardsTabLabel,
      value: "dashboards",
    },
    {
      component: <ReportManagementContainer isInternal />,
      label: copyText.reportsTabLabel,
      value: "reports",
    },
    {
      component: <RoleManagementContainer />,
      label: copyText.rolesTabLabel,
      value: "roles",
    },
  ];

  return (
    <ErrorBoundary boundaryName="InternalAdminPage">
      <Tabs
        activeValue={searchParamState.tab}
        tabs={containers}
        onSelect={(tab: string) => setSearchParamState({ tab })}
      />
    </ErrorBoundary>
  );
}

export default InternalAdminPage;
