import { isPlainObject } from "lodash";
import UError from "unilib-error";
import z from "zod";
import { Failure, NewMessage } from "../Message";
import { JiraIntegrationEntity as _JiraIntegrationEntity } from "../types";

//
// Queries
//

const FindJiraIntegrationByTenantIDQuerySchema = z.object({
  tenantID: z.string().nonempty(),
});

export class FindJiraIntegrationByTenantIDQuery extends NewMessage(
  FindJiraIntegrationByTenantIDQuerySchema
) {}

export type FindJiraIntegrationQuery = FindJiraIntegrationByTenantIDQuery;

//
// Result
//

type JiraIntegrationEntity = Omit<
  _JiraIntegrationEntity,
  "encryptedAPIToken" | "instanceID" | "issueTypeID"
>;

export class FindJiraIntegrationQueryResult {
  public readonly integration: JiraIntegrationEntity;

  public constructor({ integration }: { integration: JiraIntegrationEntity }) {
    if (!isPlainObject(integration)) {
      throw new UError(
        `${this.constructor.name}.constructor/INVALID_INTEGRATION`,
        { context: { integration } }
      );
    }

    this.integration = integration;
  }
}

//
// Failure
//

export class FindJiraIntegrationQueryFailure extends Failure {
  public constructor({ reason }: { reason: string }) {
    super({ reason });
  }

  public static readonly REASON_INTEGRATION_NOT_FOUND = `${FindJiraIntegrationQueryFailure.name}/INTEGRATION_NOT_FOUND`;
  public static readonly REASON_PERMISSION_DENIED = `${FindJiraIntegrationQueryFailure.name}/PERMISSION_DENIED`;
  public static readonly REASON_TENANT_NOT_FOUND = `${FindJiraIntegrationQueryFailure.name}/TENANT_NOT_FOUND`;
  public static readonly REASON_UNEXPECTED = `${FindJiraIntegrationQueryFailure.name}/UNEXPECTED`;
}
