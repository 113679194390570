import { useQuery } from "@tanstack/react-query";
import CoreAPIClient from "../../../api/core/CoreAPIClient";
import CoreAPIClientError from "../../../api/core/CoreAPIClientError";
import { useCoreAPIClient } from "../../../api/core/CoreAPIClientProvider";
import { UseQueryOptions, UseQueryResult } from "../../../lib/react-query";
import queryKeys from "./queryKeys";

type DataIntegrationEntity = Awaited<
  ReturnType<CoreAPIClient["getDataIntegrationsByTenantID"]>
>[0];

type Options = UseQueryOptions<DataIntegrationEntity[], CoreAPIClientError>;
type Result = UseQueryResult<DataIntegrationEntity[], CoreAPIClientError>;

export default function useGetDataIntegrationsByTenantID(
  tenantID: string,
  options?: Options
): Result {
  const client = useCoreAPIClient();

  return useQuery({
    queryKey: [queryKeys.dataIntegrations, tenantID],
    queryFn: async () => client.getDataIntegrationsByTenantID(tenantID),
    ...options,
  });
}
