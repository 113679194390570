import Modal from "@/ui-lib/components/Modal";
import { TableLegacy } from "@/ui-lib/components/Table";
import TextInput from "@/ui-lib/components/TextInput";
import { DateRange } from "@/utils/dates";
import { useTheme } from "@emotion/react";
import { faFileExport, faSearch } from "@fortawesome/free-solid-svg-icons";
import Button from "@ternary/api-lib/ui-lib/components/Button";
import Box from "@ternary/web-ui-lib/components/Box";
import Flex from "@ternary/web-ui-lib/components/Flex";
import Icon from "@ternary/web-ui-lib/components/Icon";
import Text from "@ternary/web-ui-lib/components/Text";
import { formatDate } from "@ternary/web-ui-lib/utils/dates";
import { formatCurrency } from "@ternary/web-ui-lib/utils/formatNumber";
import prettyBytes from "pretty-bytes";
import React, { useMemo, useState } from "react";
import { CSVLink } from "react-csv";
import { Column, SortByFn } from "react-table";
import copyText from "../../copyText";
import { S3Bucket } from "../types";

interface Props {
  buckets: S3Bucket[];
  date: DateRange;
  onClose: () => void;
}

type TableData = {
  bucketName: string;
  bytesDownloaded: number;
  bytesUploaded: number;
  lineItemUsageAccountID: string;
  networkCost: number;
  numberOfObjects: number;
  operationsCost: number;
  requestCount: number;
  storageClass: string;
  storageCost: number;
  storageUsedBytes: number;
  totalCost: number;
};

type BucketCSVData = {
  bucketName: string;
  bytesDownloaded: number;
  bytesUploaded: number;
  lineItemUsageAccountID: string;
  networkCost: number;
  numberOfObjects: number;
  operationsCost: number;
  requestCount: number;
  storageClass: string;
  storageCost: number;
  storageUsedBytes: number;
  totalCost: number;
};

export default function AWSStorageS3SubTable(props: Props): JSX.Element {
  const theme = useTheme();
  const [searchText, setSearchText] = useState("");

  const subTableTitle =
    props.buckets.length > 0
      ? `${props.buckets[0].accountID} / ${props.buckets[0].region}`
      : null;

  const columns = useMemo(
    (): Column<TableData>[] => [
      {
        accessor: "bucketName",
        align: "left",
        Header: copyText.s3SubTableBucketGroupHeader_bucketName,
        width: 150,
      },
      {
        accessor: "lineItemUsageAccountID",
        align: "center",
        Header: copyText.s3SubTableBucketGroupHeader_lineItemUsageAccountID,
        width: 100,
      },
      {
        accessor: "storageClass",
        align: "center",
        Header: copyText.s3SubTableBucketGroupHeader_storageClass,
        width: 80,
      },
      {
        accessor: "storageCost",
        align: "right",
        Cell: ({ value: storageCost }) => (
          <>{formatCurrency({ number: storageCost })}</>
        ),
        Header: copyText.s3SubTableBucketGroupHeader_storageCost,
        sortDescFirst: false,
        sortType: numberSort,
        width: 80,
      },
      {
        accessor: "networkCost",
        align: "right",
        Cell: ({ value: networkCost }) => (
          <>{formatCurrency({ number: networkCost })}</>
        ),
        Header: copyText.s3SubTableBucketGroupHeader_networkCost,
        sortDescFirst: false,
        sortType: numberSort,
        width: 80,
      },
      {
        accessor: "operationsCost",
        align: "right",
        Cell: ({ value: operationsCost }) => (
          <>{formatCurrency({ number: operationsCost })}</>
        ),
        Header: copyText.s3SubTableBucketGroupHeader_operationsCost,
        sortDescFirst: false,
        sortType: numberSort,
        width: 80,
      },
      {
        accessor: "requestCount",
        align: "center",
        Header: copyText.s3SubTableBucketGroupHeader_requestCount,
        width: 60,
      },
      {
        accessor: "bytesDownloaded",
        align: "center",
        Header: copyText.s3SubTableBucketGroupHeader_bytesDownloaded,
        Cell: ({ value }) => <>{prettyBytes(value)}</>,
        width: 60,
      },
      {
        accessor: "bytesUploaded",
        align: "center",
        Header: copyText.s3SubTableBucketGroupHeader_bytesUploaded,
        Cell: ({ value }) => <>{prettyBytes(value)}</>,
        width: 60,
      },
      {
        accessor: "numberOfObjects",
        align: "center",
        Header: copyText.s3SubTableBucketGroupHeader_numberOfObjects,
        width: 60,
      },
      {
        accessor: "storageUsedBytes",
        align: "center",
        Header: copyText.s3SubTableBucketGroupHeader_storageUsedBytes,
        Cell: ({ value }) => <>{prettyBytes(value)}</>,
        width: 80,
      },
      {
        accessor: "totalCost",
        align: "right",
        Cell: ({ value: totalCost }) => (
          <>{formatCurrency({ number: totalCost })}</>
        ),
        Header: copyText.s3SubTableBucketGroupHeader_totalCost,
        sortDescFirst: false,
        sortType: numberSort,
        width: 80,
      },
    ],
    [props.buckets]
  );

  const data: TableData[] = useMemo(() => {
    const tableData = props.buckets.map((bucket) => {
      return {
        bucketName: bucket.bucketName,
        storageUsedBytes: bucket.storageUsedBytes,
        bytesDownloaded: bucket.bytesDownloaded,
        bytesUploaded: bucket.bytesUploaded,
        lineItemUsageAccountID: bucket.lineItemUsageAccountID,
        numberOfObjects: bucket.numberOfObjects,
        requestCount: bucket.requestCount,
        storageClass: bucket.storageClass,
        storageCost: bucket.storageCost,
        networkCost: bucket.networkCost,
        operationsCost: bucket.operationsCost,
        totalCost: bucket.cost,
      };
    });
    return tableData;
  }, [props.buckets]);

  const filteredData = useMemo(() => {
    if (searchText.length === 0) return data;

    return data.filter((bucket) => {
      const str = searchText.toLowerCase();

      const bucketName = bucket.bucketName.toLowerCase();
      const linkedID = bucket.lineItemUsageAccountID.toLowerCase();
      const storageClass = bucket.storageClass.toLowerCase();

      return (
        bucketName.includes(str) ||
        storageClass.includes(str) ||
        linkedID.includes(str)
      );
    });
  }, [data, searchText]);

  return (
    <Modal
      isOpen
      showCloseButton
      closeOnClickOutside={false}
      onClose={props.onClose}
      minWidth={1100}
    >
      <Modal.Header>
        <Flex
          justifyContent="space-between"
          marginLeft={theme.space_sm}
          width="100%"
        >
          <Text fontSize={theme.h4_fontSize}>{subTableTitle}</Text>

          <Flex alignItems="center">
            <Box width={250} marginRight={theme.space_lg}>
              <TextInput
                disabled={!props.buckets.length}
                iconEnd={
                  <Icon color={theme.text_color_secondary} icon={faSearch} />
                }
                placeholder={copyText.searchInputPlaceholder}
                size="medium"
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
              />
            </Box>

            <CSVLink
              data={flattenDataToCSV(filteredData)}
              filename={`${subTableTitle}-${formatDate(
                new Date(),
                "MM-dd-yyyy"
              )}`}
            >
              <Button
                iconStart={<Icon color="inherit" icon={faFileExport} />}
                primary
                size="small"
                marginRight={theme.space_md}
              >
                {copyText.exportButtonLabel}
              </Button>
            </CSVLink>
          </Flex>
        </Flex>
      </Modal.Header>
      <Modal.Body>
        <Box minHeight={500}>
          <TableLegacy
            columns={columns}
            data={filteredData}
            initialState={{ sortBy: [{ id: "totalCost", desc: true }] }}
            showPagination
            sortable
          />
        </Box>
      </Modal.Body>
    </Modal>
  );
}

function isTableDataKey(key: string): key is keyof TableData {
  const tableDataKeySet: (keyof TableData)[] = [
    "bucketName",
    "bytesDownloaded",
    "bytesUploaded",
    "lineItemUsageAccountID",
    "numberOfObjects",
    "requestCount",
    "storageClass",
    "storageCost",
    "totalCost",
  ];

  return tableDataKeySet.includes(key as keyof TableData);
}

const numberSort: SortByFn<TableData> = (rowA, rowB, columnID, desc) => {
  if (!isTableDataKey(columnID)) return 0;

  const a = rowA.original[columnID];
  const b = rowB.original[columnID];

  if (typeof a !== "number" && typeof b !== "number") return 0;
  if (typeof a !== "number") return desc ? -1 : 1;
  if (typeof b !== "number") return desc ? 1 : -1;

  return a === b ? 0 : a < b ? -1 : 1;
};

function flattenDataToCSV(data: TableData[]): BucketCSVData[] {
  if (!data.length) {
    return [];
  }

  return data.map((datum) => ({
    bucketName: datum.bucketName,
    storageUsedBytes: datum.storageUsedBytes,
    bytesDownloaded: datum.bytesDownloaded,
    bytesUploaded: datum.bytesUploaded,
    lineItemUsageAccountID: datum.lineItemUsageAccountID,
    numberOfObjects: datum.numberOfObjects,
    requestCount: datum.requestCount,
    storageClass: datum.storageClass,
    storageCost: datum.storageCost,
    networkCost: datum.networkCost,
    operationsCost: datum.operationsCost,
    totalCost: datum.totalCost,
  }));
}
