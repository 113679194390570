import {
  DataSource,
  DurationType,
  Operator,
} from "@ternary/api-lib/constants/enums";

export type Input<T = unknown> = {
  value: T;
  hasChanged?: boolean;
  isValid: boolean;
};

export const RECOMMENDATION_KIND_ANOMALY = "anomaly";
export const RECOMMENDATION_KIND_DEFAULT = "recommendation";

export type QueryFilter = {
  name: string;
  operator: Operator;
  values: string[] | null;
};

export type SortRule = {
  id: string;
  desc: boolean;
};

export type ReportConfig = {
  dataSource: DataSource;
  dimensions: string[];
  durationType: DurationType;
  endDate: string;
  filters: QueryFilter[];
  measures: string[];
  startDate: string;
};
