import CoreAPIClientError from "@/api/core/CoreAPIClientError";
import { useCoreAPIClient } from "@/api/core/CoreAPIClientProvider";
import { useQuery } from "@tanstack/react-query";
import { UseQueryOptions, UseQueryResult } from "../../../lib/react-query";

export function useGenerateMspBillingManifestCsv(
  manifestID: string,
  options?: UseQueryOptions<Buffer, CoreAPIClientError>
): UseQueryResult<Buffer, CoreAPIClientError> {
  const client = useCoreAPIClient();

  return useQuery({
    queryKey: ["manifests", manifestID],
    queryFn: () => client.generateMspBillingManifestCsv(manifestID),
    ...options,
  });
}
