import {
  featureSpecificSizesAndPositions,
  fontStyles,
  sizesAndPositions,
  Theme,
} from "./default";
import palette from "./palette";

const dark_DATA_VIZ_COLORS = [
  palette.blue[60],
  palette.violet[60],
  palette.yellow[60],
  palette.green[60],
  palette.teal[60],
  palette.orange[60],
  palette.purple[60],
  palette.blue[20],
  palette.aqua[60],
  palette.violet[20],
  palette.red[60],
  palette.green[20],
];

const globalColors = {
  background_color_disabled: palette.grey[100],
  background_color: palette.black,
  backgroundColor_successPrimary_hover: palette.green[70],
  bar_chart_cursor_fill: palette.grey[100],
  border_color_dark: palette.grey[60],
  border_color: palette.grey[90],
  box_shadow: "rgba(0, 0, 0, 0.2)",
  chart_axis_text: palette.grey[50],
  chart_cartesian_grid_lines: palette.grey[100],
  chart_cursor_hover: palette.black,
  chart_cursor_line_color: palette.grey[50],
  chart_fill_opacity: 0.7,
  chart_legend_header: palette.grey[100],
  cloud_status_warning: palette.yellow[60],
  date_picker_selected_highlight: palette.blue[100],
  data_visualization_colors: dark_DATA_VIZ_COLORS,
  divider_color: palette.grey[60],
  elevated_background_color: palette.grey[100],
  feedback_negative_background: palette.red[100],
  feedback_negative_outline: palette.red[100],
  feedback_negative: palette.red[60],
  feedback_neutral_background: palette.grey[100],
  feedback_neutral_outline: palette.grey[20],
  feedback_neutral: palette.grey[60],
  feedback_positive_background: palette.green[100],
  feedback_positive_outline: palette.green[20],
  feedback_positive: palette.green[60],
  feedback_warn_background: palette.yellow[100],
  feedback_warn_outline: palette.yellow[20],
  feedback_warn: palette.yellow[60],
  input_background_color: palette.black,
  link_color_hover: palette.blue[70],
  link_color: palette.blue[60],
  loading_outline_line_color_bold: palette.grey[60],
  loading_outline_line_color: palette.grey[50],
  loading_skeleton_bg_color: palette.grey[100],
  loading_skeleton_fg_color: palette.offBlack,
  loading_spinner_color: palette.blue[20],
  overlay_background_color: "rgba(0, 0, 0, 0.65)",
  panel_backgroundColor: palette.offBlack,
  primary_color_background_inverse: palette.blue[20],
  primary_color_background: palette.blue[60],
  primary_color_border: palette.blue[20],
  primary_color_focus: palette.blue[20],
  primary_color_hover: palette.blue[70],
  primary_color_text: palette.blue[60],
  secondary_color_background_focus: palette.grey[50],
  secondary_color_background_hover: palette.grey[60],
  secondary_color_background: palette.grey[90],
  secondary_color_border: palette.grey[100],
  secondary_color: palette.offBlack,
  select_color: palette.white,
  select_control_border_color_focused: palette.white,
  select_control_border_color: palette.grey[100],
  select_is_active: palette.violet[60],
  side_drawer_background_color: palette.offBlack,
  side_drawer_header_background_color: palette.grey[100],
  side_drawer_header_text_color: palette.white,
  switch_color_off: palette.grey[50],
  switch_color_on: palette.green[60],
  table_cell_background_color_selected: palette.grey[100],
  table_cell_background_color: palette.offBlack,
  table_cell_background_color_masked: palette.grey[90],
  table_footer_background_color: palette.grey[90],
  table_header_background_color: palette.grey[50],
  table_header_text_color: palette.offBlack,
  table_row_background_color_hover: palette.grey[100],
  tag_background_color_danger: palette.red[100],
  tag_background_color_primary: palette.aqua[100],
  tag_background_color_warning: palette.yellow[100],
  tag_background_color: palette.grey[90],
  tag_button_background_color_danger_hover: palette.red[70],
  tag_button_background_color_hover: palette.grey[100],
  tag_button_background_color_warning_hover: palette.yellow[70],
  tag_button_color_danger: palette.red[20],
  tag_button_color_warning: palette.yellow[20],
  tag_button_color: palette.grey[20],
  text_color_disabled: palette.grey[90],
  text_color_inverse: palette.white,
  text_color_placeholder: palette.grey[50],
  text_color_secondary_disabled: palette.grey[100],
  text_color_secondary: palette.grey[50],
  text_color: palette.grey[20],
  tooltip_background_color: "rgba(0, 0, 0, 0.8)",
  tooltip_disabled_cell_color: palette.grey[100],
  tooltip_text_color_hover: palette.aqua[60],
  tooltip_text_color: palette.white,
};

const featureSpecificColors = {
  alert_event_feed_alert_event_node: palette.red[60],
  alert_event_feed_rule_event_node: palette.blue[60],
  alert_event_feed_timeline: palette.grey[90],
  aws_cud_color_covered: palette.violet[60],
  aws_cud_color_estimated: palette.green[60],
  aws_cud_color_on_demand: palette.blue[60],
  aws_cud_color_vis_0_sp_cost: palette.blue[60],
  aws_cud_color_vis_1_ri_cost: palette.violet[60],
  aws_cud_color_vis_2_on_demand_cost: palette.yellow[60],
  aws_cud_color_vis_3_unused_cost: palette.red[60],
  aws_cud_color_vis_lost: palette.red[60],
  aws_cud_color_vis_saved: palette.green[60],
  big_query_duration_1: palette.grey[90],
  big_query_duration_2: palette.grey[60],
  big_query_duration_3: palette.grey[50],
  big_query_duration_4: palette.grey[30],
  biqquery_flat_rate_commitment_color: palette.grey[10],
  budgets_chart_fill_actual: palette.blue[60],
  budgets_chart_fill_highlighted_version: palette.violet[20],
  budgets_chart_fill_latest_version: palette.green[60],
  budgets_chart_fill_overage_actual: palette.red[60],
  budgets_chart_fill_overage_forecast: palette.red[60],
  budgets_chart_fill_overage_historical: palette.red[60],
  budgets_chart_fill_underspend: palette.blue[60],
  budgets_chart_reference_line: palette.grey[20],
  budgets_chart_stroke: palette.grey[100],
  budgets_expand_button_background_color: palette.green[60],
  compute_vizibility_aggregation: palette.violet[60],
  cud_chart_fill_coverable_cost: palette.blue[60],
  cud_chart_fill_existing_coverage: palette.violet[60],
  cud_chart_fill_recommendation: palette.green[60],
  cud_chart_fill_recommendation_max_savings: palette.teal[60],
  cud_chart_fill_total_spend: palette.blue[20],
  cud_color_1yr_stroke: palette.blue[60],
  cud_color_1yr: palette.blue[60],
  cud_color_3yr_stroke: palette.green[60],
  cud_color_3yr: palette.green[60],
  cud_color_on_demand: palette.grey[50],
  data_table_border: palette.grey[100],
  date_picker_day_name_color: palette.grey[100],
  default_button_text_color: palette.white,
  eco_impact: palette.green[60],
  fiscal_calendar_month_background_past: palette.black,
  fiscal_calendar_month_header_background_past: palette.offBlack,
  fiscal_calendar_month_header_background: palette.grey[100],
  kubernetes_chart_color_total: palette.aqua[60],
  kubernetes_chart_color_used: palette.green[60],
  modal_background_color: palette.offBlack,
  net_cost_table_cell_background_color: palette.offBlack,
  net_cost_total_highlight: palette.yellow[100],
  ramp_plans_actuals: palette.white,
  ramp_plans_derived_projection: palette.blue[60],
  ramp_plans_projected_stroke: palette.grey[50],
  ramp_plans_projected: palette.grey[60],
  recommendations_chart_line_color: palette.blue[60],
  recommendations_meter_color_active: palette.green[60],
  recommendations_meter_color_as_designed: palette.violet[60],
  recommendations_meter_color_snoozed: palette.blue[60],
  report_display_grip_color: palette.grey[50],
  report_favorite_color_hover: palette.yellow[20],
  report_favorite_color: palette.yellow[60],
  report_management_loader_color: palette.grey[100],
  report_management_row_hover: palette.grey[100],
  resource_selector_background_hover: "rgba(30,135,255, 0.3)",
  resource_selector_border: palette.blue[70],
  section_card_border: palette.grey[100],
  side_nav_background_color: palette.offBlack,
  side_nav_color_secondary: palette.white,
  tab_text_color: palette.grey[90],
  text_color_caption: palette.grey[90],
  toast_text_color: palette.offBlack,
  tooltip_text_color_hover: palette.aqua[60],
  top_nav_background_color: palette.offBlack,
  ubergraph_advanced_controls_background: palette.violet[60],
  ubergraph_advanced_controls_text: palette.violet[60],
  ubergraph_advanced_controls: palette.violet[20],
};

const stylePaths = {
  logo_path: "/assets/img/logo-inverted.svg",
};

export const darkTheme: Theme = {
  // Defaults
  ...fontStyles,
  ...sizesAndPositions,
  ...featureSpecificSizesAndPositions,

  // Theme Specific
  ...globalColors,
  ...featureSpecificColors,
  ...stylePaths,

  // Default Overrides
  fontWeight_bold: 600,
  fontWeight_semiBold: 500,
  fontWeight_thin: 100,
  h1_fontWeight: 500,
  h2_fontWeight: 400,
  h3_fontWeight: 400,
  h4_fontWeight: 400,
  h5_fontWeight: 200,
  h6_fontWeight: 200,
};
