import commonCopyText from "../common.copyText";

export default {
  ...commonCopyText,
  accountingOtherCredits: "All Other Credits",
  accountingSudsAndCuds: "SUDs and CUDs",
  announceKitButtonLabel: "What's New?",
  anomalyStatusNotResolved: "Not Resolved",
  anomalyStatusResolved: "Resolved",
  anomalyTableHeaderType: "Anomaly Type",
  bigQueryAggregationOptionJobType: "Group By: Job Type",
  bigQueryAggregationOptionProject: "Group By: Project",
  bigQueryAggregationOptionReservation: "Group By: Reservation",
  bigQueryAggregationOptionUser: "Group By: User",
  bigQueryAggregationSelectedJobTypes: "all job types",
  bigQueryAggregationSelectedProjects: "all projects",
  bigQueryAggregationSelectedReservations: "all reservations",
  bigQueryAggregationSelectedUsers: "all users",
  bigQueryClarificationTooltip: "Only includes jobs started during selected time frame",
  bigQueryClarificationTooltipCharts: "Durations chart shows queries by their start time. Click on a bar to zoom in to that time span and view more granular data.",
  bigQueryControlsSectionAggregationPlaceholder: "Select Aggregation",
  bigQueryControlsSectionButtonOnDemand: "On Demand",
  bigQueryControlsSectionButtonReservations: "Reservations",
  bigQueryControlsSectionExcludeLoadJobs: "Exclude Load Jobs",
  bigQueryControlsSectionTitle: "BigQuery Costs",
  bigQueryCostsSectionPricingExplanation: "On-Demand cost assumes list price of $6.25/TB. Your organization's negotiated price might differ from list.",
  bigQueryCostsSectionSubTitleDataset: "Dataset",
  bigQueryCostsSectionSubTitleJobType: "Job Type",
  bigQueryCostsSectionSubTitleProject: "Project",
  bigQueryCostsSectionSubTitleReservation: "Reservation",
  bigQueryCostsSectionSubTitleResource: "Resource",
  bigQueryCostsSectionSubTitleTable: "Table",
  bigQueryCostsSectionSubTitleUser: "User",
  bigQueryCostsSectionTitle: "Usage by",
  bigQueryCostsSubtitleTotaUsage: "Total Usage",
  bigQueryCostsSubtitleUsageByTable: "Usage By Table",
  bigQueryCostsSubtitleUsageOverTime: "Usage Over Time",
  bigQueryMeterTitleAverageJobDuration: "Average Job Duration",
  bigQueryMeterTitleAverageSlots: "Average Slots Run Rate",
  bigQueryMeterTitleTopProjects: "Top Projects",
  bigQueryMeterTitleTopReservations: "Top Reservations",
  bigQueryMeterTitleTopUsers: "Top Users",
  bigQueryMeterTitleTotalCost: "Total Cost",
  bigQueryPieChartTitle: "Totals Breakdown",
  bigQueryPlaceholderUnlabelled: "(unlabelled)",
  bigQueryTablesTableHeaderDatasetId: "Dataset ID",
  bigQueryTablesTableHeaderJobCount: "Job Count",
  bigQueryTablesTableHeaderJobDurationPerJob: "Avg. Duration",
  bigQueryTablesTableHeaderOnDemandCost: "On Demand Cost",
  bigQueryTablesTableHeaderSlotsConsumption: "Avg. Slots Run Rate",
  bigQueryTablesTableHeaderSlotsMS: "Avg. Slots/Job",
  bigQueryTablesTableHeaderTableId: "Table ID",
  bigQueryTimeSeriesChartXAxisLabel: "time",
  confirmation: "Confirmation",
  confirmationNo: "No",
  confirmationYes: "Yes",
  creditBreakdownTableTitle: "Total Credits Breakdown",
  creditDescriptionCUD: "Resource-based Committed Use contracts purchased for Compute Engine in return for deeply discounted prices for VM usage.",
  creditDescriptionCUDDollarBase: "Spend-based Committed Use contracts purchased for services in exchange for your commitment to spend a minimum amount.",
  creditNameCUD: "Committed Use Discount",
  creditNameSubscriptionBenefit: "Subscription Benefit",
  csvHeaderName: "Name",
  datePickerModalTitle: "Custom Date Range",
  dateRangeControlsButton30Days: "30 Days",
  dateRangeControlsButtonLastBillingInvoice: "Last Month",
  dateRangeControlsButtonMTD: "MTD",
  dateRangeControlsButtonYTD: "YTD",
  emptyPlaceholderInsufficientPermission: "Insufficient Permissions",
  globalDateSelectorApplyButtonLabel: "Apply",
  globalDateSelectorTitle: "Global Date Selector",
  globalDateSelectorRemoveDateButtonCaption: "Remove Date",
  globalDateSelectorCustomDateRangeLabel: "Custom",
  globalDateSelectorTooltipCaption: "%switch% global date",
  globalDateNotAvailableMessage: "Global date is not available on this page",
  labelApplicatorAddRuleButtonLabel: "Add New",
  labelApplicatorButtonApply: "Apply",
  labelApplicatorLabelKey: "Key",
  labelApplicatorPlaceholderValue: "Enter Values",
  labelApplicatorPlaceholderValueAdditional: "Enter Additional Value (optional)",
  labelApplicatorRemoveAllButtonLabel: "Remove All",
  labelApplicatorTitle: "Filter Page by Labels",
  labelBadgeAddFilter: 'Add Filter',
  labelSuggestionsError: "We were unable to load your labels, but you can still type your own.",
  meterCaptionOfYourBill: "of your bill",
  meterCaptionSinceLastMonth: "vs. this time last month",
  meterTitleLastMonthTotalSpend: "Last Month Total Spend",
  meterTitleLastMonthTotalSpendFiscal: "Last Fiscal Month Total Spend",
  meterTitleMSTD: "Monthly Spend To Date",
  meterTitleMSTDFiscal: "Fiscal Month Spend To Date",
  meterTitleSavingsOpportunities: "Savings Opportunities",
  meterValueNoChange: "No Change",
  modalTitleCreateBudget: "Create Budget",
  netCostSectionGrossModeDescription: "When filters are applied, we exclude credits and commitments costs to not misapply credits.",
  netCostSectionGrossModeTitle: "Gross Cost:",
  netCostSectionTitle: "Net Cost Explorer",
  noData: "No Data To Display",
  none: "none",
  notConfiguredPlaceholderAdminLink: "Take me to the Admin Page",
  notConfiguredPlaceholderText: "It appears this feature is not yet configured. Please consult the Admin Page for more information.",
  notConfiguredPlaceholderTitle: "Not Configured",
  notFoundHeader: "Page not found.",
  notFoundLinkLabel: "Click Here to Return Home",
  nullProjectId: "(no project)",
  paginationExplanation: "Showing %start% to %end% out of %total%",
  paginationStatusLabelOutOf: "out of",
  paginationStatusLabelRange: "%start% to %end%",
  paginationStatusLabelShowing: "Showing",
  rowLimitReached: "Row limit reached. To see the full result, you may want to export the dataset as a CSV",
  ruleAnd: "and",
  ruleOr: "or",
  sectionTitleCostExplorer: "Cost Explorer",
  sectionTitleSpendByProject: "Spend By Project",
  shareButton: "Share",
  shareButtonTooltip: "Link copied to clipboard.",
  spendTableEmptyPlaceholderText: "No Projects to Display",
  spendTableHeaderCredit: "Credit",
  spendTableHeaderGrossCost: "Gross Cost",
  spendTableHeaderNetCost: "Net Cost",
  spendTableHeaderProject: "Project",
  tableAriaToggleSortBy: "Toggle Sort",
  tenantModalPlaceholder: "Select Tenant",
  tenantModalChangeActiveTenantTitle: "Change Active Tenant",
  tenantModalChangeMspTenantTitle: "Change MSP Tenant",
  topNavDropdownItemChangeMspTenant: "Change MSP Tenant",
  topNavDropdownItemSelectMspTenant: "Select MSP Tenant",
  topNavDropdownItemInternalAdmin: "Internal Admin",
  topNavDropdownItemSettings: "Settings",
  topNavDropdownItemSwitchTenant: "Switch Tenant",
  topNavDropdownLogout: "Logout",
  topNavNetCostToggleTooltip: "Net Cost Coming Soon",
  total: "Total",
  totals: "Totals",
  totalsPieChartOverallTotal: "Overall Total",
  uberControlsAdvancedDescription: "Specify your preferred drilldown order, then click in to the graph.",
  uberControlsAggregation: "Aggregation",
  uberControlsAll: "All",
  uberControlsDatePickerLabelEnd: "End Date",
  uberControlsDatePickerLabelStart: "Start Date",
  uberControlsDatePickerTitle: "Custom Date Range",
  uberControlsDefaultAggregations: "Use Default Aggregations",
  uberControlsGroupedBy: "Grouped By: ",
  uberControlsToggleAdvanced: "Toggle Advanced Controls",
  uberEmptyPlaceholderText: "No Usage Data to Display",
  unitOnDemandCost: "On Demand Cost",
  unitSeconds: "Seconds",
  unitSlots: "Slots",
  unitTB: "TB",
};
