export const Role = {
  BASIC_USER: "BASIC_USER",
  FULL_ACCESS_USER: "FULL_ACCESS_USER",
  LIMITED_USER: "LIMITED_USER",
  PARTNER_ADMIN: "PARTNER_ADMIN",
  SYSTEM_ADMIN: "SYSTEM_ADMIN",
  TENANT_ADMIN: "TENANT_ADMIN",
} as const;

export type Role = (typeof Role)[keyof typeof Role];
