import { faArrowUpRightFromSquare } from "@fortawesome/free-solid-svg-icons";
import { createColumnHelper } from "@tanstack/react-table";
import Table from "@ternary/api-lib/ui-lib/charts/Table/Table";
import Box from "@ternary/api-lib/ui-lib/components/Box";
import Button from "@ternary/api-lib/ui-lib/components/Button";
import Icon from "@ternary/api-lib/ui-lib/components/Icon";
import Link from "@ternary/api-lib/ui-lib/components/Link";
import Tooltip from "@ternary/api-lib/ui-lib/components/Tooltip";
import { formatDate } from "@ternary/api-lib/ui-lib/utils/dates";
import React, { useMemo } from "react";
import GroupingTag from "../../../components/GroupingTag";
import GroupingTooltipContent from "../../../components/GroupingTooltip";
import paths from "../../../constants/paths";
import useAuthenticatedUser from "../../../hooks/useAuthenticatedUser";
import { CostAlertDimension } from "../../alert-tracking/types";
import { getStringifiedDelta } from "../../alert-tracking/utils";
import copyText from "../copyText";

type CostAlert = {
  id: string;
  tenantDocID: string;
  createdAt: string;
  actualValue: number;
  alertRuleID: string;
  eventTime: string;
  delta: number;
  expectedValue: { lowerBound: number; upperBound: number };
  tenantName: string;
  dimensions: CostAlertDimension[];
};

type TableData = CostAlert & {
  hasTenantAccess: boolean;
};

const columnHelper = createColumnHelper<TableData>();

interface Props {
  costAlerts: CostAlert[];
  isLoading: boolean;
  onInteraction: (interaction: MspCostAlertTable.Interaction) => void;
}

export function MspCostAlertTable(props: Props) {
  const authenticatedUser = useAuthenticatedUser();

  const columns = useMemo(
    () => [
      columnHelper.accessor("tenantName", {
        header: copyText.tableHeaderTenantName,
        size: 200,
      }),
      columnHelper.accessor("createdAt", {
        cell: (cell) => formatDate(new Date(cell.getValue()), "yyyy-MM-dd"),
        header: copyText.tableHeaderAlertedAt,
        size: 110,
      }),
      columnHelper.accessor("delta", {
        cell: (cell) => {
          return getStringifiedDelta(cell.row.original.actualValue, {
            lowerBound: cell.row.original.expectedValue.lowerBound,
            upperBound: cell.row.original.expectedValue.upperBound,
          });
        },
        header: copyText.tableHeaderDelta,
        size: 110,
      }),
      columnHelper.accessor("dimensions", {
        cell: ({ row }) => {
          const dimensionGrouping = row.original?.dimensions ?? [];
          if (dimensionGrouping.length > 0) {
            return (
              <Box minWidth={0}>
                <Tooltip
                  content={
                    <GroupingTooltipContent
                      dimensionGrouping={dimensionGrouping}
                    />
                  }
                  delayHide={250}
                  placement="top"
                >
                  <GroupingTag dimensionGrouping={dimensionGrouping} />
                </Tooltip>
              </Box>
            );
          }
          return "--";
        },
        header: copyText.tableHeaderGroupings,
        size: 145,
      }),
      columnHelper.display({
        id: "goToAnomaly",
        cell: (cell) => {
          const tenantDocID = cell.row.original.tenantDocID;
          const alertID = cell.row.original.id;
          const hasAccess = cell.row.original.hasTenantAccess;

          const buttonElement = (
            <Button
              secondary
              size="tiny"
              iconStart={<Icon icon={faArrowUpRightFromSquare} />}
            >
              {copyText.goToAnomalyDetailButtonLabel}
            </Button>
          );

          return hasAccess ? (
            <Link
              target="_blank"
              to={{
                pathname: paths._alertTracking,
                search: `?tenant_id=${tenantDocID}&alertID=${alertID}`,
              }}
            >
              {buttonElement}
            </Link>
          ) : (
            <Box
              onClick={() =>
                props.onInteraction({
                  type: MspCostAlertTable.INTERACTION_LINK_CLICKED,
                  tenantDocID,
                })
              }
            >
              {buttonElement}
            </Box>
          );
        },
        size: 195,
        meta: { align: "right" },
      }),
      columnHelper.display({
        id: "investigate",
        cell: ({ cell }) => {
          const tenantDocID = cell.row.original.tenantDocID;
          const alertID = cell.row.original.alertRuleID;
          const hasAccess = cell.row.original.hasTenantAccess;

          return (
            <Button
              secondary
              size="tiny"
              iconStart={<Icon icon={faArrowUpRightFromSquare} />}
              onClick={() => {
                props.onInteraction({
                  type: MspCostAlertTable.INTERACTION_INVESTIGATE_CLICKED,
                  alertID,
                  eventTime: cell.row.original.eventTime,
                  dimensions: cell.row.original.dimensions,
                  tenantDocID,
                  hasAccess,
                });
              }}
            >
              {copyText.goToReportButtonLabel}
            </Button>
          );
        },
        size: 190,
        meta: { align: "right" },
      }),
    ],
    [props.costAlerts]
  );

  const data = props.costAlerts.map((summary) => {
    const grant = authenticatedUser.grants.find(
      (grant) => grant.tenantDocID === summary.tenantDocID
    );

    return { ...summary, hasTenantAccess: !!grant };
  });

  return (
    <Table
      columns={columns}
      data={data}
      initialState={{ sorting: [{ id: "delta", desc: true }] }}
      isLoading={props.isLoading}
      showPagination
      sortable
    />
  );
}

MspCostAlertTable.INTERACTION_LINK_CLICKED =
  "MspChildCostAlertTable.INTERACTION_LINK_CLICKED" as const;
MspCostAlertTable.INTERACTION_INVESTIGATE_CLICKED =
  "MspChildCostAlertTable.INTERACTION_INVESTIGATE_CLICKED" as const;

interface InteractionLinkClicked {
  type: typeof MspCostAlertTable.INTERACTION_LINK_CLICKED;
  tenantDocID: string;
}
interface InteractionInvestigateClicked {
  type: typeof MspCostAlertTable.INTERACTION_INVESTIGATE_CLICKED;
  alertID: string;
  dimensions: CostAlertDimension[];
  eventTime: string;
  hasAccess: boolean;
  tenantDocID: string;
}

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace MspCostAlertTable {
  export type Interaction =
    | InteractionLinkClicked
    | InteractionInvestigateClicked;
}

export default MspCostAlertTable;
