import { useTheme } from "@emotion/react";
import Box from "@ternary/api-lib/ui-lib/components/Box";
import Text from "@ternary/api-lib/ui-lib/components/Text";
import React from "react";
import copyText from "../common.copyText";

type DimensionValue = {
  key: string;
  value: string | null;
};

type Props = {
  dimensionGrouping: DimensionValue[];
};

export default function GroupingTag(props: Props): JSX.Element {
  const theme = useTheme();

  if (props.dimensionGrouping.length === 0) {
    return <>--</>;
  }

  const tagText =
    props.dimensionGrouping.length === 1
      ? props.dimensionGrouping[0].value
      : copyText.groupingTagCount.replace(
          "%COUNT%",
          String(props.dimensionGrouping.length)
        );

  return (
    <Box
      border={`1px solid ${theme.text_color}`}
      borderRadius={theme.borderRadius_2}
      paddingHorizontal={theme.space_xs}
    >
      <Text truncate>{tagText}</Text>
    </Box>
  );
}
