import { useCSVDownloader } from "@/ui-lib/components/CSVDownloader";
import Dropdown from "@/ui-lib/components/Dropdown";
import LoadingSpinner from "@/ui-lib/components/LoadingSpinner";
import TextInput from "@/ui-lib/components/TextInput";
import { useTheme } from "@emotion/react";
import {
  faFileExport,
  faSearch,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import Button from "@ternary/api-lib/ui-lib/components/Button";
import Box from "@ternary/web-ui-lib/components/Box";
import Flex from "@ternary/web-ui-lib/components/Flex";
import Icon from "@ternary/web-ui-lib/components/Icon";
import Text from "@ternary/web-ui-lib/components/Text";
import { format } from "date-fns";
import React from "react";
import copyText from "../../copyText";
import {
  AzureDatabaseInstanceGroupFilters,
  azureDatabaseSQLDimensions,
} from "../types";

type CSVData = {
  headers: { key: string; label: string }[];
  rows: Record<string, string | number | boolean>[];
};

type Props = {
  csvData: CSVData;
  debouncedSearchText: string;
  instanceGroupFilters: AzureDatabaseInstanceGroupFilters;
  isLoadingAllInstances: boolean;
  searchText: string;
  onInteraction: (
    interaction: AzureDatabaseInstanceGroupTableControls.Interaction
  ) => void;
};

function AzureDatabaseInstanceGroupTableControls(props: Props) {
  const theme = useTheme();
  const { downloadCSV, csvElement } = useCSVDownloader();

  function handleDownloadInstanceGroupsCSV() {
    downloadCSV({
      data: props.csvData.rows,
      headers: props.csvData.headers,
      fileName: `azure-databases-instance-groups-${format(
        new Date(),
        "MM-dd-yyyy"
      )}`,
    });
  }

  const csvDropdownOptions = [
    {
      label: copyText.azureCSVDownloadInstance,
      value: copyText.azureCSVDownloadInstance,
      onClick: () =>
        props.onInteraction({
          type: AzureDatabaseInstanceGroupTableControls.INTERACTION_DOWNLOAD_INSTANCES_CLICKED,
        }),
    },
    {
      label: copyText.azureCSVDownloadInstanceGroup,
      value: copyText.azureCSVDownloadInstanceGroup,
      onClick: handleDownloadInstanceGroupsCSV,
    },
  ];

  return (
    <Flex alignItems="center" minHeight={30}>
      <Flex flex="1 0 0">
        {(
          [
            azureDatabaseSQLDimensions.databaseEngine,
            azureDatabaseSQLDimensions.location,
            azureDatabaseSQLDimensions.projectId,
          ] as const
        ).map((instanceGroupFilterKey) => {
          let filterValue = props.instanceGroupFilters[instanceGroupFilterKey];

          if (filterValue === null) return;

          if (filterValue === "") {
            filterValue = "null";
          }
          const copyTextKey: keyof typeof copyText = `azureDatabaseTableInstanceGroupHeader_${instanceGroupFilterKey}`;
          return (
            <Chip
              key={instanceGroupFilterKey}
              label={copyText[copyTextKey]}
              text={filterValue}
              onClick={() =>
                props.onInteraction({
                  type: AzureDatabaseInstanceGroupTableControls.INTERACTION_REMOVE_FILTER_CLICKED,
                  filterKey: instanceGroupFilterKey,
                })
              }
            />
          );
        })}
      </Flex>

      <Box marginHorizontal={theme.space_md}>
        <TextInput
          iconEnd={
            props.searchText === props.debouncedSearchText ||
            props.searchText === "" ? (
              <Icon color={theme.text_color_secondary} icon={faSearch} />
            ) : (
              <LoadingSpinner />
            )
          }
          placeholder={copyText.searchInputPlaceholder}
          size="large"
          value={props.searchText}
          onChange={(e) =>
            props.onInteraction({
              type: AzureDatabaseInstanceGroupTableControls.INTERACTION_SEARCH_TEXT_UPDATED,
              searchText: e.target.value,
            })
          }
        />
      </Box>

      <Box>
        {csvElement}

        <Dropdown
          options={csvDropdownOptions}
          setSelectedOption={false}
          placement="bottom-end"
        >
          <Button
            iconStart={
              props.isLoadingAllInstances ? (
                <LoadingSpinner
                  color={theme.feedback_neutral_outline}
                  size="xs"
                />
              ) : (
                <Icon color="inherit" icon={faFileExport} />
              )
            }
            marginLeft={theme.space_lg}
            secondary
            size="small"
          >
            {copyText.exportButtonLabel}
          </Button>
        </Dropdown>
      </Box>
    </Flex>
  );
}

type ChipProps = {
  text: string;
  label: string;
  onClick: () => void;
};

function Chip(props: ChipProps) {
  const theme = useTheme();

  return (
    <Flex
      alignItems="center"
      backgroundColor={theme.primary_color_background}
      backgroundColorOnHover={theme.tag_button_background_color_hover}
      borderRadius={theme.borderRadius_3}
      cursor="pointer"
      marginRight={theme.space_sm}
      paddingHorizontal={theme.space_sm}
      paddingRight={theme.space_sm}
      paddingVertical={theme.space_xxs}
      position="relative"
      onClick={props.onClick}
    >
      <Box>
        <Box height={8}>
          <Text
            bold
            color={theme.text_color_inverse}
            fontSize={theme.fontSize_xs}
          >
            {props.label}:
          </Text>
        </Box>

        <Box paddingLeft={theme.space_xxs}>
          <Text color={theme.text_color_inverse}>
            {props.text || copyText.azureDatabaseTableNull}
          </Text>
        </Box>
      </Box>

      <Box paddingLeft={theme.space_sm}>
        <Icon color={theme.text_color_inverse} icon={faTimes} size="xs" />
      </Box>
    </Flex>
  );
}

AzureDatabaseInstanceGroupTableControls.INTERACTION_DOWNLOAD_INSTANCES_CLICKED =
  `AzureDatabaseInstanceGroupTableControls.INTERACTION_DOWNLOAD_INSTANCES_CLICKED` as const;

AzureDatabaseInstanceGroupTableControls.INTERACTION_REMOVE_FILTER_CLICKED =
  `AzureDatabaseInstanceGroupTableControls.INTERACTION_REMOVE_FILTER_CLICKED` as const;

AzureDatabaseInstanceGroupTableControls.INTERACTION_SEARCH_TEXT_UPDATED =
  `AzureDatabaseInstanceGroupTableControls.INTERACTION_SEARCH_TEXT_UPDATED` as const;

interface InteractionDownloadInstancesClicked {
  type: typeof AzureDatabaseInstanceGroupTableControls.INTERACTION_DOWNLOAD_INSTANCES_CLICKED;
}
interface InteractionRemoveFilterClicked {
  type: typeof AzureDatabaseInstanceGroupTableControls.INTERACTION_REMOVE_FILTER_CLICKED;
  filterKey: keyof AzureDatabaseInstanceGroupFilters;
}

interface InteractionSearchTextUpdated {
  type: typeof AzureDatabaseInstanceGroupTableControls.INTERACTION_SEARCH_TEXT_UPDATED;
  searchText: string;
}

// eslint-disable-next-line @typescript-eslint/no-namespace
namespace AzureDatabaseInstanceGroupTableControls {
  export type Interaction =
    | InteractionDownloadInstancesClicked
    | InteractionRemoveFilterClicked
    | InteractionSearchTextUpdated;
}
export default AzureDatabaseInstanceGroupTableControls;
