import paths from "@/constants/paths";
import Form, { FormField } from "@/ui-lib/components/Form";
import LoadingSpinner from "@/ui-lib/components/LoadingSpinner";
import Modal from "@/ui-lib/components/Modal";
import Select from "@/ui-lib/components/Select";
import TextInput from "@/ui-lib/components/TextInput";
import getMergeState from "@/utils/getMergeState";
import { Theme, useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import {
  faGear,
  faInfoCircle,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { Role } from "@ternary/api-lib/constants/roles";
import Button from "@ternary/api-lib/ui-lib/components/Button";
import Tooltip from "@ternary/api-lib/ui-lib/components/Tooltip";
import Box from "@ternary/web-ui-lib/components/Box";
import Flex from "@ternary/web-ui-lib/components/Flex";
import Icon from "@ternary/web-ui-lib/components/Icon";
import Text from "@ternary/web-ui-lib/components/Text";
import { isEqual, keyBy } from "lodash";
import React, { ChangeEvent, MouseEvent, useRef, useState } from "react";
import isEmail from "validator/lib/isEmail";
import externalLinks from "../../../constants/externalLinks";
import useGatekeeper from "../../../hooks/useGatekeeper";
import Switch from "../../../ui-lib/components/Switch";
import copyText from "../copyText";
import { formatRole } from "../utils";

type User = {
  id: string;
  email: string;
  grant: { roles: Role[] };
};

type Option = {
  label: string;
  value: string;
};

enum Status {
  DUPLICATE = "DUPLICATE",
  INVALID = "INVALID",
  VALID = "VALID",
}

interface TagStyles {
  backgroundColor: string;
  buttonColor: string;
  buttonBackgroundColorHover: string;
}

interface ValidateEmailInfo {
  status: Status;
  message: string;
}

type UserNotificationPreferences = {
  notifyAlerts: boolean;
  notifyBudgets: boolean;
  notifyRecommendations: boolean;
  notifyReportsDaily: boolean;
  notifyReportsMonthly: boolean;
  notifyReportsWeekly: boolean;
};

const KEYBOARD_KEY_BACKSPACE = "Backspace";

const InputWrapper = styled("div")(({ theme }) => ({
  alignItems: "center",
  backgroundColor: theme.input_background_color,
  border: `1px solid ${theme.secondary_color_border}`,
  borderRadius: theme.borderRadius_2,
  display: "flex",
  flexWrap: "wrap",
  minHeight: "38px",
  maxHeight: "6rem",
  maxWidth: "35rem",
  minWidth: "35rem",
  overflow: "auto",
  padding: theme.space_xxs,
  width: "100%",

  "&:hover": {
    borderColor: theme.primary_color_focus,
  },

  "&:focus-within": {
    borderColor: "transparent",
    boxShadow: `0 0 0 2px ${theme.primary_color_focus}`,
  },
}));

const InlineInput = styled("input")(({ theme }) => ({
  backgroundColor: theme.input_background_color,
  border: "none",
  color: theme.text_color,
  flexGrow: 1,
  margin: theme.space_xxs,
  minWidth: "5rem",
  outline: "none",
}));

interface Props {
  globalMode?: boolean;
  isOpen: boolean;
  isProcessing: boolean;
  selectedUser?: User;
  users: User[];
  onInteraction: (interaction: UserForm.Interaction) => void;
}
interface State {
  enableAlertsInput: boolean;
  enableBudgetsInput: boolean;
  enableRecommendationsInput: boolean;
  enableReportsDailyInput: boolean;
  enableReportsMonthlyInput: boolean;
  enableReportsWeeklyInput: boolean;
  inputEmail: string;
  inputRoles: Option[];
  isDisableAll: boolean;
  showPreferences: boolean;
}

const initialState: State = {
  enableAlertsInput: false,
  enableBudgetsInput: false,
  enableRecommendationsInput: false,
  enableReportsDailyInput: false,
  enableReportsMonthlyInput: false,
  enableReportsWeeklyInput: false,
  isDisableAll: false,
  inputEmail: "",
  inputRoles: [
    {
      label: formatRole(Role.BASIC_USER),
      value: Role.BASIC_USER,
    },
  ],
  showPreferences: false,
};

function UserForm(props: Props): JSX.Element {
  const gatekeeper = useGatekeeper();
  const theme = useTheme();

  const timer = useRef<NodeJS.Timeout | null>(null);

  //
  // State
  //

  // Users should never be able to assign Partner or System Admin if
  // they don't have either of those roles.
  const tenantRoles: Role[] = [
    Role.BASIC_USER,
    Role.FULL_ACCESS_USER,
    Role.LIMITED_USER,
    Role.TENANT_ADMIN,
  ];

  const globalRoles: Role[] = [];

  if (gatekeeper.canAccessMspAdmin) {
    globalRoles.push(Role.PARTNER_ADMIN);
  }

  if (gatekeeper.canAccessInternalAdmin) {
    globalRoles.push(Role.SYSTEM_ADMIN);
  }

  const inputRole = props.globalMode
    ? { label: formatRole(globalRoles[0]), value: globalRoles[0] }
    : { label: formatRole(tenantRoles[0]), value: tenantRoles[0] };

  const [state, setState] = useState<State>({
    ...initialState,
    inputEmail: props.selectedUser ? props.selectedUser.email : "",
    inputRoles:
      props.selectedUser && props.selectedUser.grant.roles
        ? props.selectedUser.grant.roles.map((role) => ({
            label: formatRole(role),
            value: role,
          }))
        : [inputRole],
  });

  const mergeState = getMergeState(setState);

  const [bubbledEmails, setBubbled] = useState<string[]>([]);

  const isMspAdminLocation = location.pathname.startsWith(paths._mspMgmt);

  const isUpdateMode =
    props.selectedUser !== null && props.selectedUser !== undefined;

  //
  // Submission Handlers
  //

  function handleClose(): void {
    props.onInteraction({ type: UserForm.INTEGRATION_CANCEL_BUTTON_CLICKED });
  }

  function handleSubmitUpdate(event: MouseEvent): void {
    event.preventDefault();

    if (!props.selectedUser) return;

    props.onInteraction({
      type: UserForm.INTEGRATION_SUBMIT_BUTTON_CLICKED_UPDATE,
      userID: props.selectedUser.id,
      roles: state.inputRoles.map((option) => option.value),
    });
  }

  function handleSubmitCreate(event: MouseEvent): void {
    event.preventDefault();

    const roles = state.inputRoles.map((option) => option.value);

    const currentNotificationPreferences = {
      notifyAlerts: state.enableAlertsInput,
      notifyBudgets: state.enableBudgetsInput,
      notifyRecommendations: state.enableRecommendationsInput,
      notifyReportsDaily: state.enableReportsDailyInput,
      notifyReportsMonthly: state.enableReportsMonthlyInput,
      notifyReportsWeekly: state.enableReportsWeeklyInput,
    };

    const initialNotificationPreferences = {
      notifyAlerts: initialState.enableAlertsInput,
      notifyBudgets: initialState.enableBudgetsInput,
      notifyRecommendations: initialState.enableRecommendationsInput,
      notifyReportsDaily: initialState.enableReportsDailyInput,
      notifyReportsMonthly: initialState.enableReportsMonthlyInput,
      notifyReportsWeekly: initialState.enableReportsWeeklyInput,
    };

    const hasNotificationChanges = !isEqual(
      initialNotificationPreferences,
      currentNotificationPreferences
    );

    props.onInteraction({
      type: UserForm.INTEGRATION_SUBMIT_BUTTON_CLICKED_CREATE,
      params: emailsToBeSubmitted.map((email) => ({ email, roles })),
      ...(hasNotificationChanges
        ? { notificationPreferences: currentNotificationPreferences }
        : {}),
    });
  }

  function handleChange(event: ChangeEvent<HTMLInputElement>): void {
    const name = event.target.name;
    const value: boolean = event.target.value === "true" ? true : false;

    mergeState({
      [`${name}Input`]: value,
    });
  }

  function handleDisableAll(checked: boolean): void {
    if (checked) {
      mergeState({
        isDisableAll: true,
        enableAlertsInput: false,
        enableBudgetsInput: false,
        enableRecommendationsInput: false,
        enableReportsDailyInput: false,
        enableReportsMonthlyInput: false,
        enableReportsWeeklyInput: false,
      });
    } else {
      mergeState({
        isDisableAll: false,
      });
    }
  }

  //
  // Validations
  //

  function canUpdateRoles(): boolean {
    if (!props.selectedUser) return false;

    const hasChanged = !isEqual(
      props.selectedUser.grant.roles.sort(),
      state.inputRoles.map((input) => input.value).sort()
    );

    return hasChanged;
  }

  function canSubmit() {
    if (isUpdateMode) {
      return state.inputRoles.length > 0 && canUpdateRoles();
    } else {
      return (
        emailsToBeSubmitted.every(
          (email) => validateEmail(email).status === Status.VALID
        ) &&
        state.inputRoles.length > 0 &&
        bubbledEmails.length > 0
      );
    }
  }

  const usersKeyedByEmail = keyBy(props.users, "email");

  function validateEmail(email: string): ValidateEmailInfo {
    if (!isEmail(email)) {
      return {
        status: Status.INVALID,
        message: copyText.invalidEmailMessage,
      };
    }

    if (
      bubbledEmails.filter((bubbledEmail) => bubbledEmail === email).length > 1
    ) {
      return {
        status: Status.DUPLICATE,
        message: copyText.duplicateEmailMessage,
      };
    }

    if (usersKeyedByEmail[email]) {
      return {
        status: Status.DUPLICATE,
        message: copyText.userAlreadyGrantedAccessMessage,
      };
    }

    return {
      status: Status.VALID,
      message: copyText.validEmailMessage,
    };
  }

  function handleChangeRoles(opts): void {
    mergeState({ inputRoles: opts });
  }

  function handleChangeInputEmails(
    event: React.ChangeEvent<HTMLInputElement>
  ): void {
    mergeState({
      inputEmail: event.target.value.toLowerCase(),
    });

    const newEmails = event.target.value
      .toLowerCase()
      .split(/[ ,]+/)
      .filter((email: string) => email.length >= 1);

    if (event.target.value.includes(",") || event.target.value.includes(" ")) {
      if (newEmails) {
        setBubbled([...bubbledEmails, ...newEmails]);
        mergeState({
          inputEmail: "",
        });
      }
    }

    const validEmail = newEmails.every((email) => {
      const status = validateEmail(email).status;
      if (status === Status.VALID || status === Status.DUPLICATE) {
        return true;
      }
    });

    if (timer.current) {
      clearTimeout(timer.current);
    }

    timer.current = setTimeout(() => {
      if (newEmails && validEmail) {
        setBubbled([...bubbledEmails, ...newEmails]);
        mergeState({
          inputEmail: "",
        });
      }
    }, 1000);
  }

  const emailsToBeSubmitted = [...bubbledEmails, state.inputEmail].reduce(
    (
      previousValue: string[],
      currentValue: string,
      currentIndex: number,
      array: string[]
    ) => {
      currentValue.trim();
      if (
        currentValue.length >= 1 &&
        array.indexOf(currentValue) === currentIndex
      ) {
        return [...previousValue, currentValue];
      } else {
        return [...previousValue];
      }
    },
    []
  );

  function handleDelete(
    event:
      | React.MouseEvent<HTMLElement>
      | React.KeyboardEvent<HTMLInputElement>,
    index: number
  ): void {
    event.preventDefault();
    const newEmails = [...bubbledEmails];
    newEmails.splice(index, 1);
    setBubbled(newEmails);
  }

  function handleEdit(
    event:
      | React.MouseEvent<HTMLElement>
      | React.KeyboardEvent<HTMLInputElement>,
    index: number,
    email: string
  ): void {
    event.preventDefault();
    mergeState({ inputEmail: email });
    handleDelete(event, index);
  }

  function handleBackspaceEdit(
    event: React.KeyboardEvent<HTMLInputElement>
  ): void {
    if (
      !state.inputEmail &&
      event.key === KEYBOARD_KEY_BACKSPACE &&
      bubbledEmails.length
    ) {
      handleEdit(
        event,
        bubbledEmails.length - 1,
        bubbledEmails[bubbledEmails.length - 1]
      );
    }
  }

  //
  // Render
  //

  const roles = props.globalMode
    ? [...tenantRoles, ...globalRoles]
    : tenantRoles;

  const roleOptions = roles.map((role) => ({
    label: formatRole(role),
    value: role,
  }));

  const captionFragments =
    copyText.userRolePermissionsDocumentationCaption.split("%link%");

  const externalLinkCaption = (
    <Flex alignItems="center">
      <Text whiteSpace="pre">{captionFragments[0]}</Text>
      <a
        href={externalLinks.readmeRolePermissionsDocumentation}
        rel="noreferrer"
        target="_blank"
      >
        {"here"}
      </a>
      <Text whiteSpace="pre">{captionFragments[1]}</Text>
    </Flex>
  );

  return (
    <Modal
      closeOnClickOutside={false}
      isOpen={props.isOpen}
      showCloseButton
      onClose={handleClose}
    >
      <Modal.Header>
        <Text appearance="h4">
          {isUpdateMode
            ? copyText.formTitleUpdateUser
            : copyText.formTitleCreateUsers}
        </Text>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Box marginBottom={theme.space_lg}>
            {isUpdateMode ? (
              <FormField
                input={TextInput}
                label={copyText.emailInputLabel}
                readOnly
                value={state.inputEmail}
              />
            ) : (
              <>
                <Flex align-items="center">
                  <Text
                    color={theme.text_color}
                    fontSize={theme.fontSize_ui}
                    marginBottom={theme.space_xxs}
                  >
                    {copyText.emailsInputLabel}
                  </Text>
                  <Tooltip
                    content={copyText.emailInputTooltipCaption}
                    icon={faInfoCircle}
                  />
                </Flex>
                <InputWrapper>
                  {bubbledEmails.map((email, index) => {
                    const validity = validateEmail(email);
                    const emailTagStyle = getTagStyles(validity.status, theme);

                    return (
                      <Flex
                        key={`${email + index}`}
                        backgroundColor={emailTagStyle.backgroundColor}
                        borderRadius={theme.space_xs}
                        cursor="pointer"
                        height="24px"
                        margin={theme.space_xxs}
                      >
                        <Tooltip
                          content={validity.message}
                          hide={validity.status === Status.VALID}
                        >
                          <Flex
                            alignItems="center"
                            height="100%"
                            paddingLeft={theme.space_xs}
                            paddingRight={theme.space_xxs}
                            onClick={(event) => handleEdit(event, index, email)}
                          >
                            <Text
                              color={theme.text_color}
                              fontSize={theme.fontSize_ui}
                              fontWeight={theme.fontWeight_thin}
                            >
                              {email}
                            </Text>
                          </Flex>
                        </Tooltip>
                        <Flex
                          alignItems="center"
                          backgroundColorOnHover={
                            emailTagStyle.buttonBackgroundColorHover
                          }
                          borderRadius={`0 ${theme.borderRadius_2} ${theme.borderRadius_2} 0`}
                          paddingHorizontal={theme.space_xs}
                          onClick={(event) => {
                            handleDelete(event, index);
                          }}
                        >
                          <Icon
                            clickable
                            color={emailTagStyle.buttonColor}
                            icon={faTimes}
                            name={email}
                            size="xs"
                          />
                        </Flex>
                      </Flex>
                    );
                  })}
                  <InlineInput
                    value={state.inputEmail}
                    onChange={handleChangeInputEmails}
                    onKeyDown={handleBackspaceEdit}
                  />
                </InputWrapper>
              </>
            )}
          </Box>
          <FormField
            caption={externalLinkCaption}
            label={copyText.rolesInputLabel}
          >
            <Flex minWidth="35rem" maxWidth="35rem">
              <Select
                isMulti
                isSearchable
                options={roleOptions}
                value={state.inputRoles}
                onChange={handleChangeRoles}
              />
            </Flex>
          </FormField>
          {!isUpdateMode && !isMspAdminLocation && (
            <FormField>
              <Flex direction="column" overflow="hidden" width={"100%"}>
                <Flex>
                  <Button
                    width={"100%"}
                    iconEnd={<Icon icon={faGear} />}
                    secondary
                    onClick={(event) => {
                      event.preventDefault();
                      mergeState({ showPreferences: !state.showPreferences });
                    }}
                  >
                    {copyText.userFormNotificationSettingsButtonLabel}
                  </Button>
                </Flex>

                <Box
                  height={state.showPreferences ? 180 : 0}
                  transition={"height ease 0.5s"}
                  width={"100%"}
                >
                  <Flex padding={theme.space_xs} width={"100%"}>
                    <Flex
                      direction="column"
                      alignItems="flex-start"
                      width={"50%"}
                    >
                      {/* Column 1 */}
                      <Flex
                        alignItems="center"
                        justifyContent="space-between"
                        marginBottom={theme.space_md}
                        width={"100%"}
                      >
                        <Flex alignItems="center">
                          <Text fontSize={theme.fontSize_base}>
                            {"Disable All"}
                          </Text>
                          <Tooltip
                            content={copyText.userFormInfoToolTipMessage}
                            icon={faInfoCircle}
                            width="15rem"
                          />
                        </Flex>
                        <Switch
                          checked={state.isDisableAll}
                          onChange={(checked) => handleDisableAll(checked)}
                        />
                      </Flex>
                      <Flex
                        alignItems="center"
                        justifyContent="space-between"
                        marginBottom={theme.space_md}
                        width={"100%"}
                      >
                        <Text fontSize={theme.fontSize_base}>
                          {copyText.userFormAlertsLabel}
                        </Text>
                        <Switch
                          disabled={state.isDisableAll}
                          checked={state.enableAlertsInput}
                          onChange={(checked) =>
                            handleChange({
                              target: {
                                name: "enableAlerts",
                                value: String(checked),
                              },
                            } as ChangeEvent<HTMLInputElement>)
                          }
                        />
                      </Flex>
                      <Flex
                        alignItems="center"
                        justifyContent="space-between"
                        marginBottom={theme.space_md}
                        width={"100%"}
                      >
                        <Text fontSize={theme.fontSize_base}>
                          {copyText.userFormBudgetsLabel}
                        </Text>
                        <Switch
                          disabled={state.isDisableAll}
                          checked={state.enableBudgetsInput}
                          onChange={(checked) =>
                            handleChange({
                              target: {
                                name: "enableBudgets",
                                value: String(checked),
                              },
                            } as ChangeEvent<HTMLInputElement>)
                          }
                        />
                      </Flex>
                      <Flex
                        alignItems="center"
                        justifyContent="space-between"
                        width={"100%"}
                      >
                        <Text fontSize={theme.fontSize_base}>
                          {copyText.userFormRecommendationsLabel}
                        </Text>
                        <Switch
                          disabled={state.isDisableAll}
                          checked={state.enableRecommendationsInput}
                          onChange={(checked) =>
                            handleChange({
                              target: {
                                name: "enableRecommendations",
                                value: String(checked),
                              },
                            } as ChangeEvent<HTMLInputElement>)
                          }
                        />
                      </Flex>
                    </Flex>

                    <Flex
                      direction="column"
                      alignItems="flex-start"
                      marginLeft={theme.space_sm}
                      width={"50%"}
                    >
                      {/* Column 2 */}
                      <Flex
                        justifyContent="center"
                        marginBottom={theme.space_md}
                        width={"100%"}
                      >
                        <Text fontSize={theme.fontSize_base}>
                          {copyText.userFormReportsLabel}
                        </Text>
                      </Flex>

                      <Flex
                        alignItems="center"
                        justifyContent="space-between"
                        marginBottom={theme.space_md}
                        width={"100%"}
                      >
                        <Text fontSize={theme.fontSize_base}>
                          {copyText.userFormReportsDailyLabel}
                        </Text>
                        <Switch
                          disabled={state.isDisableAll}
                          checked={state.enableReportsDailyInput}
                          onChange={(checked) =>
                            handleChange({
                              target: {
                                name: "enableReportsDaily",
                                value: String(checked),
                              },
                            } as ChangeEvent<HTMLInputElement>)
                          }
                        />
                      </Flex>

                      <Flex
                        alignItems="center"
                        justifyContent="space-between"
                        marginBottom={theme.space_md}
                        width={"100%"}
                      >
                        <Text fontSize={theme.fontSize_base}>
                          {copyText.userFormReportsWeeklyLabel}
                        </Text>
                        <Switch
                          disabled={state.isDisableAll}
                          checked={state.enableReportsWeeklyInput}
                          onChange={(checked) =>
                            handleChange({
                              target: {
                                name: "enableReportsWeekly",
                                value: String(checked),
                              },
                            } as ChangeEvent<HTMLInputElement>)
                          }
                        />
                      </Flex>
                      <Flex
                        alignItems="center"
                        justifyContent="space-between"
                        marginBottom={theme.space_md}
                        width={"100%"}
                      >
                        <Text fontSize={theme.fontSize_base}>
                          {copyText.userFormReportsMonthlyLabel}
                        </Text>
                        <Switch
                          disabled={state.isDisableAll}
                          checked={state.enableReportsMonthlyInput}
                          onChange={(checked) =>
                            handleChange({
                              target: {
                                name: "enableReportsMonthly",
                                value: String(checked),
                              },
                            } as ChangeEvent<HTMLInputElement>)
                          }
                        />
                      </Flex>
                    </Flex>
                  </Flex>
                </Box>
              </Flex>
            </FormField>
          )}
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button secondary width={100} onClick={handleClose}>
          {copyText.cancelButtonLabel}
        </Button>
        <Button
          disabled={!canSubmit() || props.isProcessing}
          primary
          width={100}
          onClick={isUpdateMode ? handleSubmitUpdate : handleSubmitCreate}
        >
          {props.isProcessing ? <LoadingSpinner /> : copyText.submitButtonLabel}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

function getTagStyles(status: Status, theme: Theme): TagStyles {
  switch (status) {
    case "VALID":
      return {
        backgroundColor: theme.tag_background_color,
        buttonColor: theme.tag_button_color,
        buttonBackgroundColorHover: theme.tag_button_background_color_hover,
      };

    case "DUPLICATE":
      return {
        backgroundColor: theme.tag_background_color_warning,
        buttonColor: theme.tag_button_color_warning,
        buttonBackgroundColorHover:
          theme.tag_button_background_color_warning_hover,
      };
    default:
      return {
        backgroundColor: theme.tag_background_color_danger,
        buttonColor: theme.tag_button_color_danger,
        buttonBackgroundColorHover:
          theme.tag_button_background_color_danger_hover,
      };
  }
}

UserForm.INTEGRATION_CANCEL_BUTTON_CLICKED =
  "UserForm.INTEGRATION_CANCEL_BUTTON_CLICKED" as const;
UserForm.INTEGRATION_SUBMIT_BUTTON_CLICKED_CREATE =
  "UserForm.INTEGRATION_SUBMIT_BUTTON_CLICKED_CREATE" as const;
UserForm.INTEGRATION_SUBMIT_BUTTON_CLICKED_UPDATE =
  "UserForm.INTEGRATION_SUBMIT_BUTTON_CLICKED_UPDATE" as const;

type InteractionCancelButtonClicked = {
  type: typeof UserForm.INTEGRATION_CANCEL_BUTTON_CLICKED;
};

type InteractionSubmitButtonClickedCreate = {
  type: typeof UserForm.INTEGRATION_SUBMIT_BUTTON_CLICKED_CREATE;
  params: {
    email: string;
    roles: string[];
  }[];
  notificationPreferences?: UserNotificationPreferences;
};

type InteractionSubmitButtonClickedUpdate = {
  type: typeof UserForm.INTEGRATION_SUBMIT_BUTTON_CLICKED_UPDATE;
  userID: string;
  roles: string[];
};

// eslint-disable-next-line @typescript-eslint/no-namespace
namespace UserForm {
  export type Interaction =
    | InteractionCancelButtonClicked
    | InteractionSubmitButtonClickedCreate
    | InteractionSubmitButtonClickedUpdate;
}

export default UserForm;
