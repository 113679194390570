import { groupBy, partition, uniq } from "lodash";
import { useMemo } from "react";
import { v4 as uuid } from "uuid";
import { RawData, RawValue } from "../../analytics/types";
import { ChartDatum, Dimension, Measure } from "../charts/types";
import {
  DEFAULT_X_AXIS_KEY,
  NOT_SHOWN_KEY,
  PREVIOUS_TIMESTAMP_KEY,
} from "../charts/utils";
import useReferenceIfEqual from "../hooks/useReferenceIfEqual";
import { addRawValues, sortRawData } from "./sort";

const MAX_COL_COUNT = 10; //11th column is other (not shown)

type Params = {
  data: RawData[];
  maxGroupingCount?: null | number;
  mergeMeasures?: boolean;
  dimensions: Dimension[];
  excludedChartKeys?: string[];
  excludeOther?: boolean;
  isDataSorted?: boolean;
  measures: Measure[];
  xAxisKey?: string;
};

/**
 * Grouping Key:
 *   - `${dimension0Value} [DELIM] ${dimension1Value} ... ${dimensionIValue}`
 *   - references n horizontal areas, where n = measure count
 * Chart Key:
 *   - `${Grouping Key} [DELIM] ${measureName}`
 *   - references 1 horizontal area
 * Mega Key:
 *   - `${Chart Key} [DELIM] ${xAxisValue}`
 *   - references 1 value in the chart
 */

// Do not export this string. Any value parsing should be done inside ChartDataManager.ts
const DELIM = ` [${uuid()}] `;

export default class ChartDataManager {
  private readonly chartDataKeyedByXAxisValue: { [key: string]: ChartDatum };
  private readonly excludedChartKeySet = new Set<string>();
  private readonly rawDataGroupedByChartKey: { [key: string]: RawData[] };
  private readonly rawDataGroupedByXAxisValue: { [key: string]: RawData[] };
  private readonly rawDataKeyedByMegaKey: { [key: string]: RawData };

  readonly sortedChartKeys: string[];
  readonly chartData: ChartDatum[];
  readonly dimensions: Dimension[];
  readonly excludedChartKeys: string[];
  readonly isTimeSeriesData: boolean;
  readonly maxValue: number | null;
  readonly measures: Measure[];
  readonly rawData: RawData[];
  readonly xAxisKey: string | null;
  readonly xAxisValues: string[];

  constructor(params: Params) {
    this.dimensions = [...params.dimensions];
    this.measures = [...params.measures];
    this.xAxisKey = params.xAxisKey ?? null;
    this.isTimeSeriesData = isTimeSeriesXAxisKey(params.xAxisKey);

    let rawData = this.getSortedRawData(params);
    rawData = this.getMergedRawData({ ...params, data: rawData });
    this.rawData = rawData;

    this.xAxisValues = this.getXAxisValues();

    const {
      rawDataGroupedByChartKey,
      rawDataGroupedByXAxisValue,
      rawDataKeyedByMegaKey,
    } = this.getKeyMaps();

    this.sortedChartKeys = this.getSortedChartKeys(params);
    this.excludedChartKeys = params.excludedChartKeys ?? [];
    this.excludedChartKeySet = new Set(params.excludedChartKeys);
    this.rawDataGroupedByChartKey = rawDataGroupedByChartKey;
    this.rawDataGroupedByXAxisValue = rawDataGroupedByXAxisValue;
    this.rawDataKeyedByMegaKey = rawDataKeyedByMegaKey;

    const { chartData, chartDataKeyedByXAxisValue } = this.getChartData();

    this.chartData = chartData;
    this.chartDataKeyedByXAxisValue = chartDataKeyedByXAxisValue;
    this.maxValue = this.getMaxValue();
  }

  getChartDatum(xAxisValue: RawValue): ChartDatum | null {
    return this.chartDataKeyedByXAxisValue[this.keyString(xAxisValue)] ?? null;
  }

  getChartKey(dimensionValues: string[], measure: string) {
    if (dimensionValues.length === 0) dimensionValues = [""];
    return [...dimensionValues, measure].join(DELIM);
  }

  getChartKeysFromGroupingKey(groupingKey: string) {
    const dimensionValues = groupingKey.split(DELIM);
    return this.measures.map((measure) =>
      this.getChartKey(dimensionValues, measure.name)
    );
  }

  getDimensionValuesFromChartKey(chartKey: string): DimensionWithValue[] {
    return this.getDimensionValuesFromGroupingKey(
      this.getGroupingKeyFromChartKey(chartKey)
    );
  }

  getDimensionValuesFromGroupingKey(groupingKey: string): DimensionWithValue[] {
    if (!this.dimensions.length) return [];
    return groupingKey.split(DELIM).map((value, index) => ({
      dimension: this.dimensions[index],
      value,
    }));
  }

  getGroupingKeyFromChartKey(chartKey: string): string {
    return chartKey.split(DELIM).slice(0, -1).join(DELIM);
  }

  getGroupingKeyFromDatum(datum: RawData): string {
    return this.dimensions
      .map((dimension) => this.keyString(datum[dimension.name]))
      .join(DELIM);
  }

  getDimensionValues(value: string) {
    const split = value.split(DELIM).slice(0, -1);

    return split.join(" / ");
  }

  getMeasureKey(value: string) {
    const split = value.split(DELIM);

    return split[split.length - 1];
  }

  getGroupingTable(): GroupingTable {
    const dimensionHeaders = [...this.dimensions];
    const dimensionRows: DimensionWithValue[][] = [];
    const measureHeaders = [...this.measures];
    const measureRows: MeasureWithChartKey[][] = [];

    const groupingKeys: string[] = [];

    const groupingKeySet: { [groupingKey: string]: true } = {};

    [...this.sortedChartKeys].reverse().map((chartKey) => {
      const groupingKey = this.getGroupingKeyFromChartKey(chartKey);
      const dimensionValues = this.getDimensionValuesFromChartKey(chartKey);

      if (!(groupingKey in groupingKeySet)) {
        groupingKeySet[groupingKey] = true;
        groupingKeys.push(groupingKey);
        dimensionRows.push(dimensionValues);
        const measureRow = this.measures.map((measure) => ({
          measure,
          chartKey: this.getChartKey(
            dimensionValues.map(({ value }) => value),
            measure.name
          ),
        }));

        measureRows.push(measureRow);
      }
    });

    return {
      dimensionHeaders,
      dimensionRows,
      groupingKeys,
      measureHeaders,
      measureRows,
    };
  }

  getMeasure(chartKey: string): Measure | null {
    const measureName = this.getMeasureName(chartKey);
    return (
      this.measures.find((measure) => measure.name === measureName) ?? null
    );
  }

  getRawData(chartKey: string, xAxisValue: RawValue): RawData | null {
    const megaKey = [chartKey, this.keyString(xAxisValue)].join(DELIM);
    return this.rawDataKeyedByMegaKey[megaKey] ?? null;
  }

  getRawDataAtXAxisValue(xAxisValue: RawValue): RawData[] | null {
    return this.rawDataGroupedByXAxisValue[this.keyString(xAxisValue)] ?? null;
  }

  isChartKeyInGrouping(chartKey: string, groupingKey: string) {
    const groupingValues = groupingKey.split(DELIM);
    const chartValues = chartKey.split(DELIM);

    return groupingValues.every(
      (groupingValue, index) => groupingValue === chartValues[index]
    );
  }

  isExcluded(chartKey: string) {
    return this.excludedChartKeySet.has(chartKey);
  }

  private getSortedChartKeys(params: Params) {
    const totals = totalRawData({
      data: [...this.rawData],
      groupingKeys: this.dimensions.map((d) => d.name),
      sumKeys: this.measures.map((m) => m.name),
    });

    let chartKeys: string[] = [];
    if (params.mergeMeasures) {
      const distinctTotals = totals
        .map((totalDatum) =>
          this.measures.map<RawData>(({ name: measureName }) => ({
            // all dimension values
            ...totalDatum,

            // value for measure
            value: totalDatum[measureName],
            measureName,
          }))
        )
        .flat();

      // last measure is the "biggest"
      distinctTotals.sort((a, b) => {
        const aVal = a.value ?? null;
        const bVal = b.value ?? null;

        if (aVal === null && bVal === null) return 0;
        if (aVal === null) return -1;
        if (bVal === null) return 1;

        if (aVal > bVal) return -1;
        if (aVal < bVal) return 1;

        return 0;
      });

      distinctTotals.forEach((datum) => {
        const measureName = datum.measureName as string;
        const chartKey = this.getChartKeyFromDatum(datum, measureName);
        chartKeys.push(chartKey);
      });
    } else {
      // last measure is the "smallest"
      [...this.measures].reverse().forEach((measure) => {
        const sortedByMeasureTotal = sortRawData({
          data: [...totals],
          groupingKeys: this.dimensions.map((d) => d.name),
          yAxisKeys: [measure.name],
          order: { yAxis: "asc" },
        });

        sortedByMeasureTotal.forEach((datum) => {
          const chartKey = this.getChartKeyFromDatum(datum, measure.name);
          chartKeys.push(chartKey);
        });
      });
    }

    if (params.excludeOther) {
      chartKeys = chartKeys.filter((key) => {
        const dimensionValues = this.getDimensionValuesFromChartKey(key);

        if (!dimensionValues.length) return true;

        return !dimensionValues.every(({ value }) => value === NOT_SHOWN_KEY);
      });
    }

    return chartKeys;
  }

  /**
   * Gets 1 datum for each xAxis slice. Each datum contains `params.xAxisKey`
   * and all keys from `params.chartKeys`. Any chart keys not included in
   * `params.chartKeys` will not be added to the datum. Chart keys included
   * in `params.chartKeys` and `params.excludedChartKeys` will be set to 0.
   */
  private getChartData() {
    const chartDataKeyedByXAxisValue: { [key: string]: ChartDatum } = {};
    const chartData = this.xAxisValues.map((xAxisValue): ChartDatum => {
      const chartDatum: ChartDatum = {};

      if (this.xAxisKey) chartDatum[this.xAxisKey] = xAxisValue;
      this.sortedChartKeys.forEach((chartKey) => {
        const rawDatum = this.getRawData(chartKey, xAxisValue);
        const measureName = this.getMeasureName(chartKey);
        const measureValue = rawDatum?.[measureName] ?? null;

        if (
          (typeof measureValue === "number" ||
            typeof measureValue === "string") &&
          !this.excludedChartKeySet.has(chartKey)
        ) {
          chartDatum[chartKey] = measureValue;
          chartDatum[PREVIOUS_TIMESTAMP_KEY] = rawDatum
            ? String(rawDatum[PREVIOUS_TIMESTAMP_KEY])
            : "";
        } else {
          chartDatum[chartKey] = 0;
        }
      });

      chartDataKeyedByXAxisValue[xAxisValue] = chartDatum;

      return chartDatum;
    });

    return {
      chartData,
      chartDataKeyedByXAxisValue,
    };
  }

  private getChartKeyFromDatum(datum: RawData, measure: string) {
    return [this.getGroupingKeyFromDatum(datum), measure].join(DELIM);
  }

  private getKeyMaps() {
    const rawDataGroupedByChartKey: { [key: string]: RawData[] } = {};
    const rawDataGroupedByXAxisValue: { [key: string]: RawData[] } = {};
    const rawDataKeyedByMegaKey: { [key: string]: RawData } = {};

    this.rawData.forEach((datum) => {
      const xAxisValue = this.keyString(
        this.xAxisKey ? datum[this.xAxisKey] : null
      );

      if (!(xAxisValue in rawDataGroupedByXAxisValue)) {
        rawDataGroupedByXAxisValue[xAxisValue] = [];
      }

      rawDataGroupedByXAxisValue[xAxisValue].push(datum);

      [...this.measures].reverse().forEach((measure) => {
        const measureName = measure.name;
        const chartKey = this.getChartKeyFromDatum(datum, measureName);
        const megaKey = this.getMegaKey(datum, measureName, xAxisValue);

        if (!(chartKey in rawDataGroupedByChartKey)) {
          rawDataGroupedByChartKey[chartKey] = [];
        }

        rawDataGroupedByChartKey[chartKey].push(datum);

        rawDataKeyedByMegaKey[megaKey] = datum;
      });
    });

    return {
      rawDataGroupedByChartKey,
      rawDataGroupedByXAxisValue,
      rawDataKeyedByMegaKey,
    };
  }

  private getMaxValue() {
    let maxValue: number | null = null;

    this.chartData.forEach((chartDatum) =>
      this.sortedChartKeys.forEach((chartKey) => {
        const chartValue = chartDatum[chartKey];
        if (typeof chartValue !== "number") return;
        if (maxValue === null || chartValue > maxValue) {
          maxValue = chartValue;
        }
      })
    );

    return maxValue;
  }

  private getMeasureName(chartKey: string) {
    return chartKey.split(DELIM).at(-1) ?? "";
  }

  private getMegaKey(datum: RawData, measure: string, xAxisValue: string) {
    // grouping_key + 1 measure_name + x_axis_value
    return [this.getChartKeyFromDatum(datum, measure), xAxisValue].join(DELIM);
  }

  private getMergedRawData(params: Params) {
    let rawData = params.data;

    if (typeof params.maxGroupingCount === "number") {
      rawData = mergeRawDataIntoOther({
        data: params.data,
        dimensions: params.dimensions.map(({ name }) => name),
        measures: params.measures.map(({ name }) => name),
        mergeType: this.isTimeSeriesData
          ? MergeType.TOP_GROUPINGS
          : params.xAxisKey !== DEFAULT_X_AXIS_KEY
            ? MergeType.EACH_XAXIS_KEY
            : MergeType.TOP_ITEMS,
        nonOtherCount: params.maxGroupingCount,
        xAxisKey: params.xAxisKey,
      });
    }

    return rawData;
  }

  private getSortedRawData(params: Params) {
    const rawData = [...params.data];

    if (!params.isDataSorted) {
      sortRawData({
        data: rawData,
        groupingKeys: params.dimensions.map((d) => d.name),
        order: { xAxis: "asc", yAxis: "desc" },
        xAxisKey: params.xAxisKey,
        yAxisKeys: params.measures.map((m) => m.name),
      });
    }

    return rawData;
  }

  private getXAxisValues(): string[] {
    const xAxisKey = this.xAxisKey;
    if (!xAxisKey) return [this.keyString(null)];
    return uniq(this.rawData.map((datum) => this.keyString(datum[xAxisKey])));
  }

  private keyString(value?: RawValue) {
    return String(value ?? null);
  }
}

export function useChartDataManager(params: Params) {
  const { data, ...config } = params;
  const configReference = useReferenceIfEqual(config);

  return useMemo(
    () => new ChartDataManager({ data, ...configReference }),
    [data, configReference]
  );
}

export type DimensionWithValue = { dimension: Dimension; value: string };
export type MeasureWithChartKey = { measure: Measure; chartKey: string };
export type GroupingTable = {
  dimensionHeaders: Dimension[];
  dimensionRows: DimensionWithValue[][];
  groupingKeys: string[];
  measureHeaders: Measure[];
  measureRows: MeasureWithChartKey[][];
};

export const MergeType = {
  EACH_XAXIS_KEY: "EACH_XAXIS_KEY",
  TOP_GROUPINGS: "TOP_GROUPINGS",
  TOP_ITEMS: "TOP_ITEMS",
} as const;
export type MergeType = (typeof MergeType)[keyof typeof MergeType];

export function mergeRawDataIntoOther(params: {
  data: RawData[];
  dimensions: string[];
  measures: string[];
  mergeType: MergeType;
  nonOtherCount: number;
  xAxisKey?: string;
}) {
  switch (params.mergeType) {
    case MergeType.EACH_XAXIS_KEY:
      return mergeRawDataIntoOtherForEachXAxisKey({
        data: params.data,
        dimensions: params.dimensions,
        maxColumns: MAX_COL_COUNT,
        maxPerColumn: params.nonOtherCount,
        measures: params.measures,
        xAxisKey: params.xAxisKey ?? "",
      });
    case MergeType.TOP_GROUPINGS:
      return mergeRawDataIntoOtherByTopGroupings({
        groupingCount: params.nonOtherCount,
        data: params.data,
        dimensions: params.dimensions,
        measures: params.measures,
        xAxisKey: params.xAxisKey,
      });

    case MergeType.TOP_ITEMS:
      return mergeRawDataIntoOtherByTopItems({
        itemCount: params.nonOtherCount,
        data: params.data,
        dimensions: params.dimensions,
        measures: params.measures,
      });

    default: {
      const exhaustiveCheck: never = params.mergeType;
      throw new Error(`Unhandled MergeType: ${exhaustiveCheck}`);
    }
  }
}

function mergeRawDataIntoOtherByTopGroupings(params: {
  data: RawData[];
  dimensions: string[];
  groupingCount: number;
  measures: string[];
  xAxisKey?: string;
}) {
  const groupingTotals = totalRawData({
    data: params.data,
    groupingKeys: params.dimensions,
    sumKeys: params.measures,
  });

  sortRawData({
    data: groupingTotals,
    yAxisKeys: params.measures,
    groupingKeys: params.dimensions,
    order: { yAxis: "desc" },
  });

  const topGroupings = groupingTotals.slice(0, params.groupingCount);

  const getGroupingKey = createGetGrouping(params.dimensions);

  const topGroupingsKeySet = Object.fromEntries(
    topGroupings.map(getGroupingKey).map((key) => [key, true])
  );

  const shouldMerge = (datum: RawData) =>
    !topGroupingsKeySet[getGroupingKey(datum)];

  const result = mergeRawDataIntoOtherHelper({
    shouldMerge,
    data: params.data,
    dimensions: params.dimensions,
    measures: params.measures,
    xAxisKey: params.xAxisKey,
  });

  return result;
}

function mergeRawDataIntoOtherByTopItems(params: {
  data: RawData[];
  dimensions: string[];
  itemCount: number;
  measures: string[];
}) {
  const sortedData = sortRawData({
    data: [...params.data],
    yAxisKeys: params.measures,
    groupingKeys: params.dimensions,
    order: { yAxis: "desc" },
  });

  const topItemsSet = new Set(sortedData.slice(0, params.itemCount));

  const shouldMerge = (item: RawData) => {
    return !topItemsSet.has(item);
  };

  const result = mergeRawDataIntoOtherHelper({
    shouldMerge,
    data: params.data,
    dimensions: params.dimensions,
    measures: params.measures,
  });

  return result;
}

function mergeRawDataIntoOtherForEachXAxisKey(params: {
  data: RawData[];
  dimensions: string[];
  maxPerColumn: number;
  maxColumns: number;
  measures: string[];
  xAxisKey: string;
}) {
  const sortedData = sortRawData({
    data: [...params.data],
    yAxisKeys: params.measures,
    groupingKeys: [params.xAxisKey],
    order: { yAxis: "desc" },
  });

  const sortedXAxisKeys = sortedData.reduce<string[]>(
    (accum, datum): string[] => {
      const lastXAxisValue = accum.at(-1) ?? null;
      const currentXAxisValue = String(datum[params.xAxisKey]);

      if (!lastXAxisValue) {
        return [currentXAxisValue];
      }

      if (lastXAxisValue === currentXAxisValue) {
        return accum;
      }

      return [...accum, currentXAxisValue];
    },
    []
  );

  const topXAxisKeys = sortedXAxisKeys.slice(0, params.maxColumns);
  const xAxisKeysToMerge = sortedXAxisKeys.slice(params.maxColumns);

  const groupedByXAxis = groupBy(sortedData, (datum) => datum[params.xAxisKey]);

  const topCols = topXAxisKeys
    .map((xAxisKey) => {
      const columnData = groupedByXAxis[xAxisKey];

      const unMerged = columnData.slice(0, params.maxPerColumn);

      const columnDataToMerge = columnData.slice(params.maxPerColumn);

      if (columnDataToMerge.length === 0) {
        return unMerged;
      }

      const [mergedDatum] = mergeRawDataIntoOtherHelper({
        data: columnDataToMerge,
        dimensions: params.dimensions,
        measures: params.measures,
        shouldMerge: () => true,
        xAxisKey: params.xAxisKey,
      });

      mergedDatum[xAxisKey] = unMerged[0][xAxisKey];

      return [...unMerged, mergedDatum];
    })
    .flat();

  const dataToMerge = xAxisKeysToMerge
    .map((XAxisValue) => groupedByXAxis[XAxisValue])
    .flat();

  const [otherCol] = mergeRawDataIntoOtherHelper({
    data: dataToMerge,
    dimensions: params.dimensions,
    measures: params.measures,
    shouldMerge: () => true,
  });

  if (otherCol) {
    return [...topCols, otherCol];
  }

  return topCols;
}

/**
 * Keeps all data where `shouldMerge` returns false. Everything else is merged
 * into the last position where it returns true. Original order is preserved.
 *
 * TopNOther data is assumed to be merged. `shouldMerge` is not called for TopNOther
 * data.
 */
function mergeRawDataIntoOtherHelper(params: {
  shouldMerge: (datum: RawData) => boolean;
  data: RawData[];
  dimensions: string[];
  measures: string[];
  xAxisKey?: string;
}) {
  const originalIndexLookup = new Map<RawData, number>();
  params.data.forEach((datum, index) => originalIndexLookup.set(datum, index));

  const [dataToMerge, keptData] = partition(
    params.data,
    (datum) =>
      isTopNOther(datum, params.dimensions) || params.shouldMerge(datum)
  );

  const dataToMergeGroupedByXAxisValue = groupBy(dataToMerge, (datum) =>
    stringValueAtKey(datum, params.xAxisKey)
  );

  const addDatumToTarget = (datum: RawData, target: RawData) => {
    params.measures.forEach((measure) => {
      target[measure] = addRawValues(target[measure], datum[measure]);
    });
  };

  const otherData = Object.values(dataToMergeGroupedByXAxisValue).map(
    (dataToMerge) => {
      const [first, ...rest] = dataToMerge;

      const otherDatum = { ...first };
      const otherIndex = originalIndexLookup.get(rest.at(-1) ?? first) ?? -1;

      originalIndexLookup.set(otherDatum, otherIndex);
      const xAxisValue = params.xAxisKey ? first[params.xAxisKey] : undefined;

      rest.forEach((datum) => {
        const target = otherDatum;
        addDatumToTarget(datum, target);
      });

      params.dimensions.forEach((dimension) => {
        otherDatum[dimension] = NOT_SHOWN_KEY;
      });

      if (params.xAxisKey && typeof xAxisValue !== "undefined") {
        otherDatum[params.xAxisKey] = xAxisValue;
      }

      return otherDatum;
    }
  );

  const result = [...keptData, ...otherData].sort((a, b) => {
    const aIndex = originalIndexLookup.get(a) ?? -1;
    const bIndex = originalIndexLookup.get(b) ?? -1;

    return aIndex - bIndex;
  });

  return result;
}

export function isTimeSeriesXAxisKey(xAxisKey?: string) {
  return (
    !!xAxisKey &&
    (xAxisKey === DEFAULT_X_AXIS_KEY || xAxisKey === "invoiceMonth")
  );
}

function isTopNOther(datum: RawData, dimensions: string[]) {
  return (
    dimensions.length > 0 &&
    dimensions.every((dimension) => datum[dimension] === NOT_SHOWN_KEY)
  );
}

export function stringValueAtKey(datum: RawData, key?: string) {
  return String(typeof key !== "string" ? null : (datum[key] ?? null));
}

export function removeGroupingsWithNoDataAtXAxisValue(params: {
  dataManager: ChartDataManager;
  groupingTable: GroupingTable;
  xAxisValue: string;
}): GroupingTable {
  const { dataManager, groupingTable, xAxisValue } = params;

  const rowIndicesToRemove: { [index: number]: true } = {};

  groupingTable.groupingKeys.forEach((groupingKey, index) => {
    const [chartKey] = dataManager.getChartKeysFromGroupingKey(groupingKey);

    const datum = dataManager.getRawData(chartKey, xAxisValue);

    if (datum === null) {
      rowIndicesToRemove[index] = true;
    }
  });

  const filter = (_: unknown, index: number) => !rowIndicesToRemove[index];

  return {
    ...groupingTable,
    dimensionRows: groupingTable.dimensionRows.filter(filter),
    groupingKeys: groupingTable.groupingKeys.filter(filter),
    measureRows: groupingTable.measureRows.filter(filter),
  };
}

export function totalRawData(params: {
  data: RawData[];
  groupingKeys: string[];
  sumKeys: string[];
}) {
  const getGrouping = createGetGrouping(params.groupingKeys);

  const allGroupings = uniq(params.data.map(getGrouping));

  const totalKeyedByGrouping = Object.fromEntries<RawData | null>(
    allGroupings.map((grouping) => [grouping, null])
  );

  const addToTotalDatum = (total: RawData, add: RawData) => {
    params.sumKeys.forEach((sumKey) => {
      total[sumKey] = addRawValues(total[sumKey], add[sumKey]);
    });
  };

  const totalData: RawData[] = [];

  params.data.map((datum) => {
    const grouping = getGrouping(datum);
    const foundTotalDatum = totalKeyedByGrouping[grouping];

    if (!foundTotalDatum) {
      const newTotalDatum = { ...datum };
      totalKeyedByGrouping[grouping] = newTotalDatum;
      totalData.push(newTotalDatum);
    } else {
      addToTotalDatum(foundTotalDatum, datum);
    }
  });

  return totalData;
}

export function createGetGrouping(groupingKeys: string[]) {
  return (datum: RawData) =>
    groupingKeys.map((key) => stringValueAtKey(datum, key)).join(DELIM);
}
