/* prettier-ignore */
export default {
  announceKitWidgetID: "https://announcekit.co/widgets/v2/2RdKeY",
  companyWebsite: "https://ternary.app",
  gcpGeneralPurposeCommitmentsDocumentation: "https://cloud.google.com/compute/docs/instances/signing-up-committed-use-discounts#general-purpose_commitments",
  gcpComputeOptimizedCommitmentsDocumentation: "https://cloud.google.com/compute/docs/instances/signing-up-committed-use-discounts#compute-optimized_commitments",
  zendeskBillingDataPerformanceModeDocumentation: "https://ternaryapp.zendesk.com/hc/en-us/articles/8396043225239",
  readmeBudgetsDocumentation: "https://docs.ternary.app/docs/budgets",
  readmeCostCompareDocumentation: "https://docs.ternary.app/docs/aws-gcp-cost-compare",
  readmeCustomLabelsDocumentation: "https://docs.ternary.app/docs/custom-labels-1",
  readmeGCPOnboardingDocumentation: "https://docs.ternary.app/docs/google-cloud-platform",
  readmeJiraIntegrationDocumentation: "https://docs.ternary.app/docs/jira",
  readmeRolePermissionsDocumentation: "https://docs.ternary.app/docs/users-and-roles",
  readmeSlackIntegrationDocumentation: "https://docs.ternary.app/docs/slack",
};
