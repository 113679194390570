import { useTheme } from "@emotion/react";
import { faX } from "@fortawesome/free-solid-svg-icons";
import getMergeState from "@ternary/api-lib/ui-lib/charts/utils";
import Box from "@ternary/api-lib/ui-lib/components/Box";
import Button from "@ternary/api-lib/ui-lib/components/Button";
import Flex from "@ternary/api-lib/ui-lib/components/Flex";
import Icon from "@ternary/api-lib/ui-lib/components/Icon";
import Text from "@ternary/api-lib/ui-lib/components/Text";
import { endOfDay, startOfDay } from "date-fns";
import React, { useState } from "react";
import LegacyDatePicker from "../../../ui-lib/components/LegacyDatePicker";
import LoadingSpinner from "../../../ui-lib/components/LoadingSpinner";
import Modal from "../../../ui-lib/components/Modal";
import TextInput from "../../../ui-lib/components/TextInput";
import copyText from "../copyText";

interface Props {
  isLoading: boolean;
  tenant: { name: string };
  onInteraction: (
    interaction: CreateMspBillingManifestModal.Interaction
  ) => void;
}

interface State {
  endDate: string;
  name: string;
  startDate: string;
}

const initialState: State = {
  endDate: "",
  name: "",
  startDate: "",
};

export function CreateMspBillingManifestModal(props: Props) {
  const theme = useTheme();

  const [state, setState] = useState<State>(initialState);
  const mergeState = getMergeState(setState);

  function handleChangeName(name: string) {
    mergeState({ name });
  }

  function handleChangeStartDate(date: Date | null): void {
    if (date === null) return;

    let newEndDate: Date | undefined = undefined;

    // Do not allow end time to be sooner than or equal to start time.
    if (date.getTime() >= new Date(state.endDate ?? "").getTime()) {
      newEndDate = new Date(date);
      newEndDate.setDate(date.getDate() + 1);
    }

    mergeState({
      startDate: startOfDay(date).toISOString(),
      ...(newEndDate
        ? {
            endDate: endOfDay(newEndDate).toISOString(),
          }
        : {}),
    });
  }

  function handleChangeEndDate(date: Date | null): void {
    if (date === null) return;

    mergeState({
      endDate: endOfDay(date).toISOString(),
    });
  }

  const isValid = state.endDate.length > 0 && state.startDate.length > 0;

  return (
    <Modal isOpen={true} width={1000}>
      <Modal.Header>
        <Text appearance="h4">{`${copyText.modalTitleCreateManifest} - ${props.tenant.name}`}</Text>
        <Flex alignItems="center">
          <Button
            disabled={props.isLoading}
            iconStart={<Icon icon={faX} />}
            size="small"
            onClick={() =>
              props.onInteraction({
                type: CreateMspBillingManifestModal.INTERACTION_CANCEL_BUTTON_CLICKED,
              })
            }
          />
        </Flex>
      </Modal.Header>
      <Modal.Body>
        <Box width={400}>
          <Box marginBottom={theme.space_sm}>
            <Text>Name (Optional)</Text>
            <TextInput
              value={state.name}
              onChange={(e) => handleChangeName(e.target.value)}
            />
          </Box>
          <Flex width="100%">
            <Box marginRight={theme.space_sm} width="100%">
              <Text>Start Date</Text>
              <LegacyDatePicker
                selected={
                  state.startDate.length > 0
                    ? new Date(state.startDate)
                    : undefined
                }
                onChange={(date) => handleChangeStartDate(date)}
              />
            </Box>
            <Box width="100%">
              <Text>End Date</Text>
              <LegacyDatePicker
                selected={
                  state.endDate.length > 0 ? new Date(state.endDate) : undefined
                }
                onChange={(date) => handleChangeEndDate(date)}
              />
            </Box>
          </Flex>
        </Box>

        <Flex justifyContent="flex-end">
          <Button
            disabled={!isValid || props.isLoading}
            primary
            onClick={() =>
              props.onInteraction({
                type: CreateMspBillingManifestModal.INTERACTION_SUBMIT_BUTTON_CLICKED,
                endDate: state.endDate,
                name: state.name,
                startDate: state.startDate,
              })
            }
          >
            {props.isLoading ? <LoadingSpinner /> : copyText.submitButtonLabel}
          </Button>
        </Flex>
      </Modal.Body>
    </Modal>
  );
}

CreateMspBillingManifestModal.INTERACTION_CANCEL_BUTTON_CLICKED =
  "ManifestCreateModal.INTERACTION_CANCEL_BUTTON_CLICKED" as const;
CreateMspBillingManifestModal.INTERACTION_SUBMIT_BUTTON_CLICKED =
  "ManifestCreateModal.INTERACTION_SUBMIT_BUTTON_CLICKED" as const;

type InteractionCancelButtonClicked = {
  type: typeof CreateMspBillingManifestModal.INTERACTION_CANCEL_BUTTON_CLICKED;
};

type InteractionSubmitButtonClicked = {
  type: typeof CreateMspBillingManifestModal.INTERACTION_SUBMIT_BUTTON_CLICKED;
  endDate: string;
  name: string;
  startDate: string;
};

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace CreateMspBillingManifestModal {
  export type Interaction =
    | InteractionCancelButtonClicked
    | InteractionSubmitButtonClicked;
}

export default CreateMspBillingManifestModal;
