import {
  IconDefinition,
  faChartArea,
  faCloud,
  faCogs,
  faExclamationTriangle,
  faFileInvoice,
  faFileInvoiceDollar,
  faFileLines,
  faHandHoldingDollar,
  faHandshake,
  faMicrochip,
  faPercent,
  faWrench,
} from "@fortawesome/free-solid-svg-icons";
import copyText from "./copyText";
import paths from "./paths";

export type NavOption = {
  href: string;
  icon: IconDefinition;
  keywords: string[];
  params?: Record<string, string>;
  name: string;
};

export const defaultNavOptions: NavOption[] = [
  {
    href: paths._home,
    icon: faCloud,
    keywords: [],
    name: copyText.homePageTitle,
  },
  {
    href: paths._dashboards,
    icon: faChartArea,
    keywords: [],
    name: copyText.navSearchOptionNameDashboards,
  },
  {
    href: paths._reports,
    icon: faChartArea,
    keywords: [],
    name: copyText.navSearchOptionNameReports,
  },
  {
    href: paths._settings,
    icon: faWrench,
    name: copyText.userSettingsManagementPageTitle,
    keywords: [],
  },
];

export const navOptionsForType = [
  {
    href: paths._admin,
    icon: faCogs,
    name: copyText.adminPageTitle,
    keywords: [],
  },
  {
    href: paths._admin,
    icon: faCogs,
    name: copyText.navSearchOptionNameAdminTenant,
    params: { tab: "tenant" },
    keywords: [],
  },
  {
    href: paths._admin,
    icon: faCogs,
    name: copyText.navSearchOptionNameAdminClouds,
    params: { tab: "clouds" },
    keywords: [],
  },
  {
    href: paths._admin,
    icon: faCogs,
    name: copyText.navSearchOptionNameAdminUsers,
    params: { tab: "users" },
    keywords: [],
  },
  {
    href: paths._admin,
    icon: faCogs,
    name: copyText.navSearchOptionNameAdminScopedViews,
    params: { tab: "scopedViews" },
    keywords: [],
  },
  {
    href: paths._admin,
    icon: faCogs,
    name: copyText.navSearchOptionNameAdminIntegrations,
    params: { tab: "integrations" },
    keywords: [],
  },
  {
    href: paths._admin,
    icon: faCogs,
    name: copyText.navSearchOptionNameAdminCustomLabel,
    params: { tab: "customLabels" },
    keywords: [],
  },
  {
    href: paths._admin,
    icon: faCogs,
    name: copyText.navSearchOptionNameAdmingGlobal,
    params: { tab: "global" },
    keywords: [],
  },
  {
    href: paths._admin,
    icon: faCogs,
    name: copyText.navSearchOptionNameAdminSSO,
    params: { tab: "userGroups" },
    keywords: [],
  },
  {
    href: paths._admin,
    icon: faCogs,
    name: copyText.navSearchOptionNameFiscalCalendar,
    keywords: ["fiscal"],
    params: { tab: "fiscalCalendar" },
  },
  {
    href: paths._alertTracking,
    icon: faExclamationTriangle,
    keywords: ["anomaly", "anomalydetection", "alert", "alerttracking"],
    name: copyText.navSearchOptionNameAnomalyDetection,
  },
  {
    href: paths._budgets,
    icon: faFileInvoiceDollar,
    keywords: ["forecast"],
    name: copyText.budgetsPageTitle,
  },
  {
    href: paths._cases,
    icon: faFileLines,
    keywords: [],
    name: copyText.caseManagementPageTitle,
  },
  {
    href: paths._commitmentDiscountGCP,
    icon: faPercent,
    keywords: ["gcp", "cud", "flex", "optimizations", "rateoptimizations"],
    name: copyText.navSearchOptionNameCommittedUseGCP,
  },
  {
    href: paths._commitmentDiscountAWS,
    icon: faPercent,
    keywords: [
      "aws",
      "optimizations",
      "rateoptimizations",
      "reservedinstance",
      "ri",
      "savingsplan",
    ],
    name: copyText.navSearchOptionNameCommittedUseAWS,
  },
  {
    href: paths._commitmentDiscountAzure,
    icon: faPercent,
    keywords: [
      "azure",
      "optimizations",
      "rateoptimizations",
      "reservedinstance",
      "ri",
      "savingsplan",
    ],
    name: copyText.navSearchOptionNameCommittedUseAzure,
  },
  {
    href: paths._costAssistCostCompare,
    icon: faHandHoldingDollar,
    keywords: [],
    name: copyText.costComparePageTitle,
  },
  {
    href: paths._costAssistRampPlans,
    icon: faHandHoldingDollar,
    keywords: [],
    name: copyText.rampPlansPageTitle,
  },
  {
    href: paths._costAssistReallocations,
    icon: faHandHoldingDollar,
    keywords: [],
    name: copyText.reallocationsPageTitle,
  },
  {
    href: paths._costReports,
    icon: faFileInvoice,
    keywords: [],
    name: copyText.costReportsPageTitle,
  },
  {
    href: paths._dashboards,
    icon: faChartArea,
    keywords: [],
    name: copyText.navSearchOptionNameDashboard,
  },
  {
    href: paths._forecasting,
    icon: faHandHoldingDollar,
    keywords: [],
    name: copyText.navSearchOptionNameForecasting,
  },
  {
    href: paths._home,
    icon: faCloud,
    keywords: [],
    name: copyText.homePageTitle,
  },
  {
    href: paths._insightsBigQuery,
    icon: faMicrochip,
    keywords: ["gcp", "optimizations", "usageoptimizations", "rightsizing"],
    name: copyText.bigQueryPageTitle,
  },
  {
    href: paths._insightsCompute,
    icon: faMicrochip,
    keywords: ["optimizations", "usageoptimizations", "rightsizing"],
    name: copyText.computeInsightsPageTitle,
  },
  {
    href: paths._insightsComputeAzureVM,
    icon: faMicrochip,
    keywords: ["azure", "optimizations", "usageoptimizations", "rightsizing"],
    name: copyText.navSearchoptionNameAzureCompute,
  },
  {
    href: paths._insightsComputeEC2,
    icon: faMicrochip,
    keywords: [
      "aws",
      "ec2",
      "optimizations",
      "usageoptimizations",
      "rightsizing",
    ],
    name: copyText.navSearchOptionNameEC2,
  },
  {
    href: paths._insightsComputeGCE,
    icon: faMicrochip,
    keywords: ["gcp", "optimizations", "usageoptimizations", "rightsizing"],
    name: copyText.navSearchOptionNameComputeEngine,
  },
  {
    href: paths._insightsDatabase,
    icon: faMicrochip,
    keywords: ["optimizations", "usageoptimizations", "rightsizing"],
    name: copyText.databaseInsightsPageTitle,
  },
  {
    href: paths._insightsDatabaseMemoryDB,
    icon: faMicrochip,
    keywords: ["aws", "optimizations", "usageoptimizations", "rightsizing"],
    name: copyText.databaseInsightAWSMemoryDBTitle,
  },
  {
    href: paths._insightsDatabaseSnowflake,
    icon: faMicrochip,
    keywords: ["optimizations", "usageoptimizations", "rightsizing"],
    name: copyText.navSearchOptionNameSnowflakeDatabase,
  },
  {
    href: paths._insightsDatabaseAzureSQL,
    icon: faMicrochip,
    keywords: [
      "azure",
      "sql",
      "optimizations",
      "usageoptimizations",
      "rightsizing",
    ],
    name: copyText.navSearchOptionNameAzureSQL,
  },
  {
    href: paths._insightsDatabaseCloudSQL,
    icon: faMicrochip,
    keywords: ["gcp", "optimizations", "usageoptimizations", "rightsizing"],
    name: copyText.navSearchOptionNameCloudSQL,
  },
  {
    href: paths._insightsDatabaseElastiCache,
    icon: faMicrochip,
    keywords: ["gcp", "optimizations", "usageoptimizations", "rightsizing"],
    name: copyText.navSearchOptionNameElastiCache,
  },
  {
    href: paths._insightsDatabaseOpenSearch,
    icon: faMicrochip,
    keywords: ["aws", "analytics"],
    name: copyText.navSearchOptionNameOpenSearch,
  },
  {
    href: paths._insightsDatabaseRDS,
    icon: faMicrochip,
    keywords: [
      "aws",
      "rds",
      "optimizations",
      "usageoptimizations",
      "rightsizing",
    ],
    name: copyText.navSearchOptionNameRDS,
  },
  {
    href: paths._insightsDataWarehouse,
    icon: faMicrochip,
    keywords: ["optimizations", "usageoptimizations", "rightsizing"],
    name: copyText.dataWarehouseInsightsPageTitle,
  },
  {
    href: paths._insightsDataWarehousRedshift,
    icon: faMicrochip,
    keywords: [
      "aws",
      "redshift",
      "optimizations",
      "usageoptimizations",
      "rightsizing",
    ],
    name: copyText.navSearchOptionNameRedshiftWarehouse,
  },
  {
    href: paths._insightsKubernetes,
    icon: faMicrochip,
    keywords: ["optimizations", "usageoptimizations", "rightsizing"],
    name: copyText.kubernetesInsightsPageTitle,
  },
  {
    href: paths._insightsKubernetesAKS,
    icon: faMicrochip,
    keywords: [
      "azure",
      "aks",
      "optimizations",
      "usageoptimizations",
      "rightsizing",
    ],
    name: copyText.navSearchOptionNameAKS,
  },
  {
    href: paths._insightsKubernetesEKS,
    icon: faMicrochip,
    keywords: [
      "aws",
      "eks",
      "optimizations",
      "usageoptimizations",
      "rightsizing",
    ],
    name: copyText.navSearchOptionNameEKS,
  },
  {
    href: paths._insightsKubernetesGKE,
    icon: faMicrochip,
    keywords: [
      "gcp",
      "gke",
      "optimizations",
      "usageoptimizations",
      "rightsizing",
    ],
    name: copyText.navSearchOptionNameGKE,
  },
  {
    href: paths._insightsDataWarehouseSnowflake,
    icon: faMicrochip,
    keywords: ["optimizations", "usageoptimizations", "rightsizing"],
    name: copyText.navSearchOptionNameSnowflakeWarehouse,
  },
  {
    href: paths._insightsStorage,
    icon: faMicrochip,
    keywords: ["optimizations", "usageoptimizations", "rightsizing"],
    name: copyText.storageInsightsPageTitle,
  },
  {
    href: paths._insightsStorageEBS,
    icon: faMicrochip,
    keywords: [
      "aws",
      "ebs",
      "optimizations",
      "usageoptimizations",
      "rightsizing",
    ],
    name: copyText.navSearchOptionNameEBS,
  },
  {
    href: paths._insightsStorageS3,
    icon: faMicrochip,
    keywords: [
      "aws",
      "s3",
      "optimizations",
      "usageoptimizations",
      "rightsizing",
    ],
    name: copyText.navSearchOptionNameS3,
  },
  {
    href: paths._insightsStorageGCS,
    icon: faMicrochip,
    keywords: ["gcp", "optimizations", "usageoptimizations", "rightsizing"],
    name: copyText.navSearchOptionNameCloudStorage,
  },
  {
    href: paths._mspBillingRulesEngine,
    icon: faHandshake,
    keywords: ["rerating", "partner"],
    name: copyText.sideNavItemLabelMspBillingRulesEngine,
  },
  {
    href: paths._mspDashboard,
    icon: faHandshake,
    keywords: ["partners", "partner", "msp"],
    name: copyText.navSearchOptionNameMSPDashboard,
  },
  {
    href: paths._mspMgmt,
    icon: faHandshake,
    keywords: ["partners", "partner"],
    name: copyText.mspAdminTitle,
  },
  {
    href: paths._mspAdmin,
    icon: faHandshake,
    keywords: [],
    name: copyText.navSearchOptionNameMSPAdmin,
  },
  {
    href: paths._reports,
    icon: faChartArea,
    keywords: [],
    name: copyText.navSearchOptionNameReports,
  },
  {
    href: paths._reportBuilderNew,
    icon: faChartArea,
    keywords: [],
    name: copyText.navSearchOptionNameReportBuilder,
  },
  {
    href: paths._settings,
    icon: faWrench,
    keywords: [],
    name: copyText.userSettingsManagementPageTitle,
  },
  {
    href: paths._settings,
    icon: faWrench,
    keywords: [],
    name: copyText.navSearchOptionNameSettingScopedViews,
    params: { tab: "scopedViews" },
  },
  {
    href: paths._settings,
    icon: faWrench,
    keywords: [],
    name: copyText.navSearchOptionNameSettingsAPIKeys,
    params: { tab: "apiKeys" },
  },
];

type excludedPaths =
  | typeof paths._alertRuleFeed
  | typeof paths._case
  | typeof paths._costAssistCostCompareTakeout
  | typeof paths._customLabels
  | typeof paths._dashboard
  | typeof paths._insightsComputeCloudRun
  | typeof paths._insightsDatabaseSpanner
  | typeof paths._insightsStorageBB
  | typeof paths._internalAdmin
  | typeof paths._internalMspPartnerTenants
  | typeof paths._internalTenantUsers
  | typeof paths._labelGroupingRules
  | typeof paths._mspDashboard
  | typeof paths._mspAdminTenantUsers
  | typeof paths._reportBuilder;

type ExcludeRoute<Path extends string> = Path extends excludedPaths
  ? never
  : Path;

type PathValuesFromPaths = {
  [Path in (typeof paths)[keyof typeof paths] as ExcludeRoute<Path>]: true;
};

type PathValuesFromNavOptions = {
  [NavOption in (typeof navOptionsForType)[number]["href"]]: true;
};

// if this type is throwing an error, add the new path to excludedPaths or navOptionsForType
const _ignore: PathValuesFromPaths =
  true as unknown as PathValuesFromNavOptions;

export const navOptions: NavOption[] = navOptionsForType;
