import Icon, { Props as IconProps } from "@ternary/web-ui-lib/components/Icon";
import React from "react";

export default function IconOracleCloud(props: IconProps): JSX.Element {
  return (
    <Icon {...props}>
      <svg
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 50 50"
      >
        <g id="Layer_3"></g>
        <g id="Layer_1_1_">
          <path
            fill="#d7d8d9"
            d="M35.2,3.3C35.2,3.3,35.1,3.2,35.2,3.3C35.1,3.2,35.2,3.3,35.2,3.3L35.2,3.3z"
          />
          <g id="Layer_2_1_"></g>
          <g id="Layer_1_2_">
            <g id="Layer_1_3_">
              <g>
                <circle fill="#ea1b22" cx="25" cy="25" r="24" />
              </g>
            </g>
          </g>
          <path
            fill="#ffffff"
            d="M31.2,14.2H18.8C12.8,14.2,8,19.1,8,25s4.8,10.7,10.8,10.7h12.5C37.2,35.8,42,30.9,42,25S37.2,14.2,31.2,14.2z
     M31,32H19c-3.8,0-7-3.1-7-6.9s3.1-7,7-7h12c3.8,0,7,3.1,7,7C37.9,28.9,34.8,32,31,32z"
          />
        </g>
        <g id="Layer_2"></g>
      </svg>
    </Icon>
  );
}
