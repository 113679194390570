import { useTheme } from "@emotion/react";
import { faCopy } from "@fortawesome/free-solid-svg-icons";
import { createColumnHelper } from "@tanstack/react-table";
import { TenantStatus, TenantType } from "@ternary/api-lib/constants/enums";
import { MspBillingInfoEntity } from "@ternary/api-lib/core/types";
import Table, {
  ActionMenuButton,
} from "@ternary/api-lib/ui-lib/charts/Table/Table";
import Tooltip from "@ternary/api-lib/ui-lib/components/Tooltip";
import { formatTimestamp } from "@ternary/web-ui-lib/charts/utils";
import Flex from "@ternary/web-ui-lib/components/Flex";
import Icon from "@ternary/web-ui-lib/components/Icon";
import Text from "@ternary/web-ui-lib/components/Text";
import React, { useMemo } from "react";
import { GateKeeper } from "../../../api/core/useGetGatekeeper";
import useGatekeeper from "../../../hooks/useGatekeeper";
import Dropdown from "../../../ui-lib/components/Dropdown";
import copyText from "../copyText";

type Tenant = {
  id: string;
  fsDocID: string;
  createdAt: string;
  name: string;
  serviceAccountEmail: string;
  mspBillingInfo?: MspBillingInfoEntity;
  status: TenantStatus;
  type: TenantType;
};

const columnHelper = createColumnHelper<Tenant>();

interface Props {
  isInternal: boolean;
  isLoading: boolean;
  isProcessing: boolean;
  tenants: Tenant[];
  onInteraction: (interaction: TenantTable.Interaction) => void;
}

function TenantTable(props: Props): JSX.Element {
  const gatekeeper = useGatekeeper();
  const theme = useTheme();

  const columns = useMemo(
    () => [
      columnHelper.accessor("name", { header: copyText.tableHeaderName }),
      columnHelper.accessor("createdAt", {
        cell: ({ getValue }) => formatTimestamp(getValue(), "MM/dd/yyyy"),
        header: copyText.tableHeaderCreatedAt,
        size: 60,
      }),
      columnHelper.accessor("status", {
        header: copyText.tableHeaderStatus,
        size: 50,
      }),
      ...(gatekeeper.canReadTenantsSystem
        ? [
            columnHelper.accessor("type", {
              cell: ({ getValue }) =>
                copyText[`tableTypeColumnLabel_${getValue()}`],
              header: copyText.tableHeaderType,
              size: 80,
            }),
          ]
        : []),
      columnHelper.accessor("id", {
        cell: ({ getValue, row }) => (
          <Flex alignItems="center" width="100%">
            <Text marginRight={theme.space_sm} truncate>
              <Tooltip content={row.original.fsDocID}>{getValue()}</Tooltip>
            </Text>
            <Icon
              clickable
              color={theme.text_color_secondary}
              icon={faCopy}
              onClick={() => navigator.clipboard.writeText(getValue())}
            />
          </Flex>
        ),
        header: copyText.tableHeaderID,
        size: 140,
      }),
      columnHelper.accessor("serviceAccountEmail", {
        cell: ({ getValue }) => (
          <Flex alignItems="center" width="100%">
            <Text marginRight={theme.space_sm} truncate>
              {getValue()}
            </Text>
            <Icon
              clickable
              color={theme.text_color_secondary}
              icon={faCopy}
              onClick={() => navigator.clipboard.writeText(getValue())}
            />
          </Flex>
        ),
        header: copyText.tableHeaderServiceAccountEmail,
        size: 140,
      }),
      columnHelper.display({
        id: "actionMenu",
        cell: ({ row }) => (
          <ActionDropdown
            billingInfoID={row.original.mspBillingInfo?.id}
            gateKeeper={gatekeeper}
            isInternalAdmin={props.isInternal}
            tenantID={row.original.id}
            type={row.original.type}
            onInteraction={props.onInteraction}
          />
        ),
        meta: { align: "center" },
        size: 40,
      }),
    ],
    [props.tenants, props.isLoading]
  );

  const data = useMemo(
    () =>
      props.tenants.map((tenant) => ({
        id: tenant.id,
        fsDocID: tenant.fsDocID,
        createdAt: tenant.createdAt,
        mspBillingInfo: tenant.mspBillingInfo,
        name: tenant.name,
        serviceAccountEmail: tenant.serviceAccountEmail,
        status: tenant.status,
        type: tenant.type,
      })),
    [props.tenants]
  );

  return (
    <Table
      columns={columns}
      data={data}
      initialState={{ sorting: [{ id: "name", desc: false }] }}
      isLoading={props.isLoading}
      showPagination
      sortable
    />
  );
}

interface ActionDropdownProps {
  isInternalAdmin: boolean;
  billingInfoID?: string;
  tenantID: string;
  type: TenantType;
  gateKeeper: GateKeeper;
  onInteraction: (interaction: TenantTable.Interaction) => void;
}

function ActionDropdown(props: ActionDropdownProps): JSX.Element {
  const options = [
    {
      label: copyText.actionDropdownOptionLabelAccessTenant,
      onClick: () =>
        props.onInteraction({
          type: TenantTable.INTERACTION_GRANT_TENANT_ACCESS_BUTTON_CLICKED,
          tenantID: props.tenantID,
        }),
    },
    {
      label: copyText.actionDropdownOptionLabelEditTenant,
      onClick: () =>
        props.onInteraction({
          type: TenantTable.INTERACTION_OPEN_EDIT_FORM_BUTTON_CLICKED,
          tenantID: props.tenantID,
        }),
    },
    ...(props.type === TenantType.MSP_PARENT
      ? [
          {
            label: copyText.actionDropdownOptionLabelViewTenants,
            onClick: () =>
              props.onInteraction({
                type: TenantTable.INTERACTION_VIEW_TENANTS_BUTTON_CLICKED,
                tenantID: props.tenantID,
              }),
          },
        ]
      : []),
    {
      label: copyText.actionDropdownOptionLabelViewUsers,
      onClick: () =>
        props.onInteraction({
          type: TenantTable.INTERACTION_VIEW_USERS_BUTTON_CLICKED,
          tenantID: props.tenantID,
        }),
    },
    ...(!props.isInternalAdmin && props.gateKeeper.canAccessMspInvoicing
      ? [
          {
            label: copyText.actionDropdownOptionLabelBillingInformation.replace(
              "%ACTION%",
              props.billingInfoID ? "Edit" : "Add"
            ),
            onClick: () =>
              props.onInteraction({
                type: TenantTable.INTERACTION_BILLING_INFORMATION_BUTTON_CLICKED,
                tenantID: props.tenantID,
                billingInfoID: props.billingInfoID,
              }),
          },
        ]
      : []),
    ...(!props.isInternalAdmin && props.gateKeeper.canAccessMspInvoicing
      ? [
          {
            label: copyText.actionDropdownOptionLabelExportInvoiceManifest,
            onClick: () =>
              props.onInteraction({
                type: TenantTable.INTERACTION_EXPORT_INVOICE_MANIFEST_BUTTON_CLICKED,
                tenantID: props.tenantID,
              }),
          },
        ]
      : []),
    ...(props.isInternalAdmin
      ? [
          {
            label: copyText.actionDropdownOptionLabelManageFeatureFlags,
            onClick: () =>
              props.onInteraction({
                type: TenantTable.INTERACTION_MANAGE_FEATURE_FLAGS_BUTTON_CLICKED,
                tenantID: props.tenantID,
              }),
          },
        ]
      : []),
  ];

  return (
    <Dropdown options={options} placement="bottom-end">
      <ActionMenuButton />
    </Dropdown>
  );
}

TenantTable.INTERACTION_BILLING_INFORMATION_BUTTON_CLICKED =
  "TenantTable.INTERACTION_BILLING_INFORMATION_BUTTON_CLICKED" as const;
TenantTable.INTERACTION_EXPORT_INVOICE_MANIFEST_BUTTON_CLICKED =
  "TenantTable.INTERACTION_EXPORT_INVOICE_MANIFEST_BUTTON_CLICKED" as const;
TenantTable.INTERACTION_MANAGE_FEATURE_FLAGS_BUTTON_CLICKED =
  "TenantTable.INTERACTION_MANAGE_FEATURE_FLAGS_BUTTON_CLICKED" as const;
TenantTable.INTERACTION_GRANT_TENANT_ACCESS_BUTTON_CLICKED =
  "TenantTable.INTERACTION_GRANT_TENANT_ACCESS_BUTTON_CLICKED" as const;
TenantTable.INTERACTION_OPEN_EDIT_FORM_BUTTON_CLICKED =
  "TenantTable.INTERACTION_OPEN_EDIT_FORM_BUTTON_CLICKED" as const;
TenantTable.INTERACTION_VIEW_TENANTS_BUTTON_CLICKED =
  "TenantTable.INTERACTION_VIEW_TENANTS_BUTTON_CLICKED" as const;
TenantTable.INTERACTION_VIEW_USERS_BUTTON_CLICKED =
  "TenantTable.INTERACTION_VIEW_USERS_BUTTON_CLICKED" as const;

type InteractionBillingInformationButtonClicked = {
  type: typeof TenantTable.INTERACTION_BILLING_INFORMATION_BUTTON_CLICKED;
  tenantID: string;
  billingInfoID?: string;
};

type InteractionExportInvoiceManifestButtonClicked = {
  type: typeof TenantTable.INTERACTION_EXPORT_INVOICE_MANIFEST_BUTTON_CLICKED;
  tenantID: string;
};

type InteractionManageFeatureFlagsButtonClicked = {
  type: typeof TenantTable.INTERACTION_MANAGE_FEATURE_FLAGS_BUTTON_CLICKED;
  tenantID: string;
};

type InteractionGrantTenantAccessButtonClicked = {
  type: typeof TenantTable.INTERACTION_GRANT_TENANT_ACCESS_BUTTON_CLICKED;
  tenantID: string;
};

type InteractionOpenEditFormButtonClicked = {
  type: typeof TenantTable.INTERACTION_OPEN_EDIT_FORM_BUTTON_CLICKED;
  tenantID: string;
};

type InteractionViewTenantsButtonClicked = {
  type: typeof TenantTable.INTERACTION_VIEW_TENANTS_BUTTON_CLICKED;
  tenantID: string;
};

type InteractionViewUsersButtonClicked = {
  type: typeof TenantTable.INTERACTION_VIEW_USERS_BUTTON_CLICKED;
  tenantID: string;
};

// eslint-disable-next-line @typescript-eslint/no-namespace
namespace TenantTable {
  export type Interaction =
    | InteractionBillingInformationButtonClicked
    | InteractionExportInvoiceManifestButtonClicked
    | InteractionManageFeatureFlagsButtonClicked
    | InteractionGrantTenantAccessButtonClicked
    | InteractionOpenEditFormButtonClicked
    | InteractionViewTenantsButtonClicked
    | InteractionViewUsersButtonClicked;
}

export default TenantTable;
