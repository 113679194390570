import { useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import {
  AWSRateAccountScope,
  AWSRatePaymentOption,
  AWSRateRecommendationTerm,
  AWSSavingsPlanServiceType,
  ServiceType,
} from "@ternary/api-lib/constants/enums";
import {
  AWSRateReservedInstanceRecommendationEntity,
  AWSRateSavingsPlanRecommendationEntity,
} from "@ternary/api-lib/core/types";
import Box from "@ternary/api-lib/ui-lib/components/Box";
import Button from "@ternary/api-lib/ui-lib/components/Button";
import Flex from "@ternary/api-lib/ui-lib/components/Flex";
import Text from "@ternary/api-lib/ui-lib/components/Text";
import { noop } from "lodash";
import Slider from "rc-slider";
import React, { useEffect, useRef, useState } from "react";
import { BooleanParam, useQueryParam } from "use-query-params";
import Divider from "../../../../ui-lib/components/Divider";
import SelectDropdown from "../../../../ui-lib/components/SelectDropdown";
import copyText from "../../copyText";
import {
  AWSCommittedUseFilter,
  AWSCommittedUseLookbackPeriod,
  AWSCommittedUseOfferingClass,
  AWSCommittedUseType,
} from "../types";
import { getPayerAccountIDs } from "../utils";

type Props = {
  cloudNamesKeyedByCloudID: Record<string, string>;
  committedUseType: AWSCommittedUseType;
  filters: AWSCommittedUseFilter;
  isLoading: boolean;
  reservedInstanceRecommendations: AWSRateReservedInstanceRecommendationEntity[];
  savingsPlanRecommendations: AWSRateSavingsPlanRecommendationEntity[];
  onInteraction: (
    interaction: AWSCommittedUseFilterControls.Interaction
  ) => void;
};

type Option = { label: string; value: string };

const typeOptions: Option[] = [
  { label: "Savings Plan", value: AWSCommittedUseType.SP },
  { label: "Reserved Instance", value: AWSCommittedUseType.RI },
];

const termOptions: Option[] = [
  { label: "1 year", value: AWSRateRecommendationTerm.ONE_YEAR },
  { label: "3 years", value: AWSRateRecommendationTerm.THREE_YEARS },
];

const recommendationLevelOptions: Option[] = [
  { label: "Payer", value: AWSRateAccountScope.PAYER },
  { label: "Linked Account", value: AWSRateAccountScope.LINKED },
];

const offeringClassOptions: Option[] = [
  { label: "Standard", value: AWSCommittedUseOfferingClass.STANDARD },
  { label: "Convertible", value: AWSCommittedUseOfferingClass.CONVERTIBLE },
];

/* prettier-ignore */
const paymentOptions: Option[] = [
  { label: "All Upfront", value: AWSRatePaymentOption.ALL_UPFRONT },
  { label: "Partial Upfront", value: AWSRatePaymentOption.PARTIAL_UPFRONT },
  { label: "No Upfront", value: AWSRatePaymentOption.NO_UPFRONT },
];

/* prettier-ignore */
const savingsPlanTypeOptions: Option[] = [
  { label: copyText.awsSPTableValueType_COMPUTE_SP, value: AWSSavingsPlanServiceType.CSP },
  { label: copyText.awsSPTableValueType_EC2_INSTANCE_SP, value: AWSSavingsPlanServiceType.EC2 },
  { label: copyText.awsSPTableValueType_SAGEMAKER_SP, value: AWSSavingsPlanServiceType.SAGEMAKER },
];

/* prettier-ignore */
const serviceTypeOptions: Option[] = [
  { label: copyText.awsRIServiceTypeLable_EC2, value: ServiceType.EC2 },
  { label: copyText.awsRIServiceTypeLable_ELASTICACHE,value: ServiceType.ELASTICACHE},
  { label: copyText.awsRIServiceTypeLable_MEMORY_DB,value: ServiceType.MEMORY_DB},
  { label: copyText.awsRIServiceTypeLable_OPEN_SEARCH,value: ServiceType.OPEN_SEARCH},
  { label: copyText.awsRIServiceTypeLable_REDSHIFT,value: ServiceType.REDSHIFT},
  { label: copyText.awsRIServiceTypeLable_RDS, value: ServiceType.RDS },
];

const lookbackPeriodOptions: Option[] = [
  { label: "7D", value: AWSCommittedUseLookbackPeriod.SEVEN_DAYS },
  { label: "14D", value: AWSCommittedUseLookbackPeriod.FOURTEEN_DAYS },
  { label: "30D", value: AWSCommittedUseLookbackPeriod.THIRTY_DAYS },
  { label: "60D", value: AWSCommittedUseLookbackPeriod.SIXTY_DAYS },
];

function findMatchingOption(options: Option[], value: string) {
  const found = options.find((option) =>
    value === null ? option.value === "null" : value === option.value
  );

  return found ?? options[0];
}

function AWSCommittedUseFilterControls(props: Props) {
  const theme = useTheme();
  const [showAdvancedOptions, setShowAdvancedOptions] = useQueryParam(
    "adv_options",
    BooleanParam
  );

  const selectedTypeOption = findMatchingOption(
    typeOptions,
    props.committedUseType
  );
  const selectedTermOption = findMatchingOption(
    termOptions,
    props.filters.term
  );
  const selectedRecommendationLevelOption = findMatchingOption(
    recommendationLevelOptions,
    props.filters.accountScope
  );
  const selectedOfferingClassOption = findMatchingOption(
    offeringClassOptions,
    props.filters.offeringClass
  );
  const selectedPaymentOption = findMatchingOption(
    paymentOptions,
    props.filters.paymentOption
  );
  const selectedSavingsPlanTypeOption = findMatchingOption(
    savingsPlanTypeOptions,
    props.filters.savingsPlanType
  );
  const selectedLookbackPeriodOption = findMatchingOption(
    lookbackPeriodOptions,
    props.filters.lookbackPeriod
  );
  const selectedServiceTypeOption = findMatchingOption(
    serviceTypeOptions,
    props.filters.serviceType
  );

  // PAYER ACCOUNT IDS

  const allPayerAccountIDs = getPayerAccountIDs([
    ...props.reservedInstanceRecommendations,
    ...props.savingsPlanRecommendations,
  ]);

  const allPayerAccountOptions: Option[] = allPayerAccountIDs.map(
    (accountID) => ({
      label: props.cloudNamesKeyedByCloudID[accountID] ?? accountID,
      value: accountID,
    })
  );

  const selectedPayerAccountID = props.filters.payerAccountID;
  const selectedPayerAccountName =
    props.cloudNamesKeyedByCloudID[selectedPayerAccountID] ??
    selectedPayerAccountID;

  const advancedOptionsActiveCount =
    Number(!!props.filters.minSavings) + Number(!!props.filters.minUtil);

  return (
    <Box
      backgroundColor={theme.panel_backgroundColor}
      borderRadius={theme.borderRadius_1}
      paddingVertical={theme.space_sm}
    >
      <Flex paddingHorizontal={theme.space_md} justifyContent="space-between">
        <Flex>
          <SelectDropdown
            hideSelectedOptions={false}
            options={allPayerAccountOptions}
            placement="bottom-start"
            selectedValues={[selectedPayerAccountID]}
            onChange={(value: string) =>
              props.onInteraction({
                type: AWSCommittedUseFilterControls.INTERACTION_CHANGE_PAYER_ACCOUNT_ID,
                accountID: value,
              })
            }
          >
            <Button marginRight={theme.space_md} secondary size="small">
              {getAccountCopyText(copyText.awsFiltersAccountIDsTypePayer, [
                selectedPayerAccountName,
              ])}
            </Button>
          </SelectDropdown>
          {props.committedUseType === AWSCommittedUseType.RI ? (
            <SelectDropdown
              hideSelectedOptions={false}
              options={serviceTypeOptions}
              placement="bottom-start"
              selectedValues={[selectedServiceTypeOption.value]}
              onChange={(value: string) =>
                props.onInteraction({
                  type: AWSCommittedUseFilterControls.INTERACTION_CHANGE_SERVICE_TYPE,
                  serviceType:
                    value === ServiceType.EC2 ||
                    value === ServiceType.ELASTICACHE ||
                    value === ServiceType.MEMORY_DB ||
                    value === ServiceType.OPEN_SEARCH ||
                    value === ServiceType.REDSHIFT ||
                    value === ServiceType.RDS
                      ? value
                      : ServiceType.EC2,
                })
              }
            >
              <Button marginRight={theme.space_md} secondary size="small">
                {copyText.awsServiceType.replace(
                  "%VALUE%",
                  selectedServiceTypeOption.label
                )}
              </Button>
            </SelectDropdown>
          ) : (
            <SelectDropdown
              hideSelectedOptions={false}
              options={savingsPlanTypeOptions}
              placement="bottom-start"
              selectedValues={[selectedSavingsPlanTypeOption.value]}
              onChange={(value: string) =>
                props.onInteraction({
                  type: AWSCommittedUseFilterControls.INTERACTION_CHANGE_SAVINGS_PLAN_TYPE,
                  savingsPlanType:
                    value === AWSSavingsPlanServiceType.CSP ||
                    value === AWSSavingsPlanServiceType.EC2 ||
                    value === AWSSavingsPlanServiceType.SAGEMAKER
                      ? value
                      : AWSSavingsPlanServiceType.CSP,
                })
              }
            >
              <Button marginRight={theme.space_md} secondary size="small">
                {copyText.awsFiltersSavingsPlanType.replace(
                  "%VALUE%",
                  selectedSavingsPlanTypeOption.label
                )}
              </Button>
            </SelectDropdown>
          )}
        </Flex>

        <Box>
          {lookbackPeriodOptions.map((option) => (
            <Button
              key={option.value}
              marginLeft={theme.space_xs}
              primary={option.value === selectedLookbackPeriodOption.value}
              secondary={option.value !== selectedLookbackPeriodOption.value}
              size="small"
              onClick={() =>
                props.onInteraction({
                  type: AWSCommittedUseFilterControls.INTERACTION_CHANGE_LOOKBACK_PERIOD,
                  lookbackPeriod: option.value,
                })
              }
            >
              {option.label}
            </Button>
          ))}

          <Button
            marginLeft={theme.space_xs}
            secondary
            size="small"
            width={171}
            onClick={() =>
              setShowAdvancedOptions(showAdvancedOptions ? null : true)
            }
          >
            {showAdvancedOptions
              ? copyText.awsFilterAdvancedOptionsHide
              : copyText.awsFilterAdvancedOptionsShow}

            {!showAdvancedOptions && advancedOptionsActiveCount > 0 && (
              <Text as="span">{` (${advancedOptionsActiveCount})`}</Text>
            )}
          </Button>
        </Box>
      </Flex>

      <Box paddingHorizontal={theme.space_xs}>
        <Divider margin={theme.space_sm} />
      </Box>

      <Flex paddingHorizontal={theme.space_md}>
        <SelectDropdown
          hideSelectedOptions={false}
          options={typeOptions}
          placement="bottom-start"
          selectedValues={[selectedTypeOption.value]}
          onChange={(value: string) =>
            value === AWSCommittedUseType.RI || value === AWSCommittedUseType.SP
              ? props.onInteraction({
                  type: AWSCommittedUseFilterControls.INTERACTION_CHANGE_TYPE,
                  committedUseType: value,
                })
              : noop()
          }
        >
          <Button marginRight={theme.space_md} secondary size="small">
            {copyText.awsFiltersType.replace(
              "%VALUE%",
              selectedTypeOption.label
            )}
          </Button>
        </SelectDropdown>

        <SelectDropdown
          hideSelectedOptions={false}
          options={termOptions}
          placement="bottom-start"
          selectedValues={[selectedTermOption.value]}
          onChange={(value: string) =>
            props.onInteraction({
              type: AWSCommittedUseFilterControls.INTERACTION_CHANGE_TERM,
              term: value,
            })
          }
        >
          <Button marginRight={theme.space_md} secondary size="small">
            {copyText.awsFiltersTerm.replace(
              "%VALUE%",
              selectedTermOption.label
            )}
          </Button>
        </SelectDropdown>

        <SelectDropdown
          hideSelectedOptions={false}
          options={recommendationLevelOptions}
          placement="bottom-start"
          selectedValues={[selectedRecommendationLevelOption.value]}
          onChange={(value: string) =>
            props.onInteraction({
              type: AWSCommittedUseFilterControls.INTERACTION_CHANGE_ACCOUNT_SCOPE,
              accountScope:
                value === AWSRateAccountScope.LINKED ||
                value === AWSRateAccountScope.PAYER
                  ? value
                  : AWSRateAccountScope.PAYER,
            })
          }
        >
          <Button marginRight={theme.space_md} secondary size="small">
            {copyText.awsFiltersRecommendationLevel.replace(
              "%VALUE%",
              selectedRecommendationLevelOption.label
            )}
          </Button>
        </SelectDropdown>

        {props.committedUseType === AWSCommittedUseType.RI &&
          props.filters.serviceType === ServiceType.EC2 && (
            <SelectDropdown
              hideSelectedOptions={false}
              options={offeringClassOptions}
              placement="bottom-start"
              selectedValues={[selectedOfferingClassOption.value]}
              onChange={(value: string) =>
                props.onInteraction({
                  type: AWSCommittedUseFilterControls.INTERACTION_CHANGE_OFFERING_CLASS,
                  offeringClass: value,
                })
              }
            >
              <Button marginRight={theme.space_md} secondary size="small">
                {copyText.awsFiltersofferingClass.replace(
                  "%VALUE%",
                  selectedOfferingClassOption.label
                )}
              </Button>
            </SelectDropdown>
          )}

        <SelectDropdown
          hideSelectedOptions={false}
          options={paymentOptions}
          placement="bottom-start"
          selectedValues={[selectedPaymentOption.value]}
          onChange={(value: string) =>
            props.onInteraction({
              type: AWSCommittedUseFilterControls.INTERACTION_CHANGE_PAYMENT_OPTION,
              paymentOption: value,
            })
          }
        >
          <Button marginRight={theme.space_md} secondary size="small">
            {copyText.awsFiltersPaymentOption.replace(
              "%VALUE%",
              selectedPaymentOption.label
            )}
          </Button>
        </SelectDropdown>
      </Flex>

      {showAdvancedOptions && (
        <>
          <Box paddingHorizontal={theme.space_xs}>
            <Divider margin={theme.space_sm} />
          </Box>
          <Flex paddingHorizontal={theme.space_md}>
            <Box flex="0 1 300px" marginRight={theme.space_lg} minWidth={200}>
              <PercentSlider
                label={copyText.awsFilterMinSavingsPercent}
                value={props.filters.minSavings}
                onChange={(value) => {
                  props.onInteraction({
                    type: AWSCommittedUseFilterControls.INTERACTION_CHANGE_MIN_SAVINGS,
                    minSavings: value ?? 0,
                  });
                }}
              />
            </Box>
            <Box flex="0 1 300px" marginRight={theme.space_lg} minWidth={200}>
              <PercentSlider
                label={copyText.awsFilterMinUtilPercent}
                value={props.filters.minUtil}
                onChange={(value) => {
                  props.onInteraction({
                    type: AWSCommittedUseFilterControls.INTERACTION_CHANGE_MIN_UTIL,
                    minUtil: value ?? 0,
                  });
                }}
              />
            </Box>
          </Flex>
        </>
      )}
    </Box>
  );
}

function getAccountCopyText(type: string, items: string[]) {
  return (
    items.length === 0
      ? copyText.awsFiltersAccountIDsAll
      : items.length === 1
        ? copyText.awsFiltersAccountID
        : copyText.awsFiltersAccountIDs
  )
    .replace("%TYPE%", type)
    .replace("%VALUE%", items[0])
    .replace("%MORE%", String(items.length - 1));
}

AWSCommittedUseFilterControls.INTERACTION_CHANGE_LOOKBACK_PERIOD =
  `AWSCommittedUseFilterControls.INTERACTION_CHANGE_LOOKBACK_PERIOD` as const;
AWSCommittedUseFilterControls.INTERACTION_CHANGE_MIN_SAVINGS =
  `AWSCommittedUseFilterControls.INTERACTION_CHANGE_MIN_SAVINGS` as const;
AWSCommittedUseFilterControls.INTERACTION_CHANGE_MIN_UTIL =
  `AWSCommittedUseFilterControls.INTERACTION_CHANGE_MIN_UTIL` as const;
AWSCommittedUseFilterControls.INTERACTION_CHANGE_OFFERING_CLASS =
  `AWSCommittedUseFilterControls.INTERACTION_CHANGE_OFFERING_CLASS` as const;
AWSCommittedUseFilterControls.INTERACTION_CHANGE_PAYER_ACCOUNT_ID =
  `AWSCommittedUseFilterControls.INTERACTION_CHANGE_PAYER_ACCOUNT_ID` as const;
AWSCommittedUseFilterControls.INTERACTION_CHANGE_PAYMENT_OPTION =
  `AWSCommittedUseFilterControls.INTERACTION_CHANGE_PAYMENT_OPTION` as const;
AWSCommittedUseFilterControls.INTERACTION_CHANGE_ACCOUNT_SCOPE =
  `AWSCommittedUseFilterControls.INTERACTION_CHANGE_ACCOUNT_SCOPE` as const;
AWSCommittedUseFilterControls.INTERACTION_CHANGE_SAVINGS_PLAN_TYPE =
  `AWSCommittedUseFilterControls.INTERACTION_CHANGE_SAVINGS_PLAN_TYPE` as const;
AWSCommittedUseFilterControls.INTERACTION_CHANGE_TERM =
  `AWSCommittedUseFilterControls.INTERACTION_CHANGE_TERM` as const;
AWSCommittedUseFilterControls.INTERACTION_CHANGE_TYPE =
  `AWSCommittedUseFilterControls.INTERACTION_CHANGE_TYPE` as const;
AWSCommittedUseFilterControls.INTERACTION_CHANGE_SERVICE_TYPE =
  `AWSCommittedUseFilterControls.INTERACTION_CHANGE_SERVICE_TYPE` as const;

interface InteractionChangeLookbackPeriod {
  type: typeof AWSCommittedUseFilterControls.INTERACTION_CHANGE_LOOKBACK_PERIOD;
  lookbackPeriod: string;
}

interface InteractionChangeMinSavings {
  type: typeof AWSCommittedUseFilterControls.INTERACTION_CHANGE_MIN_SAVINGS;
  minSavings: number;
}

interface InteractionChangeMinUtil {
  type: typeof AWSCommittedUseFilterControls.INTERACTION_CHANGE_MIN_UTIL;
  minUtil: number;
}

interface InteractionChangeOfferingClass {
  type: typeof AWSCommittedUseFilterControls.INTERACTION_CHANGE_OFFERING_CLASS;
  offeringClass: string;
}

interface InteractionChangePayerAccountID {
  type: typeof AWSCommittedUseFilterControls.INTERACTION_CHANGE_PAYER_ACCOUNT_ID;
  accountID: string;
}

interface InteractionChangePaymentOption {
  type: typeof AWSCommittedUseFilterControls.INTERACTION_CHANGE_PAYMENT_OPTION;
  paymentOption: string;
}

interface InteractionChangeAccountScope {
  type: typeof AWSCommittedUseFilterControls.INTERACTION_CHANGE_ACCOUNT_SCOPE;
  accountScope: AWSRateAccountScope;
}

interface InteractionChangeSavingsPlanType {
  type: typeof AWSCommittedUseFilterControls.INTERACTION_CHANGE_SAVINGS_PLAN_TYPE;
  savingsPlanType: AWSSavingsPlanServiceType;
}

interface InteractionChangeTerm {
  type: typeof AWSCommittedUseFilterControls.INTERACTION_CHANGE_TERM;
  term: string;
}

interface InteractionChangeType {
  type: typeof AWSCommittedUseFilterControls.INTERACTION_CHANGE_TYPE;
  committedUseType: AWSCommittedUseType;
}
interface InteractionChangeServiceType {
  type: typeof AWSCommittedUseFilterControls.INTERACTION_CHANGE_SERVICE_TYPE;
  serviceType: ServiceType;
}

// eslint-disable-next-line @typescript-eslint/no-namespace
namespace AWSCommittedUseFilterControls {
  export type Interaction =
    | InteractionChangeLookbackPeriod
    | InteractionChangeMinSavings
    | InteractionChangeMinUtil
    | InteractionChangeOfferingClass
    | InteractionChangePayerAccountID
    | InteractionChangePaymentOption
    | InteractionChangeAccountScope
    | InteractionChangeSavingsPlanType
    | InteractionChangeTerm
    | InteractionChangeType
    | InteractionChangeServiceType;
}

export default AWSCommittedUseFilterControls;

type PercentSliderProps = {
  label: string;
  value: number;
  onChange: (percent: number | null) => void;
};

const SliderContainer = styled(Box)(({ theme }) => ({
  paddingBottom: theme.space_lg,
  paddingLeft: theme.space_sm,
  paddingRight: theme.space_sm,

  ".rc-slider-dot": {
    backgroundColor: theme.text_color_secondary,
    border: "none",
    borderRadius: 0,
    height: theme.space_md,
    top: 0,
    width: theme.space_xxs,
  },
}));

const StyledSpan = styled("span")<{ isCurrent: boolean }>(
  ({ theme, isCurrent }) => ({
    color: theme.text_color,
    opacity: isCurrent ? 1 : 0.7,
  })
);

const SLIDER_DEBOUNCE_MS = 250;
function PercentSlider(props: PercentSliderProps) {
  const theme = useTheme();
  const timeout = useRef(0);
  const [value, setValue] = useState(props.value);

  useEffect(() => {
    setValue(props.value);
  }, [props.value]);

  function handleChange(value: number | number[]) {
    if (Array.isArray(value)) return;

    setValue(value);

    if (timeout.current) {
      clearTimeout(timeout.current);
    }

    timeout.current = window.setTimeout(() => {
      props.onChange(value > 0 ? value : null);
    }, SLIDER_DEBOUNCE_MS);
  }

  return (
    <Box>
      <Flex paddingLeft={theme.space_xs} justifyContent="space-between">
        <Text marginBottom={theme.space_xxs}>
          {props.label}
          <StyledSpan
            isCurrent={props.value === value}
          >{` ${value}%`}</StyledSpan>
        </Text>

        <Button
          marginRight={theme.space_md}
          secondary
          size="tiny"
          onClick={() => handleChange(0)}
        >
          {copyText.awsFilterClear}
        </Button>
      </Flex>

      <SliderContainer>
        <Slider
          step={1}
          max={100}
          min={0}
          marks={{
            [0]: {
              style: {
                fontSize: theme.fontSize_small,
                top: theme.space_xxs,
                color: theme.text_color,
              },
              label: "0%",
            },
            [100]: {
              style: {
                fontSize: theme.fontSize_small,
                top: theme.space_xxs,
                color: theme.text_color,
              },
              label: "100%",
            },
          }}
          handleStyle={{
            backgroundColor: theme.primary_color_text,
            padding: theme.space_sm,
            border: "none",
            marginTop: "-0.45rem",
            boxShadow: "-0.25rem 0.25rem 0.25rem rgba(0, 0, 0, 0.2)",
          }}
          trackStyle={{
            backgroundImage: `linear-gradient(to right, ${theme.secondary_color_background}, ${theme.primary_color_text})`,
            padding: "0.3rem",
          }}
          railStyle={{
            backgroundColor: theme.secondary_color_background,
            padding: "0.3rem",
          }}
          value={value}
          onChange={handleChange}
        />
      </SliderContainer>
    </Box>
  );
}
