import { useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import {
  autoPlacement,
  autoUpdate,
  useFloating,
  UseFloatingOptions,
} from "@floating-ui/react";
import Button from "@ternary/api-lib/ui-lib/components/Button";
import Flex from "@ternary/api-lib/ui-lib/components/Flex";
import React, {
  ReactElement,
  MouseEvent as ReactMouseEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { v4 as uuidv4 } from "uuid";
import { isReactElement } from "../../utils/children";

type Placement = "auto" | UseFloatingOptions["placement"];

const DropdownRoot = styled("div")({
  display: "flex",
  position: "relative",
});

const DropdownContent = styled("div")<{
  placement: Placement;
  width?: number | string;
}>(({ theme, width }) => ({
  backgroundColor: theme.panel_backgroundColor,
  borderRadius: theme.borderRadius_2,
  boxShadow: `0 4px 8px ${theme.box_shadow}`,
  borderWidth: "1px",
  borderColor: theme.border_color,
  border: `solid 1px ${theme.border_color}`,
  overflowY: "auto",
  padding: `${theme.space_sm}`,
  textAlign: "left",
  minwidth: width ?? undefined,
  width: width ?? undefined,
  zIndex: theme.zIndex_1600,
  marginTop: "8px",
}));

type Props = React.PropsWithChildren<{
  renderContent: () => JSX.Element | null;
  disabled?: boolean;
  hideInteractionButtons?: boolean;
  isOpen?: boolean;
  onCancel: () => void;
  onSubmit: () => void;
  cancelButtonText?: string;
  submitButtonText?: string;
  cancelButtonDisabled?: boolean;
  submitButtonDisabled?: boolean;
  placement?: "auto" | UseFloatingOptions["placement"];
  truncate?: boolean;
  width?: number | string;
  onClose?: () => void;
  onOpen?: () => void;
}>;

let openID: string | null = null;

export default function Dropdown(props: Props): JSX.Element {
  const theme = useTheme();
  const [isOpenState, setIsOpen] = useState(false);

  const placementOption =
    !props.placement || props.placement === "auto"
      ? { middleware: [autoPlacement()] }
      : { placement: props.placement };

  const { refs, floatingStyles } = useFloating({
    strategy: "fixed",
    whileElementsMounted: autoUpdate,
    ...placementOption,
  });

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, { capture: true });
    return () => document.removeEventListener("click", handleClickOutside);
  }, []);

  const intervalToClose = useRef<NodeJS.Timeout | null>(null);
  const openIDRef = useRef<string | null>(null);
  const timeout = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    return () => {
      if (typeof intervalToClose.current === "number") {
        clearInterval(intervalToClose.current);
      }
      if (typeof timeout.current === "number") {
        clearTimeout(timeout.current);
      }
    };
  }, []);

  const ref = useRef<HTMLDivElement>(null);

  const handleClickOutside = useCallback(
    (event: MouseEvent) => {
      if (ref.current && ref.current.contains(event.target as Node)) return;
      setIsOpen(false);

      if (props.onClose) {
        props.onClose();
      }
    },
    [ref]
  );

  function handleClickDropdown(event: ReactMouseEvent): void {
    if (props.disabled) return;

    event.stopPropagation();

    if (isOpen) {
      setIsOpen(false);
      if (props.onClose) {
        props.onClose();
      }
      return;
    }

    const openIDString = uuidv4();
    openID = openIDString;
    openIDRef.current = openIDString;

    setIsOpen(true);
    handleSetCloseInterval();

    if (props.onOpen) {
      props.onOpen();
    }
  }

  function handleSetCloseInterval(): void {
    intervalToClose.current = setInterval(() => {
      if (openID !== openIDRef.current) {
        setIsOpen(false);

        if (props.onClose) {
          props.onClose();
        }

        if (typeof intervalToClose.current === "number") {
          clearInterval(intervalToClose.current);
        }
      }
    }, 100);
  }

  const isOpen = props.isOpen !== undefined ? props.isOpen : isOpenState;

  const content = (
    <>
      <DropdownContent
        placement={props.placement}
        ref={refs.setFloating}
        style={{ ...floatingStyles }}
        width={props.width}
      >
        {props.renderContent()}
        {!props.hideInteractionButtons && (
          <Flex justifyContent="flex-end">
            <Button
              disabled={props.cancelButtonDisabled}
              size="small"
              marginRight={theme.space_xs}
              secondary
              width={100}
              onClick={props.onCancel}
            >
              {props.cancelButtonText}
            </Button>
            <Button
              size="small"
              disabled={props.submitButtonDisabled}
              primary
              width={100}
              onClick={() => {
                if (props.onClose) {
                  props.onClose();
                }

                props.onSubmit();
                setIsOpen(false);
              }}
            >
              {props.submitButtonText}
            </Button>
          </Flex>
        )}
      </DropdownContent>
    </>
  );

  const children = React.cloneElement(props.children as ReactElement, {
    disabled: props.disabled,
    onClick: handleClickDropdown,
  });

  return (
    <DropdownRoot ref={ref}>
      {isReactElement(children) && (
        <div style={{ width: "100%" }} ref={refs.setReference}>
          {children}
        </div>
      )}
      {isOpen && content}
    </DropdownRoot>
  );
}
