import DateRangeControls from "@/ui-lib/components/DateRangeControls/DateRangeControls";
import Grid from "@/ui-lib/components/Grid";
import { DateRange } from "@/utils/dates";
import { useDebounce } from "@/utils/timers";
import { useTheme } from "@emotion/react";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { getDateRangeFromDurationType } from "@ternary/api-lib/analytics/utils";
import { UnitType } from "@ternary/api-lib/constants/analytics";
import {
  ChartType,
  CloudProviderType,
  DataSource,
  DurationType,
  GcpTargetCommitmentType,
  Operator,
  RecommendationCategory,
  ServiceType,
  TimeGranularity,
} from "@ternary/api-lib/constants/enums";
import StackedBarChart from "@ternary/api-lib/ui-lib/charts/StackedBarChart";
import Button from "@ternary/api-lib/ui-lib/components/Button";
import Icon from "@ternary/api-lib/ui-lib/components/Icon";
import Box from "@ternary/web-ui-lib/components/Box";
import Flex from "@ternary/web-ui-lib/components/Flex";
import Text from "@ternary/web-ui-lib/components/Text";
import { differenceInHours, endOfDay } from "date-fns";
import React, { useEffect, useMemo, useState } from "react";
import {
  DateParam,
  DecodedValueMap,
  StringParam,
  createEnumParam,
  useQueryParams,
  withDefault,
} from "use-query-params";
import { z } from "zod";
import useGetRawData from "../../../../api/analytics/useGetRawData";
import useGetRecommendationsByTenantID from "../../../../api/core/hooks/useGetRecommendationsByTenantID";
import InsightsSelector from "../../../../components/InsightsSelector";
import useAuthenticatedUser from "../../../../hooks/useAuthenticatedUser";
import useAvailableDimensionsByDataSource from "../../../../hooks/useAvailableDimensionsByDataSource";
import useAvailableGlobalDate from "../../../../hooks/useAvailableGlobalDate";
import { createStructParam } from "../../../../lib/use-query-params";
import Dropdown from "../../../../ui-lib/components/Dropdown";
import useGetCudRecommendationsByTenantID from "../../../committed-use/gcp/hooks/useGetCudRecommendationsByTenantID";
import copyText from "../../copyText";
import {
  bigQueryCostMeasures,
  bigQueryStorageMeasures,
  bigQueryUsageDimensions,
  bigQueryUsageMeasures,
} from "../bigQuerySchema";
import useGetBigQueryDatasetGroups from "../hooks/useGetBigQueryDatasetGroups";
import useGetBigQueryEditions from "../hooks/useGetBigQueryEditions";
import useGetBigQuerySpendSummaries from "../hooks/useGetBigQuerySpendSummaries";
import { GcpBigQueryDatasetFilters } from "../types";
import GcpBigQueryDatasetGroupTable, {
  GcpBigQueryDatasetGroup,
} from "./GcpBigQueryDatasetGroupTable";
import GcpBigQueryDatasetGroupTableControls from "./GcpBigQueryDatasetGroupTableControls";
import GcpBigQueryEditionTable from "./GcpBigQueryEditionTable";
import GcpBigQueryMeters from "./GcpBigQueryMeters";

type Interaction =
  | GcpBigQueryEditionTable.Interaction
  | GcpBigQueryDatasetGroupTable.Interaction
  | GcpBigQueryDatasetGroupTableControls.Interaction;

const durationEnum = createEnumParam(Object.values(DurationType));

type QueryParams = DecodedValueMap<typeof queryParamConfigMap>;

const defaultDatasetGroupFilters = {
  datasetId: null,
  projectId: null,
};

const datasetGroupFiltersStruct = z.object({
  datasetId: z.nullable(z.string()),
  projectId: z.nullable(z.string()),
});

const CostChartOption = {
  USAGE_TYPE: "USAGE_TYPE",
  RESERVATION: "RESERVATION",
  REGION: "REGION",
  PROJECT: "PROJECT",
  USER: "USER",
} as const;

type CostChartOption = (typeof CostChartOption)[keyof typeof CostChartOption];

const costGroupingEnum = createEnumParam(Object.values(CostChartOption));

const UsageChartOption = {
  PROCESSING: "PROCESSING",
  STORAGE: "STORAGE",
} as const;

type UsageChartOption =
  (typeof UsageChartOption)[keyof typeof UsageChartOption];

const usageGroupingEnum = createEnumParam(Object.values(UsageChartOption));

type QueryParamState = {
  datasetGroupFilters: z.infer<typeof datasetGroupFiltersStruct>;
  dateRange: DateRange;
  dateRangeGranularity: TimeGranularity;
  duration: DurationType;
  selectedCostByLabelChartOption: string;
  selectedCostChartOption: CostChartOption;
  selectedDatasetID: string | null;
  selectedProjectID: string | null;
  selectedUsageChartOption: UsageChartOption;
};

const queryParamConfigMap = {
  date_range_end: DateParam,
  date_range_start: DateParam,
  duration: withDefault(durationEnum, DurationType.LAST_THIRTY_DAYS),
  dataset_group_filters: createStructParam(datasetGroupFiltersStruct),
  selected_cost_chart_option: withDefault(
    costGroupingEnum,
    CostChartOption.USAGE_TYPE
  ),
  selected_usage_chart_option: withDefault(
    usageGroupingEnum,
    UsageChartOption.PROCESSING
  ),
  selected_cost_by_label_chart_option: withDefault(StringParam, ""),
  selected_dataset_id: withDefault(StringParam, null),
  selected_project_id: withDefault(StringParam, null),
};

const costGroupingLabel = {
  [CostChartOption.USAGE_TYPE]: copyText.bigQueryCostChartOptionLabel_usageType,
  [CostChartOption.RESERVATION]:
    copyText.bigQueryCostChartOptionLabel_reservation,
  [CostChartOption.REGION]: copyText.bigQueryCostChartOptionLabel_region,
  [CostChartOption.PROJECT]: copyText.bigQueryCostChartOptionLabel_project,
  [CostChartOption.USER]: copyText.bigQueryCostChartOptionLabel_user,
};

const usageGroupingLabel = {
  [UsageChartOption.PROCESSING]:
    copyText.bigQueryUsageChartOptionLabel_processing,
  [UsageChartOption.STORAGE]: copyText.bigQueryUsageChartOptionLabel_storage,
};

const usageRecommendationTypes = [
  "gcp/bigQueryReservationBaselineUtilization",
  "gcp/bigQueryReservationMaxUtilization",
  "gcp/bigQueryStorageBillingModel",
];

const defaultCostByLabelData = [];
const defaultCostData = [];
const defaultCudRecommendations = [];
const defaultDatasetGroups = [];
const defaultEditionData = [];
const defaultUsageData = [];
const defaultUsageRecommendations = [];

export default function GcpBigQueryVisibilityContainer(): JSX.Element {
  const authenticatedUser = useAuthenticatedUser();
  const theme = useTheme();
  const globalDate = useAvailableGlobalDate();

  //
  // State
  //

  const [queryParams, setQueryParams] = useQueryParams(queryParamConfigMap);

  const queryParamState = getQueryParamState(queryParams);

  const [searchText, setSearchText] = useState("");

  const debouncedSearchText = useDebounce(searchText);

  let dateRange: Date[] = [];

  const customDateRange =
    (queryParams.duration === DurationType.CUSTOM ||
      queryParams.duration === DurationType.INVOICE) &&
    queryParams.date_range_start &&
    queryParams.date_range_end
      ? [queryParams.date_range_start, queryParams.date_range_end]
      : null;

  if (!globalDate.enabled && customDateRange) {
    dateRange = customDateRange;
  } else if (globalDate.date) {
    dateRange = globalDate.date;
  } else {
    dateRange = getDateRangeFromDurationType(queryParams.duration);
  }

  //
  // Queries
  //

  const spendSummaries = useGetBigQuerySpendSummaries({
    dateRange,
  });

  const [
    { data: selectedPeriodSpend, isFetching: isLoadingSelectedPeriodSpend },
    { data: previousPeriodSpend, isFetching: isLoadingPreviousPeriodSpend },
  ] = spendSummaries;

  const {
    data: usageRecommendations = defaultUsageRecommendations,
    isFetching: isLoadingUsageRecommendations,
  } = useGetRecommendationsByTenantID(authenticatedUser.tenant.fsDocID ?? "", {
    category: RecommendationCategory.ANALYTICS,
    cloudProviderType: CloudProviderType.GCP,
    serviceType: ServiceType.BIGQUERY,
  });

  const {
    data: cudRecommendations = defaultCudRecommendations,
    isFetching: isLoadingCudRecommendations,
  } = useGetCudRecommendationsByTenantID(authenticatedUser.tenant.id ?? "");

  const costDimensions = getCostDimensionsFromOption(
    queryParamState.selectedCostChartOption
  );

  const costMeasures = getCostMeasuresFromOption(
    queryParamState.selectedCostChartOption
  );

  const costDatasource = getCostDatasourceFromOption(
    queryParamState.selectedCostChartOption
  );

  const { data: costData = defaultCostData, isFetching: isLoadingCostData } =
    useGetRawData({
      dataSource: costDatasource,
      dateRange,
      dimensions: costDimensions,
      granularity: queryParamState.dateRangeGranularity,
      measures: costMeasures,
    });

  const {
    data: costByLabelData = defaultCostByLabelData,
    isFetching: isLoadingCostByLabelData,
  } = useGetRawData(
    {
      dataSource: DataSource.BIGQUERY_USAGE,
      dateRange,
      dimensions: [queryParamState.selectedCostByLabelChartOption],
      granularity: queryParamState.dateRangeGranularity,
      measures: [bigQueryUsageMeasures.onDemandCost],
    },
    { enabled: queryParamState.selectedCostByLabelChartOption !== "" }
  );

  const availableDimensions = useAvailableDimensionsByDataSource(
    DataSource.BIGQUERY_USAGE
  ).map((dimension) => ({ name: dimension, isDate: false }));

  const usageMeasures = getUsageMeasuresFromOption(
    queryParamState.selectedUsageChartOption
  );

  const usageDatasource = getUsageDatasourceFromOption(
    queryParamState.selectedUsageChartOption
  );

  const { data: usageData = defaultUsageData, isFetching: isLoadingUsageData } =
    useGetRawData({
      dataSource: usageDatasource,
      dateRange,
      dimensions: [],
      granularity: queryParamState.dateRangeGranularity,
      measures: usageMeasures,
    });

  const {
    data: datasetGroups = defaultDatasetGroups,
    isFetching: isLoadingResources,
  } = useGetBigQueryDatasetGroups({ dateRange });

  const {
    data: editionData = defaultEditionData,
    isFetching: isLoadingEditionData,
  } = useGetBigQueryEditions(
    {
      dateRange,
      queryFilters: [
        {
          name: "jobProjectId",
          operator: Operator.EQUALS,
          values: [queryParamState.selectedProjectID ?? ""],
        },
        {
          name: "datasetId",
          operator: Operator.EQUALS,
          values: [queryParamState.selectedDatasetID ?? ""],
        },
      ],
    },
    { enabled: !!queryParamState.selectedProjectID }
  );

  //
  // Side Effects
  //

  useEffect(() => {
    if (queryParamState.selectedCostByLabelChartOption === "") {
      setQueryParams({
        selected_cost_by_label_chart_option: availableDimensions[0].name,
      });
    }
  }, [availableDimensions]);

  //
  // Modified query Data
  //

  const filteredDatasetGroups = useMemo(() => {
    return getFilteredDatasetGroups({
      allDatasetGroups: datasetGroups ?? [],
      searchText: debouncedSearchText,
      datasetGroupFilters: queryParamState.datasetGroupFilters,
    });
  }, [debouncedSearchText, datasetGroups, queryParamState.datasetGroupFilters]);

  const csvData = useMemo(
    () => getCSVData(filteredDatasetGroups),
    [filteredDatasetGroups]
  );

  const averagedUsageData = useMemo(() => {
    return usageData.map((datum) => {
      if (typeof datum.totalSlotS !== "number") return datum;

      return {
        ...datum,
        totalSlotS: datum.totalSlotS ? datum.totalSlotS / 86400 : 0,
      };
    });
  }, [usageData]);

  //
  // Interactions
  //

  function handleInteraction(interaction: Interaction) {
    switch (interaction.type) {
      case GcpBigQueryDatasetGroupTable.INTERACTION_FILTER_CLICKED: {
        const nextFilters = { ...queryParamState.datasetGroupFilters };

        nextFilters[interaction.filterKey] = interaction.filterValue;

        setQueryParams({ dataset_group_filters: nextFilters });
        break;
      }
      case GcpBigQueryDatasetGroupTable.INTERACTION_RESOURCE_CLICKED: {
        setQueryParams({
          selected_dataset_id: interaction.selectedDatasetID,
          selected_project_id: interaction.selectedProjectID,
        });
        break;
      }
      case GcpBigQueryEditionTable.INTERACTION_CLOSE_BUTTON_CLICKED: {
        setQueryParams({ selected_project_id: null });
        break;
      }
      case GcpBigQueryDatasetGroupTableControls.INTERACTION_REMOVE_FILTER_CLICKED: {
        const nextFilters = { ...queryParamState.datasetGroupFilters };

        nextFilters[interaction.filterKey] = null;

        if (Object.values(nextFilters).every((value) => value === null)) {
          setQueryParams({ dataset_group_filters: null });
        } else {
          setQueryParams({ dataset_group_filters: nextFilters });
        }
        break;
      }
      case GcpBigQueryDatasetGroupTableControls.INTERACTION_SEARCH_TEXT_UPDATED: {
        setSearchText(interaction.searchText);
        break;
      }
    }
  }

  //
  // Render
  //

  const filteredUsageRecommendations = usageRecommendations.filter(
    (recommendation) =>
      recommendation.applicable &&
      usageRecommendationTypes.includes(recommendation.typeID)
  );

  const rateRecommendations = cudRecommendations.filter(
    (recommendation) =>
      recommendation.isApplicable &&
      !recommendation.isHidden &&
      recommendation.targetCommitmentType === GcpTargetCommitmentType.SLOTS
  );
  const costGroupingOptions = [
    CostChartOption.USAGE_TYPE,
    CostChartOption.RESERVATION,
    CostChartOption.REGION,
    CostChartOption.PROJECT,
    CostChartOption.USER,
  ].map((costGrouping) => ({
    label: costGroupingLabel[costGrouping],
    value: costGrouping,
    onClick: () => setQueryParams({ selected_cost_chart_option: costGrouping }),
  }));

  const defaultCostGroupingOption =
    costGroupingOptions.find(
      (option) => option.value === queryParams.selected_cost_chart_option
    ) ?? costGroupingOptions[0];

  const costByLabelGroupingOptions = availableDimensions.map((dimension) => ({
    label: dimension.name,
    value: dimension.name,
    onClick: () =>
      setQueryParams({ selected_cost_by_label_chart_option: dimension.name }),
  }));

  const defaultCostByLabelGroupingOption =
    costByLabelGroupingOptions.find(
      (option) =>
        option.value === queryParams.selected_cost_by_label_chart_option
    ) ?? costByLabelGroupingOptions[0];

  const usageGroupingOptions = [
    UsageChartOption.PROCESSING,
    UsageChartOption.STORAGE,
  ].map((usageGrouping) => ({
    label: usageGroupingLabel[usageGrouping],
    value: usageGrouping,
    onClick: () =>
      setQueryParams({ selected_usage_chart_option: usageGrouping }),
  }));

  const defaultUsageGroupingOption =
    usageGroupingOptions.find(
      (option) => option.value === queryParams.selected_usage_chart_option
    ) ?? usageGroupingOptions[0];

  const costKeys = {
    [bigQueryCostMeasures.computeCost]: copyText.bigQueryCostCompute,
    [bigQueryCostMeasures.egressCost]: copyText.bigQueryCostEgress,
    [bigQueryCostMeasures.storageCost]: copyText.bigQueryCostStorage,
    [bigQueryCostMeasures.streamingInsertCost]:
      copyText.bigQueryCostStreamingInsert,
  };

  const resourceUsageKeys = {
    [bigQueryStorageMeasures.activeLogicalB]:
      copyText.bigQueryResourceUsageActiveLogicalB,
    [bigQueryStorageMeasures.activePhysicalB]:
      copyText.bigQueryResourceUsageActivePhysicalB,
    [bigQueryStorageMeasures.longTermLogicalB]:
      copyText.bigQueryResourceUsageLongTermLogicalB,
    [bigQueryStorageMeasures.longTermPhysicalB]:
      copyText.bigQueryResourceUsageLongTermPhysicalB,
    [bigQueryUsageMeasures.totalSlotS]:
      copyText.bigQueryResourceUsageTotalSlotS,
  };

  const costChartReportSnapshot = {
    chartType: ChartType.STACKED_BAR,
    dataSource: costDatasource,
    dateRange:
      queryParamState.duration === DurationType.CUSTOM ? dateRange : null,
    dimensions: costDimensions,
    durationType: queryParamState.duration,
    isFiscalMode: false,
    fiscalPeriodMap: null,
    granularity: TimeGranularity.DAY,
    measures: costMeasures,
    name: copyText.bigQueryCostReportSnapshotTitle,
    xAxisKey: "timestamp",
  };

  const costByLabelChartReportSnapshot = {
    chartType: ChartType.STACKED_BAR,
    dataSource: DataSource.BIGQUERY_USAGE,
    dateRange:
      queryParamState.duration === DurationType.CUSTOM ? dateRange : null,
    dimensions: [queryParamState.selectedCostByLabelChartOption],
    durationType: queryParamState.duration,
    isFiscalMode: false,
    fiscalPeriodMap: null,
    granularity: TimeGranularity.DAY,
    measures: [bigQueryUsageMeasures.onDemandCost],
    name: copyText.bigQueryCostByLabelReportSnapshotTitle,
    xAxisKey: "timestamp",
  };

  const usageChartReportSnapshot = {
    chartType: ChartType.CLUSTERED_BAR,
    dateRange:
      queryParamState.duration === DurationType.CUSTOM ? dateRange : null,
    dataSource: DataSource.CLOUD_SPANNER_USAGE,
    dimensions: [],
    durationType: queryParamState.duration,
    isFiscalMode: false,
    fiscalPeriodMap: null,
    granularity: TimeGranularity.DAY,
    measures: usageMeasures,
    name: copyText.bigQueryUsageChartTitle,
    xAxisKey: "timestamp",
  };

  return (
    <Box>
      <Flex
        justifyContent="flex-end"
        backgroundColor={theme.panel_backgroundColor}
        borderRadius={theme.borderRadius_1}
        marginVertical={theme.space_lg}
        padding={theme.space_md}
      >
        <DateRangeControls
          dateRange={dateRange}
          durationType={queryParamState.duration}
          hiddenOptions={[DurationType.QUARTER_TO_DATE]}
          onChangeDateRange={(durationType, dateRange) => {
            setQueryParams({
              duration: durationType,
              date_range_start: dateRange?.[0] ?? null,
              date_range_end: dateRange?.[1] ?? null,
            });
          }}
        />
      </Flex>
      <Box
        backgroundColor={theme.panel_backgroundColor}
        borderRadius={theme.borderRadius_2}
        marginBottom={theme.space_lg}
        padding={theme.space_md}
      >
        {/* Meters */}
        <GcpBigQueryMeters
          isLoading={
            isLoadingCudRecommendations ||
            isLoadingPreviousPeriodSpend ||
            isLoadingSelectedPeriodSpend ||
            isLoadingUsageRecommendations
          }
          selectedPeriodSpend={selectedPeriodSpend?.cost ?? 0}
          previousPeriodSpend={previousPeriodSpend?.cost ?? 0}
          durationType={
            globalDate.enabled ? globalDate.durationType : queryParams.duration
          }
          dateRange={dateRange}
          rateRecommendations={rateRecommendations}
          usageRecommendations={filteredUsageRecommendations}
        ></GcpBigQueryMeters>
      </Box>
      <Flex height={500} marginBottom={theme.space_lg} width={"100%"}>
        <Box width={"100%"}>
          <InsightsSelector
            resourceName={copyText.bigQueryCostAggregateChartTitle}
            reportSnapshot={costChartReportSnapshot}
          >
            <Box
              backgroundColor={theme.panel_backgroundColor}
              borderRadius={theme.borderRadius_2}
              flex={1}
              height="100%"
              padding={theme.space_md}
              width="100%"
            >
              <Flex justifyContent="space-between">
                <Text fontSize={theme.h3_fontSize}>
                  {copyText.bigQueryCostAggregateChartTitle}
                </Text>
                <Dropdown
                  defaultSelectedOption={defaultCostGroupingOption}
                  options={costGroupingOptions}
                  placement="bottom-end"
                >
                  <Button
                    iconEnd={<Icon icon={faChevronDown} />}
                    secondary
                    size="small"
                    width={140}
                  >
                    {costGroupingLabel[queryParamState.selectedCostChartOption]}
                  </Button>
                </Dropdown>
              </Flex>
              <Box height={450} paddingVertical={theme.space_md}>
                {/* Aggregate Cost Chart */}
                <StackedBarChart
                  data={costData}
                  disableDrilldown
                  dimensions={costDimensions.map((dimension) => ({
                    name: dimension,
                    isDate: false,
                  }))}
                  emptyPlaceholderText={copyText.noDataPlaceholderMessage}
                  isLoading={isLoadingCostData}
                  limit={10}
                  measures={costMeasures.map(getMeasureWithUnit)}
                  readableKeys={costKeys}
                  showLegend
                  showTooltip
                  timeSeriesGranularity={queryParamState.dateRangeGranularity}
                  xAxisKey="timestamp"
                />
              </Box>
            </Box>
          </InsightsSelector>
        </Box>
      </Flex>
      <Grid
        gridColumnGap={theme.space_lg}
        gridTemplateColumns={`repeat(2, calc(50% - (${theme.space_lg} / 2) ))`}
      >
        <Flex height={500} marginBottom={theme.space_lg} width={"100%"}>
          <Box width="100%">
            <InsightsSelector
              resourceName={copyText.bigQueryCostByLabelChartTitle}
              reportSnapshot={costByLabelChartReportSnapshot}
            >
              <Box
                backgroundColor={theme.panel_backgroundColor}
                borderRadius={theme.borderRadius_2}
                flex={1}
                height="100%"
                padding={theme.space_md}
                width="100%"
              >
                <Flex justifyContent="space-between">
                  <Text fontSize={theme.h3_fontSize}>
                    {copyText.bigQueryCostByLabelChartTitle}
                  </Text>
                  {/* Cost by Label Chart Dropdown */}
                  <Dropdown
                    defaultSelectedOption={defaultCostByLabelGroupingOption}
                    options={costByLabelGroupingOptions}
                    placement="bottom-end"
                    truncate
                  >
                    <Button
                      iconEnd={<Icon icon={faChevronDown} />}
                      secondary
                      size="small"
                      width={140}
                    >
                      <Text truncate>
                        {queryParamState.selectedCostByLabelChartOption}
                      </Text>
                    </Button>
                  </Dropdown>
                </Flex>
                <Box height={450} paddingVertical={theme.space_md}>
                  {/* Cost by Label Chart */}
                  <StackedBarChart
                    data={costByLabelData}
                    disableDrilldown
                    dimensions={[
                      availableDimensions.find(
                        (dimension) =>
                          dimension.name ===
                          queryParamState.selectedCostByLabelChartOption
                      ) ?? availableDimensions[0],
                    ]}
                    emptyPlaceholderText={copyText.noDataPlaceholderMessage}
                    isLoading={isLoadingCostByLabelData}
                    limit={10}
                    measures={[
                      getMeasureWithUnit(bigQueryUsageMeasures.onDemandCost),
                    ]}
                    showLegend
                    showTooltip
                    timeSeriesGranularity={queryParamState.dateRangeGranularity}
                    xAxisKey="timestamp"
                  />
                </Box>
              </Box>
            </InsightsSelector>
          </Box>
        </Flex>
        <Flex height={500} marginBottom={theme.space_lg} width={"100%"}>
          <Box width={"100%"}>
            <InsightsSelector
              resourceName={copyText.bigQueryUsageChartTitle}
              reportSnapshot={usageChartReportSnapshot}
            >
              <Box
                backgroundColor={theme.panel_backgroundColor}
                borderRadius={theme.borderRadius_2}
                flex={1}
                height="100%"
                padding={theme.space_md}
                width="100%"
              >
                <Flex justifyContent="space-between">
                  <Text fontSize={theme.h3_fontSize}>
                    {copyText.bigQueryUsageChartTitle}
                  </Text>
                  {/* Chart Dropdown */}
                  <Dropdown
                    defaultSelectedOption={defaultUsageGroupingOption}
                    options={usageGroupingOptions}
                    placement="bottom-end"
                  >
                    <Button
                      iconEnd={<Icon icon={faChevronDown} />}
                      secondary
                      size="small"
                      width={140}
                    >
                      {
                        usageGroupingLabel[
                          queryParamState.selectedUsageChartOption
                        ]
                      }
                    </Button>
                  </Dropdown>
                </Flex>
                <Box height={450} paddingVertical={theme.space_md}>
                  {/* Usage Chart */}
                  <StackedBarChart
                    clustered
                    data={averagedUsageData}
                    dimensions={[]}
                    disableDrilldown
                    emptyPlaceholderText={copyText.noDataPlaceholderMessage}
                    isLoading={isLoadingUsageData}
                    limit={10}
                    measures={usageMeasures.map(getMeasureWithUnit)}
                    readableKeys={resourceUsageKeys}
                    showLegend
                    showTooltip
                    xAxisKey="timestamp"
                    timeSeriesGranularity={queryParamState.dateRangeGranularity}
                  />
                </Box>
              </Box>
            </InsightsSelector>
          </Box>
        </Flex>
      </Grid>
      <Box
        backgroundColor={theme.panel_backgroundColor}
        borderRadius={theme.borderRadius_1}
        marginVertical={theme.space_lg}
        padding={theme.space_md}
      >
        {/* Table Controls */}
        <GcpBigQueryDatasetGroupTableControls
          csvData={csvData}
          debouncedSearchText={debouncedSearchText}
          filters={queryParamState.datasetGroupFilters}
          searchText={searchText}
          onInteraction={handleInteraction}
        />
      </Box>
      {/* Outer Table */}
      <GcpBigQueryDatasetGroupTable
        datasets={filteredDatasetGroups}
        isLoading={isLoadingResources}
        onInteraction={handleInteraction}
      ></GcpBigQueryDatasetGroupTable>
      {queryParamState.selectedProjectID && (
        <GcpBigQueryEditionTable
          dateRange={queryParamState.dateRange}
          durationType={queryParamState.duration}
          instances={editionData}
          isLoading={isLoadingEditionData}
          selectedDatasetID={queryParamState.selectedDatasetID ?? ""}
          selectedProjectID={queryParamState.selectedProjectID}
          onInteraction={handleInteraction}
        />
      )}
    </Box>
  );
}

function getQueryParamState(queryParams: QueryParams): QueryParamState {
  const dateRange =
    queryParams.date_range_start && queryParams.date_range_end
      ? [queryParams.date_range_start, queryParams.date_range_end]
      : getDateRangeFromDurationType(queryParams.duration);

  const dateRangeDurationInHours =
    dateRange[0] && dateRange[1]
      ? differenceInHours(endOfDay(dateRange[1]), dateRange[0])
      : 0;

  const isSmallDateRange =
    dateRangeDurationInHours > 0 && dateRangeDurationInHours <= 48;

  const dateRangeGranularity = isSmallDateRange
    ? TimeGranularity.HOUR
    : TimeGranularity.DAY;

  return {
    dateRange,
    dateRangeGranularity,
    duration: queryParams.duration,
    datasetGroupFilters:
      queryParams.dataset_group_filters ?? defaultDatasetGroupFilters,
    selectedCostChartOption: queryParams.selected_cost_chart_option,
    selectedCostByLabelChartOption:
      queryParams.selected_cost_by_label_chart_option,
    selectedDatasetID: queryParams.selected_dataset_id,
    selectedProjectID: queryParams.selected_project_id,
    selectedUsageChartOption: queryParams.selected_usage_chart_option,
  };
}

function isDatasetGroupPassingFilters(
  datasetGroup: GcpBigQueryDatasetGroup,
  filters: GcpBigQueryDatasetFilters
): boolean {
  if (
    filters.datasetId !== null &&
    datasetGroup.datasetId?.toLowerCase().trim() !==
      filters.datasetId.toLowerCase().trim()
  ) {
    return false;
  }

  if (
    filters.projectId !== null &&
    datasetGroup.projectId?.toLowerCase().trim() !==
      filters.projectId.toLowerCase().trim()
  ) {
    return false;
  }

  return true;
}

function isSearchTextInServiceGroup(
  serviceGroup: GcpBigQueryDatasetGroup,
  searchText: string
): boolean {
  if (searchText === "") return true;

  const values = [serviceGroup.datasetId, serviceGroup.projectId].map(
    (value) => (value === "" ? "null" : value)
  );

  return values.some((value) =>
    value?.toLowerCase().trim().includes(searchText)
  );
}

const csvAccessors = [
  "datasetId",
  "projectId",
  "onDemandCost",
  "jobCount",
  "queryCount",
  "queryBytes",
  "maxJobSlots",
] as const;

type CSVData = {
  headers: { key: string; label: string }[];
  rows: Record<string, string | number>[];
};

function getCSVData(groups: GcpBigQueryDatasetGroup[]): CSVData {
  if (!groups.length) {
    return { headers: [], rows: [] };
  }

  const rows = groups.map((group) => ({
    datasetId: group.datasetId ?? copyText.bigQueryDatasetGroupTableNull,
    jobCount: group.jobCount,
    maxJobSlots: group.maxJobSlots,
    onDemandCost: group.onDemandCost,
    projectId: group.projectId ?? copyText.bigQueryDatasetGroupTableNull,
    queryBytes: group.queryBytes,
    queryCount: group.queryCount,
  }));

  const headers = csvAccessors.map((csvAccessor) => {
    // ensure rows has a value for each accessor
    const key: keyof (typeof rows)[number] = csvAccessor;

    // ensure copyText has a value for each accessor
    const copyTextKey: keyof typeof copyText = `bigQueryDatasetGroupTableHeader_${csvAccessor}`;
    const label = copyText[copyTextKey];

    return { key, label };
  });

  return { headers, rows };
}

function getFilteredDatasetGroups(params: {
  allDatasetGroups: GcpBigQueryDatasetGroup[];
  searchText: string | null;
  datasetGroupFilters: GcpBigQueryDatasetFilters;
}): GcpBigQueryDatasetGroup[] {
  const searchText = (params.searchText ?? "").toLowerCase().trim();

  return params.allDatasetGroups.filter((datasetGroup) => {
    if (
      !isDatasetGroupPassingFilters(datasetGroup, params.datasetGroupFilters)
    ) {
      return false;
    }

    if (!isSearchTextInServiceGroup(datasetGroup, searchText)) {
      return false;
    }

    return true;
  });
}

function getCostDatasourceFromOption(option: CostChartOption): DataSource {
  switch (option) {
    case CostChartOption.USAGE_TYPE:
      return DataSource.BIGQUERY_COST;
    default: {
      return DataSource.BIGQUERY_USAGE;
    }
  }
}

function getCostDimensionsFromOption(option: CostChartOption): string[] {
  switch (option) {
    case CostChartOption.PROJECT:
      return [bigQueryUsageDimensions.projectID];
    case CostChartOption.RESERVATION:
      return [bigQueryUsageDimensions.reservationID];
    case CostChartOption.REGION:
      return [bigQueryUsageDimensions.region];
    case CostChartOption.USAGE_TYPE:
      return [];
    case CostChartOption.USER:
      return [bigQueryUsageDimensions.userEmail];
    default: {
      const _exhaustiveCheck: never = option;
      return _exhaustiveCheck;
    }
  }
}

function getCostMeasuresFromOption(option: CostChartOption): string[] {
  switch (option) {
    case CostChartOption.USAGE_TYPE:
      return [
        bigQueryCostMeasures.computeCost,
        bigQueryCostMeasures.egressCost,
        bigQueryCostMeasures.storageCost,
        bigQueryCostMeasures.streamingInsertCost,
      ];
    default: {
      return [bigQueryUsageMeasures.onDemandCost];
    }
  }
}

function getUsageDatasourceFromOption(option: UsageChartOption): DataSource {
  switch (option) {
    case UsageChartOption.STORAGE:
      return DataSource.BIGQUERY_STORAGE;
    default: {
      return DataSource.BIGQUERY_USAGE;
    }
  }
}

function getUsageMeasuresFromOption(option: UsageChartOption): string[] {
  switch (option) {
    case UsageChartOption.PROCESSING:
      return [bigQueryUsageMeasures.totalSlotS];
    case UsageChartOption.STORAGE:
      return [
        bigQueryStorageMeasures.activeLogicalB,
        bigQueryStorageMeasures.activePhysicalB,
        bigQueryStorageMeasures.longTermLogicalB,
        bigQueryStorageMeasures.longTermPhysicalB,
      ];
    default: {
      const _exhaustiveCheck: never = option;
      return _exhaustiveCheck;
    }
  }
}

const measuresWithUnit: { name: string; unit: UnitType }[] = [
  { name: bigQueryCostMeasures.computeCost, unit: UnitType.CURRENCY },
  { name: bigQueryCostMeasures.egressCost, unit: UnitType.CURRENCY },
  { name: bigQueryCostMeasures.storageCost, unit: UnitType.CURRENCY },
  { name: bigQueryCostMeasures.streamingInsertCost, unit: UnitType.CURRENCY },
  { name: bigQueryStorageMeasures.activeLogicalB, unit: UnitType.BYTES },
  { name: bigQueryStorageMeasures.activePhysicalB, unit: UnitType.BYTES },
  { name: bigQueryStorageMeasures.longTermLogicalB, unit: UnitType.BYTES },
  { name: bigQueryStorageMeasures.longTermPhysicalB, unit: UnitType.BYTES },
  { name: bigQueryUsageMeasures.onDemandCost, unit: UnitType.CURRENCY },
  { name: bigQueryUsageMeasures.totalSlotS, unit: UnitType.STANDARD },
];

function getMeasureWithUnit(measure: string) {
  return (
    measuresWithUnit.find((other) => other.name === measure) ?? {
      name: measure,
      unit: UnitType.STANDARD,
    }
  );
}
