import {
  add,
  differenceInDays,
  endOfMonth,
  format,
  startOfMonth,
  sub,
} from "date-fns";
import { floor, keyBy, uniq } from "lodash";
import { RawData, RawValue, ReportDataConfig } from "../analytics/types";
import {
  DateRange,
  getCubeDateRangeFromDurationType,
  getDateRangeFromLastNDays,
  getDateRangeFromLastNMonths,
  isValidRollingWindow,
} from "../analytics/utils";
import { availableMeasuresMap } from "../constants/analytics";
import {
  ChartType,
  CompareDurationType,
  DataSource,
  DurationType,
  MetricAggregate,
  Operator,
  TimeGranularity,
} from "../constants/enums";
import { DashboardEntity, ReportEntity } from "../core/types";
import { Dimension, Measure } from "../ui-lib/charts/types";
import {
  COMPARISON_KEY,
  DEFAULT_X_AXIS_KEY,
  NOT_SHOWN_KEY,
  PERCENT_DIFFERENCE_KEY,
  PREVIOUS_TIMESTAMP_KEY,
  RAW_DIFFERENCE_KEY,
} from "../ui-lib/charts/utils";
import copyText from "../ui-lib/copyText";
import { formatDate } from "../ui-lib/utils/dates";

export function convertDimension(
  value: RawValue,
  dimension: Dimension,
  timeFormat: string
) {
  if (dimension.isDate && typeof value === "string" && timeFormat) {
    return formatDate(new Date(value), timeFormat);
  }

  if (value === null) {
    return "null";
  }

  return value;
}

export function getDashboardDateRange(
  dateRange: DateRange,
  durationType: DurationType
): string[] {
  if (durationType === DurationType.INVOICE) {
    return [format(dateRange[0], "MM/yyyy"), format(dateRange[1], "MM/yyyy")];
  }

  return [dateRange[0].toISOString(), dateRange[1].toISOString()];
}

export function getDateRangeFromDashboard(
  dashboard: Pick<
    DashboardEntity,
    | "durationType"
    | "endDate"
    | "invoiceMonthEnd"
    | "invoiceMonthStart"
    | "startDate"
  >
): DateRange | null {
  if (
    dashboard.durationType === DurationType.CUSTOM &&
    dashboard.endDate &&
    dashboard.startDate
  ) {
    return [new Date(dashboard.startDate), new Date(dashboard.endDate)];
  }

  if (
    dashboard.durationType === DurationType.INVOICE &&
    dashboard.invoiceMonthEnd &&
    dashboard.invoiceMonthStart
  ) {
    const startSplit = dashboard.invoiceMonthStart.split("/");
    const endSplit = dashboard.invoiceMonthEnd.split("/");

    const startDate = [startSplit[1], startSplit[0], "1"].join("-");
    const endDate = [endSplit[1], endSplit[0], "1"].join("-");

    return [
      new Date(startDate),
      add(new Date(endDate), { months: 1, days: -1 }),
    ];
  }

  return dashboard.durationType
    ? getCubeDateRangeFromDurationType(dashboard.durationType)
    : null;
}

export function getReportsWithModifiedDates(
  reports: ReportEntity[] | undefined,
  modification: {
    dateRange: DateRange | null;
    durationType: DurationType;
    invoiceMonthRange: DateRange | null;
  }
): ReportEntity[] {
  const { dateRange, durationType, invoiceMonthRange } = modification;

  return (reports ?? []).map((report) => {
    const [startDate, endDate] = dateRange
      ? getDashboardDateRange(dateRange, durationType)
      : [null, null];

    const [invoiceMonthStart, invoiceMonthEnd] = invoiceMonthRange
      ? getDashboardDateRange(invoiceMonthRange, durationType)
      : [null, null];

    return {
      ...report,
      ...((durationType === DurationType.CUSTOM ||
        durationType === DurationType.INVOICE) &&
      dateRange
        ? { compareEndDate: endDate, compareStartDate: startDate }
        : {}),
      compareType: report.compareDurationType ? durationType : null,
      ...(durationType === DurationType.CUSTOM && dateRange
        ? { endDate, startDate }
        : {}),
      durationType: durationType,
      ...(invoiceMonthRange && durationType === DurationType.INVOICE
        ? { invoiceMonthEnd, invoiceMonthStart }
        : {}),
    };
  });
}

export function getTimeDurationCaption(
  durationType: DurationType,
  isFiscalMode: boolean
): string {
  switch (durationType) {
    case DurationType.LAST_N_DAYS:
    case DurationType.LAST_N_MONTHS:
    case DurationType.CUSTOM: {
      return copyText.durationTypeCustomCaption;
    }
    case DurationType.INVOICE: {
      return copyText.durationTypeInvoiceCaption;
    }
    case DurationType.LAST_MONTH: {
      return copyText.durationTypeLastMonthCaption;
    }
    case DurationType.LAST_NINETY_DAYS: {
      return isFiscalMode
        ? copyText.durationTypeFiscalLastNinetyDaysCaption
        : copyText.durationTypeLastNinetyDaysCaption;
    }
    case DurationType.LAST_SEVEN_DAYS: {
      return isFiscalMode
        ? copyText.durationTypeFiscalLastSevenDaysCaption
        : copyText.durationTypeLastSevenDaysCaption;
    }
    case DurationType.LAST_THIRTY_DAYS: {
      return isFiscalMode
        ? copyText.durationTypeFiscalLastThirtyDaysCaption
        : copyText.durationTypeLastThirtyDaysCaption;
    }
    case DurationType.MONTH_TO_DATE: {
      return isFiscalMode
        ? copyText.durationTypeFiscalMonthToDateDaysCaption
        : copyText.durationTypeMonthToDateDaysCaption;
    }
    case DurationType.QUARTER_TO_DATE: {
      return isFiscalMode
        ? copyText.durationTypeFiscalQuarterToDateCaption
        : copyText.durationTypeQuarterToDateCaption;
    }
    case DurationType.TODAY: {
      return copyText.durationTypeTodayCaption;
    }
    case DurationType.YEAR_TO_DATE: {
      return isFiscalMode
        ? copyText.durationTypeFiscalYearToDateCaption
        : copyText.durationTypeYearToDateCaption;
    }
    case DurationType.YESTERDAY: {
      return copyText.durationTypeYesterdayCaption;
    }
    default: {
      const _exhaustiveCheck: never = durationType;
      return _exhaustiveCheck;
    }
  }
}

export function noop(): void {
  return;
}

// TODO: x is a special constant in our code
function executeFormula(params: {
  datum: RawData;
  externalDatum?: RawData;
  formula: string;
  measures: Measure[];
}): number | null {
  if (params.formula === "") {
    return null;
  }

  const externalDatumValues = Object.values(params.externalDatum ?? {});

  const externalDatumValue = externalDatumValues.find(
    (value) => typeof value === "number"
  );

  const variableMapping = params.measures.reduce(
    (accum: { [variableName: string]: number }, measure, index) => {
      const variableName = String.fromCharCode(index + 65);
      const value = params.datum[measure.name];

      if (typeof value === "number") {
        return { ...accum, [variableName]: value };
      }

      return accum;
    },
    {
      ...(params.externalDatum && typeof externalDatumValue === "number"
        ? { X: externalDatumValue }
        : {}),
    }
  );

  const numerator = variableMapping[params.formula.slice(0, 1)];
  const denominator = variableMapping[params.formula.slice(-1)];

  if (typeof numerator !== "number" || typeof denominator !== "number") {
    return null;
  }

  return numerator / denominator;
}

// NOTE: We always query all credit types for client side filtering for BILLING only.
export function getBillingMeasures(measures: string[]): string[] {
  return [
    "absoluteCreditsCommittedUsageDiscount",
    "absoluteCreditsCommittedUsageDiscountDollarBase",
    "absoluteCreditsDiscount",
    "absoluteCreditsFreeTier",
    "absoluteCreditsPromotion",
    "absoluteCreditsSubscriptionBenefit",
    "absoluteCreditsSustainedUsageDiscount",
    ...measures,
  ];
}

export function getCompareDateRangeFromDurationType(params: {
  dateRange: DateRange;
  durationType: DurationType;
}): DateRange {
  const { dateRange, durationType } = params;

  switch (durationType) {
    case DurationType.LAST_MONTH: {
      return [
        startOfMonth(dateRange[0].setMonth(dateRange[0].getMonth() - 1)),
        endOfMonth(dateRange[1].setMonth(dateRange[1].getMonth() - 1)),
      ];
    }
    case DurationType.MONTH_TO_DATE: {
      return [sub(dateRange[0], { days: 31 }), sub(dateRange[1], { days: 31 })];
    }
    case DurationType.YEAR_TO_DATE: {
      return [
        sub(dateRange[0], { days: 365 }),
        sub(dateRange[1], { days: 365 }),
      ];
    }
    default: {
      const numberOfDaysInRange = differenceInDays(dateRange[1], dateRange[0]);

      // Subtract an extra day from the start date since we are subtracting 1 for the end date
      return [
        sub(dateRange[0], { days: numberOfDaysInRange + 1 }),
        sub(dateRange[0], { days: 1 }),
      ];
    }
  }
}

export function getComparisonData(params: {
  compareData: RawData[];
  compareDurationType: CompareDurationType | null;
  data: RawData[];
  dimensions: string[];
  measures: string[];
}): RawData[] {
  if (params.compareData.length < 1) return [];

  function getKey(datum: RawData, measure: string) {
    const delim = "-%%DELIM%%-";
    const values: string[] = [measure];

    params.dimensions.forEach((dimension) =>
      values.push(String(datum[dimension]))
    );

    values.push(String(datum["timestamp"]));

    return values.join(delim);
  }

  const availableCurrentTimestamps: string[] = uniq(
    Object.values(params.data).map((datum) => String(datum.timestamp))
  );

  const availablePreviousTimestamps = uniq(
    Object.values(params.compareData).map((datum) => datum.timestamp)
  );

  const comparisonDataMap = params.compareData.reduce(
    (accum: { [key: string]: RawData }, datum) => {
      params.measures.forEach((measure) => {
        const key = getKey(datum, measure);

        accum[key] = datum;
      });
      return accum;
    },
    {}
  );

  return params.data.reduce((accum: RawData[], datum) => {
    const updated = { ...datum };

    const results: RawData[] = [];

    const currentTimestampIndex = availableCurrentTimestamps.indexOf(
      String(datum.timestamp)
    );

    const previousTimestamp =
      availablePreviousTimestamps[currentTimestampIndex] ??
      availablePreviousTimestamps[availablePreviousTimestamps.length - 1];

    params.measures.forEach((measure) => {
      let key = getKey(datum, measure);

      const delim = "-%%DELIM%%-";

      const splitKey = key.split(delim);

      splitKey[splitKey.length - 1] = String(previousTimestamp);

      key = splitKey.join(delim);

      const currentValue = datum[measure];
      if (comparisonDataMap[key]) {
        const previousValue = comparisonDataMap[key][measure];
        updated[`${measure}${COMPARISON_KEY}`] = previousValue;
        updated[PREVIOUS_TIMESTAMP_KEY] =
          comparisonDataMap[key][DEFAULT_X_AXIS_KEY] ??
          params.compareDurationType;
        if (
          typeof currentValue === "number" &&
          typeof previousValue === "number"
        ) {
          const rawDifference = currentValue - previousValue;
          // prettier-ignore
          const averageOfValues =(floor(currentValue, 3) + floor(previousValue, 3)) / 2;
          const precentDiff = rawDifference / averageOfValues;

          if (precentDiff < 0 && precentDiff >= -0.0009) {
            updated[PERCENT_DIFFERENCE_KEY] = 0;
          } else {
            updated[PERCENT_DIFFERENCE_KEY] = precentDiff;
          }

          updated[RAW_DIFFERENCE_KEY] = rawDifference;
        }
      } else {
        updated[`${measure}${COMPARISON_KEY}`] = 0;
        updated[RAW_DIFFERENCE_KEY] = currentValue;
        updated[PERCENT_DIFFERENCE_KEY] = 1;
        updated[PREVIOUS_TIMESTAMP_KEY] = previousTimestamp;
      }
    });

    results.push(updated);

    return [...accum, ...results];
  }, []);
}

export function getComparisonDateRangeFromReport(
  report: ReportDataConfig
): DateRange {
  let dateRange = getDateRangeFromReport(report);

  if (
    report.compareDurationType === CompareDurationType.CUSTOM &&
    report.compareEndDate &&
    report.compareStartDate
  ) {
    return [new Date(report.compareStartDate), new Date(report.compareEndDate)];
  }

  if (
    report.compareDurationType === CompareDurationType.INVOICE &&
    report.compareEndDate &&
    report.compareStartDate
  ) {
    const startSplit = report.compareStartDate.split("/");
    const endSplit = report.compareEndDate.split("/");

    const startDate = [startSplit[1], startSplit[0], "1"].join("-");
    const endDate = [endSplit[1], endSplit[0], "1"].join("-");

    return [
      new Date(startDate),
      add(new Date(endDate), { months: 1, days: -1 }),
    ];
  }

  dateRange = getCompareDateRangeFromDurationType({
    dateRange: dateRange,
    durationType: report.durationType,
  });

  if (!report.compareDurationType) {
    dateRange = [];
  }
  return dateRange;
}

export function getCumulativeData(params: {
  data: RawData[];
  dimensions: string[];
  measures: string[];
}): RawData[] {
  function getTotalsKey(datum: RawData, measure: string) {
    const delim = "-%%DELIM%%-";
    const values: string[] = [measure];

    params.dimensions.forEach((dimension) =>
      values.push(String(datum[dimension]))
    );

    return values.join(delim);
  }

  const runningTotals: { [key: string]: RawValue } = {};

  return params.data.map((datum) => {
    const updated: RawData = { ...datum };

    params.measures.forEach((measure) => {
      const totalsKey = getTotalsKey(datum, measure);

      const currentTotal = runningTotals[totalsKey];
      const measureValue = datum[measure];

      if (typeof currentTotal !== "number") {
        runningTotals[totalsKey] = measureValue;
        return;
      }

      if (typeof measureValue !== "number") {
        updated[measure] = currentTotal;
        return;
      }

      const cumulativeValue = currentTotal + measureValue;

      runningTotals[totalsKey] = cumulativeValue;
      updated[measure] = cumulativeValue;
    });

    return updated;
  });
}

export type BaseScopedView = {
  id: string;
  filters: ScopedViewFilter[];
};

type ScopedViewFilter = {
  dataSource: DataSource;
  name: string;
  operator: Operator;
  values: string[] | null;
};

type ScopedViewAndFilter = {
  and: ScopedViewFilter[];
};

type ScopedViewOrFilter = {
  or: ScopedViewAndFilter[] | ScopedViewFilter[];
};

export type DataSourceFilter = ScopedViewFilter | ScopedViewOrFilter;

export function getDataSourceFilters(
  filters: DataSourceFilter[],
  dataSource: DataSource
): DataSourceFilter[] {
  const globalFilters = filters.reduce((accum: DataSourceFilter[], filter) => {
    if ("dataSource" in filter) {
      return filter.dataSource === dataSource ? [...accum, filter] : accum;
    }

    if ("or" in filter) {
      const relevantFilters: ScopedViewAndFilter[] = [];

      filter.or.forEach((andFilters) => {
        if (!("and" in andFilters)) return;

        relevantFilters.push({
          and: andFilters.and.filter(
            (filter) => filter.dataSource === dataSource
          ),
        });
      });

      return [...accum, { or: relevantFilters }];
    }

    return accum;
  }, []);

  return globalFilters;
}

export function getFiltersForAllDataSources(
  scopedViews: BaseScopedView[],
  customFilters?: ScopedViewFilter[]
) {
  const scopedViewFilters = [
    {
      // Note: All scoped views filters are or'd together
      or: [
        ...scopedViews.map((scopedView) => ({
          and: scopedView.filters,
        })),
      ],
    },
  ];

  return [
    ...(scopedViews.length > 0 ? scopedViewFilters : []),

    // Custom filters are and'd on top of them.
    ...(customFilters ?? []),
  ];
}

export function getIsInvoiceMonthMode(report: ReportDataConfig) {
  return (
    report.durationType === DurationType.INVOICE &&
    report.timeGranularity === TimeGranularity.MONTH
  );
}

export function getShouldApplyGranularity(report: ReportDataConfig) {
  return (
    report.chartType !== ChartType.PIE &&
    report.chartType !== ChartType.TABLE &&
    report.chartType !== ChartType.KPI &&
    (report.xAxisKey === DEFAULT_X_AXIS_KEY || !report.xAxisKey) &&
    !getIsInvoiceMonthMode(report)
  );
}

export function getMappedAndFilteredData(
  data: RawData[],
  creditTypes: string[],
  metricAggregate?: MetricAggregate | null,
  selectedMetricName?: string
): RawData[] {
  return data.map((datum) =>
    Object.entries(datum).reduce((accum: RawData, [key, value]) => {
      if (key === "netCost") {
        const credits = creditTypes.reduce(
          (accum, creditType) => (accum += Number(datum[creditType])),
          0
        );

        return { ...accum, [key]: Number(value) + credits };
      }

      if (key === "absoluteCredits") {
        const credits = creditTypes.reduce(
          (accum, creditType) => (accum += Number(datum[creditType])),
          0
        );

        return { ...accum, [key]: Number(value) - credits };
      }

      if (
        metricAggregate &&
        key === getMeasureFromMetricAggregate(metricAggregate) &&
        selectedMetricName
      ) {
        return {
          ...accum,
          [selectedMetricName]: value,
        };
      }

      return { ...accum, [key]: datum[key] };
    }, {})
  );
}

const utilizationMeasuresKeyedByName = keyBy(
  availableMeasuresMap[DataSource.AWS_COMPUTE_UTILIZATION],
  (measure) => measure.name
);

function isUtilizationMeasure(measure: string): boolean {
  return !!utilizationMeasuresKeyedByName[measure];
}

export function getUtilizationMeasures(measures: string[]) {
  return measures.filter(isUtilizationMeasure);
}

export function getNonUtilizationMeasures(measures: string[]) {
  return measures.filter((measure) => !isUtilizationMeasure(measure));
}

export function mergeUtilizationData(params: {
  report: ReportDataConfig;
  sourceData: RawData[];
  utilizationData: RawData[];
}) {
  const sourceData = [...params.sourceData];

  if (!sourceData.length && !params.utilizationData.length) {
    return sourceData;
  }

  if (!sourceData.length) {
    return params.utilizationData;
  }

  let nextTimestamp = sourceData.at(-1)?.timestamp ?? "";

  const utilizationMeasures =
    params.report.measures.filter(isUtilizationMeasure) ?? [];

  const utilizationDataKeyedByTimestamp = keyBy(
    params.utilizationData,
    "timestamp"
  );

  for (let i = sourceData.length - 1; i >= 0; i--) {
    const sourceDatum = sourceData[i];
    const currentTimestamp = String(sourceDatum.timestamp);
    const utilizationDatum = utilizationDataKeyedByTimestamp[currentTimestamp];

    utilizationMeasures.forEach((measure) => {
      sourceDatum[measure] = null;
    });

    if (params.report.dimensions.length === 0 && utilizationDatum) {
      sourceData[i] = {
        ...sourceDatum,
        ...utilizationDatum,
      };

      continue;
    }

    if (
      currentTimestamp !== nextTimestamp &&
      utilizationDataKeyedByTimestamp[currentTimestamp]
    ) {
      const currentDatumWithUtilization = {
        ...sourceDatum,
        ...utilizationDataKeyedByTimestamp[currentTimestamp],
      };

      params.report.dimensions.forEach((dimension) => {
        currentDatumWithUtilization[dimension] = null;
      });

      params.report.measures.forEach((measure) => {
        if (isUtilizationMeasure(measure)) return;
        currentDatumWithUtilization[measure] = null;
      });

      sourceData.splice(i + 1, 0, currentDatumWithUtilization);

      nextTimestamp = currentDatumWithUtilization.timestamp ?? "";
    }
  }

  return sourceData;
}

export function getMeasureFromMetricAggregate(
  metricAggregate: MetricAggregate
): string {
  switch (metricAggregate) {
    case MetricAggregate.MAX: {
      return "maxValues";
    }
    case MetricAggregate.MEAN: {
      return "meanValues";
    }
    case MetricAggregate.MIN: {
      return "minValues";
    }
    case MetricAggregate.SUM: {
      return "sumValues";
    }
  }
}

export function getDateRangeFromReport(report: ReportDataConfig): DateRange {
  if (
    report.durationType === DurationType.LAST_N_DAYS &&
    isValidRollingWindow(report.nLookback)
  ) {
    return getDateRangeFromLastNDays({
      nLookback: report.nLookback,
    });
  }

  if (
    report.durationType === DurationType.LAST_N_MONTHS &&
    isValidRollingWindow(report.nLookback)
  ) {
    return getDateRangeFromLastNMonths({
      nLookback: report.nLookback,
    });
  }

  if (
    report.durationType === DurationType.CUSTOM &&
    report.endDate &&
    report.startDate
  ) {
    return [new Date(report.startDate), new Date(report.endDate)];
  }

  if (
    report.durationType === DurationType.INVOICE &&
    report.invoiceMonthEnd &&
    report.invoiceMonthStart
  ) {
    const startSplit = report.invoiceMonthStart.split("/");
    const endSplit = report.invoiceMonthEnd.split("/");

    const startDate = [startSplit[1], startSplit[0], "1"].join("-");
    const endDate = [endSplit[1], endSplit[0], "1"].join("-");

    return [
      new Date(startDate),
      add(new Date(endDate), { months: 1, days: -1 }),
    ];
  }

  return getCubeDateRangeFromDurationType(report.durationType);
}

export function mergeRawData(params: {
  data: RawData[];
  externalData: RawData[];
  formula: string;
  formulaAlias: string;
  measures: Measure[];
  metricAggregate: MetricAggregate | null;
  selectedMetricName?: string;
}): RawData[] {
  const externalDataKeyedByTimestamp = keyBy(params.externalData, "timestamp");

  return params.data.map((datum) => {
    const externalDatum =
      typeof datum.timestamp === "string"
        ? externalDataKeyedByTimestamp[datum.timestamp]
        : params.externalData[0];

    return {
      ...datum,
      ...(params.selectedMetricName && params.metricAggregate
        ? {
            [params.selectedMetricName]: externalDatum
              ? externalDatum[
                  getMeasureFromMetricAggregate(params.metricAggregate)
                ]
              : null,
          }
        : {}),
      ...(params.formula
        ? {
            [params.formulaAlias !== ""
              ? params.formulaAlias
              : copyText.unitEconomicsFormulaPlaceHolder]: executeFormula({
              datum,
              externalDatum,
              formula: params.formula,
              measures: params.measures,
            }),
          }
        : {}),
    };
  });
}

export function removeOtherData(params: {
  data: RawData[];
  dimensions: string[];
}) {
  return params.data.filter(
    (datum) =>
      !params.dimensions.some((dimension) => datum[dimension] === NOT_SHOWN_KEY)
  );
}
