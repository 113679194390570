import { useTheme } from "@emotion/react";
import {
  GcpCommitmentDurationType,
  GcpCommitmentType,
} from "@ternary/api-lib/constants/enums";
import Box from "@ternary/api-lib/ui-lib/components/Box";
import Button from "@ternary/api-lib/ui-lib/components/Button";
import Flex from "@ternary/api-lib/ui-lib/components/Flex";
import Text from "@ternary/web-ui-lib/components/Text";
import { isEqual } from "lodash";
import React, { useEffect, useState } from "react";
import { Input } from "../../../../types";
import { FormField } from "../../../../ui-lib/components/Form";
import LoadingSpinner from "../../../../ui-lib/components/LoadingSpinner";
import Select from "../../../../ui-lib/components/Select";
import getMergeState from "../../../../utils/getMergeState";
import { GCPIntegration } from "../../../admin/components/GCPIntegrationForm/types";
import copyText from "../../copyText";
import { isGCPCommitmentDurationType, isGCPCommitmentType } from "../utils";

interface Props {
  commitmentLength: GcpCommitmentDurationType;
  commitmentType: GcpCommitmentType;
  gcpIntegrations: GCPIntegration[];
  isLoadingGCPIntegrations: boolean;
  isProcessing: boolean;
  onInteraction: (
    interaction: EditCUDRecommendationSettingsForm.Interaction
  ) => void;
}

type State = {
  commitmentLengthInput: Input<GcpCommitmentDurationType>;
  commitmentTypeInput: Input<GcpCommitmentType>;
};

export function EditCUDRecommendationSettingsForm(props: Props): JSX.Element {
  const theme = useTheme();

  //
  // state
  //

  const initialState = {
    commitmentLengthInput: {
      hasChanged: false,
      isValid: true,
      value: props.commitmentLength,
    },
    commitmentTypeInput: {
      hasChanged: false,
      isValid: true,
      value: props.commitmentType,
    },
  };

  const [state, setState] = useState<State>(initialState);
  const mergeState = getMergeState(setState);

  //
  // Side Effects
  //

  useEffect(() => {
    setState(initialState);
  }, [props.commitmentLength, props.commitmentType]);

  //
  // Interaction Handlers
  //

  function handleChangeCommitmentLength(commitmentLength: string): void {
    if (!isGCPCommitmentDurationType(commitmentLength)) return;

    const hasChanged = !isEqual(commitmentLength, props.commitmentLength);

    mergeState({
      commitmentLengthInput: {
        hasChanged,
        isValid: true,
        value: commitmentLength,
      },
    });
  }

  function handleChangeCommitmentType(commitmentType: string): void {
    if (!isGCPCommitmentType(commitmentType)) return;

    const hasChanged = !isEqual(commitmentType, props.commitmentType);

    mergeState({
      commitmentTypeInput: { value: commitmentType, isValid: true, hasChanged },
    });
  }

  function handleCancel(): void {
    props.onInteraction({
      type: EditCUDRecommendationSettingsForm.INTERACTION_CANCEL_BUTTON_CLICKED,
    });

    setState(initialState);
  }

  function handleSubmit(event): void {
    event.preventDefault();

    props.onInteraction({
      type: EditCUDRecommendationSettingsForm.INTERACTION_SUBMIT_BUTTON_CLICKED,
      commitmentLength: state.commitmentLengthInput.value,
      commitmentType: state.commitmentTypeInput.value,
    });
  }

  //
  // Render
  //

  const commitmentLengthOptions = [
    {
      label: copyText.commitmentLengthOption1YearLabel,
      value: GcpCommitmentDurationType.TWELVE_MONTH,
    },
    {
      label: copyText.commitmentLengthOption3YearLabel,
      value: GcpCommitmentDurationType.THIRTY_SIX_MONTH,
    },
  ];

  const selectedCommitmentLengthOption = commitmentLengthOptions.find(
    (option) => option.value === state.commitmentLengthInput.value
  );

  const commitmentTypeOptions = [
    {
      label: copyText.commitmentTypeOptionSpendLabel,
      value: GcpCommitmentType.SPEND,
    },
    {
      label: copyText.commitmentTypeOptionResourceLabel,
      value: GcpCommitmentType.RESOURCE,
    },
  ];

  const selectedCommitmentTypeOption = commitmentTypeOptions.find(
    (option) => option.value === state.commitmentTypeInput.value
  );

  const hasChanged = Object.values(state).some((input) => input.hasChanged);

  const isValid = Object.values(state).every((input) => input.isValid === true);

  const canSubmit = hasChanged && isValid;

  return (
    <Flex direction="column">
      <Text appearance="h4" marginBottom={theme.space_md}>
        {copyText.cudRecSettingsFormFiltersLabel}
      </Text>
      {/* Commitment Length */}
      <Flex marginBottom={theme.space_lg}>
        <FormField label={copyText.cudRecSettingsFormCommitmentLengthLabel}>
          <Box width={200}>
            <Select
              menuPlacement="bottom"
              options={commitmentLengthOptions}
              value={selectedCommitmentLengthOption}
              onChange={(option) =>
                option && handleChangeCommitmentLength(option.value)
              }
            />
          </Box>
        </FormField>

        {/* Commitment Type */}
        <FormField
          label={copyText.cudRecSettingsFormCommitmentTypeLabel}
          marginLeft={theme.space_xxl}
        >
          <Box width={200}>
            <Select
              menuPlacement="bottom"
              options={commitmentTypeOptions}
              value={selectedCommitmentTypeOption}
              onChange={(option) =>
                option && handleChangeCommitmentType(option.value)
              }
            />
          </Box>
        </FormField>
      </Flex>
      <Text appearance="h4" marginBottom={theme.space_md}>
        {copyText.cudRecSettingsFormConsoleLinksLabel}
      </Text>
      <Text color={theme.text_color_secondary} marginBottom={theme.space_md}>
        {copyText.cudRecSettingsFormWarningMessage}
      </Text>
      <Text color={theme.text_color_secondary} marginBottom={theme.space_md}>
        {copyText.cudRecSettingsFormConsoleLinksDescription}
      </Text>
      {/* Links to GCP console recommendation config settings */}
      {props.gcpIntegrations.map((integration, index) => {
        const organizationID =
          integration.config.rootElement?.split("/")[0] === "organizations"
            ? integration.config.rootElement.split("/")[1]
            : undefined;
        const link = `https://console.cloud.google.com/billing/${integration.config.billingAccountID}/cost-recommendations/config?organizationId=${organizationID}&orgonly=true`;

        return organizationID ? (
          <a
            href={link}
            key={index}
            rel="noreferrer"
            target="_blank"
            onClick={(e) => e.stopPropagation()}
          >
            <Text
              appearance="link"
              color={theme.link_color}
              marginVertical={theme.space_xxs}
            >
              {`${integration.name} - ${integration.config.billingAccountID} - ${integration.config.rootElement}`}
            </Text>
          </a>
        ) : null;
      })}
      {(props.gcpIntegrations.length === 0 ||
        !props.gcpIntegrations.find(
          (integration) =>
            integration.config.rootElement?.split("/")[0] === "organizations"
        )) && (
        <Text>{copyText.cudRecSettingsFormNoValidCloudLinksMessage}</Text>
      )}
      <Flex justifyContent={"flex-end"} marginTop={theme.space_sm}>
        <Button
          disabled={props.isProcessing}
          secondary
          type="reset"
          width={100}
          onClick={handleCancel}
        >
          {copyText.cancelButtonLabel}
        </Button>
        <Button
          disabled={!canSubmit || props.isProcessing}
          marginLeft={theme.space_sm}
          primary
          type="button"
          width={100}
          onClick={handleSubmit}
        >
          {props.isProcessing ? <LoadingSpinner /> : copyText.submitButtonLabel}
        </Button>
      </Flex>
    </Flex>
  );
}

EditCUDRecommendationSettingsForm.INTERACTION_CANCEL_BUTTON_CLICKED =
  "EditCUDRecommendationSettingsForm.INTERACTION_CANCEL_BUTTON_CLICKED" as const;

EditCUDRecommendationSettingsForm.INTERACTION_SUBMIT_BUTTON_CLICKED =
  "EditCUDRecommendationSettingsForm.INTERACTION_SUBMIT_BUTTON_CLICKED_CREATE" as const;

type InteractionCancelButtonClicked = {
  type: typeof EditCUDRecommendationSettingsForm.INTERACTION_CANCEL_BUTTON_CLICKED;
};

type InteractionSubmitButtonClicked = {
  type: typeof EditCUDRecommendationSettingsForm.INTERACTION_SUBMIT_BUTTON_CLICKED;
  commitmentLength: GcpCommitmentDurationType;
  commitmentType: GcpCommitmentType;
};

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace EditCUDRecommendationSettingsForm {
  export type Interaction =
    | InteractionCancelButtonClicked
    | InteractionSubmitButtonClicked;
}

export default EditCUDRecommendationSettingsForm;
