import { createStructParam } from "@/lib/use-query-params";
import Modal from "@/ui-lib/components/Modal";
import { TableLegacy } from "@/ui-lib/components/Table";
import TextInput from "@/ui-lib/components/TextInput";
import { useTheme } from "@emotion/react";
import { faFileExport, faSearch } from "@fortawesome/free-solid-svg-icons";
import Button from "@ternary/api-lib/ui-lib/components/Button";
import Box from "@ternary/web-ui-lib/components/Box";
import Flex from "@ternary/web-ui-lib/components/Flex";
import Icon from "@ternary/web-ui-lib/components/Icon";
import Text from "@ternary/web-ui-lib/components/Text";
import { formatDate } from "@ternary/web-ui-lib/utils/dates";
import {
  formatCurrency,
  formatPercentage,
} from "@ternary/web-ui-lib/utils/formatNumber";
import prettyBytes from "pretty-bytes";
import React, { useMemo, useState } from "react";
import { CSVLink } from "react-csv";
import { Column, SortByFn } from "react-table";
import { useQueryParam, withDefault } from "use-query-params";
import { z } from "zod";
import copyText from "../../copyText";
import { AWSDatabaseInstance } from "../types";

type TableData = {
  cpuUtilization: number;
  databaseConnections: number;
  instanceId: string;
  instanceType: string;
  networkBytes: number;
  productMemoryBytes: number;
  productVCPU: number;
  ramUtilization: number;
  totalCost: number;
  usedDiskBytes: number;
};

type Props = {
  instances: AWSDatabaseInstance[];
  isLoading: boolean;
  onClose: () => void;
};

const sortRuleStruct = z.object({
  id: z.string(),
  desc: z.boolean(),
});

export default function AWSDatabaseInstanceTable(props: Props) {
  const theme = useTheme();

  const [sortRule, setSortRule] = useQueryParam(
    "instance_table_sort",
    withDefault(createStructParam(sortRuleStruct), {
      desc: true,
      id: "totalCost",
    })
  );

  const [searchText, setSearchText] = useState("");

  const tableData = useMemo(
    () => getTableData(props.instances),
    [props.instances]
  );

  const filteredData = useMemo(() => {
    if (searchText.length === 0) return tableData;

    return tableData.filter((bucket) => {
      const str = searchText.toLowerCase();

      const instanceId = bucket.instanceId.toLowerCase();
      const instanceType = bucket.instanceType.toLowerCase();

      return instanceId.includes(str) || instanceType.includes(str);
    });
  }, [tableData, searchText]);

  const columns = useMemo(
    (): Column<TableData>[] => [
      {
        accessor: "instanceId",
        align: "left",
        Cell: ({ value: instanceId }) => (
          <>{instanceId || copyText.awsDatabaseTableNull}</>
        ),
        Header: copyText.awsDatabaseTableInstanceHeader_instanceId,
        truncate: true,
        sortType: stringSort,
        width: 220,
      },
      {
        accessor: "totalCost",
        align: "center",
        Cell: ({ value: totalCost }) => (
          <>{formatCurrency({ number: totalCost })}</>
        ),
        Header: copyText.awsDatabaseTableInstanceHeader_totalCost,
        sortType: numberSort,
        width: 125,
      },
      {
        accessor: "instanceType",
        align: "center",
        Cell: ({ value: instanceType }) => (
          <>{instanceType || copyText.awsDatabaseTableNull}</>
        ),
        Header: copyText.awsDatabaseTableInstanceHeader_instanceType,
        sortType: stringSort,
        width: 125,
      },
      {
        accessor: "productVCPU",
        align: "center",
        Header: copyText.awsDatabaseTableInstanceHeader_productVCPU,
        sortType: numberSort,
        width: 125,
      },
      {
        accessor: "cpuUtilization",
        align: "center",
        Cell: ({ value: cpuUtilization }) => (
          <>{formatPercentage(cpuUtilization / 100)}</>
        ),
        Header: copyText.awsDatabaseTableInstanceHeader_cpuUtilization,
        sortType: numberSort,
        width: 125,
      },
      {
        accessor: "productMemoryBytes",
        align: "center",
        Cell: ({ value: productMemoryBytes }) => (
          <>{prettyBytes(productMemoryBytes, { binary: true })}</>
        ),
        Header: copyText.awsDatabaseTableInstanceHeader_productMemoryBytes,
        sortType: numberSort,
        width: 125,
      },
      {
        accessor: "ramUtilization",
        align: "center",
        Cell: ({ value: ramUtilization }) => (
          <>{formatPercentage(ramUtilization / 100)}</>
        ),
        Header: copyText.awsDatabaseTableInstanceHeader_ramUtilization,
        sortType: numberSort,
        width: 125,
      },
      {
        accessor: "networkBytes",
        align: "center",
        Cell: ({ value: networkBytes }) => <>{prettyBytes(networkBytes)}</>,
        Header: copyText.awsDatabaseTableInstanceHeader_networkBytes,
        sortType: numberSort,
        width: 125,
      },
      {
        accessor: "usedDiskBytes",
        align: "center",
        Cell: ({ value: usedDiskBytes }) => <>{prettyBytes(usedDiskBytes)}</>,
        Header: copyText.awsDatabaseTableInstanceHeader_usedDiskBytes,
        sortType: numberSort,
        width: 125,
      },
      {
        accessor: "databaseConnections",
        align: "center",
        Header: copyText.awsDatabaseTableInstanceHeader_databaseConnections,
        sortType: numberSort,
        width: 125,
      },
    ],
    []
  );

  return (
    <Modal isOpen showCloseButton onClose={props.onClose} minWidth={1100}>
      <Modal.Header>
        <Flex
          justifyContent="space-between"
          marginLeft={theme.space_sm}
          width="100%"
        >
          <Text fontSize={theme.h4_fontSize}>
            {copyText.awsDatabaseTableInstanceTitle}
          </Text>

          <Flex alignItems="center">
            <Box width={250} marginRight={theme.space_lg}>
              <TextInput
                disabled={!props.instances.length}
                iconEnd={
                  <Icon color={theme.text_color_secondary} icon={faSearch} />
                }
                placeholder={copyText.searchInputPlaceholder}
                size="medium"
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
              />
            </Box>

            <CSVLink
              data={flattenDataToCSV(filteredData)}
              filename={`Instances-${formatDate(new Date(), "MM-dd-yyyy")}`}
            >
              <Button
                iconStart={<Icon color="inherit" icon={faFileExport} />}
                primary
                size="small"
                marginRight={theme.space_md}
              >
                {copyText.exportButtonLabel}
              </Button>
            </CSVLink>
          </Flex>
        </Flex>
      </Modal.Header>
      <Modal.Body>
        <TableLegacy
          columns={columns}
          data={filteredData}
          initialState={{ sortBy: [sortRule] }}
          isLoading={props.isLoading}
          showPagination
          sortable
          onChangeSortBy={([sortRule]) => setSortRule(sortRule, "replaceIn")}
        />
      </Modal.Body>
    </Modal>
  );
}

function getTableData(instances: AWSDatabaseInstance[]): TableData[] {
  return instances.map((instance) => ({
    cpuUtilization: instance.cpuUtilization,
    databaseConnections: instance.databaseConnections,
    instanceId: instance.instanceId,
    instanceType: instance.instanceType,
    networkBytes: instance.networkBytes,
    productMemoryBytes: instance.productMemoryBytes,
    productVCPU: instance.productVCPU,
    ramUtilization: instance.ramUtilization,
    totalCost: instance.totalCost,
    usedDiskBytes: instance.usedDiskBytes,
  }));
}

function isTableDataKey(key: string): key is keyof TableData {
  const tableDataKey: (keyof TableData)[] = [
    "cpuUtilization",
    "databaseConnections",
    "instanceId",
    "instanceType",
    "networkBytes",
    "productMemoryBytes",
    "productVCPU",
    "ramUtilization",
    "totalCost",
    "usedDiskBytes",
  ];

  return tableDataKey.includes(key as keyof TableData);
}

const numberSort: SortByFn<TableData> = (rowA, rowB, columnID, desc) => {
  if (!isTableDataKey(columnID)) return 0;

  const a = rowA.original[columnID];
  const b = rowB.original[columnID];

  if (typeof a !== "number" && typeof b !== "number") return 0;
  if (typeof a !== "number") return desc ? -1 : 1;
  if (typeof b !== "number") return desc ? 1 : -1;

  return a === b ? 0 : a < b ? -1 : 1;
};

const stringSort: SortByFn<TableData> = (rowA, rowB, columnID, desc) => {
  if (!isTableDataKey(columnID)) return 0;

  const a = rowA.original[columnID];
  const b = rowB.original[columnID];

  if (typeof a !== "string" && typeof b !== "string") return 0;
  if (typeof a !== "string" || a === "") return desc ? -1 : 1;
  if (typeof b !== "string" || b === "") return desc ? 1 : -1;

  if (a.toLowerCase() === b.toLowerCase()) return 0;
  return a.toLowerCase() < b.toLowerCase() ? -1 : 1;
};

function flattenDataToCSV(data: TableData[]) {
  if (!data.length) {
    return [];
  }

  return data.map((datum) => ({
    cpuUtilization: datum.cpuUtilization,
    databaseConnections: datum.databaseConnections,
    instanceId: datum.instanceId,
    instanceType: datum.instanceType,
    networkBytes: datum.networkBytes,
    productMemoryBytes: datum.productMemoryBytes,
    productVCPU: datum.productVCPU,
    ramUtilization: datum.ramUtilization,
    totalCost: datum.totalCost,
    usedDiskBytes: datum.usedDiskBytes,
  }));
}
